import { PlaceholderNode } from '@/src/modules/RulesModule/interfaces';

import { generateUniqueId } from './generateUniqueId';

export const createPlaceholderNode = (parent: PlaceholderNode): PlaceholderNode => {
  const newPlaceholderId = generateUniqueId();

  return {
    id: newPlaceholderId,
    data: {
      title_detail: {
        title_text: 'placeholder',
        icon: 'icon',
      },
      data: {
        description: {
          html: 'placeholder',
        },
        configuration_name: 'placeholder',
        configuration_parameters: {
          tag_id: '',
        },
      },
      type: 'placeholder',
      id: newPlaceholderId,
      parent: parent.id,
      children: [],
      is_root: false,
      is_leaf: true,
      is_valid: true,
      is_deleted: false,
      errors: {},
      reactflowType: 'placeholder',
    },
    position: {
      x: +parent.position.x,
      y: +parent.position.y + 150,
    },
    type: 'placeholder',
  };
};
