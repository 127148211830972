import Parser from 'html-react-parser';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Spinner } from '@/lib/v2/components';
import { Alert } from '@/lib/v2/components/Alert';
import { Input } from '@/lib/v2/components/Input';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Text } from '@/lib/v2/components/Text';

import CreateSelectableItem from './SubComponents/CreateSelectableItem';
import SelectableItem, { ISelectableItem } from './SubComponents/SelectableItem';

export interface ISelectable {
  className?: string;
  isAcceptDisabled?: boolean;
  wordings: {
    title?: string;
    reminder?: string;
    subtitle?: string;
    resumeSelected?: string;
    search?: {
      label?: string;
      placeholder?: string;
    };
    actions?: {
      accept?: string;
      cancel?: string;
    };
  };
  items: ISelectableItem[];
  onAccept: (e: SyntheticEvent<HTMLButtonElement>, selected: any[], unselected: any[]) => void;
  onClose: (value: boolean) => void;
  limitSelection?: number;
  withCreateItem?: boolean;
  labelInputCreate?: string;
  onCreate?: (toCreate: string) => Promise<void>;
}

interface ISelectedDictionary {
  [key: string]: ISelectableItem;
}

const Selectable = (props: ISelectable) => {
  const {
    wordings,
    items,
    limitSelection = 0,
    className = '',
    isAcceptDisabled,
    withCreateItem = false,
    labelInputCreate = 'Create item',
    onCreate,
  } = props;

  const { t } = useTranslation();
  const [selected, setSelected] = useState<ISelectedDictionary>({});
  const [unselected, setUnselected] = useState<ISelectedDictionary>({});
  const [searchText, setSearchText] = useState('');
  const countSelected = useMemo(() => {
    const withDisabled = Object.values(items).filter((item) => item.disabled && item.checked);
    return Object.keys(selected).length - withDisabled.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const list = useMemo(() => {
    if (!searchText) return items;
    return items.filter((item) =>
      item.label?.toLowerCase()?.includes(searchText?.toLocaleLowerCase())
    );
  }, [items, searchText]);

  const handleItemClick = useCallback(
    (e: SyntheticEvent<HTMLDivElement>, item: ISelectableItem) => {
      if (limitSelection > 0 && countSelected === limitSelection && !selected[item.id]) return;

      if (item.checked) {
        if (!selected[item.id]) {
          const newSelected = {
            ...selected,
            [item.id]: item,
          };

          setSelected(newSelected);
          setUnselected((prevUnselected) => {
            const { [item.id]: _, ...newUnselected } = prevUnselected;
            return newUnselected;
          });
        }
      } else {
        if (selected[item.id]) {
          const { [item.id]: _, ...newSelected } = { ...selected };
          setSelected(newSelected);
        }
        setUnselected((prevUnselected) => ({ ...prevUnselected, [item.id]: item }));
      }
    },
    [selected]
  );

  useEffect(() => {
    const areChecked: ISelectableItem[] = items?.filter((items) => items.checked) ?? [];
    const result: ISelectedDictionary = {};

    if (areChecked.length === 0) return;

    for (const item of areChecked) {
      result[item.id] = item;
    }

    setSelected(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listMapper = useCallback(
    (item: ISelectableItem) => {
      let isDisabled;
      if (limitSelection > 0 && limitSelection === countSelected)
        isDisabled = selected?.[item.id] && !item.disabled ? false : true;
      else isDisabled = item.disabled;

      return (
        <SelectableItem
          {...item}
          key={item.id}
          checked={selected?.[item.id] ? true : item.checked}
          disabled={isDisabled}
          indeterminate={item.indeterminate}
          onClick={handleItemClick}
        />
      );
    },
    [countSelected, handleItemClick, limitSelection, selected]
  );

  const clearAllInputs = () => {
    setSearchText('');
  };

  const [isCreating, setIsCreating] = useState(false);

  const handleOnItemCreated = useCallback(
    async (value: string) => {
      setIsCreating(true);
      try {
        await onCreate?.(value);
        clearAllInputs();
      } catch (error) {
        console.error(error);
      } finally {
        setIsCreating(false);
      }
    },
    [onCreate]
  );

  const [existInList, setExistInList] = useState(false);

  const normalizeText = (text: string) => text?.replace(/\s+/g, '').toLowerCase();
  useEffect(() => {
    setExistInList(list.some((item) => normalizeText(item.label) === normalizeText(searchText)));
  }, [list, searchText]);

  return (
    <Flex column noGrow className={`max-h-[80vh] w-[450px] ${className}`}>
      {wordings?.title && (
        <Text className="mb-2 w-full" variant="sub-headline">
          {wordings?.title}
        </Text>
      )}

      <hr className={!wordings?.reminder ? 'mb-4 w-full' : 'w-full'} />

      {wordings?.reminder && (
        <Alert className={'mb-6 mt-4 w-full'} description={wordings.reminder} type="info" />
      )}

      <Input
        label={wordings?.search?.label}
        placeHolder={wordings?.search?.placeholder}
        value={searchText}
        onChange={(e) => setSearchText(e?.target?.value ?? '')}
      />

      {searchText && !existInList && withCreateItem && (
        <Flex alignment="start">
          <CreateSelectableItem
            disabled={isCreating}
            title={labelInputCreate}
            value={searchText}
            onItemCreated={handleOnItemCreated}
          />
          {isCreating && (
            <div className="scale-50">
              {' '}
              <Spinner withoutOverlay />
            </div>
          )}
        </Flex>
      )}

      <Flex column className={'my-4 h-full max-h-[250px] overflow-y-auto py-2'}>
        {!list.length && !searchText && <Spinner withoutOverlay />}
        {!list.length && searchText && (
          <span className="block truncate py-2 text-center text-[#A7B1CC]">
            <>{t('IMPORT_MAIN.empty_results')}</>
          </span>
        )}
        {Array.isArray(list) && list.map(listMapper)}
      </Flex>

      <hr className="w-full" />

      {wordings?.resumeSelected && (
        <Flex noGrow alignment="end" className="my-4 w-full">
          <Text variant="text">
            {Parser(
              wordings.resumeSelected
                ?.replace(
                  '{0}',
                  `<strong className="text-emblueBlue-dark">${countSelected.toString()}</strong>`
                )
                ?.replace(
                  '{1}',
                  `<strong className="text-emblueBlue-dark">${limitSelection?.toString()}</strong>`
                )
            )}
          </Text>
        </Flex>
      )}

      <Flex noGrow withGap alignment="end" className="mt-2 w-full">
        {wordings?.actions?.cancel && (
          <Button outline onClick={() => props.onClose(false)}>
            {wordings.actions.cancel}
          </Button>
        )}
        <Button
          disabled={isAcceptDisabled}
          onClick={(e) => props.onAccept(e, Object.values(selected), Object.values(unselected))}
        >
          {wordings?.actions?.accept}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Selectable;
