import Cookies from 'universal-cookie';

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setCookie(cookieName: string, cookieValue: string) {
  const cookies = new Cookies();
  cookies.set(cookieName, cookieValue, { domain: '.embluemail.com' });
}

export function parseJwt(token: string) {
  if (!token) return;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// utils/cookieUtils.ts

/**
 * Set a cookie with a specified name, value, and expiration in days.
 * @param name - Name of the cookie.
 * @param value - Value of the cookie.
 * @param days - Number of days until the cookie expires.
 */
export function setCookieWithExpiry(name: string, value: string, days: number): void {
  const cookies = new Cookies();
  const options = {
    domain: '.embluemail.com',
    path: '/',
    maxAge: days * 24 * 60 * 60, // maxAge in seconds
  };
  cookies.set(name, value, options);
}

/**
 * Get a cookie by its name.
 * @param name - Name of the cookie to retrieve.
 * @returns - Value of the cookie or null if not found.
 */
function getCookieValue(name: string): string | null {
  const cookies = new Cookies();
  return cookies.get(name) || null;
}

// Exported utility functions

/**
 * Set a cookie with a specified name and value that expires in 30 days.
 * @param name - Name of the cookie.
 * @param value - Value of the cookie.
 */
export function setCookieFor30Days(name: string, value: string): void {
  setCookieWithExpiry(name, value, 30);
}

/**
 * Set a cookie with a specified name and value that expires in 1 day.
 * @param name - Name of the cookie.
 * @param value - Value of the cookie.
 */
export function setCookieFor1Day(name: string, value: string): void {
  setCookieWithExpiry(name, value, 1);
}

/**
 * Get a cookie by its name.
 * @param name - Name of the cookie to retrieve.
 * @returns - Value of the cookie or null if not found.
 */
export function readCookie(name: string): string | null {
  return getCookieValue(name);
}
