import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { IconSvg } from '@/lib/v2/components/IconSvg';
import { BlockedIcon, Warning2Icon } from '@/lib/v2/icons/outline';
import { Check } from '@/lib/v2/icons/solid/Check';

import { Option } from './Select';

import { SenderStatus } from '@/modules/SendersModule/interfaces/Senders';

export interface SelectOptionProps {
  option: Option;
}

const IconSelectStatus = ({ status }: { status: SenderStatus }) => {
  const statusIcon = {
    check: <IconSvg svgComponent={<Check />} />,
    noCheck: <IconSvg svgComponent={<BlockedIcon />} />,
    warning: <IconSvg svgComponent={<Warning2Icon />} />,
  };
  return statusIcon[status] || [];
};

const SelectOption = ({ option }: SelectOptionProps) => {
  return (
    <Listbox.Option
      key={option.id}
      className={({ active, disabled }) =>
        classNames('relative cursor-default select-none py-2 pl-2 pr-5', {
          'text-white bg-emblue-primary': active,
          'text-gray-900': !active,
          '!text-emblue-disabled': disabled,
        })
      }
      disabled={option.disabled}
      value={option}
    >
      {({ selected, active }) => {
        return (
          <>
            <div className="flex items-center gap-2">
              {option.iconStatus && <IconSelectStatus status={option.iconStatus} />}
              <span
                className={`block truncate text-left ${selected ? 'font-semibold' : 'font-normal'}`}
              >
                {option.name}
              </span>
            </div>
            {selected ? (
              <span
                className={classNames(
                  active ? 'text-white' : 'text-emblue-primary',
                  'absolute inset-y-0 right-0 flex items-center pr-1'
                )}
              >
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            ) : null}
          </>
        );
      }}
    </Listbox.Option>
  );
};

export default SelectOption;
