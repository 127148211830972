import classNames from 'classnames';
import {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  memo,
  MouseEventHandler,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
} from 'react';

import { withController } from '@/lib/v2/HOCs/WithController';

import './InputTextArea.tailwind.css';

interface InputTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /** controlled input text area value */
  value?: string;
  /** default value input text area not controlled */
  defaultValue?: string;
  fullWidth?: boolean;
  /** name of input */
  name?: string;
  /** name label of input */
  label?: string;
  /** HTML id attribute */
  id?: string;
  /** show input text area placeholder message */
  placeHolder?: string;
  /** to indicate that there is a validation error */
  error?: boolean;
  /** validation information or error message  */
  message?: string;
  /** rows to display in the text area */
  rows?: number;
  /** cols to display in the text area */
  cols?: number;
  /** callback function onClick of input */
  onClick?: MouseEventHandler<HTMLTextAreaElement>;
  /** callback function onChange of input */
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  dataTestid?: string;
  focusClick?: boolean;
  /** Show mark when is required field */
  isRequired?: boolean;
  /** show skeleton */
  isLoading?: boolean;
}

const InputTextArea = forwardRef<HTMLTextAreaElement, InputTextAreaProps>(
  (
    {
      value,
      defaultValue,
      name,
      label,
      id,
      rows = 4,
      cols,
      onClick,
      onChange,
      placeHolder,
      error,
      message,
      focusClick,
      isRequired,
      fullWidth,
      isLoading,
      ...restOfProps
    }: InputTextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const classesContainer = classNames({
      'w-full': fullWidth,
    });
    const classes = classNames('eb-text-area', {
      'eb-text-area--error': error,
      'w-full': fullWidth,
    });

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (textareaRef.current && focusClick) {
        const textarea = textareaRef.current;
        textarea.focus();
        textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      }
    }, [focusClick]);

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(textareaRef.current);
        } else {
          ref.current = textareaRef.current;
        }
      }
    }, [ref]);

    if (isLoading) {
      return (
        <div className="flex w-full animate-pulse flex-col" data-testid="select-component">
          <div className="mt-1.5 h-2.5 w-28 rounded-full bg-gray-200"></div>
          <div className="my-1 h-[100px] w-full rounded bg-gray-200"></div>
        </div>
      );
    }

    return (
      <div className={classesContainer} data-testid="input-textarea-component" id={id}>
        {label && (
          <label className="label" htmlFor={name}>
            {label} {isRequired && <span className="text-red-500"> * </span>}
          </label>
        )}
        <div className="my-1">
          <textarea
            ref={textareaRef}
            className={classes}
            cols={cols}
            defaultValue={defaultValue}
            id={id}
            name={name}
            placeholder={placeHolder}
            rows={rows}
            value={value}
            onChange={onChange}
            onClick={onClick}
            {...restOfProps}
          />
        </div>
        {error && (
          <p className={'message-error'} id={`${name}-message`}>
            {message}
          </p>
        )}
      </div>
    );
  }
);

export default memo(withController(InputTextArea));
