import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, Flex } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';
import { ContactsIcon } from '@/lib/v2/icons/solid';

import { HappinessTableContainer } from './components/HappinessTableContainer';

import { useHappinessStateContext } from '@/modules/ContactsModule/contexts/HappinessContext';

export const HappinessContactsInvolved = () => {
  const { t } = useTranslation();
  const [subtitleCountMessage, setSubtitleCountMessage] = useState<ReactNode>();
  const { npsTotalContactsCount } = useHappinessStateContext();

  const tableTitle = useMemo(() => <>({npsTotalContactsCount})</>, [npsTotalContactsCount]);

  return (
    <Card>
      <CardSection noPadding>
        <Flex column noGrow itemAlignment="start">
          <SectionHeader
            icon={<ContactsIcon />}
            justifyContentActionsButtons="end"
            subtitle={subtitleCountMessage}
            title={t('HAPPINESS_DASHBOARD.contactsTableTitle')}
            titleDetail={tableTitle}
          />
          <div className="h-full w-full">
            <HappinessTableContainer setCountMessage={setSubtitleCountMessage} />
          </div>
        </Flex>
      </CardSection>
    </Card>
  );
};
