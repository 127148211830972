import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { If, Text } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/Badges';

import { numberToCurrency } from '@/src/utils/currency';

type PlanPriceComponentProps = {
  proratedPlanPrice?: number;
  planPrice: number;
  planNameSelected?: string;
  monthSuffixText: string;
  isAnnuallySelected: boolean;
};

const useProratedPrice = (isAnnuallySelected: boolean, proratedPrice?: number): boolean => {
  return !isAnnuallySelected && !!proratedPrice;
};

export const PlanPriceComponent = memo(
  ({
    proratedPlanPrice,
    planPrice,
    planNameSelected,
    monthSuffixText,
    isAnnuallySelected,
  }: PlanPriceComponentProps) => {
    const { t } = useTranslation();
    const useProratedPriceVar = useProratedPrice(isAnnuallySelected, proratedPlanPrice);
    const boldPrice = useProratedPriceVar ? (proratedPlanPrice as number) : planPrice;
    return (
      <>
        <div className="flex flex-col">
          <Text fontWeight="medium">
            {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.planPrice', {
              planName: planNameSelected,
            })}
          </Text>
        </div>
        <div className="flex flex-col items-end">
          <Text fontWeight="medium">
            USD {numberToCurrency(boldPrice)}
            {useProratedPriceVar ? undefined : monthSuffixText}
          </Text>
          <If condition={useProratedPriceVar}>
            <Text variant="text">
              {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.nextMonthPrice')} USD{' '}
              {numberToCurrency(planPrice)}
              {monthSuffixText}
            </Text>
          </If>
        </div>
      </>
    );
  }
);

type EmailPriceComponentProps = {
  proratedEmailPrice?: number;
  emailAmount: number;
  emailPrice: number;
  priceIncreaseEmail: number;
  monthSuffixText: string;
  isAnnuallySelected: boolean;
};

export const EmailPriceComponent = memo(
  ({
    proratedEmailPrice,
    emailPrice,
    emailAmount,
    priceIncreaseEmail,
    monthSuffixText,
    isAnnuallySelected,
  }: EmailPriceComponentProps) => {
    const { t } = useTranslation();
    const useProratedPriceVar = useProratedPrice(isAnnuallySelected, proratedEmailPrice);
    const boldPrice = useProratedPriceVar ? (proratedEmailPrice as number) : emailPrice;
    return (
      <>
        <div className="flex flex-col">
          <Text fontWeight="medium">{t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.emails')}</Text>
          <Text variant="text">
            {numberToCurrency(emailAmount, true)} emails {monthSuffixText}
          </Text>
        </div>
        <div className="flex flex-col items-end">
          <Text fontWeight="medium">
            USD {numberToCurrency(boldPrice)}
            {useProratedPriceVar ? undefined : monthSuffixText}
          </Text>
          <If condition={priceIncreaseEmail !== 0}>
            <Text variant="text">
              {`${priceIncreaseEmail > 0 ? '+' : '-'} USD ${numberToCurrency(
                Math.abs(priceIncreaseEmail)
              )}`}
            </Text>
          </If>
          <If condition={useProratedPriceVar}>
            <Text variant="text">
              {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.nextMonthPrice')} USD{' '}
              {numberToCurrency(emailPrice)}
              {monthSuffixText}
            </Text>
          </If>
          {/* Only 1 if is true at the same time */}
        </div>
      </>
    );
  }
);

type SmsPriceComponentProps = {
  proratedSmsPrice?: number;
  smsAmount: number;
  smsPrice: number;
  priceIncreaseSms: number;
  monthSuffixText: string;
  isAnnuallySelected: boolean;
};

export const SmsPriceComponent = memo(
  ({
    proratedSmsPrice,
    smsPrice,
    smsAmount,
    priceIncreaseSms,
    monthSuffixText,
    isAnnuallySelected,
  }: SmsPriceComponentProps) => {
    const { t } = useTranslation();
    const useProratedPriceVar = useProratedPrice(isAnnuallySelected, proratedSmsPrice);
    const boldPrice = useProratedPriceVar ? (proratedSmsPrice as number) : smsPrice;
    return (
      <>
        <div className="flex flex-col">
          <Text fontWeight="medium">{t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.sms')}</Text>
          <Text variant="text">
            {numberToCurrency(smsAmount, true)} SMS {monthSuffixText}
          </Text>
        </div>
        <div className="flex flex-col items-end">
          <Text fontWeight="medium">
            USD {numberToCurrency(boldPrice)}
            {useProratedPriceVar ? undefined : monthSuffixText}
          </Text>
          <If condition={priceIncreaseSms !== 0}>
            <Text variant="text">{`${priceIncreaseSms > 0 ? '+' : '-'} USD ${numberToCurrency(
              Math.abs(priceIncreaseSms)
            )}`}</Text>
          </If>
          <If condition={useProratedPriceVar}>
            <Text variant="text">
              {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.nextMonthPrice')} USD{' '}
              {numberToCurrency(smsPrice)}
              {monthSuffixText}
            </Text>
          </If>
          {/* Only 1 if is true at the same time */}
        </div>
      </>
    );
  }
);

type OnsitePriceComponentProps = {
  proratedOnsitePrice?: number;
  onsiteAmount: number;
  onsitePrice: number;
  priceIncreaseOnsite: number;
  monthSuffixText: string;
  isAnnuallySelected: boolean;
};

export const OnsitePriceComponent = memo(
  ({
    proratedOnsitePrice,
    onsitePrice,
    onsiteAmount,
    priceIncreaseOnsite,
    monthSuffixText,
    isAnnuallySelected,
  }: OnsitePriceComponentProps) => {
    const { t } = useTranslation();
    const useProratedPriceVar = useProratedPrice(isAnnuallySelected, proratedOnsitePrice);
    const boldPrice = useProratedPriceVar ? (proratedOnsitePrice as number) : onsitePrice;
    return (
      <>
        <div className="flex flex-col">
          <Text fontWeight="medium">{t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.onsite')}</Text>
          <Text variant="text">
            {numberToCurrency(onsiteAmount, true)} Onsite {monthSuffixText}
          </Text>
        </div>
        <div className="flex flex-col items-end">
          <Text fontWeight="medium">
            USD {numberToCurrency(boldPrice)}
            {useProratedPriceVar ? undefined : monthSuffixText}
          </Text>
          <If condition={priceIncreaseOnsite !== 0}>
            <Text variant="text">{`${priceIncreaseOnsite > 0 ? '+' : '-'} USD ${numberToCurrency(
              Math.abs(priceIncreaseOnsite)
            )}`}</Text>
          </If>
          <If condition={useProratedPriceVar}>
            <Text variant="text">
              {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.nextMonthPrice')} USD{' '}
              {numberToCurrency(onsitePrice)}
              {monthSuffixText}
            </Text>
          </If>
          {/* Only 1 if is true at the same time */}
        </div>
      </>
    );
  }
);

type TotalPriceComponentProps = {
  proratedTotalPrice?: number;
  monthlyTotal: number;
  annuallyTotal: number;
  monthSuffixText: string;
  isAnnuallySelected: boolean;
  isFreemiumPlan: boolean;
  prorationAmount?: number;
  prorationAmountIsLoading?: boolean;
};

export const TotalPriceComponent = memo(
  ({
    proratedTotalPrice,
    monthlyTotal,
    annuallyTotal,
    monthSuffixText,
    isAnnuallySelected,
    isFreemiumPlan,
    prorationAmount,
    prorationAmountIsLoading,
  }: TotalPriceComponentProps) => {
    const { t } = useTranslation();
    const useProratedPriceVar = useProratedPrice(isAnnuallySelected, proratedTotalPrice);
    const monthlyBoldPrice = useProratedPriceVar ? (proratedTotalPrice as number) : monthlyTotal;
    return (
      <>
        <div className="flex justify-between gap-2">
          <div className="h-[26px]">
            <If condition={isAnnuallySelected && !isFreemiumPlan}>
              <BadgeText
                text={`${t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.payAnnually')} -20%`}
                variantColor="success"
              />
            </If>
          </div>
          <div className="flex flex-col">
            <Text alignment="right" variant="title-1">
              USD {numberToCurrency(monthlyBoldPrice)}
              {useProratedPriceVar ? undefined : monthSuffixText}
            </Text>
            <If condition={useProratedPriceVar}>
              <Text alignment="right" variant="text">
                {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.nextMonthPrice')} USD{' '}
                {numberToCurrency(monthlyTotal)}
                {monthSuffixText}
              </Text>
            </If>
            <If condition={isAnnuallySelected && !isFreemiumPlan}>
              <Text alignment="right" fontWeight="medium">
                {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.annuallyTotal', {
                  amount: numberToCurrency(annuallyTotal),
                })}
              </Text>
            </If>
            <If condition={prorationAmountIsLoading}>
              <div className="h-3 w-full animate-pulse rounded-full bg-gray-200"></div>
            </If>
            <If condition={prorationAmount !== undefined}>
              <Text alignment="right" fontWeight="medium" variant="text">
                {t('TotalPriceComponent.differenceToPay')} USD{' '}
                {numberToCurrency(prorationAmount ?? 0)}
              </Text>
            </If>
          </div>
        </div>
      </>
    );
  }
);
