import classNames from 'classnames';
import { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

interface CollapseProps {
  isOpened?: boolean;
  children?: ReactNode;
  id?: string;
}

const Collapse = ({ isOpened = false, children, id }: CollapseProps) => {
  const classes = classNames('w-full transition-all duration-300 overflow-hidden');
  const contentElement = useRef<HTMLDivElement>(null);
  const containerChildrenRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>('0px');

  useEffect(() => {
    if (!isOpened) contentElement.current?.classList.remove('overflow-visible');
    const newHeight = isOpened ? `${containerChildrenRef.current?.scrollHeight}px` : '0px';

    height !== newHeight && setHeight(newHeight);
  }, [children, height, isOpened]);

  const handleTransitionEnd = useCallback(() => {
    if (isOpened) {
      contentElement.current?.classList.add('overflow-visible');
    }
  }, [isOpened]);

  return (
    <div
      ref={contentElement}
      className={classes}
      data-testid="collapse-component"
      id={id}
      style={{ height: height }}
      onTransitionEnd={handleTransitionEnd}
    >
      <div ref={containerChildrenRef}>{children}</div>
    </div>
  );
};

export default memo(Collapse);
