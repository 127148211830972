import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardSection, Flex, IconSvg, Text } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/Badges';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';
import { ActionMasterIcon, EditPencilIcon } from '@/lib/v2/icons/outline';

import {
  atomActionInputModalIsOpen,
  atomCreateActionsModalIsOpen,
  atomCreateRules,
} from '@/src/modules/CampaignsModule/atoms/createActionsAtom';
import { CreateActionsModal } from '@/src/modules/CampaignsModule/components/CampaignActions/CreateActionsModal';
import { useHandleEvents } from '@/src/modules/CampaignsModule/components/RecurrentActions/ActionsTable/hooks/useHandleEvents';
import { TableContainer } from '@/src/modules/CampaignsModule/components/RecurrentActions/TableContainer';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { useRecurrentActionsData } from '@/src/modules/CampaignsModule/hooks/useRecurrentActionsData';
import { useRecurrentResetAtomOnUnmount } from '@/src/modules/CampaignsModule/hooks/useRecurrentResetAtomOnUnmount';
import {
  ERulesActionType,
  ITableCampaignActions,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ELanguageMap, formattedNumber } from '@/src/modules/CampaignsModule/utils/formattedNumber';

import { atomDetailCampaignRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomActionMaster } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

const CampaignRecurrentActionsMain = () => {
  const { t, i18n } = useTranslation();

  const [actionCreationsModalIsOpen, setActionCreationsModalIsOpen] = useAtom(
    atomCreateActionsModalIsOpen
  );
  const setCreateRules = useSetAtom(atomCreateRules);

  const [isMount, setIsMount] = useState(false);

  const { resetAtomActionsList } = useRecurrentResetAtomOnUnmount();

  const { actionsList, loadingProcess, totalsActionsList, filtersCount, isActionActive } =
    useRecurrentActionsData();

  const actionMaster = useAtomValue(atomActionMaster);

  useEffect(() => {
    setIsMount(true);
  }, [isMount]);

  useEffect(() => {
    return () => {
      resetAtomActionsList();
    };
  }, []);

  const handleCloseActionCreationsModal = useCallback(() => {
    setActionCreationsModalIsOpen(false);
    setCreateRules(ERulesActionType.ALL);
  }, [setActionCreationsModalIsOpen, setCreateRules]);

  const { name } = useAtomValue(atomDetailCampaignRecurrent);
  const navigate = useNavigate();

  const { handleRowClick } = useHandleEvents();

  return (
    <>
      <CreateActionsModal
        isOpen={actionCreationsModalIsOpen}
        onClose={handleCloseActionCreationsModal}
      />
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <Button
                  iconLeft={<IconSvg strokeColor="white" svgComponent={<EditPencilIcon />} />}
                  id="edit-recurrent-button"
                  size="small"
                  onClick={() => handleRowClick(actionMaster as ITableCampaignActions)}
                >
                  {t('RECURRENT_MESSAGE.edit')}
                </Button>
              }
              backButton={true}
              icon={
                <IconSvg
                  fillColor="success"
                  height="20px"
                  svgComponent={<ActionMasterIcon />}
                  width="20px"
                />
              }
              subtitle={
                <SectionHeaderSubtitle
                  countFilters={filtersCount}
                  countRecordsPartial={totalsActionsList.partial}
                  dataList={actionsList}
                  i18nKey="CAMPAIGN_ACTIONS_MAIN.search"
                  loading={loadingProcess}
                />
              }
              title={name}
              titleDetail={
                <>
                  {actionsList?.length && !loadingProcess && isMount ? (
                    <Text
                      as="span"
                      className="mr-1"
                      color="primary"
                      fontWeight="medium"
                      id="total-count-actions"
                    >
                      {`${
                        totalsActionsList.total &&
                        ` (${
                          formattedNumber(
                            totalsActionsList.total,
                            i18n.language as keyof typeof ELanguageMap
                          ) || 0
                        })`
                      }`}
                    </Text>
                  ) : (
                    ' (0) '
                  )}
                  <BadgeText
                    text={
                      isActionActive
                        ? t('CAMPAIGNS_MAIN.STATES.active')
                        : t('CAMPAIGNS_MAIN.STATES.inactive')
                    }
                    variantColor={isActionActive ? 'success' : 'inactive'}
                  />
                </>
              }
              onBack={() => navigate('/v2/campaigns/recurrent')}
            />
            <Flex column withGap gapSize="medium">
              <TableContainer />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </>
  );
};

export default CampaignRecurrentActionsMain;
