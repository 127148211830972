import { useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Alert, Button, IconSvg, Iframe, Spinner } from '@/lib/v2/components';
import { ModalConfirmation } from '@/lib/v2/examples';
import { DeleteIcon } from '@/lib/v2/icons/solid';

import ErrorAlert from './ErrorAlert/ErrorAlert';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomOpenMessageModal } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { IContent } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IMessageDisplay {
  html: IContent;
  isLoading?: boolean;
  onDeleteMessage?: () => void;
  hasOverlay?: boolean;
  disabled?: boolean;
}

const MessageDisplay = ({
  html,
  isLoading,
  onDeleteMessage,
  hasOverlay = true,
  disabled = false,
}: IMessageDisplay) => {
  const { t } = useTranslation();
  const setLocation = useNavigate();
  const { campaignId, actionId } = useParams();
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const dragAndDrop = actionInfoEmail ? actionInfoEmail.content.editorVersion === 'beefree' : '';
  const editorRoute = dragAndDrop ? 'editor' : 'html-editor';

  const [openModal, setOpenModal] = useAtom(atomOpenMessageModal);

  const redirectLocation = useMemo(
    () => `/v2/campaigns/${Number(campaignId)}/email/${Number(actionId)}`,
    [campaignId, actionId]
  );

  const HTMLClean = useMemo(() => html.message.replace(/&nbsp;/g, ' '), [html.message]);

  const isError = html.errors.length > 0;

  return (
    <>
      {isLoading ? (
        <Spinner withoutOverlay />
      ) : (
        <div className="size-full">
          {isError && (
            <div className="flex flex-col gap-2 px-10 py-2">
              <ErrorAlert errors={html.errors || []} />
            </div>
          )}
          <div
            className={`relative grid ${
              isError ? 'min-h-[calc(100vh-245px)]' : 'min-h-[calc(100vh-186px)]'
            } place-items-center px-10 ${isError ? 'pb-6' : 'py-6'}`}
          >
            <div className="relative size-full">
              <Iframe withoutOverflow html={HTMLClean} id="message-display-iframe" title="html" />
              {hasOverlay && (
                <div className="group absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                  <div className="flex flex-col items-center gap-6">
                    <Button
                      fullWidth
                      secondary
                      standard
                      disabled={disabled}
                      id="preview-edit__button"
                      size="large"
                      onClick={() => setLocation(`${redirectLocation}/${editorRoute}`)}
                    >
                      {t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.edit`)}
                    </Button>
                    <Button
                      fullWidth
                      secondary
                      standard
                      id="preview__button"
                      size="large"
                      onClick={() => setLocation(`${redirectLocation}/preview`)}
                    >
                      {t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.preview`)}
                    </Button>
                  </div>

                  <div className="absolute right-8 top-8">
                    <Button
                      secondary
                      standard
                      disabled={disabled}
                      iconLeft={
                        <IconSvg
                          fillColor="gray-dark"
                          height="20"
                          svgComponent={<DeleteIcon />}
                          width="20"
                        />
                      }
                      id="preview-delete__button"
                      size="large"
                      onClick={() => setOpenModal(true)}
                    ></Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {openModal && (
        <ModalConfirmation
          isLoading={isLoading}
          isOpen={openModal}
          textCancelButton={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.cancel')}
          textConfirmButton={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.delete`)}
          title={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.modalConfirmation')}
          onClose={() => setOpenModal(false)}
          onConfirmButton={onDeleteMessage}
        />
      )}
    </>
  );
};

export default MessageDisplay;
