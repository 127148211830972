import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { atomDowngradeIsPending } from '@/src/modules/MyPlanModule/atoms/ManagePlan';

export const useDowngradeStatus = () => {
  const [downgradeStatus, , downgradeStatusIsLoading] = useEmblue(
    ServiceMethods.getDowngradeStatus
  );

  const [downgradeIsPending, setDowngradeIsPending] = useAtom(atomDowngradeIsPending);

  useEffect(() => {
    if (downgradeStatus?.length) {
      const isPending = downgradeStatus[0].status === 'pending';
      setDowngradeIsPending(isPending);
    }
  }, [downgradeStatus, setDowngradeIsPending]);

  return { downgradeIsPending, downgradeStatusIsLoading };
};
