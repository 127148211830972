import { t } from 'i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { UseFormSetError } from 'react-hook-form';

import { Option } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import {
  atomDetailCampaign,
  atomRefetchAllData,
  atomRefetchDetailData,
  atomRefetchStrategyList,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomModalTypeProcess } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  IDefaultFormToCompareProps,
  IFormInput,
  ModalType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IUseHandleEventsProps {
  setHasOtherStrategy: Dispatch<SetStateAction<boolean>>;
  setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  onClose: () => void;
  setError: UseFormSetError<IFormInput>;
  defaultFormToCompare: IDefaultFormToCompareProps | undefined;
}

export const useHandleEvents = ({
  setHasOtherStrategy,
  setDateRange,
  onClose,
  setError,
  defaultFormToCompare,
}: IUseHandleEventsProps) => {
  const { createCampaign, loading, duplicateCampaign } = useCampaign();
  const { fetchServiceValidName } = useValidNameResource();
  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const setRefetchDetailData = useSetAtom(atomRefetchDetailData);
  const setRefetchStrategyList = useSetAtom(atomRefetchStrategyList);
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);

  const handleChangeStrategy = useCallback(
    (value: Option | Option[]) => {
      const data = value as Option;
      setHasOtherStrategy(data?.value === 'other');
    },
    [setHasOtherStrategy]
  );

  const handleCloseModal = useCallback(() => onClose(), [onClose]);

  const handleSubmitData = useCallback(
    async (formData: IFormInput) => {
      const isValidName = await fetchServiceValidName({
        name: formData.name,
        resource: EResourceType.CAMPAIGN,
      });

      if (!isValidName) {
        return setError('name', {
          type: 'validate',
          message: t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign'),
        });
      }

      if (isEqual(formData, defaultFormToCompare)) {
        onClose();
        return;
      }

      if (modalTypeProcess === ModalType.DUPLICATE) {
        await duplicateCampaign({ ...formData, campaignId: detailCampaign.campaignId });
      }

      if (modalTypeProcess === ModalType.CREATE || modalTypeProcess === ModalType.EDIT) {
        await createCampaign(formData);
      }

      setRefetchDetailData(true);
      setRefetchStrategyList(true);
      handleCloseModal();
      setRefetchAllData(true);
    },
    [
      createCampaign,
      defaultFormToCompare,
      fetchServiceValidName,
      handleCloseModal,
      onClose,
      setError,
      setRefetchAllData,
      setRefetchDetailData,
      setRefetchStrategyList,
    ]
  );

  const handleOnChangeDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) setDateRange(event as [Date | null, Date | null]);
    },
    [setDateRange]
  );

  return {
    loading,
    handleChangeStrategy,
    handleCloseModal,
    handleSubmitData,
    handleOnChangeDate,
  };
};
