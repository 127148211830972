import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { closeModal, openModal } from '@/lib/components/Modal/Modal';
import { Accordion, FloatModal, IconSvg, Spinner, toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EditPencilIcon } from '@/lib/v2/icons/outline';

import { ContactsTagModal } from '@/src/ContactsModule/components';
import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { sortItemsByProperty } from '@/src/ContactsModule/utils/sortItemsByProperty';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';

export const ContactsProfileDetailTags = ({
  permanentEmblue,
  emailId,
}: {
  permanentEmblue: boolean;
  emailId: number;
}) => {
  const { t } = useTranslation();
  const service = useService();
  const [tags] = useEmblue(ServiceMethods.getTagsDropDown, { identifier: 'TAGS_DROP_DOWN' });
  const [isModalOpen] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [unselectedTags, setUnselectedTags] = useState<number[]>([]);

  const [tagsList, setTagsList] = useState<
    | {
        list: ITag[];
        total: number;
      }
    | undefined
  >();

  useEffect(() => {
    if (selectedTags.length >= 1 || unselectedTags.length >= 1) {
      updateContactsToTags().catch(() => {});
    }
  }, [selectedTags, unselectedTags]);

  const updateContactsToTags = async () => {
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: (
        <FloatModal>
          <Spinner withoutOverlay />
        </FloatModal>
      ),
    });
    const result = await service?.updateContactsToTags({
      contactsIds: [emailId],
      tagsToAssign: selectedTags,
      tagsToUnassign: unselectedTags,
    });
    closeModal(); // spinner
    toast({
      variant: result ? 'success' : 'error',
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
      autoCloseDelay: 5000,
    });
    void tagByContacts();
  };

  useEffect(() => {
    void tagByContacts();
  }, [service]);

  const handleRead = () => {
    setShowTagsModal(true);
  };

  const tagByContacts = useCallback(async () => {
    const tagByContact = await service?.getContactTagsById({ ids: [emailId] });
    const tagByContactList =
      tagByContact && Object.prototype.hasOwnProperty.call(tagByContact.list, emailId)
        ? (tagByContact.list as Record<number, ITag[]>)[emailId]
        : [];
    setTagsList({ list: tagByContactList, total: tagByContactList.length });
  }, [service]);

  const tagsListSorted: ITag[] = sortItemsByProperty<ITag, 'name'>(tagsList?.list, 'name');

  const tagsCountText: string = tagsList ? ` (${tagsList.total.toString()})` : ' (0)';

  return (
    <div className="static w-full">
      <Accordion
        btnActive={isModalOpen}
        btnIcon={
          <IconSvg
            strokeColor={!isModalOpen ? 'secondary' : 'primary'}
            svgComponent={<EditPencilIcon />}
          />
        }
        btnText={t('CONTACTS_PROFILE.edit')}
        startOpen={permanentEmblue}
        title={t('TAGS_MAIN.tag') + tagsCountText}
        onClickButton={() => handleRead()}
      >
        <div className="visible-scrollbar max-h-[32rem] overflow-auto bg-white pr-2">
          <Flex textAlignment="left">
            <div className="py-2 pl-8 pr-4">
              {tagsListSorted &&
                tagsListSorted.map((t, key) => {
                  return (
                    <div key={key} className="pb-2">
                      {t.name}
                    </div>
                  );
                })}
            </div>
          </Flex>
        </div>
      </Accordion>
      {showTagsModal && (
        <ContactsTagModal
          contactTags={tagsList && tagsList.list}
          isUpdateCheckboxModal={true}
          setSelectedTags={setSelectedTags}
          setShowTagsModal={setShowTagsModal}
          setUnselectedTags={setUnselectedTags}
          tags={tags === undefined ? [{ id: 0, name: '' }] : tags.list}
        />
      )}
    </div>
  );
};
