import { graphlib, layout } from 'dagre';
import { useEffect } from 'react';
import { ReactFlowState, useReactFlow, useStore } from 'reactflow';

export enum Direction {
  TOP_BOTTOM = 'TB',
  LEFT_RIGHT = 'LR',
  RIGHT_LEFT = 'RL',
  BOTTOM_TOP = 'BT',
}

const nodeCountSelector = (state: ReactFlowState) => state.nodeInternals.size;
const nodesInitializedSelector = (state: ReactFlowState) =>
  Array.from(state.nodeInternals.values()).every(
    (node) =>
      node.width !== undefined &&
      node.width !== null &&
      node.height !== undefined &&
      node.height !== null
  );

export const useAutoLayout = (direction: Direction = Direction.TOP_BOTTOM) => {
  const nodeCount = useStore(nodeCountSelector);
  const nodesInitialized = useStore(nodesInitializedSelector);
  const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();

  useEffect(() => {
    if (!nodeCount || !nodesInitialized) {
      return;
    }

    const dagreGraph = new graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: direction });

    const nodes = getNodes();
    const edges = getEdges();

    nodes.forEach((node) => {
      if (node.width && node.height) {
        dagreGraph.setNode(node.id, {
          width: node.width,
          height: node.height,
        });
      }
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    layout(dagreGraph);

    setNodes((nodes) =>
      nodes.map((node) => {
        return {
          ...node,
          style: { ...node.style, opacity: 1 },
        };
      })
    );
  }, [nodeCount, nodesInitialized, getNodes, getEdges, setNodes, setEdges, direction]);
};
