import { ReactNode } from 'react';
import { CalendarContainer } from 'react-datepicker';

export interface DateInputContainerProps {
  children: ReactNode;
  className?: string;
}

const DateInputContainer = ({ className, children }: DateInputContainerProps) => {
  return (
    <CalendarContainer className={className}>
      <div className="relative">{children}</div>
    </CalendarContainer>
  );
};

export default DateInputContainer;
