/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReactFlow } from 'reactflow';

import {
  NodeDetails,
  PlaceholderEdge,
  PlaceholderNode,
} from '@/src/modules/RulesModule/interfaces';
import { createPlaceholderNode } from '@/src/modules/RulesModule/utils/createPlaceholderNode';

export const useAddNode = () => {
  const { setEdges, setNodes, getNode, getNodes } = useReactFlow();

  const onClick = (nodeDetails: NodeDetails) => {
    const { type, id, title, description, configurationParameters, icon, configurationName } =
      nodeDetails;

    const parent = getNode(id) as PlaceholderNode;
    const newPlaceholderNode: PlaceholderNode = createPlaceholderNode(parent);

    const newPlaceholderEdge: PlaceholderEdge = {
      id: `${id}->${newPlaceholderNode.id}`,
      source: id,
      target: newPlaceholderNode.id,
      type: 'placeholder',
      data: {
        showButton: false,
      },
    };

    let currentNodeIndex = -1;
    const allNodes = getNodes().map((node, index) => {
      if (node.id === id) {
        currentNodeIndex = index;
        return {
          ...node,
          type: type.toLowerCase(),
          data: {
            ...node.data,
            title_detail: { title_text: title, icon: icon },
            data: {
              description: { html: description },
              configuration_name: configurationName,
              configuration_parameters: configurationParameters,
            },
            type: type.toLowerCase(),
            reactflowType: type.toLowerCase(),
            children: [newPlaceholderNode.id],
            is_leaf: false,
          },
        };
      }
      return node;
    });

    const nodesWithPlaceholder = [
      ...allNodes.slice(0, currentNodeIndex + 1),
      newPlaceholderNode,
      ...allNodes.slice(currentNodeIndex + 1),
    ];

    setNodes(nodesWithPlaceholder);
    setEdges((edges) => [
      ...edges.map((edge) => (edge.target === id ? { ...edge, type: 'buttonEdge' } : edge)),
      newPlaceholderEdge,
    ]);
  };

  return onClick;
};
