import classNames from 'classnames';
import { memo } from 'react';

import { Flex } from '@/lib/v2/components';
import InsightCard, { InsightCardProps } from '@/lib/v2/examples/InsightCard/InsightCard';

export interface MessageCardsListProps {
  /** list cards filter */
  cardsList: InsightCardProps[];
}

const MessageCardsList = ({ cardsList }: MessageCardsListProps) => {
  const classes = classNames('mb-6 grid gap-3 lg:gap-6 w-full mx-auto xl:w-auto');

  return (
    <Flex alignment="center">
      <div className={classes}>
        {cardsList.map((item) => {
          const { ...restOfProps } = item;
          return (
            <InsightCard
              key={item.title}
              hasSpacing
              titleColor={'primary'}
              {...restOfProps}
              isLoading={false}
            />
          );
        })}
      </div>
    </Flex>
  );
};

export default memo(MessageCardsList);
