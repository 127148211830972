//cspell:ignore áéíóú aeiou trábol trabol
import { t } from 'i18next';

import { STRATEGY_MAP_BY_ID } from '@/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/constants';

const locales = ['en', 'es', 'pt'];

/**
 * The function `verifyDefaultStrategyName` checks whether a provided strategy name matches any of the
 * predefined default strategy names. It does a case-insensitive comparison by converting both the input
 * name and the default strategy names to lower case.
 *
 * @param {string} name - A name of a strategy to verify.
 * @returns The function `verifyDefaultStrategyName` returns a boolean value indicating whether the
 * provided name matches any of the default strategy names.
 */
export const verifyDefaultStrategyName = (name: string) => {
  const normalizedInputName = removeAccents(name.toLowerCase());

  const defaultStrategyNamesByLanguage = Object.values(STRATEGY_MAP_BY_ID).flatMap((key) =>
    Object.values(locales).map((locale) =>
      removeAccents(t(`CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${key}`, { lng: locale }).toLowerCase())
    )
  );

  return defaultStrategyNamesByLanguage.includes(normalizedInputName);
};

/**
 * The `removeAccents` function is used to remove accents and diacritics from a string.
 *
 * @param {string} str - The string from which to remove the accents.
 * @returns The `removeAccents` function returns a new string without any accents or diacritical marks. For example, the input 'trábol' would be converted into 'trabol'.
 *
 * @example
 *
 *   const str = 'áéíóú';
 *   const result = removeAccents(str);
 *   console.log(result); // Output: 'aeiou'
 */

const removeAccents = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[\u00C0-\u00C5]/g, 'A')
    .replace(/[\u00E0-\u00E5]/g, 'a')
    .replace(/[\u008C\u009C\u00D2-\u00D6\u00D8\u00F2-\u00F6\u00F8]/g, 'O')
    .replace(/[\u00CC-\u00CF\u00EC-\u00EF]/g, 'I')
    .replace(/[\u00D9-\u00DC\u00F9-\u00FC]/g, 'U');
};
