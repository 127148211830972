import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-layout-masonry';

import { Button, IconSvg, Text } from '@/lib/v2/components';
import { DeleteIcon } from '@/lib/v2/icons/outline';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { ModalDelete } from '@/src/modules/CampaignsModule/components/ModalDelete/ModalDelete';
import { SkeletonMyCatalogTemplates } from '@/src/modules/CampaignsModule/components/SkeletonMyCatalogTemplates';
import { useCatalogEmails } from '@/src/modules/CampaignsModule/hooks/useCatalogEmails';
import { IEmailOfCatalog } from '@/src/modules/CampaignsModule/interfaces/Beefree';

interface ICatalogItems {
  data: IEmailOfCatalog;
  onUse: (templateId: number) => Promise<void>;
  onDelete: (item: number) => Promise<void>;
  onPreview: (template: IEmailOfCatalog) => Promise<void>;
}

const CatalogItem = ({ data, onUse, onDelete, onPreview }: ICatalogItems) => {
  const { thumbnail, title } = data ?? {};
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const isUserCreatorEmail = useFeatureFlag('templateEmailCreator');
  if (!data) {
    console.warn('Template is corrupted', data);
    return null;
  }

  return (
    <div
      className={`group flex min-h-56 flex-row justify-between ${
        loading ? 'animate-pulse' : ''
      } flex-col overflow-hidden rounded-md bg-gray-100 shadow-md`}
      data-id={data.id}
    >
      <div className="relative h-full">
        <img alt={title} className="w-full" src={thumbnail} onLoad={() => setLoading(false)} />
        <div className="absolute left-0 top-0 hidden size-full flex-col items-center justify-center gap-4 bg-black/80 backdrop-opacity-75 group-hover:flex">
          {isUserCreatorEmail && (
            <Button
              outline
              standard
              className="absolute right-2 top-2"
              onClick={() => void onDelete(data.id)}
            >
              <IconSvg fillColor="gray-dark" svgComponent={<DeleteIcon color="transparent" />} />
            </Button>
          )}

          <Button
            fullWidth
            gray
            outline
            standard
            classNameContainer="max-w-28"
            onClick={() => void onPreview(data)}
          >
            {t('BEEFREE_TEMPLATES_GALLERY.preview')}
          </Button>

          <Button
            fullWidth
            gray
            outline
            standard
            classNameContainer="max-w-28"
            onClick={() => void onUse(data.id)}
          >
            {t('BEEFREE_TEMPLATES_GALLERY.use')}
          </Button>
        </div>
      </div>
      <Text className="border-t-[1px] border-t-gray-200 bg-white p-2" variant="text">
        {data.title}
      </Text>
    </div>
  );
};

export const CatalogEmails = () => {
  const {
    catalog,
    getCatalog,
    handleUseEmail,
    handleDeleteEmail,
    handlePreviewEmail,
    showModalDelete,
    setShowModalDelete,
    masonryRef,
    hasMore,
  } = useCatalogEmails();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleClose = useCallback(async () => {
    setShowModalDelete(null);
  }, [setShowModalDelete]);
  const handleOpenDelete = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (item: IEmailOfCatalog) => {
      setShowModalDelete(item);
    },
    [setShowModalDelete]
  );

  const modalDeleteWordings = useMemo(
    () => ({
      title: t('CATALOG_MODAL_DELETE_TEMPLATE.title'),
      message: t('CATALOG_MODAL_DELETE_TEMPLATE.message'),
      actions: {
        cancel: t('CATALOG_MODAL_DELETE_TEMPLATE.cancel'),
        accept: t('CATALOG_MODAL_DELETE_TEMPLATE.accept'),
      },
    }),
    [t]
  );

  /**
   * @desc Mapper of catalog of emails
   */
  const catalogMapper = useCallback(
    (item: IEmailOfCatalog) => {
      if (!item.id) return null;
      return (
        <CatalogItem
          key={item.id}
          data={item}
          onDelete={() => handleOpenDelete(item)}
          onPreview={handlePreviewEmail}
          onUse={handleUseEmail}
        />
      );
    },
    [handleOpenDelete, handlePreviewEmail, handleUseEmail]
  );

  return (
    <div ref={masonryRef} className="visible-scroll w-full px-0 py-4">
      <InfiniteScroll
        className="w-full"
        dataLength={catalog?.length ?? 0}
        endMessage={
          <Text alignment="center" className="py-6" color="light-black" fontWeight="normal">
            <b>{t('noMoreResults')}</b>
          </Text>
        }
        hasMore={hasMore}
        loader={
          <div className="w-full">
            <SkeletonMyCatalogTemplates columns={5} />
          </div>
        }
        next={getCatalog}
      >
        <Masonry columns={5} gap={16}>
          {catalog?.map(catalogMapper)}
        </Masonry>
      </InfiniteScroll>

      <ModalDelete
        data={showModalDelete}
        open={showModalDelete ? true : false}
        wordings={modalDeleteWordings}
        onAccept={handleDeleteEmail}
        onCancel={handleClose}
      />
    </div>
  );
};
