/* eslint-disable @typescript-eslint/ban-types */
import { datadogRum } from '@datadog/browser-rum';
import { useAtom, useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IStep } from '@/lib/components/Stepper/Stepper';

import { TrackEvent } from '@/src/application/util/mixPanel';
import { atomShowCampaignsV1 } from '@/src/atoms/Campaigns';
import configData from '@/src/config.json';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useCurrentStep } from '@/src/infrastructure/services/useCurrentStep';
import {
  onCreateCampaignCardClick,
  onCreateContactCardClick,
} from '@/src/presentation/components/Main/Cards';
import { mixPanelEvents } from '@/src/presentation/util/enum/mixPanelEventsEnum';
import { StepEnum } from '@/src/presentation/util/enum/StepEnum';
import { ContactsStepperImg } from '@/src/presentation/util/icon/ContactsStepperImg';
import { SendEmailStepperImg } from '@/src/presentation/util/icon/SendEmailStepperImg';

import { useFeatureFlag } from './util/useFeatureFlag';
import { useStepWithPostMessage } from './useStepWithPostMessage';
import { UserData } from './useUserDataInterface';
import { useVersion } from './useVersion';

import { atomSignalSendersOnboarding } from '@/modules/OnboardingModule/atoms/OnboardingModule.atoms';

function getStatusStep(step: number, stepRequired: number): 'current' | 'upcoming' | 'done' {
  return step === stepRequired ? 'current' : step < stepRequired ? 'upcoming' : 'done';
}

interface useStepProps {
  userData: UserData;
}

export const useStep = ({ userData }: useStepProps) => {
  const [step, setStep] = useState<number>(StepEnum.sender);
  const [loading, setLoading] = useState(false);
  const [stepNextFromApi, setStepNextFromApi] = useState<number>(StepEnum.sender);
  const { t } = useTranslation();
  const { version } = useVersion();
  const navigate = useNavigate();
  const service = useService();
  const showCampaignsV1 = useAtomValue(atomShowCampaignsV1);
  const [, setSignalSenders] = useAtom(atomSignalSendersOnboarding);
  const importV2IsEnabled = useFeatureFlag('contactMenuBarImport');

  const nextStep = useCallback((steps: IStep[], stepValue: number) => {
    let nextStepVal: number;
    if (stepValue === StepEnum.finished || stepValue === StepEnum.sendEmail) {
      nextStepVal = StepEnum.finished;
    } else if (stepValue === StepEnum.sender) {
      nextStepVal = StepEnum.contacts;
    } else {
      nextStepVal = StepEnum.sendEmail;
    }
    if (nextStepVal === 0) {
      setCurrentStep(null);
    } else {
      setCurrentStep(steps[nextStepVal - 1]);
    }
    setStep(nextStepVal);
  }, []);

  /* eslint-disable @typescript-eslint/no-shadow */
  const clickSkipStep = useCallback(
    (userData: UserData, steps: IStep[], stepValue: number) => {
      nextStep(steps, stepValue);
      TrackEvent(userData, mixPanelEvents.SkipStep, { skippedStep: stepValue.toString() });
      if (version === 'v2') {
        const stepNext: number = service?.setNextStep(userData.companyId, step);
        setStepNextFromApi(stepNext);
      } else {
        window.parent.postMessage(
          { message: configData.HOME.SKIP_STEP, actualStep: stepValue },
          '*'
        );
      }
    },
    [nextStep, version, service, step]
  );

  const steps: IStep[] = useMemo(
    () => [
      {
        id: 1,
        name: `${t('STEPPER.step')} 1`,
        description: `${t('STEPPER.setTheSender')}`,
        status: getStatusStep(step, StepEnum.sender),
        taskTitle: `${t('STEPPER.senderTaskTitle')}`,
        taskDescription: `${t('STEPPER.senderTaskDescription')}`,
        onClick: () => {
          setSignalSenders({
            origin: '/v2',
            complete: false,
          });
          navigate('/v2/configuration/senders');
        },
        clickCallToAction: `${t('STEPPER.setTheSender')}`,
        onSkip: () => clickSkipStep(userData, steps, StepEnum.sender),
        skipCallToAction: `${t('STEPPER.skip')}`,
        image: SendEmailStepperImg,
      },
      {
        id: 2,
        name: `${t('STEPPER.step')} 2`,
        description: `${t('STEPPER.importContacts')}`,
        status: getStatusStep(step, StepEnum.contacts),
        taskTitle: `${t('STEPPER.contactsTaskTitle')}`,
        taskDescription: `${t('STEPPER.contactsTaskDescription')}`,
        onClick: () => onCreateContactCardClick(userData, version, navigate, importV2IsEnabled),
        clickCallToAction: `${t('STEPPER.uploadMyContacts')}`,
        onSkip: () => clickSkipStep(userData, steps, StepEnum.contacts),
        skipCallToAction: `${t('STEPPER.skip')}`,
        image: ContactsStepperImg,
      },
      {
        id: 3,
        name: `${t('STEPPER.step')} 3`,
        description: `${t('STEPPER.sendYourFistMessage')}`,
        status: getStatusStep(step, StepEnum.sendEmail),
        taskTitle: `${t('STEPPER.sendMessageTaskTitle')}`,
        taskDescription: `${t('STEPPER.sendMessageTaskDescription')}`,
        onClick: () =>
          onCreateCampaignCardClick(userData, version, navigate, showCampaignsV1 === 'true'),
        clickCallToAction: `${t('STEPPER.sendFirstMessage')}`,
        onSkip: () => clickSkipStep(userData, steps, StepEnum.sendEmail),
        skipCallToAction: `${t('STEPPER.skip')}`,
        image: SendEmailStepperImg,
      },
    ],
    [
      t,
      step,
      setSignalSenders,
      navigate,
      clickSkipStep,
      userData,
      version,
      importV2IsEnabled,
      showCampaignsV1,
    ]
  );

  let [stepFromApi] = useCurrentStep();
  const [currentStep, setCurrentStep] = useState<IStep | null>(
    stepFromApi ? steps[stepFromApi] : steps[0]
  );
  const [stepFromPostMessage] = useStepWithPostMessage();

  useEffect(() => {
    if (version === 'v2') {
      if (stepFromApi < step) {
        stepFromApi = step;
      } else if (stepFromApi === StepEnum.finished) {
        setStep(StepEnum.finished);
      }
      setStep(stepFromApi);
      if (stepNextFromApi === StepEnum.contacts) {
        setStep(stepNextFromApi);
      } else if (stepNextFromApi === StepEnum.sendEmail) {
        setStep(stepNextFromApi);
      } else if (stepNextFromApi === StepEnum.finished) {
        setStep(stepNextFromApi);
      }

      setLoading(false);
    } else {
      setStep(stepFromPostMessage);
    }
    if (step !== 0 && stepFromApi !== 0) {
      setCurrentStep(steps[step - 1]);
    } else {
      setCurrentStep(null);
    }
  }, [step, stepFromApi, stepNextFromApi, stepFromPostMessage, steps, version]);

  const obj = { step, steps, currentStep, nextStep, loading };
  return obj;
};
