import { Node as ReactFlowNode, XYPosition } from 'reactflow';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

export interface ConfigurationParameters {
  tag_id: string;
  [key: string]: unknown;
}

export interface NodeData {
  title_detail: {
    title_text: string;
    icon: string;
  };
  data: {
    description: {
      html: string;
    };
    configuration_name: string;
    configuration_parameters: ConfigurationParameters;
  };
  type: string;
  id: string;
  parent: string | null;
  children: string[];
  is_root: boolean;
  is_leaf: boolean;
  is_valid: boolean;
  is_deleted: boolean;
  errors: Record<string, unknown>;
  reactflowType: string;
}

export interface PlaceholderNode extends ReactFlowNode<NodeData> {
  position: XYPosition;
}

export interface NodeDetails {
  type: string;
  id: string;
  title?: string | null;
  description?: string | null;
  configurationParameters?: Record<string, unknown>;
  icon?: string;
  configurationName?: string | null;
  reactflowType?: string | null;
  nature?: unknown;
}

export interface PlaceholderEdge {
  id: string;
  source: string;
  target: string;
  type: string;
  data: {
    showButton: boolean;
  };
}

export enum AddOperationTypes {
  condition = 'condition',
  action = 'action',
  trigger = 'trigger',
  placeholder = 'placeholder',
}

//cspell:disable
export interface IRulesV1Response {
  acumuladaCantidad: number | null;
  campoDTO: FieldDTO;
  camposPersonalizadosDTO: CustomFieldsDTO[];
  desencadenante: string;
  elementoId: number;
  elementosDTO: unknown[];
  elementosHnosCantidad: number;
  enviarCadaAnio: null;
  envioRecurrente: boolean;
  fechaEnvio: string;
  group_rules: number | null;
  group_rules_in: number | null;
  group_rules_type: string;
  grupoDTO: null;
  horaEnvio: string | null;
  landingPageDTO: null;
  landingPageErrorDTO: null;
  redireccionamientoFinal: string;
  redireccionamientoFinalError: string;
  tag_id: unknown[];
  tipo: string;
  urlDestino: null;
  urlDestinoError: null;
}

export interface FieldDTO {
  id: number;
  nombre: string;
  integracion_id: number | null;
}

export interface CustomFieldsDTO {
  id: number;
  requerido: boolean;
}

interface GroupRecipientsV1 {
  id: string;
  nombre: string;
  tipo: string;
}

export interface IRulesRecipientsResponseV1 {
  elementoId: number;
  grupos: GroupRecipientsV1[];
  listas: unknown[];
}
//cspell:enable

interface TriggerRuleOptions {
  trigger?: Option;
  customField?: Option;
  days?: Option;
  frequency?: Option;
  execution?: Option;
  period?: Option;
  hours?: Option;
  minutes?: Option;
}

interface ConditionOptions {
  selectedGroupOrSegment?: OptionExternal[];
  presenceCondition?: Option;
  touchRules?: boolean;
}

export interface RulesOptions extends TriggerRuleOptions, ConditionOptions {}

export enum DataTypeCustomField {
  DATE = 'date',
}

export enum FormTypes {
  TRIGGER = 'trigger',
  CONDITION = 'condition',
}
