import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { useSaveDataRules } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useSaveDataRules';

import { useConditionOptions } from './useConditionOptions';

import { atomRuleData, atomToggleSidePanel } from '@/modules/RulesModule/atoms/rules';

export interface IConditionForm {
  selectedGroupOrSegment: OptionExternal[] | undefined;
  presenceCondition: Option;
  touchRules: boolean;
}

export const useFormCondition = () => {
  const { presenceOptions } = useConditionOptions();

  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);
  const [rulesData, setRulesData] = useAtom(atomRuleData);
  const { saveRule } = useSaveDataRules();

  console.log('rulesData?.selectedGroupOrSegment', rulesData?.selectedGroupOrSegment);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IConditionForm>({
    defaultValues: {
      selectedGroupOrSegment: [rulesData?.selectedGroupOrSegment],
      presenceCondition: rulesData?.presenceCondition || presenceOptions[0],
      touchRules: rulesData?.touchRules || undefined,
    },
  });

  const onSubmit: SubmitHandler<IConditionForm> = useCallback(
    async (conditionValues) => {
      setRulesData((prevRulesData) => ({ ...prevRulesData, ...conditionValues }));
      await saveRule(conditionValues);
      setToggleSidePanel(false);
    },
    [saveRule, setToggleSidePanel]
  );

  const RULES = useMemo(
    () => ({
      presenceCondition: {
        required: 'is required',
      },
      selectedGroupOrSegment: {
        required: 'is required',
      },
      touchRules: {
        required: 'is required',
      },
    }),
    []
  );

  const handleCancel = useCallback(() => {
    setToggleSidePanel(false);
  }, [setToggleSidePanel]);

  return {
    control,
    watch,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    RULES,
    handleCancel,
    setValue,
    getValues,
  };
};
