import { useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDebounce } from '@/src/application/hooks/useDebounce';

import {
  atomSelectedActionsRowsWithID,
  atomSelectedRowActionName,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomRefetchAllData } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleEditActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useHandleEvents = () => {
  const { id } = useParams();
  const { editAction, loading } = useCampaignActions();

  const actionId = useAtomValue(atomSelectedActionsRowsWithID);
  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const setToggleEditActionsModal = useSetAtom(atomToggleEditActionsModal);
  const actionName = useAtomValue(atomSelectedRowActionName);

  const [inputValue, setInputValue] = useState<string>('');
  const debouncedValue = useDebounce(inputValue, 400);
  const isValidName = useValidNameResource({
    name: debouncedValue,
    resource: EResourceType.ACTION,
    campaignId: Number(id),
  });

  const handleCloseModal = useCallback(() => {
    setToggleEditActionsModal(false);
    setInputValue('');
  }, [setToggleEditActionsModal]);

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      if (actionName === nameValue) {
        handleCloseModal();
        return;
      }

      await editAction({ name: nameValue, actionId: Number(actionId) });
      setRefetchAllData(true);
      handleCloseModal();
    },
    [actionId, actionName, editAction, handleCloseModal, setRefetchAllData]
  );

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
  }, []);

  return {
    handleChange,
    handleSubmitData,
    handleCloseModal,
    loading,
    isValidName,
  };
};
