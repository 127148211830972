import { ChangeEvent, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, If, Input, Modal, Text } from '@/lib/v2/components';
import { AlertProps } from '@/lib/v2/components/Alert/Alert';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';

import { formValidations } from '@/src/constants/FormValidations';

import ButtonsSection from './ButtonsSection';

type FormInput = {
  input: string;
};

interface ModalCreateItemProps {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  onSubmit?: (nameValue: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
  inputLabel?: string;
  textAction?: string;
  textClose?: string;
  rules?: {
    input: object;
  };
  /** controller value input */
  value?: string;
  disabledSubmitButton?: boolean;
  alert?: AlertProps;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ModalSingleInput = ({
  id,
  isOpen,
  onClose,
  title,
  onSubmit,
  inputLabel,
  textAction,
  isLoading,
  textClose,
  rules,
  disabledSubmitButton,
  disabled,
  value,
  alert,
  onChange,
}: ModalCreateItemProps) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm<FormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 500,
    values: {
      input: value || '',
    },
  });

  const { inputLengths } = formValidations;

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    setValue('input', '');
    onClose();
  }, [onClose, setValue]);

  const onAction = handleSubmit((values) => {
    const { input } = values;
    onSubmit?.(input);
  });

  const RULES = {
    input: {
      required: t('FORMS_RULES_MESSAGES.required'),
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
    },
  };

  const label = inputLabel ?? t('CREATE_SEGMENT_MODAL.fieldName');

  return (
    <Modal noPadding withAuto id={id} open={isOpen} onClose={handleClose}>
      <div className="h-full w-[90vw] p-6 sm:w-[550px]">
        <Divide>
          <Flex column gapSize="small" itemAlignment="stretch">
            <Text color="light-black" fontWeight="bold">
              {title}
            </Text>
            <div className="flex flex-col">
              <div className="w-full py-4">
                <div className="w-full">
                  <section className="mb-3">
                    <div className="pt-4">
                      <Input
                        isRequired
                        control={control}
                        disabled={disabled}
                        id={`${id ?? 'name'}-input`}
                        label={label}
                        name="input"
                        rules={rules?.input ?? RULES.input}
                        type="text"
                        onChange={onChange}
                      />
                    </div>
                  </section>
                </div>
              </div>
              <If condition={alert !== undefined}>
                <Alert {...alert} />
              </If>
            </div>
            <ButtonsSection
              disabledSubmitButton={disabledSubmitButton || !isValid}
              id={id}
              isLoading={isLoading}
              textAction={textAction}
              textClose={textClose}
              onAction={onAction}
              onClose={handleClose}
            />
          </Flex>
        </Divide>
      </div>
    </Modal>
  );
};

export default memo(ModalSingleInput);
