import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FrequentContactIcon,
  InactiveContactIcon,
  NewContactIcon,
  SleepContactIcon,
} from '@/lib/icon';
import { IconSvg } from '@/lib/v2/components';
import { InsightCardProps } from '@/lib/v2/examples/InsightCard/InsightCard';

import { IStatusList } from '@/src/ContactsModule/interfaces/Interfaces';

import { FilterCardsList } from '@/modules/ContactsModule/components';
import { useMutationContactsContext } from '@/modules/ContactsModule/contexts/ContactsContext';

interface ContactsFilterCardsProps {
  items: IStatusList[];
  titleIsLoading?: boolean;
}

const ContactsFilterCards = ({ items, titleIsLoading = true }: ContactsFilterCardsProps) => {
  const { t } = useTranslation();
  const { setStatusFilter, setLastPageViewed } = useMutationContactsContext();

  const cardList = useMemo<(InsightCardProps & { filter: string })[]>(
    () => [
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="success"
            svgComponent={<NewContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_CARDS.new'),
        title: '0',
        tooltip: t('STATUS_CARDS.activity15Days'),
        isLoading: true,
        filter: 'new',
        onClick: () => {
          setLastPageViewed(0);
          setStatusFilter(['new']);
        },
        id: 'new-card-filter',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="primary"
            svgComponent={<FrequentContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_CARDS.frequent'),
        title: '0',
        tooltip: t('STATUS_CARDS.activity45Days'),
        isLoading: true,
        filter: 'frequent',
        onClick: () => {
          setLastPageViewed(0);
          setStatusFilter(['frequent']);
        },
        id: 'frequent-card-filter',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="sleepy"
            svgComponent={<SleepContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_CARDS.asleep'),
        title: '0',
        tooltip: t('STATUS_CARDS.activity120Days'),
        isLoading: true,
        filter: 'asleep',
        onClick: () => {
          setLastPageViewed(0);
          setStatusFilter(['asleep']);
        },
        id: 'asleep-card-filter',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="secondary"
            svgComponent={<InactiveContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_CARDS.inactive'),
        title: '0',
        tooltip: t('STATUS_CARDS.activityNever'),
        isLoading: true,
        filter: 'inactive',
        onClick: () => {
          setLastPageViewed(0);
          setStatusFilter(['inactive']);
        },
        id: 'inactive-card-filter',
      },
    ],
    [setLastPageViewed, setStatusFilter, t]
  );

  return (
    <FilterCardsList cardsList={cardList} statusList={items} titleIsLoading={titleIsLoading} />
  );
};

export default memo(ContactsFilterCards);
