import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb } from '@/lib/v2/components';

import { CalendarMain } from '@/src/modules/CampaignsModule/components/campaignCalendar/CalendarMain';
import { CalendarSidebar } from '@/src/modules/CampaignsModule/components/campaignCalendar/CalendarSidebar';

const CampaignCalendarMain = () => {
  const navigate = useNavigate();
  const CampaignCalendarPaths = useMemo(() => [{ name: t('CALENDAR.HEADER.title') }], []);

  const handleOnBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="min-h-[calc(100vh_-_48px)] bg-white">
      <Breadcrumb customPaths={CampaignCalendarPaths} handleOnBackClick={handleOnBackClick} />
      <div className="flex size-full gap-5 px-10 pb-2 pt-4 text-left">
        <div className="w-80 pr-1">
          <CalendarSidebar />
        </div>
        <div className="flex-1">
          <CalendarMain />
        </div>
      </div>
    </div>
  );
};

export default CampaignCalendarMain;
