import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/lib/v2/components';
import { ModalSingleInput } from '@/lib/v2/examples';

import { formValidations } from '@/src/constants/FormValidations';

import {
  atomDetailCampaignRecurrent,
  atomFiltersRecurrent,
  atomHistorySearchPagerRecurrent,
  atomLoadingDetail,
  atomPagerCampaignsRecurrent,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomToggleDuplicateCampaignModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';
import { useCampaignRecurrent } from '@/modules/CampaignsModule/hooks/useCampaignRecurrent';
import { useDeprecatedBanner } from '@/modules/CampaignsModule/hooks/useDeprecatedBanner';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const TRANSLATE_MODAL = 'RECURRENT_MESSAGE.CREATE_RECURRENT';
const TRANSLATE_MODAL_DUPLICATE = 'RECURRENT_MESSAGE.DUPLICATE_RECURRENT';

interface IDuplicateCampaignRecurrentProps {
  onClose: (value?: boolean) => void;
}

const DuplicateCampaignRecurrent = ({ onClose }: IDuplicateCampaignRecurrentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { duplicateRecurrent } = useCampaignRecurrent();
  const { fetchServiceValidName } = useValidNameResource();
  const { inputLengths, formRegExp } = formValidations;

  // TODO remove this when the feature flag showDragDropRemovalNotice is removed
  const { showDeprecatedDragDropForRow } = useDeprecatedBanner();

  const detailCampaign = useAtomValue(atomDetailCampaignRecurrent);
  const loadingDetail = useAtomValue(atomLoadingDetail);
  const toggleDuplicateCampaignModal = useAtomValue(atomToggleDuplicateCampaignModal);
  const filters = useAtomValue(atomFiltersRecurrent);
  const pager = useAtomValue(atomPagerCampaignsRecurrent);
  const [historySearchPagerCampaign, setHistorySearchPagerCampaign] = useAtom(
    atomHistorySearchPagerRecurrent
  );

  const VALIDATION_RULES = {
    input: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
      validate: async (value: string) => {
        const isValidName = await fetchServiceValidName({
          name: value,
          resource: EResourceType.CAMPAIGN,
        });

        return isValidName || t('RECURRENT_MESSAGE.CREATE_RECURRENT.existCampaignRecurrent');
      },
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
    },
  };

  const handleCloseModal = useCallback(() => onClose(), [onClose]);

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      const campaignIdDuplicate = await duplicateRecurrent({
        name: nameValue,
        campaignId: Number(detailCampaign.campaignId),
      });
      handleCloseModal();

      if (campaignIdDuplicate) {
        if (!historySearchPagerCampaign) {
          setHistorySearchPagerCampaign({
            filters: { ...filters },
            pager: {
              page: pager?.page || defaultPager.page,
              limit: pager?.limit || defaultPager.limit,
            },
          });
        }
        navigate(`/v2/campaigns/recurrent/${campaignIdDuplicate}`);
      }
    },
    [
      duplicateRecurrent,
      detailCampaign.campaignId,
      handleCloseModal,
      historySearchPagerCampaign,
      navigate,
      setHistorySearchPagerCampaign,
      filters,
      pager,
    ]
  );

  return loadingDetail ? (
    <Spinner />
  ) : (
    <ModalSingleInput
      alert={
        !showDeprecatedDragDropForRow
          ? {
              description: t(`${TRANSLATE_MODAL_DUPLICATE}.description`),
              type: 'info',
            }
          : {
              description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.deprecated.featured'),
              type: 'warning',
            }
      }
      id="duplicate-recurrent-modal"
      isOpen={toggleDuplicateCampaignModal}
      rules={VALIDATION_RULES}
      title={t(`${TRANSLATE_MODAL_DUPLICATE}.title`)}
      value={`${String(detailCampaign.name)} ${t(`${TRANSLATE_MODAL}.copy`)}`}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData}
    />
  );
};
export default DuplicateCampaignRecurrent;
