import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import esTranslation from './locales/es/translation.json';
import ptTranslation from './locales/pt/translation.json';

import enEditorHtml from './locales/en/htmlEditor.json';
import esEditorHtml from './locales/es/htmlEditor.json';
import ptEditorHtml from './locales/pt/htmlEditor.json';

import enApiManager from './locales/en/apiManager.json';
import esApiManager from './locales/es/apiManager.json';
import ptApiManager from './locales/pt/apiManager.json';

import enRules from './locales/en/rules.json';
import esRules from './locales/es/rules.json';
import ptRules from './locales/pt/rules.json';

const userLang = navigator.language || navigator.userLanguage;
const locale = userLang && userLang !== '' ? userLang.substring(0, 2) : 'en';

i18n.use(initReactI18next).init({
  lng: locale,
  fallbackLng: 'en',
  react: {
    wait: false,
    useSuspense: false,
  },
});

i18n.addResourceBundle('en', 'translation', enTranslation);
i18n.addResourceBundle('es', 'translation', esTranslation);
i18n.addResourceBundle('pt', 'translation', ptTranslation);

i18n.addResourceBundle('en', 'editorHtml', enEditorHtml);
i18n.addResourceBundle('es', 'editorHtml', esEditorHtml);
i18n.addResourceBundle('pt', 'editorHtml', ptEditorHtml);

i18n.addResourceBundle('en', 'apiManager', enApiManager);
i18n.addResourceBundle('es', 'apiManager', esApiManager);
i18n.addResourceBundle('pt', 'apiManager', ptApiManager);

i18n.addResourceBundle('en', 'rules', enRules);
i18n.addResourceBundle('es', 'rules', esRules);
i18n.addResourceBundle('pt', 'rules', ptRules);

export default i18n;
