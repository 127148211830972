import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@/lib/components/Table';
import { Text } from '@/lib/v2/components';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyCampaignsIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { PhonePreviewModal } from '@/src/modules/CampaignsModule/components/CampaignsModal';

import { useActionsTableColumns } from './hooks/useActionsTableColumns';
import { useHandleEvents } from './hooks/useHandleEvents';

import {
  atomActionsList,
  atomCurrentActionId,
  atomIndexMasterAction,
  atomPagerAction,
  atomSelectedRowsAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomLoadingProcess,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignAtom';

const ActionsTable = () => {
  const { t } = useTranslation();
  const showRecurrentMessage = useFeatureFlag('showRecurrentMessage');

  const actionsList = useAtomValue(atomActionsList);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsActionList = useAtomValue(atomTotalsProcess);
  const pager = useAtomValue(atomPagerAction);
  const indexMasterAction = useAtomValue(atomIndexMasterAction);
  const currentActionId = useAtomValue(atomCurrentActionId);

  const setSelectedRows = useSetAtom(atomSelectedRowsAction);

  const { actionsColumns } = useActionsTableColumns();
  const {
    handleCreateActionByCampaign,
    handleClickEmptyScreenNoResult,
    handleRowClick,
    handleChangeTablePage,
  } = useHandleEvents();

  const totalRecords = useMemo(
    () => Math.ceil(totalsActionList.partial / (pager?.limit || 10)),
    [totalsActionList.partial, pager?.limit]
  );

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        stickyHeader
        classNamePagination="px-4 mb-2"
        columns={actionsColumns}
        data={actionsList}
        defaultSize={pager?.limit}
        emptyScreen={{
          totalCount: totalsActionList.total || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t(
                'CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsActionText'
              )}
              description={t(
                'CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsDescription'
              )}
              id="noResults-actions"
              image={<EmptyContactsIcon />}
              title={t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsTitle')}
              onClickAction={handleClickEmptyScreenNoResult}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={
                <Text className="text-center !text-14 text-emblue-text" fontWeight="normal">
                  {t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescriptionAlt')}
                  <button
                    className="cursor-pointer !text-14 font-medium text-emblue-primary"
                    data-testid="nodata-actions"
                    id="nodata-actions"
                    role="link"
                    onClick={handleCreateActionByCampaign}
                  >
                    {t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsAction')}
                  </button>
                </Text>
              }
              description={t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescription')}
              image={<EmptyCampaignsIcon />}
              title={t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsTitle')}
            />
          ),
        }}
        gotoPageIndex={pager?.page ? pager.page - 1 : 0}
        isLoading={loadingProcess}
        lastPageViewed={pager?.page}
        rowIndexWithoutCheckbox={!showRecurrentMessage ? indexMasterAction : undefined}
        setSelectedRows={setSelectedRows}
        withCheckbox={true}
        withPagination={{
          totalPages: totalRecords,
          fetchData: handleChangeTablePage,
        }}
        onRowClick={handleRowClick}
      />
      {currentActionId && <PhonePreviewModal />}
    </div>
  );
};

export default ActionsTable;
