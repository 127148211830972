import { TableFilterBar } from '@/lib/v2/examples/TableFilterBar';

import FiltersDropdown from './components/FiltersDropdown/FiltersDropdown';
import { useFiltersDropdown } from './components/FiltersDropdown/useFiltersDropdown';
import { useInputDate } from './hooks/useInputDate';
import { useInputSearch } from './hooks/useInputSearch';
import { useResetButton } from './hooks/useResetButton';

const FilterBar = () => {
  const {
    handleOnClickSearch,
    onClearSearch,
    id: idInputSearch,
    reset: resetSearch,
    defaultValue: defaultSearchValue,
    placeholder: placeholderSearch,
    resetInputSearch,
  } = useInputSearch();

  const {
    id: idInputDate,
    dateRange,
    handleOnClickDate,
    placeholder: placeholderInputDate,
    resetDateRange,
  } = useInputDate();

  const { placeholderFilters } = useFiltersDropdown();

  const {
    id: idResetButton,
    disabled: disabledResetButton,
    handleClickReset,
  } = useResetButton(resetInputSearch, resetDateRange);

  return (
    <TableFilterBar>
      <TableFilterBar.InputSearch
        defaultValue={defaultSearchValue}
        id={idInputSearch}
        placeholder={placeholderSearch}
        reset={resetSearch}
        onAction={handleOnClickSearch}
        onClear={onClearSearch}
      />
      <TableFilterBar.InputDateRange
        dateRange={dateRange}
        id={idInputDate}
        placeholder={placeholderInputDate}
        onAction={handleOnClickDate}
      />
      <TableFilterBar.DropdownFilter id="filter-campaigns-recurrent" label={placeholderFilters}>
        <FiltersDropdown />
      </TableFilterBar.DropdownFilter>
      <TableFilterBar.ResetButton
        disabled={disabledResetButton}
        id={idResetButton}
        onAction={handleClickReset}
      />
    </TableFilterBar>
  );
};

export default FilterBar;
