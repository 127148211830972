import { CalendarIcon } from '@heroicons/react/outline';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardSection, Flex, IconSvg, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';
import { useShowModalSignal } from '@/lib/v2/hooks/useShowModalSignal';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { CampaignsIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  atomSelectedRowsWithID,
  atomStrategyList,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateCampaignModal } from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CampaignCards } from '@/src/modules/CampaignsModule/components/CampaignCards';
import {
  CreateEditDuplicateCampaigns,
  DeleteCampaigns,
} from '@/src/modules/CampaignsModule/components/CampaignsModal';
import { DuplicateCampaign } from '@/src/modules/CampaignsModule/components/CampaignsModal/DuplicateCampaign';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { TRANSLATE_CALENDAR_HEADER } from '@/src/modules/CampaignsModule/constants';
import { useCampaignData } from '@/src/modules/CampaignsModule/hooks/useCampaignData';
import { useCampaignModal } from '@/src/modules/CampaignsModule/hooks/useCampaignModal';
import { useResetAtomOnUnmount } from '@/src/modules/CampaignsModule/hooks/useResetAtomOnUnmount';
import { formattedNumber } from '@/src/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/src/modules/CampaignsModule/utils/formattedNumber';

import { TableContainer } from '@/modules/CampaignsModule/components/Campaigns/TableContainer';

const CampaignsMain = () => {
  const { t, i18n } = useTranslation();

  const [toggleDuplicateCampaignModal, setToggleDuplicateCampaignModal] = useAtom(
    atomToggleDuplicateCampaignModal
  );
  const selectedCampaignsByID = useAtomValue(atomSelectedRowsWithID);

  const showCampaignCalendar = useFeatureFlag('showCampaignCalendar');
  const showCampaignInfoCards = useFeatureFlag('showCampaignInfoCards');

  const { campaignList, loadingProcess, totalsCampaignList, filtersCount } = useCampaignData();
  const {
    handleOnOpenModal,
    handleOnCloseModal,
    handleLinkCalendar,
    toggleDeleteModal,
    toggleEditCreateCampaignModal,
  } = useCampaignModal();

  const { resetAtomProcess } = useResetAtomOnUnmount();

  useEffect(() => {
    return () => {
      resetAtomProcess();
    };
  }, []);

  useShowModalSignal('CreateCampaign', handleOnOpenModal);

  const handleOnCloseDuplicateModal = () => {
    setToggleDuplicateCampaignModal(false);
  };

  const strategyList = useAtomValue(atomStrategyList);

  if (strategyList.length === 0) return null;

  return (
    <>
      {showCampaignInfoCards && (
        <div className="mb-6 flex gap-4">
          <CampaignCards
            description={t('CAMPAIGNS_MAIN.CARDS.knowYourCustomers.description')}
            title={t('CAMPAIGNS_MAIN.CARDS.knowYourCustomers.title')}
          />
          <CampaignCards
            description={t('CAMPAIGNS_MAIN.CARDS.moreEffectiveDeliveries.description')}
            title={t('CAMPAIGNS_MAIN.CARDS.moreEffectiveDeliveries.title')}
          />
        </div>
      )}
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <>
                  {showCampaignCalendar && (
                    <Button
                      gray
                      outline
                      iconLeft={<IconSvg strokeColor="secondary" svgComponent={<CalendarIcon />} />}
                      id="campaigns-calendar"
                      size="small"
                      onClick={handleLinkCalendar}
                    >
                      {t(`${TRANSLATE_CALENDAR_HEADER}.title`)}
                    </Button>
                  )}
                  <Button
                    iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
                    id="create-campaigns-button"
                    size="small"
                    onClick={handleOnOpenModal}
                  >
                    {t('CAMPAIGNS_MAIN.button')}
                  </Button>
                </>
              }
              icon={<IconSvg height="20px" svgComponent={<CampaignsIcon />} width="20px" />}
              subtitle={
                <SectionHeaderSubtitle
                  countFilters={filtersCount}
                  countRecordsPartial={totalsCampaignList.partial}
                  dataList={campaignList}
                  i18nKey="CAMPAIGNS_MAIN.search"
                  loading={loadingProcess}
                />
              }
              title={t('CAMPAIGNS_MAIN.title')}
              titleDetail={
                campaignList?.length && !loadingProcess ? (
                  <Text as="span" color="primary" fontWeight="medium" id="total-count-campaigns">
                    {`
                    ${
                      totalsCampaignList.total &&
                      ` (${
                        formattedNumber(
                          totalsCampaignList.total,
                          i18n.language as keyof typeof ELanguageMap
                        ) || 0
                      })`
                    }`}
                  </Text>
                ) : (
                  ' (0)'
                )
              }
            />
            <Flex column withGap gapSize="medium">
              <TableContainer />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
      {toggleEditCreateCampaignModal && (
        <CreateEditDuplicateCampaigns onClose={handleOnCloseModal} />
      )}
      {toggleDeleteModal && <DeleteCampaigns campaigns={selectedCampaignsByID} />}
      {toggleDuplicateCampaignModal && <DuplicateCampaign onClose={handleOnCloseDuplicateModal} />}
    </>
  );
};

export default CampaignsMain;
