import _ from 'lodash';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table, TextSkeleton } from '@/lib/components';
import { NotesIcon } from '@/lib/icon';
import { Text, toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable, Notes } from '@/lib/v2/examples';
import { EmptyActivityIcon } from '@/lib/v2/icons/general';

import { useDate } from '@/src/infrastructure/services/useDate';

import { Note, NotesResponse } from '@/modules/ContactsModule/types/Notes';

export interface ContactsProfileNotesTableProps {
  notesList: NotesResponse;
  onGetListNotes: () => Promise<void>;
  notesTotalCount: number;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
  setTableOrderByList?: Dispatch<SetStateAction<{ id: string; isAsc: boolean | undefined }[]>>;
  tableOrderByList?: { id: string; isAsc: boolean | undefined }[];
  onOpenNote?: (value: boolean) => void;
  onDeleteNote?: (noteId: number) => Promise<boolean>;
  onEditNote?: (note: Note) => Promise<boolean>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ContactsProfileNotesTable = ({
  notesList,
  notesTotalCount,
  changeTablePage,
  totalPages,
  isLoading,
  onGetListNotes,
  onEditNote,
  onDeleteNote,
  onOpenNote,
}: ContactsProfileNotesTableProps) => {
  const [noteSelected, setNoteSelected] = useState<Note>();
  const [openNote, setOpenNote] = useState(false);
  const { dateFormatter } = useDate();
  const [isEditing, setIsEditing] = useState(false);

  const toggleOpenNote = () => setOpenNote((prevOpenNote) => !prevOpenNote);
  const { t } = useTranslation();

  const handleDeleteNote = useCallback(async () => {
    const result = await onDeleteNote?.(noteSelected?.id ? noteSelected?.id : 0);
    if (result) {
      toast({
        variant: 'success',
        title: t('PROFILE_NOTES_TABLE.noteDeleted'),
        autoCloseDelay: 5000,
        id: 'note-deleted-notification',
      });
      await onGetListNotes();
    } else {
      toast({
        variant: 'error',
        title: t('PROFILE_NOTES_TABLE.noteDeletedError'),
        autoCloseDelay: 5000,
        id: 'error-deleting-note-notification',
      });
    }
    toggleOpenNote();
  }, [noteSelected?.id, onDeleteNote, onGetListNotes, t]);

  const handleEditNote = useCallback(async () => {
    const result = await onEditNote?.(noteSelected as Note);
    if (result) {
      toast({
        variant: 'success',
        title: t('PROFILE_NOTES_TABLE.noteEdited'),
        autoCloseDelay: 5000,
        id: 'note-edited-notification',
      });
      await onGetListNotes();
    } else {
      toast({
        variant: 'error',
        title: t('PROFILE_NOTES_TABLE.noteEditedError'),
        autoCloseDelay: 5000,
        id: 'error-editing-note-notification',
      });
    }
    toggleOpenNote();
  }, [noteSelected, onEditNote, onGetListNotes, t]);

  const handleOnChangeNote = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setIsEditing(noteSelected?.content.length !== e.target.value.length);
      setNoteSelected((prevNote) => {
        const newNote = _.cloneDeep(prevNote);
        if (!newNote) {
          return prevNote;
        }
        newNote.content = e.target.value;
        return newNote;
      });
    },
    [noteSelected?.content.length]
  );

  const activityColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_NOTES_TABLE.activity')}</span>
      </Flex>
    ),
    accessor: 'activity',
    id: 'activity',
    width: '25%',
    Cell: () => (
      <Flex withGap alignment="start">
        <NotesIcon color="#004DBC" />
        <span className="self-start px-1 text-[#364365]">
          <strong>{`${t('PROFILE_NOTES_TABLE.note')}`}</strong>
        </span>
      </Flex>
    ),
  };

  const dateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_NOTES_TABLE.date')}</span>
      </Flex>
    ),
    accessor: 'creationDate',
    id: 'creationDate',
    width: '25%',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          <>{(value && dateFormatter(value)) || <TextSkeleton size="sm" />}</>
        </span>
      </Flex>
    ),
  };

  const descriptionColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_NOTES_TABLE.description')}</span>
      </Flex>
    ),
    accessor: 'content',
    id: 'content',
    width: '25%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start" className="max-w-150 md:max-w-200 lg:max-w-[400px]">
        <span className="self-start overflow-hidden text-ellipsis text-[#364365]">{value}</span>
      </Flex>
    ),
  };

  const stateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_NOTES_TABLE.preview')}</span>
      </Flex>
    ),
    accessor: 'preview',
    id: 'preview',
    width: '25%',
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: Note } };
    }) => {
      const handleViewNote = useCallback(() => {
        setNoteSelected(original);
        setOpenNote(true);
      }, [original]);

      return (
        <Flex withGap alignment="start">
          <Text link variant="text" onClick={handleViewNote}>
            {t('PROFILE_NOTES_TABLE.viewNote')}
          </Text>
        </Flex>
      );
    },
  };
  const activityTableColumns: Column<object>[] = [
    activityColumn,
    dateColumn,
    descriptionColumn,
    stateColumn,
  ].filter((x) => x) as Column<object>[];

  return (
    <div className="h-full w-full pt-6 text-base text-[#364365] 2xl:text-14">
      <Table
        isPaginateOnOverflow
        classNamePagination="pr-4"
        columns={activityTableColumns}
        data={notesList}
        emptyScreen={{
          totalCount: notesTotalCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityNoteAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityNoteDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyNoteTitle')}
              onClickAction={() => onOpenNote?.(true)}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityNoteAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityNoteDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyNoteTitle')}
              onClickAction={() => onOpenNote?.(true)}
            />
          ),
        }}
        isLoading={isLoading}
        withCheckbox={false}
        withPagination={{
          totalPages,
          fetchData: changeTablePage,
        }}
      />
      <Notes
        withModal
        disableButton={!isEditing}
        headerTitle={t('PROFILE_NOTES_TABLE.viewNote')}
        id="view-note"
        open={openNote}
        textCancelButton={t('PROFILE_NOTES_TABLE.cancel')}
        textSaveButton={t('PROFILE_NOTES_TABLE.save')}
        onChange={handleOnChangeNote}
        onCloseModal={toggleOpenNote}
        tooltipDelete={t('PROFILE_NOTES_TABLE.delete')}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onActionSave={handleEditNote}
        value={noteSelected?.content}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onActionDelete={handleDeleteNote}
      />
    </div>
  );
};
