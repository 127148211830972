import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Button, Flex, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/Badges';
import { ActionMasterIcon } from '@/lib/v2/icons/outline';
import { ReportIcon as ReportIconSolid } from '@/lib/v2/icons/solid';

import {
  EStates,
  ICampaign,
  IRecurrenceMessage,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useHandleEvents } from './useHandleEvents';

import { atomOrderBy } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { STRATEGY_MAP_BY_ID } from '@/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/constants';
import { TitleHeadCell } from '@/modules/CampaignsModule/components/TitleHeadCell';
import {
  DEFAULT_ORDERBY_RECURRENT_MESSAGE,
  TABLE_COLUMN_WIDTH,
} from '@/modules/CampaignsModule/constants';
import {
  DateFormat,
  formatDate,
  formattedNumber,
  getRecurrence,
} from '@/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';

export const useCampaignRecurrentTableColumns = () => {
  const { t, i18n } = useTranslation();
  const { handleClickReport } = useHandleEvents();

  const setOrderBy = useSetAtom(atomOrderBy);

  const [tableOrderByList, setTableOrderByList] = useState(DEFAULT_ORDERBY_RECURRENT_MESSAGE);

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
      setOrderBy({ sortField: element.id, sortOrder: orderDirectionValue });
    },
    [setOrderBy]
  );

  /** cspell:disable */
  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.name')} />
        <SortTableArrow
          elementName={'name'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'campaign',
    maxWidth: '340px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => (
      <div className="grid grid-cols-campaigns gap-4">
        <div className="shrink-0">
          <IconSvg
            rounded
            bgColor={value.state === EStates.ACTIVE ? 'success_new' : 'inactive'}
            fillColor={value.state === EStates.ACTIVE ? 'success_new' : 'inactive'}
            height="auto"
            svgComponent={<ActionMasterIcon />}
            width="40px"
            withPadding="small"
          />
        </div>
        <Tooltip isClickable noArrow alignment="start" content={value.name} position="top">
          <div className="self-start text-left !text-14">
            <div className="emblue-subheadings max-w-[85%] truncate font-medium">{value.name}</div>
            {value.strategyId && (
              <span className="emblue-text font-normal">
                {t(
                  `CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${
                    STRATEGY_MAP_BY_ID[Number(value.strategyId)]
                  }`,
                  String(value.strategy)
                )}
              </span>
            )}
          </div>
        </Tooltip>
      </div>
    ),
  };

  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.state')} />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'state',
    Cell: ({ cell: { value } }: { cell: { value: IRecurrenceMessage } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <BadgeText
            text={
              value.state === EStates.ACTIVE
                ? t('CAMPAIGNS_MAIN.STATES.active')
                : t('CAMPAIGNS_MAIN.STATES.inactive')
            }
            variantColor={value.state === EStates.ACTIVE ? 'success' : 'inactive'}
          />
        </Flex>
      </Flex>
    ),
  };

  const recurrenceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('RECURRENT_MESSAGE.TABLE.COLUMNS.recurrence')} />
        <SortTableArrow
          elementName={'recurrenceId'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'recurrence',
    Cell: ({ cell: { value } }: { cell: { value: IRecurrenceMessage } }) => {
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {getRecurrence(value.recurrenceId, t)}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const creationColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('RECURRENT_MESSAGE.TABLE.COLUMNS.date')} />
        <SortTableArrow
          elementName={'creationDate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'creation',
    Cell: ({ cell: { value } }: { cell: { value: IRecurrenceMessage } }) => {
      const formattedDate = value.creationDate
        ? formatDate(value.creationDate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const executionColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('RECURRENT_MESSAGE.TABLE.COLUMNS.execution')} />
        <SortTableArrow
          elementName={'executionDate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'execution',
    Cell: ({ cell: { value } }: { cell: { value: IRecurrenceMessage } }) => {
      const formattedDate = value.executionDate
        ? formatDate(value.executionDate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const audienceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('RECURRENT_MESSAGE.TABLE.COLUMNS.audience')} />
        <SortTableArrow
          elementName={'audience'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'audience',
    Cell: ({ cell: { value } }: { cell: { value: IRecurrenceMessage } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {formattedNumber(Number(value.audience), i18n.language as keyof typeof ELanguageMap) ||
              '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const buttonColumn = {
    Header: () => (
      <Flex withGap alignment="center">
        <TitleHeadCell text={t('RECURRENT_MESSAGE.TABLE.COLUMNS.report')} />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'buttons',
    width: TABLE_COLUMN_WIDTH.report,
    Cell: ({ cell: { value } }: { cell: { value: IRecurrenceMessage } }) => (
      <Flex withGap alignment="center" className="z-10">
        <Button
          gray
          outline
          data-id={value.campaignId}
          id="detail-actions"
          size="small"
          onClick={(event) => handleClickReport(event, value)}
        >
          <IconSvg
            fillColor="gray-dark"
            height="16px"
            svgComponent={<ReportIconSolid />}
            width="13px"
          />
        </Button>
      </Flex>
    ),
  };
  /** cspell:enable */

  const recurrentMessageColumns: Column<object>[] = [
    nameColumn,
    statusColumn,
    recurrenceColumn,
    creationColumn,
    executionColumn,
    audienceColumn,
    buttonColumn,
  ].filter((x) => x) as Column<object>[];

  return { recurrentMessageColumns };
};
