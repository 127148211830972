import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Card, CardSection, Flex } from '@/lib/v2/components';

import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import IframeTransport from '@/src/compat/iframeTransport';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import useMessage from '@/src/compat/useMessage';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { ICampaignDetailResponse } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

const CampaignReport = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showSpinnerByAction, setShowSpinnerByAction] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const haveData = useMemo(() => infoCampaignV1?.campaign?.name, [infoCampaignV1]);
  const service = useCampaignsService();
  const { t } = useTranslation();

  useMessage(EventListenerType.Loaded, (_) => {
    showSpinnerByAction && setIsLoaded(true);
  });

  useEffect(() => {
    const type = 'manual';
    isLoaded &&
      showSpinnerByAction &&
      SendActionsPostMessage('Campaign', 'ViewReport', { id, type });
  }, [showSpinnerByAction, isLoaded, id]);

  const getCampaignData = async () => {
    if (!id) return;

    const response: ICampaignDetailResponse = await service.getCampaignByID(parseInt(id));

    setInfoCampaignV1({
      campaign: {
        id: parseInt(id || '0'),
        name: response?.campaign?.name ?? '',
      },
    });
  };

  const handleOnBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useMessage(EventListenerType.LoadedReport, (_) => {
    if (isLoaded && showSpinnerByAction) {
      if (!haveData) {
        void getCampaignData();
      }
      setShowSpinnerByAction(false);
    }
  });

  useEffect(() => {
    SendActionsPostMessage('Campaign', 'VerifyLoaded');
  }, []);

  const CampaignReportPaths = useMemo(
    () => [
      {
        name: t('REPORT'),
      },
      {
        name: infoCampaignV1?.campaign?.name,
      },
    ],
    [infoCampaignV1?.campaign?.name, t]
  );

  return (
    <>
      <Breadcrumb customPaths={CampaignReportPaths} handleOnBackClick={handleOnBackClick} />
      <Card>
        <CardSection noPadding>
          <Flex column>
            <Flex column withGap gapSize="medium">
              <IframeTransport loading={showSpinnerByAction} />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </>
  );
};

export default CampaignReport;
