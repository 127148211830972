import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Card, CardSection, IconSvg, IconSvgProps, Text } from '@/lib/v2/components';
import { CardAction } from '@/lib/v2/examples';

import { useRedirectToItemIFrameV1 } from '@/src/application/hooks/useRedirectToItemIframeV1';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  AutomationIcon,
  EcommerceIcon,
  NpsIcon,
  PixelTrackingIcon,
  SmsIcon,
  WidgetIcon,
} from '@/src/modules/OnboardingModule/images/icons';

import { LockIcon } from './LockIcon';
interface CardEmblueItemProps {
  title: string;
  description: string;
  icon: ReactElement<IconSvgProps>;
  isLocked: boolean;
  onClick?: () => void;
}

const CardEmblue = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { redirectToItemIFrameV1 } = useRedirectToItemIFrameV1();
  const showNpsModule = useFeatureFlag('showNpsModule');

  const cardsItems = useMemo<CardEmblueItemProps[]>(
    () => [
      {
        title: t('ONBOARDING.CARD_EMBLUE.pixelTitle'),
        description: t('ONBOARDING.CARD_EMBLUE.pixelDescription'),
        icon: (
          <IconSvg
            rounded
            bgColor="often"
            className="!rounded-md"
            height="40px"
            svgComponent={<PixelTrackingIcon />}
            width="40px"
            withPadding="small"
          />
        ),
        onClick: () => redirectToItemIFrameV1('/v2/configuration', ['btn-webconnect']),
        isLocked: false,
      },
      {
        title: t('ONBOARDING.CARD_EMBLUE.smsTitle'),
        description: t('ONBOARDING.CARD_EMBLUE.smsDescription'),
        icon: (
          <IconSvg
            rounded
            bgColor="sleepy"
            className="!rounded-md"
            height="40px"
            svgComponent={<SmsIcon />}
            width="40px"
            withPadding="small"
          />
        ),
        onClick: () => navigate('/v2/campaigns'),
        isLocked: false,
      },
      {
        title: t('ONBOARDING.CARD_EMBLUE.widgetTitle'),
        description: t('ONBOARDING.CARD_EMBLUE.widgetDescription'),
        icon: (
          <IconSvg
            rounded
            bgColor="greenLight"
            className="!rounded-md"
            height="40px"
            svgComponent={<WidgetIcon />}
            width="40px"
            withPadding="small"
          />
        ),
        onClick: () => navigate('/v2/onsite'),
        isLocked: false,
      },
      {
        title: t('ONBOARDING.CARD_EMBLUE.automationTitle'),
        description: t('ONBOARDING.CARD_EMBLUE.automationDescription'),
        icon: (
          <IconSvg
            rounded
            bgColor="primary"
            className="!rounded-md"
            height="40px"
            svgComponent={<AutomationIcon />}
            width="40px"
            withPadding="small"
          />
        ),
        onClick: () => navigate('/v2/automation'),
        isLocked: true,
      },
      {
        title: t('ONBOARDING.CARD_EMBLUE.npsTitle'),
        description: t('ONBOARDING.CARD_EMBLUE.npsDescription'),
        icon: (
          <IconSvg
            rounded
            bgColor="often"
            className="!rounded-md"
            height="40px"
            svgComponent={<NpsIcon />}
            width="40px"
            withPadding="small"
          />
        ),
        onClick: () => {
          if (showNpsModule) navigate('/v2/nps');
          else navigate('/v2/onsite');
        },
        isLocked: true,
      },
      {
        title: t('ONBOARDING.CARD_EMBLUE.ecommerceTitle'),
        description: t('ONBOARDING.CARD_EMBLUE.ecommerceDescription'),
        icon: (
          <IconSvg
            rounded
            bgColor="sleepy"
            className="!rounded-md"
            height="40px"
            svgComponent={<EcommerceIcon />}
            width="40px"
            withPadding="small"
          />
        ),
        onClick: () => {
          if (showNpsModule) navigate('/v2/nps');
          else navigate('/v2/onsite');
        },
        isLocked: true,
      },
    ],
    [navigate, redirectToItemIFrameV1, showNpsModule, t]
  );

  // const Badged = () => {
  //   return (
  //     <div className="flex items-center justify-center gap-2 rounded-xl border border-[#FC3293] px-1 py-0.5">
  //       <IconSvg strokeColor="alert" svgComponent={<LockIcon />} />
  //       <Text className="!text-[#FC3293]" fontWeight="medium" variant="text">
  //         {t('ONBOARDING.CARD_EMBLUE.locked')}
  //       </Text>
  //     </div>
  //   );
  // };

  return (
    <Card>
      <CardSection noPadding>
        <div className="w-full p-4">
          <Text fontWeight="medium" variant="sub-headline">
            {t('ONBOARDING.CARD_EMBLUE.title')}
          </Text>
        </div>
        <div className="grid w-full grid-cols-2 divide-x divide-y md:grid-cols-3">
          {cardsItems.map((item) => {
            const { title, description, icon, onClick } = item;
            return (
              <button key={title} className="w-full first:border-t" onClick={onClick}>
                <CardAction
                  noBorder
                  noShadow
                  classNameSection="!p-0"
                  description={description}
                  icon={icon}
                  title={title}
                />
              </button>
            );
          })}
        </div>
      </CardSection>
    </Card>
  );
};

export default CardEmblue;
