import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { UseControllerProps, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useAutomationService } from '@/src/infrastructure/Protocol/Automation/useBeefreeService';
import {
  atomCreateEventForm,
  atomCreateEventFormError,
  atomCreateEventFormIsValid,
  atomEventData,
} from '@/src/modules/AutomationModule/atoms/createEventAtoms';
import { useEditActions } from '@/src/modules/AutomationModule/components/AutomationModals/FlowRename/hooks/useEditActions';
import { AUTOMATION_PATHS } from '@/src/modules/AutomationModule/constants';
import { EMaxMinLength } from '@/src/modules/AutomationModule/interfaces/automation';
import { CreateEventForm } from '@/src/modules/AutomationModule/types/events';

type WithValue = {
  value: string;
};

interface UseFormCreateEventProps {
  isEditView?: boolean;
}

export const useFormCreateEvent = ({ isEditView = false }: UseFormCreateEventProps) => {
  const { t } = useTranslation();
  const service = useAutomationService();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { VALIDATION_RULES_EVENTS } = useEditActions();

  const setCreateEventForm = useSetAtom(atomCreateEventForm);
  const [eventData, setEventData] = useAtom(atomEventData);
  const setCreateEventFormIsValid = useSetAtom(atomCreateEventFormIsValid);
  const createEventFormError = useAtomValue(atomCreateEventFormError);

  const {
    control,
    formState: { isValid },
    setValue,
    watch,
    getValues,
    trigger,
    setError,
  } = useForm<CreateEventForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 500,
    defaultValues: {
      name: '',
      description: '',
      modelData: false,
      attributes: [],
      eventItems: [],
    },
  });

  const {
    fields: attributeFields,
    append: appendAttribute,
    remove: removeAttribute,
  } = useFieldArray({
    control,
    name: 'attributes',
  });

  const {
    fields: eventItemsFields,
    append: appendEventItems,
    remove: removeEventItems,
  } = useFieldArray({
    control,
    name: 'eventItems',
  });

  const stateForm = watch();

  const findDuplicateValues = useCallback(
    <T extends WithValue>(arrayList: T[], value: string) => {
      const values = arrayList.map((attr) => attr.value);
      const hasDuplicates = values.filter((val) => val === value).length > 1;
      return !hasDuplicates || t('useFormCreateEvent.attributesUnique');
    },
    [t]
  );

  const rules: { [K in keyof CreateEventForm]: UseControllerProps['rules'] } = useMemo(
    () => ({
      name: VALIDATION_RULES_EVENTS.input,
      description: {
        required: t('SIGN_UP_FORM_ONE.required'),
        minLength: VALIDATION_RULES_EVENTS.input.minLength,
        maxLength: {
          value: EMaxMinLength.DESCRIPTION_EVENT_MAX,
          message: t(`AUTOMATION_MAIN_MODULE.MODALS.MODALS_FLOW.RENAME.INPUTS_ERROR.maxLength`, {
            name: t(
              `AUTOMATION_MAIN_MODULE.MODALS.MODALS_FLOW.RENAME.INPUTS_FORM.description.label`
            ).toLowerCase(),
            max: EMaxMinLength.DESCRIPTION_EVENT_MAX,
          }),
        },
      },
      modelData: {},
      attributes: {
        required: t('SIGN_UP_FORM_ONE.required'),
        pattern: {
          value: /^[\w ]+$/,
          message: t('useFormCreateEvent.attributesPattern'),
        },
        validate: {
          unique: (value: string) => {
            return findDuplicateValues(stateForm.attributes, value);
          },
        },
      },
      eventItems: {
        required: t('SIGN_UP_FORM_ONE.required'),
        pattern: {
          value: /^[\w ]+$/,
          message: t('useFormCreateEvent.attributesPattern'),
        },
        validate: {
          unique: (value: string) => {
            return findDuplicateValues(stateForm.eventItems, value);
          },
        },
      },
    }),
    [
      VALIDATION_RULES_EVENTS.input,
      findDuplicateValues,
      stateForm.attributes,
      stateForm.eventItems,
      t,
    ]
  );

  const handleResetModelData = useCallback(() => {
    setValue('attributes', []);
    setValue('eventItems', []);
  }, [setValue]);

  const handleOnChangeInputReplaceSpace = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      const valueSpaceReplaced = value.replace(/\s+/g, '_');
      setValue(name as keyof CreateEventForm, valueSpaceReplaced);
    },
    [setValue]
  );

  const handleRemoveAttribute = useCallback(
    async (index: number) => {
      removeAttribute(index);
      await trigger('attributes');
    },
    [removeAttribute, trigger]
  );

  const handleRemoveEventItems = useCallback(
    async (index: number) => {
      removeEventItems(index);
      await trigger('eventItems');
    },
    [removeEventItems, trigger]
  );

  const handleSetValuesFormToAtom = useCallback(() => {
    const values = getValues();
    setCreateEventForm(values);
  }, [getValues, setCreateEventForm]);

  const getDetailEventById = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const response = await service.getEventById(+id);
      if (response === null) {
        toast({
          id: 'getDetailEventByIdError',
          title: t('useCreateEvent.getDetailEventById.error.title'),
          body: t('useCreateEvent.getDetailEventById.error.body'),
          variant: 'error',
        });
        navigate(`/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.EVENTS_PATH}`);
        return;
      } else {
        if (response.name.startsWith('onsite')) {
          navigate(`/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.EVENTS_PATH}`);
          return;
        }
        const { integrationId, ...restData } = response;
        setCreateEventForm(restData);
        setEventData({ ...restData, integrationId });
        setValue('name', response.name);
        setValue('description', response.description);
        setValue('modelData', response.modelData);
        setValue('attributes', response.attributes);
        setValue('eventItems', response.eventItems);
        void trigger();
      }
      setIsLoading(false);
    },
    [navigate, service, setCreateEventForm, setEventData, setValue, t, trigger]
  );

  const validIntegrationIds = [1, 8, 12];
  const isIntegrationEvent = validIntegrationIds.includes(eventData?.integrationId ?? 0);

  useEffect(() => {
    setCreateEventFormIsValid(isValid);
  }, [isValid, setCreateEventFormIsValid]);

  useEffect(() => {
    if (createEventFormError !== '') {
      setError('name', { message: createEventFormError });
    }
  }, [createEventFormError, setError]);

  useEffect(() => {
    if (isEditView && eventId) {
      void getDetailEventById(eventId);
    }
  }, []);

  return {
    control,
    rules,
    stateForm,
    handleResetModelData,
    attributeFields,
    eventItemsFields,
    appendAttribute,
    appendEventItems,
    handleRemoveAttribute,
    handleRemoveEventItems,
    handleOnChangeInputReplaceSpace,
    handleSetValuesFormToAtom,
    isLoading,
    isIntegrationEvent,
  };
};
