import deepEqual from 'fast-deep-equal';
import { atom } from 'jotai';
import { atomFamily, atomWithReset, RESET } from 'jotai/utils';
import { ChangeEvent } from 'react';

export type TValue = boolean | number | string | Blob | [] | any;

export interface IField<T extends TValue> {
  id?: string;
  validate?: (name: string, value: TValue, formData: IForm) => boolean;
  defaultValue?: TValue | T;
  value?: T;
  disabled?: boolean;
  label?: string;
  date?: Date | null;
  checked?: boolean;
  isDirty?: boolean;
  isRequired?: boolean;
  onChange?: (evt: ChangeEvent<HTMLInputElement> | any, field: IField<any>) => T;
  readOnly?: boolean;
  placeholder?: string;
  type?: string;
  step?: number;
  required?: string;
  pristine?: boolean;
  maxLength?: number;
  pattern?: {
    value: string;
    message?: string;
  };
}

export interface IFormConfig<T extends TValue> {
  [key: string]: IField<T>;
}

export interface IForm {
  [key: string]: IField<TValue>;
}

interface IProps<T> {
  name: string;
  config: IFormConfig<T>;
}

export interface IRegister<T extends TValue>
  extends Omit<IField<T>, 'pattern' | 'required' | 'pristine' | 'onChange'> {
  name?: string;
  value?: T;
  checked?: boolean;
  onChange?: (evt: any) => void;
  error?: boolean;
  message?: string;
}

export const atomCurrentForm = atomWithReset<null | IForm>(null);
atomCurrentForm.debugLabel = 'atomCurrentForm';

export const currentFormSelector = atomFamily(<T>(args: IProps<T>) => {
  const { name, config } = args;
  const newAtom = atom(
    (get) => {
      const vForms = get(atomCurrentForm);
      const newForm = {
        ...config,
        ...(vForms?.[name] ?? {}),
      };

      return newForm as IForm;
    },
    (get, set, arg: IForm | typeof RESET) => {
      const vForms = get(atomCurrentForm);

      if (arg === RESET) {
        set(atomCurrentForm, RESET);
        return {};
      }

      const newItem = {
        ...(config?.[name] ?? {}),
        ...(vForms?.[name] ?? {}),
        ...arg,
      };

      const payload: IForm = {
        ...vForms,
        [name]: {
          ...config,
          ...newItem,
        },
      };

      if (
        newItem?.maxLength &&
        typeof newItem?.value === 'string' &&
        newItem?.value.length > newItem?.maxLength
      )
        return vForms?.[name] ?? {};

      set(atomCurrentForm, payload);
    }
  );
  newAtom.debugLabel = '@atomFamily/currentFormSelector.' + name;

  return newAtom;
}, deepEqual);

export const currentValidSelector = atomFamily(
  <T>(args: IProps<T> & { config: IFormConfig<T> }) => {
    const { name, config } = args;

    const validateField = (field: IField<T>) => {
      const { value, pattern, required, defaultValue } = field;

      if (required && !pattern && (typeof value === 'string' || typeof defaultValue === 'string')) {
        const result =
          typeof value === 'undefined' ? (defaultValue !== '' ? true : false) : value !== '';

        return result;
      }

      if (!pattern?.value) {
        return true;
      }

      const exp = new RegExp(pattern?.value);

      if (typeof value !== 'string' && typeof defaultValue !== 'string') {
        return true;
      }

      const matches = (typeof value !== 'undefined' ? value : defaultValue).match(exp);

      return Array.isArray(matches) && matches?.length > 0 ? true : false;
    };

    const newAtom = atom((get) => {
      const vForms = get(atomCurrentForm);
      const result = [];

      const form = (vForms?.[name] ?? config ?? {}) as IForm;

      for (const fieldName of Object.keys(form)) {
        const input = form?.[fieldName as keyof typeof form] as IField<T>;
        const field = {
          ...(config?.[fieldName] ?? {}),
          ...input,
        };

        if ((!field.validate && !field.pattern && !field.required) || form === null) continue;

        if (field.validate) {
          result.push(field.validate(fieldName, field, form));
        } else if (field.pattern || field.required) {
          result.push(validateField(field));
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return result?.every((item) => (item ? true : false));
    });

    newAtom.debugLabel = '@atomFamily/currentValidSelector.' + name;

    return newAtom;
  },
  deepEqual
);

export const currentErrorsSelector = atomFamily(
  <T>(args: IProps<T> & { config: IFormConfig<T> }) => {
    const { name, config } = args;

    const validateField = (field: IField<T>) => {
      const { pattern, value, defaultValue } = field;

      if (pattern && pattern.value) {
        const exp = new RegExp(pattern?.value);
        const newValue = value === undefined ? defaultValue : value;

        if ((typeof value !== 'string' && typeof defaultValue !== 'string') || newValue === '')
          return false;

        return exp.test(newValue);
      }

      if (pattern && typeof field?.pattern?.value === 'undefined') return false;
      if (
        field.required &&
        !pattern &&
        ((typeof value === 'string' && value !== '') ||
          (value === undefined && typeof defaultValue === 'string' && defaultValue !== ''))
      )
        return true;

      return false;
    };

    const newAtom = atom((get) => {
      const vForms = get(atomCurrentForm);
      const result = {} as { [key: string]: string };

      const form = (vForms?.[name] ?? config ?? {}) as IForm;

      for (const fieldName of Object.keys(form)) {
        const input = form?.[fieldName as keyof typeof form] as IField<T>;
        const field = {
          ...(config?.[fieldName] ?? {}),
          ...input,
        };

        if ((!field.validate && !field.pattern && !field.required) || form === null) continue;

        if (field.validate && !field.validate(fieldName, field as IField<T>, form)) {
          result[fieldName] = field?.required ?? field.pattern?.message ?? '';
        } else if (field.pattern && field.required && !validateField(field)) {
          result[fieldName] = field.pattern?.message || '';
        } else if (!field.pattern && field.required && !field.validate && !validateField(field)) {
          result[fieldName] = field?.required || '';
        }
      }

      return result;
    });

    newAtom.debugLabel = '@atomFamily/currentErrorsSelector.' + name;

    return newAtom;
  },
  deepEqual
);

export const currentPristineSelector = atomFamily(<T>(args: IProps<T>) => {
  const { name } = args;

  const newAtom = atom((get) => {
    let vForms = get(atomCurrentForm);
    const vSaved = localStorage.getItem('@form-persistence');
    if (vSaved && vForms === null) {
      vForms = JSON.parse(vSaved);
    }

    const result = true;

    const form = vForms?.[name];
    if (!form) return true;

    for (const fieldName of Object.keys(form)) {
      const input = form?.[fieldName as keyof typeof form] as IField<T>;

      if (input.value) return false;
    }

    return result;
  });

  newAtom.debugLabel = '@atomFamily/currentErrorsSelector.' + name;

  return newAtom;
}, deepEqual);
