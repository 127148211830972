import { useTranslation } from 'react-i18next';

import { formValidations } from '@/src/constants/FormValidations';

import useDebouncedFetchService from '@/modules/CampaignsModule/hooks/useDebouncedFetchService';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useRecurrentForm = () => {
  const { t } = useTranslation();
  const { inputLengths, formRegExp } = formValidations;
  const { fetchServiceValidName } = useValidNameResource();

  const { validateFetchService, isLoading: isLoadingDebounce } = useDebouncedFetchService(
    async (value: string) => {
      const isValid = await fetchServiceValidName({
        name: value,
        resource: EResourceType.CAMPAIGN,
      });
      return !!isValid;
    },
    500
  );

  const VALIDATION_RULES = {
    input: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
      validate: async (value: string) => {
        return (
          (await validateFetchService(value)) ||
          t('RECURRENT_MESSAGE.CREATE_RECURRENT.existCampaignRecurrent')
        );
      },
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
    },
  };

  return {
    loadingValidate: isLoadingDebounce,
    VALIDATION_RULES,
  };
};
