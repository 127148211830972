import { useAtomValue } from 'jotai';
import { noop } from 'lodash';
import { MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/lib/components/Button/Button';
import { Card, CardProps } from '@/lib/components/Card/Card';
import { Icon } from '@/lib/components/Image/Icon';
import { RoundedImage } from '@/lib/components/Image/RoundedImage';
import { Counter } from '@/lib/components/Text/Counter';
import { Heading } from '@/lib/components/Text/Heading';
import { Link } from '@/lib/components/Text/Link';
import { SubHeading } from '@/lib/components/Text/SubHeading';
import { RouteEnum } from '@/lib/components/TopBar/routeEnum';
import { Circle } from '@/lib/components/Util/Circle';
import { Spinner } from '@/lib/components/Util/Spinner';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useSenderScore } from '@/src/application/hooks/useSenderScore';
import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { useVersion } from '@/src/application/hooks/useVersion';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { TrackEvent } from '@/src/application/util/mixPanel';
import { atomShowCampaignsV1 } from '@/src/atoms/Campaigns';
import configData from '@/src/config.json';
import { mixPanelEvents } from '@/src/presentation/util/enum/mixPanelEventsEnum';
import { SenderScoring } from '@/src/presentation/util/enum/senderScoringEnum';
import { AddContact } from '@/src/presentation/util/icon/AddContact';
import { AutomationIcon } from '@/src/presentation/util/icon/AutomationIcon';
import { ContactImage } from '@/src/presentation/util/icon/ContactIcon';
import { ECommerceIcon } from '@/src/presentation/util/icon/ECommerceIcon';
import { FacebookIcon } from '@/src/presentation/util/icon/FacebookIcon';
import { GroupIcon } from '@/src/presentation/util/icon/GroupIcon';
import { IntegrationImage } from '@/src/presentation/util/icon/IntegrationIcon';
import { ListIcon } from '@/src/presentation/util/icon/ListIcon';
import { MailImage } from '@/src/presentation/util/icon/MailIcon';
import { MainIcon } from '@/src/presentation/util/icon/MainIcon';
import { SmsImage } from '@/src/presentation/util/icon/SmsIcon';
import { UserIcon } from '@/src/presentation/util/icon/UserIcon';
import { WidgetImage } from '@/src/presentation/util/icon/WidgetIcon';

export function redirectToActions(route: string, message: string, navigate: Function) {
  navigate(`/v2${route}`);

  setTimeout(() => {
    const iframe = document.getElementById('v1-container') as HTMLIFrameElement;
    const createActions = {
      payload: {
        event: 'HomeActions',
        message: message,
      },
    };

    if (iframe) {
      iframe && iframe.contentWindow?.postMessage(createActions, '*');
    }
  }, 1500);
}

export function onCreateCampaignCardClick(
  userData: UserData,
  version: string,
  navigate: Function,
  showCampaignsV1: boolean
) {
  TrackEvent(userData, mixPanelEvents.CreateCampaignButton);
  if (version === 'v2') {
    if (showCampaignsV1) {
      const message = configData.HOME.CREATE_CAMPAIGN_MESSAGE;
      redirectToActions(RouteEnum.Campaigns, message, navigate);
    } else {
      navigate('/v2/campaigns');
    }
  } else {
    window.parent.postMessage({ message: configData.HOME.CREATE_CAMPAIGN_MESSAGE }, '*');
  }
}

export const CreateCampaignCard = ({
  onAction,
  userData,
}: CardProps & { userData?: UserData; onAction?: MouseEventHandler<HTMLButtonElement> }) => {
  const { t } = useTranslation();
  const { version } = useVersion();
  const navigate = useNavigate();
  const showCampaignsV1 = useAtomValue(atomShowCampaignsV1);

  return (
    <Card>
      <Flex column withGap>
        <RoundedImage size="8rem" src={<MailImage />} />
        <Heading>{t('CreateCampaignCard.title')}</Heading>
        <SubHeading>{t('CreateCampaignCard.desc')}</SubHeading>
        <Button
          fullWidth
          onAction={
            onAction ||
            (userData &&
              (() =>
                onCreateCampaignCardClick(
                  userData,
                  version,
                  navigate,
                  showCampaignsV1 === 'true'
                ))) ||
            noop
          }
        >
          {t('CreateCampaignCard.button')}
        </Button>
      </Flex>
    </Card>
  );
};

export function onCreateContactCardClick(
  userData: UserData,
  version: string,
  navigate: Function,
  importV2FF: boolean
) {
  TrackEvent(userData, mixPanelEvents.CreateContactButton);
  if (version === 'v2') {
    if (importV2FF) {
      navigate(RouteEnum.ContactsImport);
    } else {
      const message = configData.HOME.CREATE_CONTACT_MESSAGE;
      redirectToActions(RouteEnum.Contacts, message, navigate);
    }
  } else {
    window.parent.postMessage({ message: configData.HOME.CREATE_CONTACT_MESSAGE }, '*');
  }
}

export const CreateContactCard = ({
  onAction,
  userData,
}: CardProps & { userData?: UserData; onAction?: MouseEventHandler<HTMLButtonElement> }) => {
  const { t } = useTranslation();
  const { version } = useVersion();
  const navigate = useNavigate();
  const importV2IsEnabled = useFeatureFlag('contactMenuBarImport');

  return (
    <Card>
      <Flex column withGap textAlignment="left">
        <RoundedImage size="8rem" src={<ContactImage />} />
        <Heading>{t('CreateContactCard.title')}</Heading>
        <SubHeading>{t('CreateContactCard.desc')}</SubHeading>
        <Button
          fullWidth
          onAction={
            onAction ||
            (userData &&
              (() => onCreateContactCardClick(userData, version, navigate, importV2IsEnabled))) ||
            noop
          }
        >
          {t('CreateContactCard.button')}
        </Button>
      </Flex>
    </Card>
  );
};

function onCreateSmsCardClick(
  userData: UserData,
  version: string,
  navigate: Function,
  showCampaignsV1: boolean
) {
  TrackEvent(userData, mixPanelEvents.CreateSmsButton);
  if (version === 'v2') {
    if (showCampaignsV1) {
      const message = configData.HOME.CREATE_SMS_MESSAGE;
      redirectToActions(RouteEnum.Campaigns, message, navigate);
    } else {
      navigate('/v2/campaigns');
    }
  } else {
    window.parent.postMessage({ message: configData.HOME.CREATE_SMS_MESSAGE }, '*');
  }
}

export const CreateSmsCard = ({
  onAction,
  userData,
}: CardProps & { userData?: UserData; onAction?: MouseEventHandler<HTMLButtonElement> }) => {
  const { t } = useTranslation();
  const { version } = useVersion();
  const navigate = useNavigate();
  const showCampaignsV1 = useAtomValue(atomShowCampaignsV1);

  return (
    <Card>
      <Flex column withGap>
        <RoundedImage size="8rem" src={<SmsImage />} />
        <Heading>{t('CreateSmsCard.title')}</Heading>
        <SubHeading>{t('CreateSmsCard.desc')}</SubHeading>
        <Button
          fullWidth
          onAction={
            onAction ||
            (userData &&
              (() =>
                onCreateSmsCardClick(userData, version, navigate, showCampaignsV1 === 'true'))) ||
            noop
          }
        >
          {t('CreateSmsCard.button')}
        </Button>
      </Flex>
    </Card>
  );
};

function onCreateWidgetCardClick(userData: UserData, version: string, navigate: Function) {
  TrackEvent(userData, mixPanelEvents.CreateWidgetButton);

  if (version === 'v2') {
    const message = configData.HOME.GO_TO_WIDGET;
    redirectToActions(RouteEnum.OnSite, message, navigate);
  } else {
    window.parent.postMessage({ message: configData.HOME.GO_TO_WIDGET }, '*');
  }
}

export const CreateWidgetCard = ({
  onAction,
  userData,
}: CardProps & { userData?: UserData; onAction?: MouseEventHandler<HTMLButtonElement> }) => {
  const { t } = useTranslation();
  const { version } = useVersion();
  const navigate = useNavigate();
  return (
    <Card>
      <Flex column withGap>
        <RoundedImage size="8rem" src={<WidgetImage />} />
        <Heading>{t('CreateWidgetCard.title')}</Heading>
        <SubHeading>{t('CreateWidgetCard.desc')}</SubHeading>
        <Button
          fullWidth
          onAction={
            onAction ||
            (userData && (() => onCreateWidgetCardClick(userData, version, navigate))) ||
            noop
          }
        >
          {t('CreateWidgetCard.button')}
        </Button>
      </Flex>
    </Card>
  );
};

function onIntegrationCardClick(userData: UserData, version: string, navigate: Function) {
  TrackEvent(userData, mixPanelEvents.IntegrationButton);

  if (version === 'v2') {
    const message = configData.HOME.GO_TO_INTEGRATIONS_MESSAGE;
    redirectToActions(RouteEnum.Configuration, message, navigate);
  } else {
    window.parent.postMessage({ message: configData.HOME.GO_TO_INTEGRATIONS_MESSAGE }, '*');
  }
}

export const IntegrationCard = ({
  onAction,
  userData,
}: CardProps & { userData?: UserData; onAction?: MouseEventHandler<HTMLButtonElement> }) => {
  const { t } = useTranslation();
  const { version } = useVersion();
  const navigate = useNavigate();
  return (
    <Card>
      <Flex column withGap>
        <Icon
          circle
          backgroundClass="bg-emblueBlue/25"
          padding="2rem"
          size="8rem"
          src={<IntegrationImage />}
        />
        <SubHeading>{t('INTEGRATION.description')}</SubHeading>
        <Button
          fullWidth
          onAction={
            onAction ||
            (userData && (() => onIntegrationCardClick(userData, version, navigate))) ||
            noop
          }
        >
          {t('INTEGRATION.button')}
        </Button>
      </Flex>
    </Card>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SenderScoringCard = ({
  onAction,
  senderScore,
}: CardProps & { onAction?: MouseEventHandler<HTMLButtonElement>; senderScore?: number }) => {
  const { t, i18n } = useTranslation();
  const [scoring, error] = useSenderScore();

  const colorProps = { red: false, blue: false, yellow: false };
  const dateLabel = useMemo(
    () =>
      new Date().toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }),
    [i18n.language]
  );
  let label, title, textLink;

  if (typeof scoring === 'number' && scoring >= SenderScoring.maxScore) {
    title = t('SENDER_SCORING.title');
    textLink = t('SENDER_SCORING.textLink');
    label = t('SENDER_SCORING.high');
    colorProps.blue = true;
  } else if (typeof scoring === 'number' && scoring <= SenderScoring.minScore) {
    title = t('SENDER_SCORING.title');
    textLink = t('SENDER_SCORING.textLink');
    label = t('SENDER_SCORING.low');
    colorProps.red = true;
  } else if (typeof scoring === 'number') {
    title = t('SENDER_SCORING.title');
    textLink = t('SENDER_SCORING.textLink');
    label = t('SENDER_SCORING.medium');
    colorProps.yellow = true;
  }

  return (
    <Card {...colorProps}>
      <Flex column withGap alignment="spaceEvenly">
        {typeof scoring !== 'number' ? (
          <>
            <Spinner />
          </>
        ) : null}
        {typeof scoring === 'number' ? (
          <>
            <Heading>{title}</Heading>
            <Circle size="12rem">
              <Counter>{scoring.toString()}</Counter>
            </Circle>
            <Heading>{label}</Heading>
            <Link
              onClick={() => {
                window.open(configData.HOME.SENDER_SCORING_GUIDE_URL, '_blank');
              }}
            >
              {textLink}
            </Link>
            <SubHeading>{dateLabel}</SubHeading>
          </>
        ) : null}
      </Flex>
    </Card>
  );
};

export const ContactListCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass="bg-[#f0fdfa]"
          padding="0.75rem"
          size="3rem"
          src={<ListIcon />}
        />
        <Heading>{t('CARDS.contactListHeading')}</Heading>
        <SubHeading>{t('CARDS.contactListSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};

export const GetNewClientsCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass="bg-[#F5F3FF]"
          padding="0.75rem"
          size="3rem"
          src={<AddContact />}
        />
        <Heading>{t('CARDS.newClientsHeading')}</Heading>
        <SubHeading>{t('CARDS.newClientsSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};

export const UserActionInfoCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass="bg-[#f0fdfa]"
          padding="0.75rem"
          size="3rem"
          src={<UserIcon />}
        />
        <Heading>{t('CARDS.userActionHeading')}</Heading>
        <SubHeading>{t('CARDS.userActionSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};

export const ECommerceCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass="bg-[#F5F3FF]"
          padding="0.75rem"
          size="3rem"
          src={<ECommerceIcon />}
        />
        <Heading>{t('CARDS.eCommerceHeading')}</Heading>
        <SubHeading>{t('CARDS.eCommerceSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};

export const FacebookCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass="bg-[#F0F9FF]"
          padding="1rem"
          size="3rem"
          src={<FacebookIcon />}
        />
        <Heading>{t('CARDS.facebookHeading')}</Heading>
        <SubHeading>{t('CARDS.facebookSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};

export const ConversationCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass="bg-[#FFFBEB]"
          padding="0.75rem"
          size="3rem"
          src={<MainIcon />}
        />
        <Heading>{t('CARDS.conversationHeading')}</Heading>
        <SubHeading>{t('CARDS.conversationSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};

export const AutomationCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass="bg-[#F0F9FF]"
          padding="0.75rem"
          size="3rem"
          src={<AutomationIcon />}
        />
        <Heading>{t('CARDS.automateHeading')}</Heading>
        <SubHeading>{t('CARDS.automateSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};

export const OmnichannelCard = () => {
  const { t } = useTranslation();
  return (
    <Card noShadow>
      <Flex column withGap alignment="spaceEvenly" itemAlignment="start" textAlignment="left">
        <Icon
          rounded
          backgroundClass={'bg-[#FFFBEB]'}
          padding="0.75rem"
          size="3rem"
          src={<GroupIcon />}
        />
        <Heading>{t('CARDS.omnichannelHeading')}</Heading>
        <SubHeading>{t('CARDS.omnichannelSubHeading')}</SubHeading>
      </Flex>
    </Card>
  );
};
