import { useSetAtom } from 'jotai';
import { memo, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert, Card, CardSection, Text, toast } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

import { atomDowngradeIsPending } from '@/modules/MyPlanModule/atoms/ManagePlan';

interface DownGradeStatusProps {
  isAlert?: boolean;
}

const DownGradeStatus = ({ isAlert }: DownGradeStatusProps) => {
  const { t } = useTranslation();
  const service = useService();

  const setDowngradeIsPending = useSetAtom(atomDowngradeIsPending);
  const [cancelDowngradeIsLoading, setCancelDowngradeIsLoading] = useState(false);

  const updateDowngradeStatus = useCallback(async () => {
    const response = await service.getDowngradeStatus();
    if (response.length) {
      const isPending = response[0].status === 'pending';
      setDowngradeIsPending(isPending);
    } else {
      setDowngradeIsPending(false);
    }
  }, [service, setDowngradeIsPending]);

  const handleCancelDowngrade = useCallback(async () => {
    setCancelDowngradeIsLoading(true);
    const { success } = await service.cancelDowngrade();
    if (success) {
      toast({
        id: 'success',
        title: t('DownGradeStatus.notification.title'),
      });
      void updateDowngradeStatus();
    } else {
      toast({
        id: 'error',
        variant: 'error',
        title: t('DownGradeStatus.notification.errorTitle'),
      });
    }
    setCancelDowngradeIsLoading(false);
  }, [service, t, updateDowngradeStatus]);

  const Content = useCallback(() => {
    return (
      <>
        <Text alignment="center" fontWeight="medium" variant="text">
          {t('BILLING_AND_CONSUMPTION.DOWNGRADE_STATUS.title')}
        </Text>
        <Text alignment="center" variant="text-sm">
          <Trans
            components={{
              li: (
                <Text
                  link
                  color="primary"
                  fontWeight="medium"
                  id="link"
                  variant="text-sm"
                  {...(!cancelDowngradeIsLoading && {
                    onClick: () => void handleCancelDowngrade(),
                  })}
                />
              ),
            }}
            i18nKey="BILLING_AND_CONSUMPTION.DOWNGRADE_STATUS.action"
          ></Trans>
        </Text>
      </>
    );
  }, [cancelDowngradeIsLoading, handleCancelDowngrade, t]);

  if (isAlert) {
    return (
      <Alert type="warning">
        <Content />
      </Alert>
    );
  }

  return (
    <Card>
      <CardSection>
        <Content />
      </CardSection>
    </Card>
  );
};

export default memo(DownGradeStatus);
