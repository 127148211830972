export enum RouteEnum {
  Home = '',
  Dashboard = '/dashboard',
  Contacts = '/contacts',
  ContactsImport = 'contacts/import',
  Campaigns = '/campaigns',
  Reports = '/reports',
  Automation = '/automation',
  OnSite = '/onsite',
  Configuration = '/configuration',
  Inbox = '/inbox',
  Nps = '/nps',
}

export const Menu: Array<string> = [
  'Home',
  'Dashboard',
  'Contacts',
  'Campaigns',
  'Reports',
  'Automation',
  'OnSite',
  'NPS',
];
