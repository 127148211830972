import { STATES_PROCESS_ACTION } from '@/src/modules/CampaignsModule/constants';

export const isActionEditable = (status: number): boolean => {
  if (!status) return false;

  return (
    STATES_PROCESS_ACTION.confirmableDraft.includes(status) ||
    STATES_PROCESS_ACTION.draft.includes(status)
  );
};
