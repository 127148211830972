import { Option } from '@/lib/v2/components/Select/Select';

/* eslint-disable pii/no-email */
export const CONTACTS_EXAMPLES = 'Apps/ContactsModule/Examples';

export const CONTACTS_SCREENS = 'Apps/ContactsModule/Screens';

export const AUTH_SCREENS = 'Apps/AuthModule/Screens';

export const AUTH_COMPONENTS = 'Apps/AuthModule/Components';

export const ONBOARDING_SCREENS = 'Apps/OnboardingModule/Screens';

export const CONTACTS_COMPONENTS = 'Apps/ContactsModule/Components';

export const SEGMENT_FILTER_GROUP = {
  contacts: 'Contacts',
  cpSystem: 'CP SISTEMA',
};

export const SEGMENT_PATH = '/v2/contacts/segments';
export const GROUP_PATH = '/v2/contacts/groups';

export const RADIO_BUTTON_NAME = 'senderRadioButtons';

export const DEFAULT_EMAIL_ADDRESS = 'defaultsender@mailingmktg.com';

export const DELIMITER_LIST: Option[] = [
  {
    id: 0,
    name: 'Comma',
    value: ',',
  },
  {
    id: 1,
    name: 'Semicolon',
    value: ';',
  },
  {
    id: 2,
    name: 'Tab Space',
    value: 't',
  },
  {
    id: 3,
    name: 'Vertical Bar (|)',
    value: '|',
  },
];

export const IMPORT_DB = 'import DB';
export const ACTION_CREATED = 'action created';
export const ACTION_SENT = 'action sent';
