import { useAtomValue } from 'jotai';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Node, NodeProps, Position, useNodes } from 'reactflow';

import { Button, Card, IconSvg, Text } from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';

import { atomRuleData } from '@/src/modules/RulesModule/atoms/rules';
import { TRANSLATE_RULES_CALL_TO_ACTION } from '@/src/modules/RulesModule/constants';
import { useAddNode } from '@/src/modules/RulesModule/hooks/useAddNode';
import { AddOperationTypes, NodeData, RulesOptions } from '@/src/modules/RulesModule/interfaces';

const PlaceholderNode = ({ id }: NodeProps) => {
  const { t } = useTranslation('rules');
  const [showDropdown, setShowDropdown] = useState(false);
  const nodes = useNodes<NodeData>();
  const addNode = useAddNode();
  const rulesData: RulesOptions = useAtomValue(atomRuleData);

  const addOperations = (type: AddOperationTypes) => {
    setShowDropdown(false);
    addNode({ type, id });
  };

  const haveActionNode = nodes.some(
    (node: Node<NodeData>) => node.data.type === AddOperationTypes.action
  );

  const haveConditionNode = nodes.some(
    (node: Node<NodeData>) => node.data.type === AddOperationTypes.condition
  );

  const isFormDataEmpty = useMemo(() => {
    return (
      !rulesData ||
      Object.values(rulesData).every((value) => value.value === null || value.value === undefined)
    );
  }, [rulesData]);

  return (
    <>
      {!haveActionNode && !isFormDataEmpty && (
        <div className="flex w-72 items-center justify-center">
          {!showDropdown ? (
            <Button link size="tiny" onClick={() => setShowDropdown(true)}>
              <IconSvg
                fullWidth
                rounded
                bgColor="gray-light"
                className="border-1 border-emblue-primary"
                strokeColor="white"
                svgComponent={<CreateIcon />}
                withPadding="small"
              />
            </Button>
          ) : (
            <Card className="w-40 items-center !p-0">
              <div className="flex w-full flex-col items-center">
                {!haveConditionNode && (
                  <Button link onClick={() => addOperations(AddOperationTypes.condition)}>
                    <Text fontWeight="medium" variant="text-sm">
                      {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.addCondition`)}
                    </Text>
                  </Button>
                )}
                <hr className="w-full" />
                {!haveActionNode && (
                  <Button link onClick={() => addOperations(AddOperationTypes.action)}>
                    <Text fontWeight="medium" variant="text-sm">
                      {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.addAction`)}
                    </Text>
                  </Button>
                )}
              </div>
            </Card>
          )}
          <Handle className="handle" isConnectable={false} position={Position.Top} type="target" />
          <Handle
            className="handle"
            isConnectable={false}
            position={Position.Bottom}
            type="source"
          />
        </div>
      )}
    </>
  );
};

export default memo(PlaceholderNode);
