import he from 'he';

export const removeScriptHTML = (html: string) => {
  const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  while (SCRIPT_REGEX.test(html)) {
    html = html.replace(SCRIPT_REGEX, '');
  }
  html = he.encode(html, {
    useNamedReferences: true,
    allowUnsafeSymbols: true,
  });
  return html;
};
