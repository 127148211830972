import { Route, Routes } from 'react-router-dom';

import { MY_PLAN_PATHS } from '@/src/modules/MyPlanModule/constants';
import { BillingAndConsumption, ManagePlan } from '@/src/modules/MyPlanModule/screens';

export const MyPlanModuleRouter = () => {
  return (
    <Routes>
      <Route
        element={<BillingAndConsumption />}
        path={MY_PLAN_PATHS.BILLING_AND_CONSUMPTION_PATH}
      />
      <Route element={<ManagePlan />} path={MY_PLAN_PATHS.MANAGE_PLAN} />
    </Routes>
  );
};
