import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table, TextSkeleton } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyActivityIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';

import { NpsBadge } from '@/src/ContactsModule/components/Badges/NpsBadge';
import { ITableNpsActivity, TableContact } from '@/src/ContactsModule/interfaces/Interfaces';
import { useDate } from '@/src/infrastructure/services/useDate';

export function ContactsProfileHappinessTable({
  npsList,
  totalNpsCount,
  changeTableOrder,
  changeTablePage,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
  setTableOrderByList,
  tableOrderByList,
}: ITableNpsActivity) {
  const { t } = useTranslation();
  const { dateFormatter } = useDate();

  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_HAPPINESS_TABLE.status')}</span>
      </Flex>
    ),
    accessor: (row: TableContact) => ({ npsStatus: row.npsStatus, npsCampaign: row.npsCampaign }),
    id: 'npsStatus',
    Cell: ({ cell: { value } }: { cell: { value: TableContact } }) => (
      <Flex withGap alignment="start">
        <NpsBadge npsCampaign={value.npsCampaign} npsStatus={value.npsStatus} />
      </Flex>
    ),
  };
  const dateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_HAPPINESS_TABLE.date')}</span>
        {
          <SortTableArrow
            elementName={'npsDate'}
            getTableOrderByList={changeTableOrder}
            setTableOrderByList={setTableOrderByList}
            tableOrderByList={tableOrderByList}
          />
        }
      </Flex>
    ),
    accessor: 'npsDate',
    id: 'npsDate',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          <>{(value && dateFormatter(value)) || <TextSkeleton size="sm" />}</>
        </span>
      </Flex>
    ),
  };
  const npsProcessColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">
          {t('PROFILE_HAPPINESS_TABLE.npsProcess')}
        </span>
        {
          <SortTableArrow
            elementName={'npsProcess'}
            getTableOrderByList={changeTableOrder}
            setTableOrderByList={setTableOrderByList}
            tableOrderByList={tableOrderByList}
          />
        }
      </Flex>
    ),
    accessor: 'npsCampaign',
    id: 'npsCampaign',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };
  const qualificationColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">
          {t('PROFILE_HAPPINESS_TABLE.qualification')}
        </span>
      </Flex>
    ),
    accessor: 'npsScore',
    id: 'npsScore',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}/10</span>
      </Flex>
    ),
  };

  const activityTableColumns: Column<object>[] = [
    statusColumn,
    dateColumn,
    npsProcessColumn,
    qualificationColumn,
  ].filter((x) => x) as Column<object>[];

  return (
    <div className="h-full w-full text-base text-[#364365] 2xl:text-14">
      <Table
        // eslint-disable-next-line react/no-children-prop
        children={<></>}
        isPaginateOnOverflow
        classNamePagination="pr-4"
        columns={activityTableColumns}
        data={npsList}
        emptyScreen={{
          totalCount: totalNpsCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.activity')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityNpsAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityNpsDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyActivityTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        withCheckbox={false}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
      />
    </div>
  );
}
