import { Cell, Pie, PieChart } from 'recharts';

import { TextSkeleton } from '@/lib/components/Skeletons/TextSkeleton';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { IPieChart } from '@/lib/v2/examples/Charts/types';

export const ChartSkeleton = ({ halfPie = false }: { halfPie?: boolean }) => {
  const data = [
    { id: 'Group A', name: 'Group A', value: 20, color: '#4D5766' },
    { id: 'Group B', name: 'Group B', value: 30, color: '#718096' },
    { id: 'Group C', name: 'Group C', value: 50, color: '#91A5C2' },
  ];
  const startAngle = halfPie ? 180 : -305;
  const endAngle = halfPie ? 0 : undefined;
  return (
    <div className="animate-pulse">
      <Flex withGap alignment="center" gapSize="large">
        <PieChart data-testid="pie-chart-component" height={200} width={200}>
          <Pie
            cx={'50%'}
            cy={'50%'}
            data={data}
            dataKey="value"
            endAngle={endAngle}
            fill="#718096"
            innerRadius={75}
            outerRadius={95}
            paddingAngle={1}
            startAngle={startAngle}
          >
            {data.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        {!halfPie ? (
          <div>
            <Flex column withGap gapSize="medium" itemAlignment="start">
              {data.map((element: IPieChart) => {
                return (
                  <Flex key={element.id} withGap gapSize="large">
                    <TextSkeleton size="xxs"></TextSkeleton>
                    <TextSkeleton size="xs"></TextSkeleton>
                  </Flex>
                );
              })}
            </Flex>
          </div>
        ) : (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <Flex column withGap>
              <TextSkeleton size="xxs"></TextSkeleton>
              <TextSkeleton size="xs"></TextSkeleton>
            </Flex>
          </div>
        )}
      </Flex>
    </div>
  );
};
