import { useCallback } from 'react';
import { useReactFlow } from 'reactflow';

import { INITIAL_EDGES, PLACEHOLDER_NODE } from '@/src/modules/RulesModule/constants/InitialFlow';

export const useAddPlaceholderNode = () => {
  const { setNodes, setEdges } = useReactFlow();

  const addPlaceholderNode = useCallback(() => {
    setNodes((nodes) =>
      nodes
        .map((node) => {
          return node;
        })
        .concat([PLACEHOLDER_NODE])
    );
    setEdges((edges) => edges.concat(INITIAL_EDGES));
  }, [setNodes, setEdges]);

  return addPlaceholderNode;
};
