import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomSelectedActionsRowsWithID } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomDetailCampaignRecurrent } from '@/src/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { ICampaignActionsListPayload } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

// TODO remove this when the feature flag showDragDropRemovalNotice is removed
export const useDeprecatedBanner = () => {
  const { pathname } = useLocation();
  const { actionId } = useParams();

  const campaignService = useCampaignsService();
  const [actionDragDrop, setActionDragDrop] = useState(false);
  const selectedActionsRowsWithID = useAtomValue(atomSelectedActionsRowsWithID);
  const detailRecurrent = useAtomValue(atomDetailCampaignRecurrent);

  const showDragDropRemovalNotice = useFeatureFlag('showDragDropRemovalNotice');

  const fetchEmailInfo = useCallback(
    async (itemId?: number) => {
      if (!itemId) return;

      const emailInfoResponse = await campaignService.getEmailInfo(Number(itemId));
      const actionDragDropLegacy =
        emailInfoResponse?.content?.originId === 8 && !emailInfoResponse?.content?.editorVersion;
      setActionDragDrop(actionDragDropLegacy);
    },
    [campaignService]
  );

  const fetchActionsByCampaign = useCallback(
    async (campaignId: number) => {
      if (!campaignId) return;
      const payload: ICampaignActionsListPayload = {
        campaignId,
      };
      const response = await campaignService.getActionsByCampaign(payload);
      if (response?.actions && response.actions.length > 0) {
        await fetchEmailInfo(response.actions[0].action.actionId);
      }
    },
    [campaignService, fetchEmailInfo]
  );

  useEffect(() => {
    if (selectedActionsRowsWithID.length === 1) {
      void fetchEmailInfo(selectedActionsRowsWithID[0]);
    } else if (detailRecurrent) {
      void fetchActionsByCampaign(Number(detailRecurrent.campaignId));
    }
  }, [selectedActionsRowsWithID, detailRecurrent, fetchEmailInfo, fetchActionsByCampaign]);

  const showDeprecatedDragDropForRow = useMemo(() => {
    if (selectedActionsRowsWithID.length === 1 || detailRecurrent) {
      return actionDragDrop;
    }
    return false;
  }, [selectedActionsRowsWithID, detailRecurrent, actionDragDrop]);

  const showDeprecatedDragDropForPath = useMemo(() => {
    if (!showDragDropRemovalNotice) return false;
    if (pathname.includes('editor')) return false;

    if (pathname.includes('/email/') && pathname.includes('campaigns')) {
      void fetchEmailInfo(Number(actionId));
      return actionDragDrop;
    } else if (pathname.includes('campaigns')) {
      return true;
    }
    return false;
  }, [actionId, fetchEmailInfo, actionDragDrop, pathname, showDragDropRemovalNotice]);

  return { showDeprecatedDragDropForRow, showDeprecatedDragDropForPath };
};
