import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

const useDebouncedFetchService = (
  validationFunction: (value: string) => Promise<boolean>,
  debounceTime: number
) => {
  const [isLoading, setIsLoading] = useState(false);

  const debouncedValidation = useCallback(
    debounce(
      async (
        value: string,
        resolve: (isValid: boolean) => void,
        reject: (error: unknown) => void
      ) => {
        try {
          setIsLoading(true);
          const isValid = await validationFunction(value);
          resolve(!!isValid);
        } catch (error) {
          reject(false);
        } finally {
          setIsLoading(false);
        }
      },
      debounceTime
    ),
    [validationFunction, debounceTime]
  );

  const validateFetchService = useCallback(
    async (value: string): Promise<boolean> => {
      return new Promise<boolean>((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        debouncedValidation(value, resolve, reject);
      });
    },
    [debouncedValidation]
  );

  return { validateFetchService, isLoading };
};

export default useDebouncedFetchService;
