import classNames from 'classnames';
import { memo, useCallback, useMemo } from 'react';

import { If } from '@/lib/v2/components/If';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Text } from '@/lib/v2/components/Text';

import { Description } from '@/src/ContactsModule/components/ContactsViewInfo/ProgressCard';
import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

interface ProgressBarProps {
  percentage: number;
  status: loadingStatus;
  id?: string;
}

const ProgressBar = ({ percentage, status, id }: ProgressBarProps) => {
  const { percentageToMaximum, surplusPercentage } = useMemo(() => {
    let percentageToMaximum;
    let surplusPercentage;

    if (percentage <= 100) {
      percentageToMaximum = percentage;
      surplusPercentage = 0;
    } else {
      percentageToMaximum = (100 / percentage) * 100;
      surplusPercentage = 100 - percentageToMaximum;
    }

    return {
      percentageToMaximum,
      surplusPercentage,
    };
  }, [percentage]);

  const classesProgressToMaximum = classNames('h-1.5 rounded-l-full transition-all', {
    'rounded-r-full': surplusPercentage === 0,
    'bg-blue-600': status === loadingStatus.loading,
    'bg-[#FF4261]': status === loadingStatus.error,
    'bg-[#18C678]': status === loadingStatus.ready,
  });

  const classesProgressSurplus = classNames(
    'h-1.5 rounded-r-full bg-emblue-tertiary-hover transition-all'
  );

  return (
    <div
      className="flex h-1.5 w-full rounded-full bg-gray-200"
      data-testid="progressbar-component"
      id={id}
    >
      <div
        className={classesProgressToMaximum}
        style={{ width: `${status === loadingStatus.error ? 100 : percentageToMaximum}%` }}
      />
      <If condition={surplusPercentage > 0}>
        <div className={classesProgressSurplus} style={{ width: `${surplusPercentage}%` }} />
      </If>
    </div>
  );
};

export default memo(ProgressBar);

interface DataCardProps {
  dataList: Description[];
  percentage: number;
  step: number;
  status: loadingStatus;
  id?: string;
}

export const DataCard = memo(({ dataList, percentage, step, status, id }: DataCardProps) => {
  if (!Array.isArray(dataList) || dataList.length === 0) return null;

  const length = dataList.length - 1;

  return (
    <Flex column withGap data-testid={id || 'data-card-component'} gapSize="small" id={id}>
      <Flex withGap alignment="center" gapSize="medium">
        <div className="size-24">{step !== 0 && dataList[step - 1].icon}</div>
        <div className="size-32">{dataList[step].icon}</div>
        <div className="size-24">{step !== length && dataList[step + 1].icon}</div>
      </Flex>
      <Flex className="my-4">
        <ProgressBar percentage={percentage} status={status} />
      </Flex>
      <Text alignment="center" className="mb-2">
        {dataList[step].description}
      </Text>
      <Text alignment="center">{percentage} %</Text>
    </Flex>
  );
});
