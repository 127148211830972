import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TagCloud } from 'react-tagcloud';

import { CloudSkeleton } from '@/lib/components/Skeletons/CloudSkeleton';
import { Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyContactsIcon, EmptyTagsIcon } from '@/lib/v2/icons/general';

import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';

interface TagCloudProps {
  tagList: { tag: ITag }[];
  isLoading?: boolean;
  callToAction?: [() => void, (() => void)?];
  totalTagCount?: number;
}

interface tagData {
  value: string;
  count: number;
  id: number;
}

const options = {
  luminosity: 'medium',
  hue: 'blue',
};

const sizes = {
  baseSize: 15,
  maxSize: 70,
};

const TagCloudComponent = ({ tagList, isLoading, callToAction, totalTagCount }: TagCloudProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const calculateTagSize = (contacts: number) => {
    if (!contacts || typeof contacts !== 'number') return sizes.baseSize;
    const maxContacts = Math.max(
      ...tagList
        .filter((tag) => tag.tag.contacts !== undefined)
        .map((tag) => tag.tag.contacts as number)
    );
    return (contacts / maxContacts) * (sizes.maxSize - sizes.baseSize) + sizes.baseSize;
  };

  const data: tagData[] = tagList.map((tag) => {
    return {
      value: tag.tag.name,
      count: tag.tag.contacts ? tag.tag.contacts : 0,
      id: tag.tag.id,
      props: {
        title: tag.tag.name,
        style: {
          margin: '5px',
          cursor: 'pointer',
          textShadow: '0.3px 0.3px 0.5px rgba(0, 0, 0, 0.3)',
          fontSize: tag.tag.contacts && calculateTagSize(tag.tag.contacts ? tag.tag.contacts : 0),
          ...(tag.tag.contacts === 0 ? { fontSize: '10px', cursor: 'default' } : {}),
        },
      },
    };
  });

  const goToTagDetail = (tag: tagData) => {
    tag.count !== 0 && navigate(`../tags/${tag.id}`);
  };

  const renderTagCloud = () => {
    return (
      <div className="my-12 max-w-[1000px]">
        <Flex alignment="center" itemAlignment="center">
          <TagCloud
            shuffle
            colorOptions={options}
            maxSize={sizes.maxSize}
            minSize={sizes.baseSize}
            tags={data}
            onClick={goToTagDetail}
          />
        </Flex>
      </div>
    );
  };

  const renderEmptyScreen = (screenType: 'tagsNotFound' | 'emptyTags') => (
    <Flex alignment="center" itemAlignment="center">
      {screenType === 'tagsNotFound' ? (
        <EmptyScreenTable
          actionText={t('EMPTY_SCREEN_TABLE.tags')}
          description={t('EMPTY_SCREEN_TABLE.callToAction')}
          image={<EmptyContactsIcon />}
          title={t('EMPTY_SCREEN_TABLE.noResults')}
          onClickAction={callToAction && callToAction[0]}
        />
      ) : (
        <EmptyScreenTable
          description={
            <>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyTagsDescription1')}
              </Text>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyTagsDescription2')}
                <button
                  className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
                  data-testid="nps-screen-empty-table-create-link"
                  role="link"
                  onClick={callToAction && callToAction[1]}
                >
                  {t('EMPTY_SCREEN_TABLE.emptyTagsAction')}
                </button>
              </Text>
            </>
          }
          image={<EmptyTagsIcon />}
          title={t('EMPTY_SCREEN_TABLE.emptyTagsTitle')}
        />
      )}
    </Flex>
  );

  return (
    <>
      {isLoading && <CloudSkeleton />}

      {!isLoading && tagList.length > 0 && renderTagCloud()}

      {!isLoading &&
        tagList.length === 0 &&
        totalTagCount !== 0 &&
        renderEmptyScreen('tagsNotFound')}

      {!isLoading && totalTagCount === 0 && renderEmptyScreen('emptyTags')}
    </>
  );
};

export default TagCloudComponent;
