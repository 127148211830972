import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection } from '@/lib/v2/components';
import Text from '@/lib/v2/components/Text/Text';
import { UploadArrowIcon } from '@/lib/v2/icons/solid';

import { MappedFieldModal } from '@/src/ContactsModule/components/ImportModal/MappedFieldsModal';

import DetailTable from './components/DetailTable';
import RecentFileTable from './components/RecentFileTable';
import Steps from './components/Steps';

import { SectionHeader } from '@/modules/ContactsModule/components';

export type selectedGroupProps = {
  ListIdSelected: number[];
  ListNameGroups: string[];
};

export const ImportMain = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Card>
        <CardSection noPadding>
          <SectionHeader
            withoutDivide
            icon={<UploadArrowIcon />}
            title={
              <Text fontWeight="medium" variant="title-1">
                {t('IMPORT_MAIN.importFile')}
              </Text>
            }
          />
        </CardSection>
        <Steps />
      </Card>

      <RecentFileTable />

      <DetailTable />

      <MappedFieldModal />
    </>
  );
});
