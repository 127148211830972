//cspell:disable
import { TFunction } from 'react-i18next';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import {
  TRANSLATE_RULES_EXECUTION_OPTIONS,
  TRANSLATE_RULES_FREQUENCY_OPTIONS,
  TRANSLATE_RULES_PERIOD_OPTIONS,
  TRANSLATE_RULES_PRESENCE_OPTIONS,
  TRANSLATE_RULES_TRIGGER_OPTIONS,
} from '@/src/modules/RulesModule/constants';
import { IRulesV1Response, RulesOptions } from '@/src/modules/RulesModule/interfaces';

// Types
type Language = 'es' | 'en' | 'pt';
type ActionLabel = 'sameDay' | 'before' | 'after';

interface ExecutionGroup {
  id: number;
  label: ActionLabel;
  values: Language[];
}

interface ExecutionTranslations {
  [key: string]: Record<Language, string>;
}

// Constants
const EXECUTION_STATE_GROUPED: ExecutionGroup[] = [
  { id: 1, label: 'sameDay', values: ['es', 'en', 'pt'] },
  { id: 2, label: 'before', values: ['es', 'en', 'pt'] },
  { id: 3, label: 'after', values: ['es', 'en', 'pt'] },
];

const executionTranslations: ExecutionTranslations = {
  sameDay: { es: 'mismo', en: 'on the same', pt: 'mismo' },
  after: { es: 'después', en: 'after the', pt: 'despois' },
  before: { es: 'antes', en: 'before the', pt: 'antes' },
};

// Helper functions
const normalizeString = (str: string): string => str.trim().toLowerCase().replace(/\s+/g, '');

const findMatchingGroup = (normalizedValue: string): ExecutionGroup | undefined =>
  EXECUTION_STATE_GROUPED.find((group) =>
    group.values.some(
      (lang) => normalizeString(executionTranslations[group.label][lang]) === normalizedValue
    )
  );

const getExecutionOption = (value: string, t: (key: string) => string): Option | undefined => {
  const normalizedValue = normalizeString(value);
  const matchingGroup = findMatchingGroup(normalizedValue);

  if (matchingGroup) {
    return {
      id: matchingGroup.id,
      name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.${matchingGroup.label}`),
      value: value,
    };
  }

  return undefined;
};

const transformToOption = ({
  id,
  name,
  value,
}: {
  id?: string | number;
  name: string;
  value?: string | number;
}): Option => ({
  id: id ?? name,
  name,
  value: value ?? id,
  disabled: false,
});

const transformToExternalOption = ({
  id,
  name,
  value,
}: {
  id: string | number;
  name: string;
  value: string | number;
}): OptionExternal => ({
  id,
  name,
  value,
});

export const parseRuleData = (
  recipientsOptions: OptionExternal[],
  data: IRulesV1Response | null,
  t: TFunction<['rules'], undefined>
): RulesOptions => {
  const safeData = data ?? ({} as IRulesV1Response);

  const [hours, minutes] = safeData.horaEnvio?.split(':') ?? [];
  const [days, sendFrequency, sendExecution, sendPeriod] = safeData.fechaEnvio?.split('|') ?? [];

  const selectedGroupOption = recipientsOptions.find(
    (option) => option.id === safeData.group_rules
  );

  return {
    trigger: safeData.desencadenante
      ? transformToOption({
          id: normalizeString(safeData.desencadenante),
          name: t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.${normalizeString(safeData.desencadenante)}`),
          value: safeData.desencadenante,
        })
      : undefined,
    customField: safeData.campoDTO
      ? transformToOption({ id: safeData.campoDTO.id, name: safeData.campoDTO.nombre })
      : undefined,
    days: days ? transformToOption({ name: days, value: days }) : undefined,
    frequency: sendFrequency
      ? transformToOption({
          id: sendFrequency,
          name: t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.${sendFrequency}`),
        })
      : undefined,
    execution: sendExecution ? getExecutionOption(sendExecution, t) : undefined,
    period: sendPeriod
      ? transformToOption({
          id: sendPeriod,
          name: t(`${TRANSLATE_RULES_PERIOD_OPTIONS}.${sendPeriod}`),
        })
      : undefined,
    hours: hours ? transformToOption({ name: hours, value: hours }) : undefined,
    minutes: minutes ? transformToOption({ name: minutes, value: minutes }) : undefined,
    selectedGroupOrSegment: selectedGroupOption
      ? [
          transformToExternalOption({
            id: selectedGroupOption.id,
            name: selectedGroupOption.name.toString(),
            value: Number(selectedGroupOption.value),
          }),
        ]
      : undefined,
    presenceCondition: transformToOption({
      id: safeData.group_rules_in ? 0 : 1,
      name: t(
        `${TRANSLATE_RULES_PRESENCE_OPTIONS}.${safeData.group_rules_in ? 'notPresent' : 'present'}`
      ),
      value: safeData.group_rules_in ? 0 : 1,
    }),
  };
};
//cspell:enable
