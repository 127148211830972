import { useCallback, useEffect, useState } from 'react';

import { RulesOptions } from '@/src/modules/RulesModule/interfaces';

const useIsConditionFormComplete = (rulesData: RulesOptions) => {
  const [isComplete, setIsComplete] = useState(false);

  const isConditionFormComplete = useCallback((formValues: RulesOptions | null): boolean => {
    if (!formValues) return false;

    const { presenceCondition, selectedGroupOrSegment } = formValues;
    return Boolean(presenceCondition?.name && selectedGroupOrSegment?.at(0)?.id);
  }, []);

  useEffect(() => {
    setIsComplete(isConditionFormComplete(rulesData));
  }, [rulesData, isConditionFormComplete]);

  return isComplete;
};

export default useIsConditionFormComplete;
