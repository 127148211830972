import { useAtom } from 'jotai';
import {
  lazy,
  MouseEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TableSkeleton } from '@/lib/components';
import { Spinner } from '@/lib/components/Util/Spinner';
import {
  Card,
  CardSection,
  CleanInputSearchRef,
  Container,
  FloatModal,
  IconSvg,
  InputSearch,
  toast,
} from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { useShowModalSignal } from '@/lib/v2/hooks/useShowModalSignal';
import { SegmentsIcon } from '@/lib/v2/icons/solid';

import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import { atomContactsDownload } from '@/src/atoms/Contacts';
import useMessage from '@/src/compat/useMessage';
import { DeleteModal } from '@/src/ContactsModule/components/ContactsModals';
import { ContactsToFlowModal } from '@/src/ContactsModule/components/ContactsModals/ContactsToFlowModal';
import { DuplicateModal } from '@/src/ContactsModule/components/ContactsModals/DuplicateModal';
import { SegmentsActionBar } from '@/src/ContactsModule/components/SegmentsActionBar/SegmentsActionBar';
import { SegmentsSwitcherEnum } from '@/src/ContactsModule/utils/enums/SegmentsSwitcher';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  useMutationContactsContext,
  useStateContactsContext,
} from '@/src/modules/ContactsModule/contexts/ContactsContext';
import { useMutationCreateSegmentModalContext } from '@/src/modules/ContactsModule/contexts/CreateSegmentModalContext';
import CardsFilterSegments from '@/src/modules/ContactsModule/screens/CreateSegment/components/CardsFilterSegments/CardsFilterSegments';
import { ISegmentInfo } from '@/src/modules/ContactsModule/types/Segments';

import { CreateEmblueSegmentModal } from './components/CreateEmblueSegment/CreateEmblueSegmentModal';
import { useRecalculateSegment } from './useRecalculateSegment';

import { DistributeModal, SectionHeader } from '@/modules/ContactsModule/components';
import { useConnectContactsToFlows } from '@/modules/ContactsModule/hooks/useConnectContactsToFlows';
import { useDistributeContacts } from '@/modules/ContactsModule/hooks/useDistributeContacts';
import { useSegmentMain } from '@/modules/ContactsModule/hooks/useSegmentMain';
import {
  CreateSegmentButton,
  SegmentsTable,
  SegmentTitle,
} from '@/modules/ContactsModule/screens/SegmentsMain/components';

const ModalSelectable = lazy(() => import('@/lib/v2/examples/ModalSelectable'));

type SegmentsSwitcherType = SegmentsSwitcherEnum;

export const SegmentsMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // action bar states
  const [selectedSegmentsRows, setSelectedSegmentsRows] = useState<number[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDuplicate, setConfirmDuplicate] = useState(false);
  // END action bar states

  const {
    searchQuery: search,
    pageSize,
    pageIndex,
    totalPages,
    gotoPageIndex,
  } = useStateContactsContext();
  const { setSearchQuery, changeTablePage, resetPagination, setListTotalPagination } =
    useMutationContactsContext();
  const service = useService();

  const cleanBoxRef = useRef<CleanInputSearchRef>(null);
  const [isRecalculateSegment, setIsRecalculateSegment] = useState(false);

  const [lastRefresh, setLastRefresh] = useState<number>(0);

  const [showCreateEmblueSegmentModal, setShowCreateEmblueSegmentModal] = useState<boolean>(false);
  const { setCreateSegmentModal } = useMutationCreateSegmentModalContext();
  const [contactsDownload, setContactsDownload] = useAtom(atomContactsDownload);

  const [segmentsSwitcher, setSegmentsSwitcher] = useState<SegmentsSwitcherType>(
    (sessionStorage.getItem('segmentType') as SegmentsSwitcherType) ??
      SegmentsSwitcherEnum.Customized
  );
  const [wordingsDownload, setWordingsDownload] = useState({
    title: t('MODAL_CONTACTS_DOWNLOAD.title'),
    reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
    resumeSelected: t('MODAL_CONTACTS_DOWNLOAD.resumeSelected'),
    search: {
      label: t('MODAL_CONTACTS_DOWNLOAD.search_label'),
      placeholder: t('MODAL_CONTACTS_DOWNLOAD.search_placeholder'),
    },
    actions: {
      accept: t('MODAL_CONTACTS_DOWNLOAD.export'),
    },
  });
  const [flowsLists, , flowsLoading] = useEmblue(ServiceMethods.getFlowsByAccount);
  const [contactsFieldsResponse] = useEmblue(ServiceMethods.getFieldsFilters);
  const contactsFields = useMemo(() => {
    let items: any[] = [
      {
        id: 'email',
        label: 'Email',
        checked: true,
        disabled: true,
      },
    ];
    const data = contactsFieldsResponse?.data;

    if (!data || typeof data === 'string') return [];

    // Defaults fields
    const defaultFields = data?.defaultFields?.map((item) => ({
      ...item,
      label: t(`CONTACT_INFORMATION.${item.name}`),
    }));
    items = items.concat(defaultFields ?? []);

    // Customs fields
    const customFields =
      data?.customFields
        ?.filter((item) => item?.metadata?.fieldType !== 'extended')
        ?.map((item) => ({ ...item, label: item.name })) ?? [];
    items = items.concat(customFields);

    // Activity fields
    const activityFields =
      data?.activityFields
        ?.filter((item) => item.name !== 'lastSend')
        ?.map((item) => {
          return {
            ...item,
            id: item.name,
            label: t(`CONTACT_INFORMATION.${item.name}`),
          };
        }) ?? [];
    items = items.concat(activityFields);

    return items;
  }, [contactsFieldsResponse, t]);

  // services
  const segmentType = useMemo(() => {
    let numberType;
    if (SegmentsSwitcherEnum.Customized === segmentsSwitcher) {
      numberType = 0;
    } else if (SegmentsSwitcherEnum.EmBlueRecipes === segmentsSwitcher) {
      numberType = 1;
    } else {
      numberType = 2;
    }

    return numberType;
  }, [segmentsSwitcher]);

  const {
    segmentList,
    isLoadingSegments,
    isLoadingSegmentCountTotal,
    segmentsCountOfSearch,
    segmentsCountTotal,
    emblueSegmentTotalsCard,
    setOrderColumn,
    setOrderDirection,
  } = useSegmentMain({
    lastRefresh,
    segmentTypes: [segmentType],
    search,
    page: pageIndex,
    pageSize,
  });

  const {
    distributeIsLoading,
    handleDistributeSubmit,
    distributeIsDisabled,
    openDistributeModal,
    setOpenDistributeModal,
    quantityOptions,
  } = useDistributeContacts({
    currentList: segmentList?.list ?? [],
    selectedRows: selectedSegmentsRows,
  });

  const {
    flowsListsAux,
    openConnectToFlows,
    setOpenConnectToFlows,
    handleContactToFlow,
    showLoadingFlows,
  } = useConnectContactsToFlows({ type: 'perfil' });

  const lastRefreshIncrement = useCallback(() => {
    setLastRefresh((prevState: number) => prevState + 1);
    service?.incrementLastRefreshSegmentsValue();
  }, [service]);

  useShowModalSignal('CreateSegment', () => setShowCreateEmblueSegmentModal(true));

  let queryParamsFilters = '';

  if (search) {
    queryParamsFilters += `filterSearch=${search}`;
  }

  sessionStorage.setItem('queryParamsFilters', queryParamsFilters);

  useEffect(() => {
    setListTotalPagination(segmentsCountOfSearch?.count ?? 0);
  }, [segmentsCountOfSearch?.count, setListTotalPagination]);

  //Remove this when create segment V2 its on production
  useMessage(EventListenerType.RefreshSegments, () => {
    lastRefreshIncrement();
  });

  const [recalculateSegment, recalculateSegmentResponse] = useRecalculateSegment();

  const createSegment = useCallback(() => {
    setShowCreateEmblueSegmentModal(true);
  }, []);

  useEffect(() => {
    if (recalculateSegmentResponse) {
      setIsRecalculateSegment(false);
      lastRefreshIncrement();
    }
  }, [lastRefreshIncrement, recalculateSegmentResponse]);

  const recalculateSegmentHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>, segmentId: number) => {
      event.stopPropagation();
      if (segmentId) {
        setIsRecalculateSegment(true);
        recalculateSegment(segmentId, lastRefresh);
      }
    },
    [recalculateSegment, lastRefresh]
  );

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
      setOrderDirection(orderDirectionValue);
      setOrderColumn(element.id);
    },
    [setOrderColumn, setOrderDirection]
  );

  const goToSegmentDetail = useCallback(
    (segmentId: number) => {
      navigate(`../segments/${segmentId}`);
    },
    [navigate]
  );

  const resetSegments = useCallback(() => {
    setSearchQuery('');
    resetPagination();
    cleanBoxRef.current?.cleanInputSearch();
  }, [resetPagination, setSearchQuery]);

  const handleActionInputSearch = useCallback(
    (value: string) => {
      resetPagination();
      setSearchQuery(value);
    },
    [resetPagination, setSearchQuery]
  );

  useEffect(() => {
    setIsChecked(selectedSegmentsRows.length > 0);
  }, [selectedSegmentsRows]);

  const deleteSegments = useCallback(async () => {
    const currentSegmentList = segmentList?.list;
    if (currentSegmentList === undefined || selectedSegmentsRows.length < 1) return;
    // spinner on
    setShowSpinner(true);
    const segmentsIds: number[] =
      selectedSegmentsRows.length === pageSize
        ? currentSegmentList.map((segment) => segment.segment.id) // all ids of page
        : currentSegmentList
            .filter((s, i) => selectedSegmentsRows.includes(i))
            .map((segment) => segment.segment.id); // ids of some contacts in page
    const result = await service?.deleteSegments({ ids: segmentsIds });
    // spinner off
    setShowSpinner(false);
    toast({
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
      variant: result ? 'success' : 'error',
    });
  }, [pageSize, segmentList?.list, selectedSegmentsRows, service, t]);

  useEffect(() => {
    if (confirmDelete) {
      deleteSegments()
        .then(() => lastRefreshIncrement())
        .catch(console.error);
      setConfirmDelete(false);
    }
  }, [confirmDelete, deleteSegments, lastRefreshIncrement]);

  const duplicateSegments = useCallback(async () => {
    const currentSegmentList = segmentList?.list;
    if (currentSegmentList === undefined || selectedSegmentsRows.length < 1) return;
    // spinner on
    setShowSpinner(true);
    const segment = currentSegmentList.filter((s, i) => selectedSegmentsRows.includes(i));
    const result = await service?.duplicateSegment({
      id: segment[0].segment.id,
      name: `${segment[0].segment.name} ${t('duplicate')}`,
    });
    // spinner off
    setShowSpinner(false);
    if (result?.success) {
      toast({
        variant: 'success',
        title: t('CREATE_SEGMENT.success'),
        body: t('CREATE_SEGMENT.bodyMessageSuccess'),
        autoCloseDelay: 5000,
      });
      goToSegmentDetail(result.data.segmentId);
    } else {
      toast({
        variant: 'error',
        title: t('CREATE_SEGMENT.error'),
        body: result?.data.errorByName
          ? t('EMBLUE_SEGMENTS.Notification.ERROR.bodyRepeatedName')
          : t('CREATE_SEGMENT.bodyMessageError'),
        autoCloseDelay: 5000,
      });
    }
  }, [goToSegmentDetail, segmentList?.list, selectedSegmentsRows, service, t]);

  useEffect(() => {
    if (confirmDuplicate) {
      duplicateSegments()
        .then(() => lastRefreshIncrement())
        .catch(console.error);
      setConfirmDuplicate(false);
    }
  }, [confirmDuplicate, duplicateSegments, lastRefreshIncrement]);

  const handleActionCardsFilters = useCallback(
    (value: string) => {
      resetSegments();
      setSegmentsSwitcher(value as SegmentsSwitcherType);

      sessionStorage.removeItem('segmentType');
    },
    [resetSegments]
  );

  const getSegmentSelected = useCallback(() => {
    if (segmentList === undefined || selectedSegmentsRows.length < 1) return [];
    const segmentIds: ISegmentInfo[] =
      selectedSegmentsRows.length === pageSize
        ? segmentList.list.map((g) => g.segment) // all ids of page
        : segmentList.list.filter((c, i) => selectedSegmentsRows.includes(i)).map((g) => g.segment); // ids of some contacts in page

    return segmentIds;
  }, [pageSize, segmentList, selectedSegmentsRows]);

  const handleConfirmGroupsToFlow = useCallback(
    async (flowId: number) => {
      const segments = getSegmentSelected()?.map((item) => item?.id);

      if (!segments) return;

      await handleContactToFlow(flowId, segments);

      lastRefreshIncrement();
    },
    [getSegmentSelected, handleContactToFlow, lastRefreshIncrement]
  );

  const isAvailableFileExport = useCallback(() => {
    const dateStorage: string = localStorage.getItem('@segments/last_download') ?? '';
    const countDownload: string = localStorage.getItem('@segments/count_download') ?? '';
    const lastDownload = new Date(dateStorage);
    const currentTime = new Date();

    if (!dateStorage || parseInt(countDownload) < 5) return true;

    const diff = currentTime.getTime() - lastDownload.getTime();
    const hours = diff / (1000 * 60 * 60);

    if (hours >= 1) {
      localStorage.removeItem('@segments/last_download');
      localStorage.removeItem('@segments/count_download');

      return true;
    }

    return false;
  }, []);

  const handleExportContacts = useCallback(
    (fieldsForExport: any[]) => {
      const segment = getSegmentSelected()?.[0];
      const countDownloadFile = localStorage.getItem('@segments/count_download');

      if (!isAvailableFileExport()) return;
      void service?.exportContacts(
        Number(segment.id),
        fieldsForExport.filter((item) => item.id !== 'email')
      );
      setOpenDownloadModal(false);
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        show: true,
        file: {
          ...prevContactDownload.file,
          name: segment?.name ?? 'segment',
        },
        status: 'loading',
        error: false,
        wordings: {
          message: t('NOTIFICATION_CONTACTS_DOWNLOAD.exporting'),
          action: '',
        },
      }));

      // Limit for 2 file download in 1h
      localStorage.setItem('@segments/last_download', new Date().toISOString());
      localStorage.setItem(
        '@segments/count_download',
        (countDownloadFile ? parseInt(countDownloadFile) + 1 : 1).toString()
      );
    },
    [getSegmentSelected, isAvailableFileExport, service, setContactsDownload, t]
  );

  const handleOpenModalDownload = useCallback(() => {
    if (!isAvailableFileExport()) {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.limitExceeded'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: true,
      }));
    }

    setOpenDownloadModal(true);
  }, [isAvailableFileExport, setContactsDownload, t]);

  const handleCloseModalDownload = useCallback(() => {
    setOpenDownloadModal(false);

    setTimeout(() => {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: false,
      }));
    }, 500);
  }, [setContactsDownload, t]);

  return (
    <>
      <CardsFilterSegments
        emblueSegments={emblueSegmentTotalsCard}
        onAction={handleActionCardsFilters}
      />
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <Flex withGap alignment="end">
                  <CreateSegmentButton createSegment={createSegment} />
                </Flex>
              }
              icon={<IconSvg height="30px" svgComponent={<SegmentsIcon />} width="30px" />}
              justifyContentActionsButtons="end"
              title={
                <SegmentTitle
                  isLoadingSegmentCountTotal={isLoadingSegmentCountTotal}
                  isLoadingSegments={isLoadingSegments}
                  search={search ?? ''}
                  segmentListLength={segmentList?.list.length ?? 0}
                  segmentsCountOfSearch={segmentsCountOfSearch?.count ?? 0}
                  segmentsCountTotal={segmentsCountTotal?.count ?? 0}
                  segmentsSwitcher={segmentsSwitcher}
                />
              }
            />

            <Flex column withGap className="mt-7" gapSize="medium">
              {!isChecked ? (
                <Flex
                  alignment="start"
                  className={`mb-[-62px] ${
                    !isChecked && '!w-[340px] xl:max-w-[900px] 2xl:max-w-fit'
                  } self-start`}
                >
                  <Container background="white">
                    {(segmentsCountTotal ? segmentsCountTotal?.count : 0) > 0 && (
                      <div className="w-[340px]">
                        <InputSearch
                          ref={cleanBoxRef}
                          defaultValue={search}
                          placeHolder={t('SEGMENTS_FILTERS.searchInputBar')}
                          onAction={handleActionInputSearch}
                          onClear={resetSegments}
                        />
                      </div>
                    )}
                  </Container>
                </Flex>
              ) : (
                <Flex alignment="start" className="z-50 mb-[-62px] self-start">
                  <SegmentsActionBar
                    contactSelection={selectedSegmentsRows.length}
                    distributeIsDisabled={distributeIsDisabled}
                    segmentsSelected={getSegmentSelected()}
                    segmentsSwitcher={segmentsSwitcher}
                    setOpenContactsToFlow={setOpenConnectToFlows}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenDistributeModal={setOpenDistributeModal}
                    setOpenDownloadModal={handleOpenModalDownload}
                    setOpenDuplicateModal={setOpenDuplicateModal}
                  />
                </Flex>
              )}
              {isLoadingSegments && (
                <div className="size-full py-10">
                  <TableSkeleton />
                </div>
              )}
              {segmentsSwitcher && segmentList && (
                <SegmentsTable
                  callToAction={[resetSegments, createSegment]}
                  changeTableOrder={changeOrderBy}
                  changeTablePage={changeTablePage}
                  gotoPageIndex={gotoPageIndex}
                  isLoading={isLoadingSegments}
                  isRecalculateSegment={isRecalculateSegment}
                  pageSize={pageSize}
                  recalculateSegment={recalculateSegmentHandler}
                  segmentList={segmentList.list}
                  segmentsSwitcher={segmentsSwitcher}
                  setSelectedSegments={setSelectedSegmentsRows}
                  totalPages={totalPages}
                  totalSegmentCount={segmentsCountTotal?.count ?? 0}
                />
              )}
            </Flex>
          </Flex>
        </CardSection>
      </Card>
      <CreateEmblueSegmentModal
        isShow={showCreateEmblueSegmentModal}
        lastRefreshIncrement={lastRefreshIncrement}
        setCreateCustomSegment={setCreateSegmentModal}
        setIsLoading={setShowSpinner}
        onClose={setShowCreateEmblueSegmentModal}
      />
      <DeleteModal
        description={
          selectedSegmentsRows.length === 1
            ? t('SEGMENT_DELETE_MODAL.description')
            : t('SEGMENT_DELETE_MODAL.descriptionPlural')
        }
        metadata={{ entity: 'segment' }}
        setConfirmDelete={setConfirmDelete}
        title={
          selectedSegmentsRows.length === 1
            ? t('SEGMENT_DELETE_MODAL.title')
            : t('SEGMENT_DELETE_MODAL.titlePlural')
        }
        v2Modal={{ showModal: openDeleteModal, setShowModal: setOpenDeleteModal }}
      />
      <DuplicateModal
        description={t('SEGMENT_DUPLICATE_MODAL.description')}
        metadata={{ entity: 'segment' }}
        setConfirmDuplicate={setConfirmDuplicate}
        title={t('SEGMENT_DUPLICATE_MODAL.title')}
        v2Modal={{ showModal: openDuplicateModal, setShowModal: setOpenDuplicateModal }}
      />
      <DistributeModal
        isLoading={distributeIsLoading}
        isOpen={openDistributeModal}
        quantityOptions={quantityOptions}
        onClose={setOpenDistributeModal}
        onSubmit={handleDistributeSubmit}
      />

      {openConnectToFlows && !flowsLoading && (
        <ContactsToFlowModal
          itemList={
            flowsLists && typeof flowsLists?.list === 'string' ? flowsLists.list : flowsListsAux
          }
          loading={showLoadingFlows}
          setConfirmToFlow={handleConfirmGroupsToFlow}
          wordings={{
            title: 'CONTACT_SEGMENTS_FLOW_MODAL.title',
            description: 'CONTACT_SEGMENTS_FLOW_MODAL.description',
          }}
          onClose={() => setOpenConnectToFlows(false)}
        />
      )}

      <Suspense>
        <ModalSelectable
          isAcceptDisabled={contactsDownload.disabled}
          items={contactsFields}
          show={openDownloadModal}
          wordings={wordingsDownload}
          onAccept={(e, selected) => handleExportContacts(selected)}
          onClose={handleCloseModalDownload}
        />
      </Suspense>

      {showSpinner && (
        <FloatModal>
          <Spinner />
        </FloatModal>
      )}
    </>
  );
};
