import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components/Toast/helper';

import { useUserData } from '@/src/application/hooks/useUserData';
import { useBeefreeService } from '@/src/infrastructure/Protocol/Beefree/useBeefreeService';
import {
  atomModalCatalog,
  atomSelectedCategories,
  atomTemplateDataSave,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { FormInput } from '@/src/modules/CampaignsModule/components/ModalTemplate/ModalTemplate';

import { useBeefreeLogger } from './useBeefreeLogger';

interface IUseModalCatalog {
  onClose: () => void;
}

export const useModalCatalog = ({ onClose }: IUseModalCatalog) => {
  const [catalog, setCatalog] = useAtom(atomModalCatalog);
  const serviceBeefree = useBeefreeService();
  const [templateDataSave] = useAtom(atomTemplateDataSave);
  //const [selectedTags] = useAtom(atomSelectedTags);
  const [selectedCategories] = useAtom(atomSelectedCategories);
  const [userData] = useUserData();
  const { t } = useTranslation();
  const [loadingSave, setLoadingSave] = useState(false);
  const { logger } = useBeefreeLogger();

  const getCatalog = useCallback(async () => {
    const tags = (await serviceBeefree.getTags()) ?? [];
    const categories = (await serviceBeefree.getCategories()) ?? [];
    const collections = (await serviceBeefree.getCollections()) ?? [];

    setCatalog({
      tags,
      categories,
      collections,
    });
  }, [serviceBeefree, setCatalog]);

  const onSave = useCallback(
    async (payload: FormInput) => {
      const { collection = { id: 0 } } = payload ?? {};
      const idCollection = typeof collection === 'string' ? parseInt(collection) : collection.id;

      const data = {
        name: payload.name,
        collection: idCollection,
        jsonData: templateDataSave.jsonData as string,
        htmlFile: templateDataSave.html as string,
        designer: payload.asEmblueTemplate ? 'emblue' : parseInt(userData.companyId),
        tags: [],
        categories: [selectedCategories?.id ? (selectedCategories?.id as number) : 0],
        createdBy: parseInt(userData.companyId),
        version: '2',
      };

      setLoadingSave(true);

      const response = await serviceBeefree.saveTemplate(data);
      const error = response?.error ? true : false;

      const dictionaryErrors = {
        NAME_ALREADY_EXISTS: t('BEEFREE_MODAL_TEMPLATE.errors.nameAlreadyExist'),
      };
      const errorKnow = response?.type
        ? dictionaryErrors[response.type as keyof typeof dictionaryErrors]
        : false;

      if (!error) {
        await logger('saveTemplate', 'action', {
          name: payload.name,
        });
      } else {
        await logger('saveTemplate', 'error', payload);
      }

      toast({
        variant: !error ? 'success' : 'error',
        show: true,
        title: !error
          ? t('BEEFREE_MODAL_TEMPLATE.saved.title')
          : t('BEEFREE_MODAL_TEMPLATE.error.title'),
        body: !error
          ? t('BEEFREE_MODAL_TEMPLATE.saved.body')
          : errorKnow || t('BEEFREE_MODAL_TEMPLATE.error.body'),
      });

      localStorage.removeItem('@beefree/template.create');
      setLoadingSave(false);
      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onClose,
      selectedCategories?.id,
      serviceBeefree,
      t,
      templateDataSave.html,
      templateDataSave.jsonData,
      userData.companyId,
    ]
  );

  useEffect(() => {
    void getCatalog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    catalog,
    onSave,
    loadingSave,
  };
};
