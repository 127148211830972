import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Option, toast } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomModalTypeProcess,
  atomScreenTypeProcess,
} from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { STRATEGY_OTHER } from '@/src/modules/CampaignsModule/constants';
import { ICheckNameResource } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ECampaignsTypeOptions,
  ICampaignListPayload,
  ICreateEditCampaigns,
  IDuplicateCampaigns,
  IDuplicateCampaignsV1,
  IFormInput,
  ModalType,
  ScreenType,
  TCampaignID,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import {
  atomCampaignList,
  atomCampaignListAll,
  atomDetailCampaign,
  atomLoadingDetail,
  atomLoadingProcess,
  atomSelectedRowsWithID,
  atomTotalSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { TRANSLATE_MODAL } from '@/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/constants';

const TRANSLATE_MODAL_DELETE = 'CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.TOAST_MESSAGES';
const TRANSLATE_MODAL_DUPLICATE = 'CAMPAIGNS_MAIN.DUPLICATE_CAMPAIGNS.TOAST_MESSAGES';

export const useCampaign = () => {
  const { t } = useTranslation();
  const service = useCampaignsService();
  const serviceV1 = useService();

  const [detailCampaign, setDetailCampaign] = useAtom(atomDetailCampaign);
  const setTotalsCampaignList = useSetAtom(atomTotalsProcess);
  const setCampaignList = useSetAtom(atomCampaignList);
  const setCampaignListAll = useSetAtom(atomCampaignListAll);
  const setLoadingProcess = useSetAtom(atomLoadingProcess);
  const setLoadingDetail = useSetAtom(atomLoadingDetail);
  const selectedRowsWithID = useAtomValue(atomSelectedRowsWithID);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);
  const screenTypeProcess = useAtomValue(atomScreenTypeProcess);

  const duplicateCampaignV2 = useFeatureFlag('duplicateCampaign');

  const [loading, setLoading] = useState<boolean>(false);

  const TOAST_MESSAGES = `${TRANSLATE_MODAL}.TOAST_MESSAGES`;

  const getList = useCallback(
    async (payload: ICampaignListPayload) => {
      setLoadingProcess(true);

      const response = await service.getCampaign(payload);

      setCampaignList(response?.campaigns);
      setTotalsCampaignList({
        total: response?.total || 0,
        partial: response?.partial || 0,
      });

      setLoadingProcess(false);
    },
    [service, setCampaignList, setTotalsCampaignList, setLoadingProcess]
  );

  const getListAll = useCallback(
    async (payload: ICampaignListPayload) => {
      setLoading(true);

      const response = await service.getCampaign(payload);
      setCampaignListAll(response?.campaigns);

      setLoading(false);
    },
    [service, setCampaignListAll, setLoading]
  );

  const createCampaign = useCallback(
    async (data: IFormInput) => {
      const { name, objective, strategy, otherStrategy, date } = data;
      const strategyData = strategy as Option;
      setLoading(true);

      const campaignData: ICreateEditCampaigns = {
        name: name.replace(/\s+/g, ' ').trim(),
        campaignStatesId: 1,
        campaignTypeId: Number(ECampaignsTypeOptions.MANUAL),
        flowId: null,
        autoConfirmation: 1,
        notifyGroupId: 0,
        strategyId: strategyData?.value !== STRATEGY_OTHER.value ? Number(strategyData?.id) : null,
        strategyName:
          strategyData?.value !== STRATEGY_OTHER.value ? String(strategyData?.name) : otherStrategy,
        goal: objective.replace(/\s+/g, ' ').trim(),
        startDate: date[0],
        endDate: date[1],
      };

      let campaignId = {};

      if (totalSelectedRows === 1) {
        campaignId = { campaignId: selectedRowsWithID[0] };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      } else if (modalTypeProcess === ModalType.EDIT && screenTypeProcess === ScreenType.DETAIL) {
        campaignId = { campaignId: detailCampaign?.campaignId };
      }

      const { statusOK } = await service.createCampaign({
        campaign: {
          ...campaignData,
          ...campaignId,
        },
      });
      setLoading(false);

      let title, message;

      if (
        totalSelectedRows === 1 ||
        (modalTypeProcess === ModalType.EDIT && screenTypeProcess === ScreenType.DETAIL)
      ) {
        if (statusOK) {
          title = t(`${TOAST_MESSAGES}.EDIT.SUCCESS.title`);
          message = t(`${TOAST_MESSAGES}.EDIT.SUCCESS.message`);
        } else {
          title = t(`${TOAST_MESSAGES}.EDIT.ERROR.title`);
          message = t(`${TOAST_MESSAGES}.EDIT.ERROR.message`);
        }
      } else if (statusOK) {
        title = t(`${TOAST_MESSAGES}.SUCCESS.title`);
        message = t(`${TOAST_MESSAGES}.SUCCESS.message`);
      } else {
        title = t(`${TOAST_MESSAGES}.ERROR.title`);
        message = t(`${TOAST_MESSAGES}.ERROR.message`);
      }

      toast({
        title,
        body: message,
        variant: statusOK ? 'success' : 'error',
      });
    },
    [service, totalSelectedRows, selectedRowsWithID, t, TOAST_MESSAGES]
  );

  const duplicateCampaign = useCallback(
    async (formData: IFormInput) => {
      if (!formData.campaignId) return;
      const { campaignId, name, objective, strategy, otherStrategy, date } = formData;
      const strategyData = strategy as Option;
      setLoading(true);

      const campaignPayload: IDuplicateCampaigns = {
        name: name.replace(/\s+/g, ' ').trim(),
        campaignId: Number(campaignId),
        strategyId: strategyData?.value !== STRATEGY_OTHER.value ? Number(strategyData?.id) : null,
        strategyName: otherStrategy,
        goal: objective,
        startDate: date ? date[0] : null,
        endDate: date ? date[1] : null,
      };

      const campaignPayloadV1: IDuplicateCampaignsV1 = {
        campaignId: Number(campaignId),
        name: name,
      };
      let statusOK, campaignIdDuplicate;

      if (duplicateCampaignV2) {
        ({ statusOK, campaignId: campaignIdDuplicate } = await service.duplicateCampaigns(
          campaignPayload
        ));
      } else {
        ({ statusOK, campaignIdDuplicate } = await serviceV1.duplicateV1Campaigns(
          campaignPayloadV1
        ));
      }

      setLoading(false);

      toast({
        title: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.title`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.title`),
        body: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.message`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.message`),
        variant: statusOK ? 'success' : 'error',
      });

      return campaignIdDuplicate;
    },
    [serviceV1, t]
  );

  const deleteCampaign = useCallback(
    async (campaignID: number[]) => {
      if (!campaignID) return;
      const isSingle: boolean = campaignID?.length === 1;

      if (campaignID?.length === 0) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      setLoading(true);

      const { statusOK } = await serviceV1.deleteV1Campaigns(campaignID);

      if (!statusOK) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      setLoading(false);
    },
    [serviceV1, t]
  );

  const createV1Campaigns = useCallback(
    async (data: any) => {
      const { name, templateId, campaignType } = data;

      setLoading(true);

      const isSingle: boolean = name?.length === 1;

      const { statusOK } = await serviceV1.createV1Campaigns({ name, templateId, campaignType });
      if (!statusOK) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      setLoading(false);
    },
    [serviceV1, t]
  );

  const getDetailCampaign = useCallback(
    async ({ campaignID }: { campaignID: TCampaignID }) => {
      setLoadingDetail(true);
      const { campaign } = await service.getCampaignByID(campaignID);
      setDetailCampaign(campaign);
      setLoadingDetail(false);
    },
    [service, setDetailCampaign, setLoadingDetail]
  );

  const isValidNameResource = useCallback(
    async (payload: ICheckNameResource) => {
      const isValid: boolean = await service.checkNameResource(payload);
      return isValid;
    },
    [service]
  );

  return {
    getList,
    getListAll,
    createCampaign,
    createV1Campaigns,
    duplicateCampaign,
    deleteCampaign,
    getDetailCampaign,
    isValidNameResource,
    loading,
  };
};
