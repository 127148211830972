export const ActionCopyIcon = () => {
  return (
    <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3139_9742)">
        <path
          d="M11.3999 4.3066C11.3999 3.3623 11.3999 2.89014 11.2457 2.51769C11.04 2.02111 10.6454 1.62657 10.1489 1.42087C9.77637 1.2666 9.30426 1.2666 8.35994 1.2666H5.31994C3.40917 1.2666 2.45379 1.2666 1.8602 1.8602C1.2666 2.45379 1.2666 3.40917 1.2666 5.31994V8.35994C1.2666 9.30426 1.2666 9.77637 1.42087 10.1489C1.62657 10.6454 2.02111 11.04 2.51769 11.2457C2.89014 11.3999 3.3623 11.3999 4.3066 11.3999"
          stroke="#0056B8"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1.5"
        />
        <path
          d="M15.7068 7.6001H9.62676C8.50747 7.6001 7.6001 8.50747 7.6001 9.62676V15.7068C7.6001 16.8261 8.50747 17.7334 9.62676 17.7334H15.7068C16.8261 17.7334 17.7334 16.8261 17.7334 15.7068V9.62676C17.7334 8.50747 16.8261 7.6001 15.7068 7.6001Z"
          stroke="#0056B8"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3139_9742">
          <rect fill="white" height="19" width="19" />
        </clipPath>
      </defs>
    </svg>
  );
};
