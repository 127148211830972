//cspell:ignore elemento
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import { Button, Card, CardSection, Flex, IconSvg, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';
import { EditPencilIcon } from '@/lib/v2/icons/outline';

import { TRANSLATE_RULES_CALL_TO_ACTION } from '@/src/modules/RulesModule/constants';

const RulesLayout = () => {
  const { t } = useTranslation('rules');

  const actionsButtons = useMemo(
    () => (
      <>
        <Button
          outline
          id="header-action__activate"
          size="small"
          onClick={() => {
            alert('cancel');
          }}
        >
          {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.activate`)}
        </Button>
      </>
    ),
    [t]
  );

  return (
    <>
      <ReactFlowProvider>
        <Card>
          <CardSection noPadding>
            <Flex column>
              <SectionHeader
                backButton
                sticky
                actionsButtons={actionsButtons}
                isLoading={false}
                titleNode={
                  <Text
                    as="h3"
                    className="!2xl:text-20 !text-16"
                    fontWeight="medium"
                    variant="title-1"
                  >
                    <Flex withGap>
                      {'New Rule'}
                      {
                        <Button
                          link
                          className="!h-auto !p-0"
                          iconLeft={
                            <IconSvg height="16px" svgComponent={<EditPencilIcon />} width="16px" />
                          }
                          id="back-to-campaigns-button"
                          onClick={() => {
                            alert('edit name');
                          }}
                        />
                      }
                    </Flex>
                  </Text>
                }
                onBack={() => {
                  alert('back');
                }}
              />
              <Outlet />
            </Flex>
          </CardSection>
        </Card>
      </ReactFlowProvider>
    </>
  );
};

export default RulesLayout;
