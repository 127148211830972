import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { IconSvg, If } from '@/lib/v2/components';
import { ModalActionCreation, ModalSingleInput } from '@/lib/v2/examples';
import { CreateActionIcon } from '@/lib/v2/icons/outline';
import { EmailTriggerIcon } from '@/lib/v2/icons/solid';

import {
  TRANSLATE_MODAL,
  TRANSLATE_MODAL_INPUTS,
} from '@/src/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/constants';

import { useTabsPanes } from './hooks/useTabsPanes';

import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomActionInputModalIsOpen,
  atomActionTypeName,
  atomCreateRules,
} from '@/modules/CampaignsModule/atoms/createActionsAtom';
import { useCreateAction } from '@/modules/CampaignsModule/hooks/useCreateAction';
import useDebouncedFetchService from '@/modules/CampaignsModule/hooks/useDebouncedFetchService';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import {
  EResourceType,
  ERulesActionType,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { EMaxMinLength } from '@/modules/CampaignsModule/interfaces/Campaigns';

type CreateActionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateActionsModal = ({ isOpen, onClose }: CreateActionsModalProps) => {
  const { t } = useTranslation();
  const { id: campaignId } = useParams();

  const [actionInputModalIsOpen, setActionInputModalIsOpen] = useAtom(atomActionInputModalIsOpen);
  const [createRules, setCreateRules] = useAtom(atomCreateRules);
  const { name: campaignName } = useAtomValue(atomDetailCampaign);
  const setActionTypeName = useSetAtom(atomActionTypeName);

  const { handleSubmit, createActionIsLoading } = useCreateAction(campaignId ?? '');
  const {
    state: { tabItems, tabPanes },
  } = useTabsPanes({ onClose });
  const { fetchServiceValidName } = useValidNameResource();
  const { validateFetchService, isLoading: isLoadingDebounce } = useDebouncedFetchService(
    async (value: string) => {
      const isValid = await fetchServiceValidName({
        name: value,
        resource: EResourceType.ACTION,
        campaignId: Number(campaignId),
      });
      return !!isValid;
    },
    500
  );

  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t(`${TRANSLATE_MODAL}.INPUTS_ERROR.required`),
        },
        minLength: {
          value: EMaxMinLength.MIN_LENGTH,
          message: t(`${TRANSLATE_MODAL}.INPUTS_ERROR.minLength`, {
            name: t(`${TRANSLATE_MODAL_INPUTS}.name.label`).toLowerCase(),
            min: EMaxMinLength.MIN_LENGTH,
          }),
        },
        maxLength: {
          value: EMaxMinLength.NAME_MAX,
          message: t(`${TRANSLATE_MODAL}.INPUTS_ERROR.maxLength`, {
            name: t(`${TRANSLATE_MODAL_INPUTS}.name.label`).toLowerCase(),
            max: EMaxMinLength.NAME_MAX,
          }),
        },
        validate: async (value: string) => {
          return (await validateFetchService(value)) || t('CAMPAIGN_ACTIONS_MAIN.existAction');
        },
      },
    }),
    [t, validateFetchService]
  );

  const handleCloseCreateItem = useCallback(() => {
    setActionInputModalIsOpen(false);
    setActionTypeName(RESET);
  }, [setActionInputModalIsOpen, setActionTypeName]);

  const handleOnSubmitCreateItem = useCallback(
    (actionName: string) => {
      void handleSubmit(actionName, campaignName ?? '');
    },
    [campaignName, handleSubmit]
  );

  const rootActionPanes = useMemo(() => createRules === ERulesActionType.ALL, [createRules]);

  const handleOnBack = useMemo(() => {
    return !rootActionPanes ? () => setCreateRules(ERulesActionType.ALL) : undefined;
  }, [rootActionPanes, setCreateRules]);

  return (
    <>
      <ModalSingleInput
        id="create-action-modal"
        isLoading={createActionIsLoading || isLoadingDebounce}
        isOpen={actionInputModalIsOpen}
        rules={VALIDATION_RULES}
        title={
          rootActionPanes
            ? t('CREATE_ACTIONS_MODAL.title')
            : t('ALL_CARDS_ACTIONS.RULES_TYPES.title')
        }
        onClose={handleCloseCreateItem}
        onSubmit={handleOnSubmitCreateItem}
      />
      <ModalActionCreation
        currentTab={1}
        icon={
          <IconSvg
            rounded
            bgColor="primary"
            height="40px"
            svgComponent={
              <>
                <If condition={rootActionPanes}>
                  <CreateActionIcon />
                </If>
                <If condition={!rootActionPanes}>
                  <EmailTriggerIcon />
                </If>
              </>
            }
            width="40px"
            withPadding="small"
          />
        }
        isOpen={isOpen}
        tabItems={tabItems}
        tabPanes={tabPanes}
        title={
          rootActionPanes
            ? t('CREATE_ACTIONS_MODAL.title')
            : t('ALL_CARDS_ACTIONS.RULES_TYPES.title')
        }
        onBack={handleOnBack}
        onClose={onClose}
      />
    </>
  );
};

export default CreateActionsModal;
