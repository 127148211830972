import { useCallback, useState } from 'react';

import { Alert } from '@/lib/v2/components/Alert';
import { Button } from '@/lib/v2/components/Button';
import { Text } from '@/lib/v2/components/Text';

import AssignmentOptions from './components/AssignmentOptions';
import SelectedEntityPanel from './components/SelectedEntityPanel';

export interface EntityAssignmentProps {
  items: { id: number; name: string }[];
  onCreate?: (toCreate: string) => Promise<void>;
  onClose: (value: boolean) => void;
  onApply: (
    e: React.MouseEvent<HTMLButtonElement>,
    selected: ISelectedDictionary,
    unselected: ISelectedDictionary
  ) => void;
  wordings: {
    title: string;
    reminder?: string;
    search: {
      placeholder: string;
    };
    actions: {
      apply: string;
      cancel: string;
    };
    selectedCount: number;
    description: {
      selected: string;
      unselected: string;
    };
    labelInputCreate: string;
  };
  isLoading?: boolean;
  limitSelection?: number;
}

export interface ISelectedDictionary {
  [key: number]: { id: number; name: string };
}

const EntityAssignment = ({
  items,
  onCreate,
  onClose,
  onApply,
  wordings,
  isLoading,
  limitSelection = Infinity,
}: EntityAssignmentProps) => {
  const [selected, setSelected] = useState<ISelectedDictionary>({});
  const [unselected, setUnselected] = useState<ISelectedDictionary>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const selectionCount = Object.keys(selected).length + Object.keys(unselected).length;

  const normalizeText = (text: string) => text.replace(/\s+/g, '').toLowerCase();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowCreateButton(!items.some((item) => normalizeText(item.name) === normalizeText(value)));
  };

  const handleCreateItem = useCallback(
    async (value: string) => {
      setIsCreating(true);
      try {
        await onCreate?.(value);
        clearAllInputs();
      } catch (error) {
        console.error(error);
      } finally {
        setIsCreating(false);
      }
    },
    [onCreate]
  );

  const clearAllInputs = () => {
    setSearchTerm('');
  };

  const filteredItems = items.filter((item) =>
    normalizeText(item.name).includes(normalizeText(searchTerm))
  );

  const handleSelectItem = useCallback(
    (id: number) => {
      if (selectionCount >= limitSelection && !unselected[id]) return;

      const item = items.find((item) => item.id === id);
      if (!item) return;

      const newSelected = {
        ...selected,
        [id]: item,
      };

      setSelected(newSelected);
      setUnselected((prevUnselected) => {
        const { [id]: _, ...newUnselected } = prevUnselected;
        return newUnselected;
      });
    },
    [selectionCount, selected, items, limitSelection]
  );

  const handleDeselectItem = useCallback(
    (id: number) => {
      if (selectionCount >= limitSelection && !selected[id]) return;

      const item = items.find((item) => item.id === id);
      if (!item) return;

      const { [id]: _, ...newSelected } = { ...selected };
      setSelected(newSelected);
      setUnselected((prevUnselected) => ({
        ...prevUnselected,
        [id]: item,
      }));
    },
    [selectionCount, selected, items, limitSelection]
  );

  const deleteSelected = useCallback(
    (id: number) => () => {
      const { [id]: _, ...newSelected } = { ...selected };
      setSelected(newSelected);
    },
    [selected]
  );

  const deleteUnselected = useCallback(
    (id: number) => () => {
      const { [id]: _, ...newUnselected } = { ...unselected };
      setUnselected(newUnselected);
    },
    [unselected]
  );

  return (
    <>
      <div className="flex w-full items-center gap-2 border-b px-6 pb-4 pt-6">
        <Text fontWeight="medium" variant="sub-headline">
          {wordings.title}
        </Text>
      </div>
      {wordings?.reminder && (
        <div className="w-full max-w-[720px] p-6 pb-0">
          <Alert description={wordings.reminder} type="info" />
        </div>
      )}
      <div className="flex min-h-80 border-b px-6 pb-3 pt-6">
        <AssignmentOptions
          handleDeselectItem={handleDeselectItem}
          handleSelectItem={handleSelectItem}
          isCreating={isCreating}
          items={filteredItems}
          limitSelection={limitSelection}
          searchTerm={searchTerm}
          selected={selected}
          selectionCount={selectionCount}
          showCreateButton={showCreateButton}
          unselected={unselected}
          wordings={wordings}
          onCreateItem={handleCreateItem}
          onSearchChange={handleSearchChange}
        />
        <SelectedEntityPanel
          deleteSelected={deleteSelected}
          deleteUnselected={deleteUnselected}
          selected={selected}
          unselected={unselected}
          wordings={wordings}
        />
      </div>
      <div className="flex justify-end gap-2 py-5 pr-6">
        <Button outline disabled={isLoading} id="cancel-button" onClick={() => onClose?.(false)}>
          {wordings.actions.cancel}
        </Button>
        <Button
          primary
          disabled={isLoading}
          id="apply-button"
          isLoading={isLoading}
          onClick={(e) =>
            selectionCount > 0
              ? onApply(e, Object.values(selected), Object.values(unselected))
              : onClose?.(false)
          }
        >
          {wordings.actions.apply}
        </Button>
      </div>
    </>
  );
};

export default EntityAssignment;
