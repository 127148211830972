import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuBar } from '@/lib/components/MenuBar';
import {
  ChannelsTableIcon,
  ItemsTableIcon,
  NotesIcon,
  RemarketingTableIcon,
  TagProfileTableIcon,
} from '@/lib/icon';
import { Card, CardFooter, CardSection } from '@/lib/v2/components';

import { ECommerceIcon } from '@/src/ContactsModule/Icons/ECommerceIcon';
import { GroupIcon } from '@/src/ContactsModule/Icons/GroupIcon';
import { HappinessIcon } from '@/src/ContactsModule/Icons/HappinessIcon';

import { ContactsProfileActivityChannelTable } from './ContactsProfileChannelTable';
import { ContactsProfileActivityECommerceTable } from './ContactsProfileECommerceTable';
import { ContactsProfileActivityGroupsTable } from './ContactsProfileGroupsTable';
import { ContactsProfileActivityHappinessTable } from './ContactsProfileHappinessTable';
import { ContactsProfileActivityItemsTable } from './ContactsProfileItemsTable';
import { ContactsProfileActivityNotesTable } from './ContactsProfileNotesTable';
import { ContactsProfileActivityTagsTable } from './ContactsProfileTagsTable';

export type MenuButtonProps = {
  name: string;
  id: string;
  href: string;
  current: boolean;
  icon: React.ReactNode;
  activeIcon?: React.ReactNode | undefined;
  component?: JSX.Element;
  enable: boolean;
};

export const ContactsProfileActivityTable = ({
  emailId,
  tabVisibility,
}: {
  emailId: number;
  tabVisibility: boolean[];
}) => {
  const { t } = useTranslation();

  const [isECommerceTab] = tabVisibility;

  const contactButtons = useMemo(
    () => [
      //TODO: REMOVE ENABLE PROP WHEN FEATURE FLAG IS TOTAL REMOVED.
      {
        name: t('CONTACTS_PROFILE_MENU_BAR.channels'),
        id: 'channels-tab',
        href: '#',
        current: true,
        icon: <ChannelsTableIcon color="#96a6ce" />,
        activeIcon: <ChannelsTableIcon color="#004dbc" />,
        component: <ContactsProfileActivityChannelTable emailId={emailId} />,
        enable: true,
      },

      {
        name: t('CONTACTS_PROFILE_MENU_BAR.notes'),
        id: 'notes-tab',
        href: '#',
        current: false,
        icon: <NotesIcon color="#96a6ce" />,
        activeIcon: <NotesIcon color="#004dbc" />,
        component: <ContactsProfileActivityNotesTable emailId={emailId} />,
        enable: true,
      },

      {
        name: t('CONTACTS_PROFILE_MENU_BAR.tags'),
        id: 'tags-tab',
        href: '#',
        current: false,
        icon: <TagProfileTableIcon color="#96a6ce" />,
        activeIcon: <TagProfileTableIcon color="#004dbc" />,
        component: <ContactsProfileActivityTagsTable emailId={emailId} />,
        enable: true,
      },
      {
        name: t('CONTACTS_PROFILE_MENU_BAR.items'),
        id: 'items-tab',
        href: '#',
        current: false,
        icon: <ItemsTableIcon color="#96a6ce" />,
        activeIcon: <ItemsTableIcon color="#004dbc" />,
        component: <ContactsProfileActivityItemsTable emailId={emailId} />,
        enable: true,
      },

      {
        name: t('CONTACTS_PROFILE_MENU_BAR.remarketing'),
        id: 'remarketing-tab',
        href: '#',
        current: false,
        icon: <RemarketingTableIcon color="#96a6ce" />,
        activeIcon: <RemarketingTableIcon color="#004dbc" />,
        component: <span>No table has yet been associated with the tab</span>,
        enable: false,
      },

      {
        name: t('CONTACTS_PROFILE_MENU_BAR.groupsAndSegments'),
        id: 'groups-tab',
        href: '#',
        current: false,
        icon: <GroupIcon secondary stroked tableSize />,
        activeIcon: <GroupIcon stroked tableSize />,
        component: <ContactsProfileActivityGroupsTable emailId={emailId} />,
        enable: true,
      },

      {
        name: t('CONTACTS_PROFILE_MENU_BAR.happiness'),
        id: 'happiness-tab',
        href: '#',
        current: false,
        icon: <HappinessIcon secondary tableSize />,
        activeIcon: <HappinessIcon tableSize />,
        component: <ContactsProfileActivityHappinessTable emailId={emailId} />,
        enable: true,
      },

      {
        name: t('CONTACTS_PROFILE_MENU_BAR.eCommerce'),
        id: 'eCommerce-tab',
        href: '#',
        current: false,
        icon: <ECommerceIcon secondary tableSize />,
        activeIcon: <ECommerceIcon tableSize />,
        component: <ContactsProfileActivityECommerceTable emailId={emailId} />,
        enable: isECommerceTab,
      },
    ],
    [isECommerceTab, emailId, t]
  );

  const [value, setValue] = useState<MenuButtonProps[]>(contactButtons);

  const clickHandler = (item: MenuButtonProps) => {
    let newCurrent = null;
    return (event: React.MouseEvent) => {
      const newValue = value.map((element: MenuButtonProps) => {
        newCurrent = element.name === item.name ? true : false;
        return {
          ...element,
          current: newCurrent,
        };
      });
      setValue(newValue);
      event.preventDefault();
    };
  };

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  const borderClasses = (item: MenuButtonProps) => {
    let borderClass = '';

    if (item.current && item.name) {
      borderClass =
        'border-[#004DBC] text-[#004DBC] border-b-[3px] py-6 px-3 gap-2 items-center h-[75px] w-max';
    } else if (item.current && !item.name) {
      borderClass =
        'border-[#f71963] text-[#004DBC] border-b-[3px] py-6 px-3 gap-2 items-center h-[75px] w-max';
    } else {
      borderClass =
        'border-transparent text-[#96a6ce] hover:border-gray-300 gap-2 items-center h-[75px] w-max';
    }

    return borderClass;
  };

  const tabs = value.map(
    (item: MenuButtonProps) =>
      item.enable && (
        <a
          key={item.name}
          className={classNames(
            `inline-flex text-[14px] py-6 px-3 gap-2 items-center font-semibold ${borderClasses(
              item
            )}`
          )}
          href={item.href}
          id={item.id}
          onClick={clickHandler(item)}
        >
          {' '}
          {item.current ? item.activeIcon : item.icon}
          {item.name}
        </a>
      )
  );

  useEffect(() => {
    setValue(contactButtons);
  }, [contactButtons, isECommerceTab]);

  return (
    <>
      <Card>
        <div className="h-full w-full">
          <CardSection noPadding>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <MenuBar tabs={tabs} />
          </CardSection>
          <CardFooter withDivide color="white">
            <>
              {value.map((item: MenuButtonProps) => (
                <>{item.current ? item.component : ''}</>
              ))}
            </>
          </CardFooter>
        </div>
      </Card>
    </>
  );
};
