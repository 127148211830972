import { ComponentType, FC } from 'react';

const withDeprecated = <OriginalProps extends object>(
  ChildComponent: ComponentType<OriginalProps>,
  message: string = ''
): FC<OriginalProps> => {
  const DeprecateComponent = (props: OriginalProps) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(`${ChildComponent.name} is deprecated. `, message);
    }

    return <ChildComponent {...props} />;
  };

  return DeprecateComponent;
};

export default withDeprecated;
