import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, IconSvg, Iframe, Spinner, Switch, Text } from '@/lib/v2/components';
import { UserIcon } from '@/lib/v2/icons/solid';

import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';

interface IHtmlPreviewProps {
  htmlString: string | undefined;
  subject: string | undefined;
  senderName: string | undefined;
  emailFrom: string | undefined;
  loading: boolean;
}

const HtmlPreview = ({
  htmlString,
  subject,
  senderName,
  emailFrom,
  loading,
}: IHtmlPreviewProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { campaignId, actionId } = useParams();

  const [isMobilePreview, setIsMobilePreview] = useState(false);

  const handleClick = useCallback(() => {
    navigate(`${CAMPAIGNS_PATH}/${Number(campaignId)}/email/${Number(actionId)}`);
  }, [actionId, campaignId, navigate]);

  return (
    <section className="relative mt-5 overflow-y-auto bg-emblueLightGray-preview px-[70px] pb-10 pt-12">
      <div className="absolute right-2 top-2.5">
        <Switch
          withLabels
          checked={isMobilePreview}
          id="preview__responsive-version"
          leftLabel={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.desktop')}
          rightLabel={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.mobile')}
          onChange={setIsMobilePreview}
        />
      </div>
      <div className="flex justify-center">
        <div
          className={`flex flex-col rounded-xl bg-white
          ${isMobilePreview ? 'w-[480px]' : 'w-[1092px]'}`}
        >
          <div>
            <div className={`pb-6 ${isMobilePreview ? 'pl-6' : 'pl-16'} pt-4`}>
              {subject && (
                <Text
                  className="!text-21 !text-emblue-text"
                  fontWeight="normal"
                  variant="sub-headline"
                >
                  {subject}
                </Text>
              )}
            </div>
            <div className={`${isMobilePreview ? 'pl-6 pr-4' : 'px-[10px]'}`}>
              <span className="flex h-[38px] items-center gap-4">
                <IconSvg
                  rounded
                  bgColor="gray-light"
                  fillColor="gray-dark"
                  height="38px"
                  svgComponent={<UserIcon />}
                  width="38px"
                  withPadding="small"
                />
                <div className={`flex ${isMobilePreview ? 'flex-col' : 'gap-[6px]'}`}>
                  <Text
                    className="inline-block !text-14 !text-emblue-text"
                    fontWeight="medium"
                    variant="text"
                  >
                    {senderName ? (
                      senderName
                    ) : (
                      <div className="animate-pulse">
                        <div className="h-3 w-250 rounded-full bg-gray-300 " />
                      </div>
                    )}
                  </Text>
                  <Text
                    className="inline-block !text-14"
                    fontWeight="normal"
                    variant="sub-headline"
                  >
                    {emailFrom && `<${emailFrom}>`}
                  </Text>
                </div>
              </span>
            </div>
          </div>
          {loading ? (
            <div className="h-300">
              <Spinner withoutOverlay />
            </div>
          ) : (
            <>
              {htmlString ? (
                <div className="relative size-full p-8">
                  <Iframe
                    fullHeight
                    withoutOverflow
                    html={htmlString}
                    id="message-display-iframe"
                    isMobile={isMobilePreview}
                    title="html"
                  />
                </div>
              ) : (
                <div className="flex h-300 flex-col items-center justify-center">
                  <Text className="!text-14 !text-emblue-text">
                    {t('CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.emptyAction')}
                  </Text>
                  <Button gray outline className="m-2" size="small" onClick={handleClick}>
                    {t('CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.title')}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default HtmlPreview;
