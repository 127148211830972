import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  addEdge as reactFlowAddEdge,
  Background,
  Connection,
  Edge,
  Node,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import { SidePanel } from '@/lib/v2/examples/SidePanel';

import { atomCurrentFormType, atomToggleSidePanel } from '@/src/modules/RulesModule/atoms/rules';
import FormCondition from '@/src/modules/RulesModule/components/FormCondition';
import FormTrigger from '@/src/modules/RulesModule/components/FormTrigger';
import { INITIAL_NODES } from '@/src/modules/RulesModule/constants/InitialFlow';
import { Direction, useAutoLayout } from '@/src/modules/RulesModule/hooks/useAutoLayout';
import { useFitView } from '@/src/modules/RulesModule/hooks/useFitView';
import { useRuleData } from '@/src/modules/RulesModule/hooks/useRuleData';
import { FormTypes } from '@/src/modules/RulesModule/interfaces';
import { NODE_TYPES } from '@/src/modules/RulesModule/types/';

import 'reactflow/dist/style.css';
import './RulesDetail.css';

const RulesDetail: React.FC = () => {
  const [nodes, , onNodesChange] = useNodesState<Node[]>(INITIAL_NODES);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);
  const { fitViewOptions } = useFitView(nodes);

  const { actionId } = useParams<{ actionId: string }>();
  useRuleData(Number(actionId));
  useAutoLayout(Direction.TOP_BOTTOM);

  const toggleSidePanel = useAtomValue(atomToggleSidePanel);
  const currentFormType = useAtomValue(atomCurrentFormType);

  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((eds) => reactFlowAddEdge(params, eds)),
    [setEdges]
  );

  return (
    <div className="h-[calc(100vh-137px)] w-full cursor-default overflow-hidden">
      <ReactFlow
        edges={edges}
        fitViewOptions={fitViewOptions}
        maxZoom={1}
        minZoom={1}
        nodeTypes={NODE_TYPES}
        nodes={nodes}
        nodesConnectable={false}
        nodesDraggable={false}
        panOnDrag={false}
        panOnScroll={false}
        zoomOnDoubleClick={false}
        zoomOnScroll={false}
        onConnect={onConnect}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
      >
        <Background color="#F4F5FA" gap={16} size={4} />
      </ReactFlow>
      {toggleSidePanel && (
        <SidePanel noPadding open={toggleSidePanel} side="right">
          {currentFormType === FormTypes.TRIGGER ? <FormTrigger /> : <FormCondition />}
        </SidePanel>
      )}
    </div>
  );
};

export default RulesDetail;
