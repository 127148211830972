import { useAtomValue } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Position } from 'reactflow';

import { IconSvg, Text } from '@/lib/v2/components';
import { CubeIcon } from '@/lib/v2/icons/outline';

import InitialNode from '@/src/modules/RulesModule/components/InitialNode';
import useIsTriggerFormComplete from '@/src/modules/RulesModule/components/TriggerNode/hooks/useIsTriggerFormComplete';
import {
  TRANSLATE_RULES_SEQUENCE_SETTINGS,
  TRANSLATE_RULES_TRIGGER_TYPES,
} from '@/src/modules/RulesModule/constants';
import { RulesOptions } from '@/src/modules/RulesModule/interfaces';

import { atomRuleData } from '@/modules/RulesModule/atoms/rules';

const TriggerNodeContent = () => {
  const { t } = useTranslation('rules');

  const rulesData: RulesOptions = useAtomValue(atomRuleData);
  const isComplete = useIsTriggerFormComplete(rulesData);

  const formatTriggerDetails = useCallback(
    (formValues: RulesOptions): string => {
      const { frequency, days, period, execution, hours, minutes } = formValues;

      return [
        frequency?.name,
        days?.name,
        period?.name,
        execution?.name,
        `${t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.atTime`)} ${hours?.name}:${minutes?.name}`,
      ].join(' ');
    },
    [t]
  );

  if (Object.keys(rulesData).length === 0) {
    return (
      <div className="mb-2 flex w-full animate-pulse flex-col gap-2 pt-1">
        <div className="h-3 w-20 rounded-full bg-gray-200"></div>
        <hr />
        <div className="h-3 w-20 rounded-full bg-gray-200"></div>
        <div className="h-3 w-36 rounded-full bg-gray-200"></div>
      </div>
    );
  }

  if (!isComplete) {
    return <InitialNode title={t(`${TRANSLATE_RULES_TRIGGER_TYPES}.triggerDate`)} />;
  }

  return (
    <>
      <div className="mb-2 flex items-center">
        <IconSvg
          fillColor="primary"
          height="24px"
          id="cube"
          svgComponent={<CubeIcon />}
          width="24px"
        />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {t(`${TRANSLATE_RULES_TRIGGER_TYPES}.triggerDate`)}
        </Text>
      </div>
      <hr className="w-full min-w-64" />
      <div className="flex flex-col justify-center pb-4 pt-2">
        <Text fontWeight="medium" variant="text">
          {rulesData?.customField?.name}
        </Text>
        <Text fontWeight="medium" variant="text-sm">
          {rulesData && formatTriggerDetails(rulesData)}
        </Text>
      </div>
      <Handle className="handle" position={Position.Bottom} type="source" />
    </>
  );
};

export default memo(TriggerNodeContent);
