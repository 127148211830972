import { useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkbox, Input, TimeInput } from '@/lib/v2/components';
import DateInput, { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { eventOnChangeTimeInput } from '@/lib/v2/components/TimeInput/TimeInput';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomCallbackStep,
  atomDeliveryInformation,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import useSchedulingForm, {
  SchedulingFormState,
} from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/hooks/useSchedulingForm';
import { SubjectButtons } from '@/modules/CampaignsModule/components/SubjectButtons';
import { ISubjectChangeButtonsProps } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import {
  IDeliveryActionResponse,
  ISetDeliveryActionPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EActionStatusAvailable,
  EChannelsID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';
import { DateFormat, formatDate } from '@/modules/CampaignsModule/utils';
import { formatTime } from '@/modules/CampaignsModule/utils/formatDate';

interface IDeliveryFormProps {
  disabled?: boolean;
}

const ADD_MINUTES = 10;

const SchedulingDeliveryForm = ({ disabled = false }: IDeliveryFormProps) => {
  const { t } = useTranslation();
  const { campaignId, actionId } = useParams();
  const navigate = useNavigate();

  const deliveryInformation = useAtomValue(atomDeliveryInformation);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);

  const { control, RULES, handleSubmit, setValue, getValues, resetField, clearErrors } =
    useSchedulingForm();
  const { saveDeliveryAction, setActionState } = useActionEmailData(Number(actionId));

  const [scheduledDeliveryDate, setScheduledDeliveryDate] = useState<Date | undefined>(undefined);
  const [scheduledDeliveryTime, setScheduledDeliveryTime] = useState<Date | undefined>(undefined);
  const [scheduledDeliveryMinDateTime, setScheduledDeliveryMinDateTime] = useState<
    Date | undefined
  >(undefined);
  const [reinforcementDateState, setReinforcementDateState] = useState<Date | undefined>(undefined);
  const [reinforcementTimeState, setReinforcementTimeState] = useState<Date | undefined>(undefined);
  const [reinforcementMinDateTime, setReinforcementMinDateTime] = useState<Date | undefined>(
    undefined
  );
  const [reinforcementMinDate, setReinforcementMinDate] = useState<Date | undefined>(undefined);
  const [reinforcementCheckbox, setReinforcementCheckbox] = useState<boolean>(
    ((deliveryInformation?.reinforcement?.isReinforcementShipment &&
      deliveryInformation?.shippingDate) as boolean) || false
  );

  useEffect(() => {
    const {
      shippingDate,
      reinforcement: { subject, startDate: reinforcementStartDate },
    } = deliveryInformation as IDeliveryActionResponse;

    if (shippingDate && !scheduledDeliveryDate) {
      const newShippingDate = new Date(shippingDate);

      setValue('scheduledDeliveryDate', newShippingDate);
      setScheduledDeliveryDate(newShippingDate);

      setValue('scheduledDeliveryTime', newShippingDate);
      setScheduledDeliveryTime(newShippingDate);

      if (newShippingDate.getDate() === new Date().getDate()) {
        if (newShippingDate.getHours() > new Date().getHours()) {
          setScheduledDeliveryMinDateTime(new Date());
        } else {
          setScheduledDeliveryMinDateTime(newShippingDate);
        }
      } else {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        setScheduledDeliveryMinDateTime(currentDate);
      }
    }

    if (reinforcementCheckbox && !!shippingDate) {
      const newReinforcementDate = new Date(reinforcementStartDate);
      setValue('reinforcementCheckbox', true);
      setValue('reinforcementSubject', subject);
      setValue('reinforcementDate', reinforcementStartDate ? newReinforcementDate : null);
      setValue('reinforcementTime', reinforcementStartDate ? newReinforcementDate : null);

      setReinforcementDateState(reinforcementStartDate ? newReinforcementDate : undefined);
      setReinforcementTimeState(reinforcementStartDate ? newReinforcementDate : undefined);

      if (newReinforcementDate.getDate() === new Date().getDate()) {
        if (newReinforcementDate.getHours() > new Date().getHours()) {
          setReinforcementMinDate(new Date());
          setReinforcementMinDateTime(new Date());
        } else {
          setReinforcementMinDate(new Date());
          setReinforcementMinDateTime(newReinforcementDate);
        }
      } else {
        const currentDate = new Date(shippingDate);
        currentDate.setHours(
          currentDate.getHours(),
          Math.floor(currentDate.getMinutes() / 10) * 10
        );
        currentDate.setDate(currentDate.getDate() + 1);

        setReinforcementMinDate(currentDate);
        setReinforcementMinDateTime(currentDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeScheduledDeliveryDate: eventOnChangeDateInput = useCallback(
    (event) => {
      const currentDate = new Date();
      const inputDate = event as Date;
      const currentDateMin = new Date(inputDate);

      inputDate && inputDate.getDate() === currentDate.getDate()
        ? inputDate.setHours(currentDate.getHours(), Math.floor(currentDate.getMinutes() / 10) * 10)
        : inputDate && inputDate.setHours(0, 0, 0, 0);

      currentDateMin.setDate(currentDateMin.getDate() + 1);

      setScheduledDeliveryDate(inputDate);
      setScheduledDeliveryTime(undefined);
      setScheduledDeliveryMinDateTime(inputDate);
      setReinforcementMinDate(currentDateMin);
      resetField('scheduledDeliveryTime');
    },
    [resetField]
  );

  const onChangeScheduledDeliveryTime: eventOnChangeTimeInput = useCallback(
    (event) => {
      setScheduledDeliveryTime(event as Date);

      resetField('reinforcementDate');
      resetField('reinforcementTime');
      setReinforcementDateState(undefined);
      setReinforcementTimeState(undefined);

      const reinforcementMinDateTime = new Date(event as Date);
      reinforcementMinDateTime.setMinutes(reinforcementMinDateTime.getMinutes() + ADD_MINUTES);

      setReinforcementMinDateTime(reinforcementMinDateTime);
    },
    [resetField]
  );

  const onChangeReinforcementDate: eventOnChangeDateInput = useCallback(
    (event) => {
      const inputDate = event as Date;
      const reinforcementMinDateTime = new Date(inputDate);

      setReinforcementDateState(inputDate);

      const currentDeliveryDate = new Date(scheduledDeliveryDate as Date);

      if (inputDate.getDate() === currentDeliveryDate.getDate() + 1) {
        setReinforcementMinDateTime(scheduledDeliveryTime);
      } else {
        reinforcementMinDateTime.setHours(0, 0, 0, 0);
        setReinforcementMinDateTime(reinforcementMinDateTime);
      }

      setReinforcementTimeState(undefined);
      resetField('reinforcementTime');
    },
    [resetField, scheduledDeliveryDate, scheduledDeliveryTime]
  );

  const onChangeReinforcementTime: eventOnChangeTimeInput = useCallback((event) => {
    setReinforcementTimeState(event as Date);
  }, []);

  const handleChangeReinforcement = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setReinforcementCheckbox(checked);
      if (!checked) clearErrors();
    },
    [clearErrors]
  );

  const handleSubmitData = useCallback(
    async (formData: SchedulingFormState) => {
      setGlobalLoadingAction(true);

      const {
        scheduledDeliveryDate,
        scheduledDeliveryTime,
        reinforcementSubject,
        reinforcementCheckbox,
        reinforcementDate,
        reinforcementTime,
      } = formData;

      const DeliveryAsDate = new Date(scheduledDeliveryDate as Date);
      const DeliveryAsTime = new Date(scheduledDeliveryTime as Date);

      const DeliveryYear = DeliveryAsDate?.getFullYear();
      const DeliveryMonth = DeliveryAsDate?.getMonth();
      const DeliveryDay = DeliveryAsDate?.getDate();
      const DeliveryHour = DeliveryAsTime?.getHours();
      const DeliveryMinutes = DeliveryAsTime?.getMinutes();

      const deliveryDatePayload = new Date(
        DeliveryYear,
        DeliveryMonth,
        DeliveryDay,
        DeliveryHour,
        DeliveryMinutes
      );

      const ReinforcementAsDate = new Date(reinforcementDate as Date);
      const ReinforcementAsTime = new Date(reinforcementTime as Date);

      const ReinforcementYear = ReinforcementAsDate?.getFullYear();
      const ReinforcementMonth = ReinforcementAsDate?.getMonth();
      const ReinforcementDay = ReinforcementAsDate?.getDate();
      const ReinforcementHour = ReinforcementAsTime?.getHours();
      const ReinforcementMinutes = ReinforcementAsTime?.getMinutes();

      const reinforcementDatePayload = new Date(
        ReinforcementYear,
        ReinforcementMonth,
        ReinforcementDay,
        ReinforcementHour,
        ReinforcementMinutes
      );

      const payload: ISetDeliveryActionPayload = {
        actionType: EChannelsID.EMAIL,
        actionId: Number(actionId),
        isImmediateShipment: false,
        delivery: {
          date: formatDate(String(deliveryDatePayload), DateFormat.ShortDateServer),
          hour: formatTime(String(deliveryDatePayload), DateFormat.TimeOnly),
        },
        reinforcement: {
          subject: reinforcementCheckbox ? reinforcementSubject : null,
          isReinforcementShipment: reinforcementCheckbox ?? false,
          date: reinforcementCheckbox
            ? formatDate(String(reinforcementDatePayload), DateFormat.ShortDateServer)
            : null,
          hour: reinforcementCheckbox
            ? formatTime(String(reinforcementDatePayload), DateFormat.TimeOnly)
            : null,
        },
      };

      await saveDeliveryAction(payload);
      await setActionState({
        actionId: Number(actionId),
        statusId: EActionStatusAvailable.CONFIRMABLE_DRAFT,
      });

      setGlobalLoadingAction(false);
      navigate(`/v2/campaigns/${Number(campaignId)}/email/${Number(actionId)}/summary`);
    },
    [actionId, campaignId, navigate, saveDeliveryAction, setActionState, setGlobalLoadingAction]
  );

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setGlobalLoadingAction(false);
  }, []);

  const handleChangeSubjectButton = ({ item }: ISubjectChangeButtonsProps) => {
    const currentSubject = getValues('reinforcementSubject');
    setValue('reinforcementSubject', `${currentSubject} ${item}`);
  };

  return (
    <form
      id="scheduling-form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit(handleSubmitData);
      }}
    >
      <div className="mb-5 flex gap-3">
        <div className="flex w-[200px]">
          <DateInput
            fullWidth
            isRequired
            control={control}
            date={scheduledDeliveryDate}
            disabled={disabled}
            id="scheduledDeliveryDate"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            minDate={new Date()}
            name="scheduledDeliveryDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.scheduledDeliveryDate}
            onChange={onChangeScheduledDeliveryDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            isRequired
            control={control}
            date={scheduledDeliveryTime}
            disabled={disabled}
            id="scheduledDeliveryTime"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={scheduledDeliveryMinDateTime || new Date()}
            name="scheduledDeliveryTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.scheduledDeliveryTime}
            onChange={onChangeScheduledDeliveryTime}
          />
        </div>
      </div>
      <div className="mb-5 flex items-center gap-3" id="scheduling-checkbox-container">
        <Checkbox
          checked={reinforcementCheckbox}
          color="#D3DDEA"
          control={control}
          disabled={disabled}
          id="reinforcementCheckbox"
          label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.reinforcement')}
          name="reinforcementCheckbox"
          size={16}
          onChange={handleChangeReinforcement}
        />
      </div>
      <div className="mb-4 flex w-3/4 gap-2" id="scheduling-subject-container">
        <Input
          control={control}
          disabled={!reinforcementCheckbox || disabled}
          id="reinforcementSubject"
          isRequired={reinforcementCheckbox}
          name="reinforcementSubject"
          placeHolder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.subjectPlaceholder`)}
          rules={RULES.reinforcementSubject}
        />
        <div className="mt-1">
          <SubjectButtons
            actionId={Number(actionId)}
            disabled={!reinforcementCheckbox || disabled}
            id="email-scheduling-buttons"
            searchPlaceholder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.searchDropdown`)}
            onChangeButtons={handleChangeSubjectButton}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex w-[200px]">
          <DateInput
            control={control}
            date={reinforcementDateState}
            disabled={
              !reinforcementCheckbox ||
              disabled ||
              !(scheduledDeliveryDate && scheduledDeliveryTime)
            }
            id="reinforcementDate"
            isRequired={reinforcementCheckbox}
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            minDate={reinforcementMinDate}
            name="reinforcementDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.reinforcementDate}
            onChange={onChangeReinforcementDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            control={control}
            date={reinforcementTimeState}
            disabled={
              !reinforcementCheckbox ||
              disabled ||
              !(scheduledDeliveryDate && scheduledDeliveryTime)
            }
            id="reinforcementTime"
            isRequired={reinforcementCheckbox}
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={reinforcementMinDateTime}
            name="reinforcementTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.reinforcementTime}
            onChange={onChangeReinforcementTime}
          />
        </div>
      </div>
    </form>
  );
};

export default SchedulingDeliveryForm;
