import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { ModalType, ScreenType } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export const atomToggleEditCreateCampaignModal = atom<boolean>(false);
atomToggleEditCreateCampaignModal.debugLabel = 'atomToggleEditCreateCampaignModal';

export const atomModalTypeProcess = atomWithReset<ModalType>(ModalType.CREATE);
atomModalTypeProcess.debugLabel = 'atomModalTypeProcess';

export const atomScreenTypeProcess = atomWithReset<ScreenType>(ScreenType.MAIN);
atomScreenTypeProcess.debugLabel = 'atomScreenTypeProcess';

export const atomToggleDeleteModal = atom<boolean>(false);
atomToggleDeleteModal.debugLabel = 'atomToggleDeleteModal';

export const atomToggleEditActionsModal = atom<boolean>(false);
atomToggleEditActionsModal.debugLabel = 'atomToggleEditActionsModal';

export const atomToggleDuplicateActionsModal = atom<boolean>(false);
atomToggleDuplicateActionsModal.debugLabel = 'atomToggleDuplicateActionsModal';

export const atomToggleMoveActionsModal = atom<boolean>(false);
atomToggleMoveActionsModal.debugLabel = 'atomToggleMoveActionsModal';

export const atomToggleDeleteActionsModal = atom<boolean>(false);
atomToggleDeleteModal.debugLabel = 'atomToggleDeleteModal';

export const atomToggleDuplicateCampaignModal = atom<boolean>(false);
atomToggleDuplicateCampaignModal.debugLabel = 'atomToggleDuplicateCampaignModal';
