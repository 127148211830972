export const CubeIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.75 3H3V9.7575H4.5V4.5H9.75V3ZM22.1175 9.84L15.3675 6.09C15.2535 6.02417 15.1242 5.98952 14.9925 5.98952C14.8608 5.98952 14.7315 6.02417 14.6175 6.09L7.8675 9.84C7.75333 9.90766 7.65915 10.0044 7.59459 10.1204C7.53002 10.2363 7.49738 10.3673 7.5 10.5V18C7.50097 18.1336 7.53764 18.2646 7.60621 18.3793C7.67478 18.494 7.77275 18.5884 7.89 18.6525L14.64 22.4025C14.7499 22.4648 14.8737 22.4983 15 22.5C15.1286 22.498 15.2548 22.4645 15.3675 22.4025L22.1175 18.6525C22.2334 18.5874 22.3298 18.4926 22.397 18.378C22.4642 18.2633 22.4998 18.1329 22.5 18V10.5C22.5011 10.3658 22.4662 10.2338 22.3989 10.1177C22.3317 10.0017 22.2345 9.90574 22.1175 9.84ZM14.25 20.475L9 17.5575V11.7675L14.25 14.685V20.475ZM15 13.3875L9.795 10.5L15 7.605L20.205 10.5L15 13.3875ZM21 17.5575L15.75 20.475V14.685L21 11.7675V17.5575Z"
        fill="#0056B8"
      />
    </svg>
  );
};
