import { ExclamationIcon } from '@heroicons/react/solid';
import { useAtom, useAtomValue } from 'jotai';
import { ChangeEvent, FocusEvent, useCallback, useEffect, useRef } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alert, Checkbox, Flex, IconSvg, InputTextArea, Text } from '@/lib/v2/components';

import { CountSMS } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsForm/components/CountSMS';
import { ISMSFormInfo } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsForm/hooks/useSmsForm';
import { useSmsFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsForm/hooks/useSmsFormEvents';
import { MAX_LENGTH_SMS } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/constants';
import { replaceUrlsWithShortLinks } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/utils';

import {
  atomActionInfoSms,
  atomInfoSmsPayload,
  atomMessageSMS,
} from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { SubjectButtons } from '@/modules/CampaignsModule/components/SubjectButtons';
import {
  EButtonType,
  ISubjectChangeButtonsProps,
} from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { TRANSLATE_SMS_ACTION_FORM } from '@/modules/CampaignsModule/constants';
import { IFormProps, ISmsInfoPayload } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IMessageInputProps {
  loading?: boolean;
  form: IFormProps<ISMSFormInfo>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const MessageInput = ({ loading, form, rules, disabled = false }: IMessageInputProps) => {
  const { t } = useTranslation();
  const { actionId } = useParams();

  const [messageSMS, setMessageSMS] = useAtom(atomMessageSMS);
  const [infoSmsPayload, setInfoSmsPayload] = useAtom(atomInfoSmsPayload);
  const actionInfoSms = useAtomValue(atomActionInfoSms);

  const { setInfoOnEvent } = useSmsFormEvents({ actionId: Number(actionId) });

  const { control, setValue } = form;

  const messageRef = useRef<HTMLTextAreaElement>(null);

  /* cspell:disable */
  useEffect(() => {
    const message = actionInfoSms?.mensaje ?? '';

    setValue('message', message);
    setMessageSMS({ ...messageSMS, original: message });
  }, [actionInfoSms?.mensaje, setMessageSMS, setValue]);
  /* cspell:enable */

  const handleChangeMessage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setMessageSMS({ ...messageSMS, original: value });
    },
    [messageSMS, setMessageSMS]
  );

  /* cspell:disable */
  const handleBlurMessage = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;

      const payload: ISmsInfoPayload = { ...infoSmsPayload, mensaje: value };

      setInfoOnEvent(payload);
      setInfoSmsPayload(payload);
    },
    [infoSmsPayload, setInfoOnEvent, setInfoSmsPayload]
  );
  /* cspell:enable */

  /* cspell:disable */
  const handleChangeSubjectButton = useCallback(
    ({ item }: ISubjectChangeButtonsProps) => {
      const currentMessage = messageSMS.original;
      const message = currentMessage ? `${currentMessage} ${item}` : item;
      setValue('message', message);
      setMessageSMS({ ...messageSMS, original: message });

      const payload: ISmsInfoPayload = { ...infoSmsPayload, mensaje: message };

      setInfoOnEvent(payload);
      setInfoSmsPayload(payload);
    },
    [setValue, setMessageSMS, messageSMS, infoSmsPayload, setInfoOnEvent, setInfoSmsPayload]
  );
  /* cspell:enable */

  const handleChangeShortUrl = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      const currentSMS = messageSMS.original;
      const shortenedSMS = replaceUrlsWithShortLinks(currentSMS);

      setValue('message', checked ? shortenedSMS : currentSMS);

      const payload: ISmsInfoPayload = { ...infoSmsPayload, track: !!checked };

      setInfoOnEvent(payload);
      setInfoSmsPayload(payload);
      setMessageSMS({
        ...messageSMS,
        original: currentSMS,
        shortened: checked ? shortenedSMS : '',
        track: !!checked,
      });
    },
    [infoSmsPayload, messageSMS, setInfoOnEvent, setInfoSmsPayload, setMessageSMS, setValue]
  );

  return (
    <div>
      <Text
        className="sticky top-0 z-[1] border-b border-solid border-emblue-borders bg-emblue-white pb-2"
        color="primary"
        fontWeight="medium"
        variant="sub-headline"
      >
        {t(`${TRANSLATE_SMS_ACTION_FORM}.message`)}
      </Text>
      <Text className="mt-4" color="dark" fontWeight="normal" variant="text">
        {t(`${TRANSLATE_SMS_ACTION_FORM}.messageDisclaimer`)}
      </Text>
      <div className="mb-10">
        <Flex withGap className="mt-9" gapSize="small" itemAlignment="start" textAlignment="center">
          <div className="-mt-1 w-full">
            <InputTextArea
              ref={messageRef}
              control={control}
              disabled={disabled || messageSMS.track}
              id="sms_message"
              isLoading={loading}
              name="message"
              rows={7}
              rules={rules.message}
              onBlur={handleBlurMessage}
              onChange={handleChangeMessage}
            />
            {!loading && (
              <div
                className={`flex gap-1 ${messageSMS.count > 1 ? 'justify-between' : 'justify-end'}`}
              >
                {messageSMS.count > 1 && (
                  <Text className="mt-1 flex w-4/5 gap-2" fontWeight="normal" variant="text">
                    <IconSvg
                      className="mt-0.5 min-w-[20px]"
                      fillColor="passive"
                      height="20px"
                      svgComponent={<ExclamationIcon />}
                      width="20px"
                    />
                    {t(`${TRANSLATE_SMS_ACTION_FORM}.messageDisclaimerLength`)}
                  </Text>
                )}
                <CountSMS
                  count={messageSMS.count}
                  length={messageSMS.length}
                  maxLength={MAX_LENGTH_SMS}
                />
              </div>
            )}
          </div>
          <SubjectButtons
            vertical
            actionId={Number(actionId)}
            disabled={disabled || messageSMS.track}
            exclude={[EButtonType.TAG, EButtonType.EMOJI]}
            id="sms_form-buttons"
            searchPlaceholder={t(`${TRANSLATE_SMS_ACTION_FORM}.searchDropdown`)}
            onChangeButtons={handleChangeSubjectButton}
          />
        </Flex>

        {messageSMS?.customFields > 0 && (
          <Alert
            className="mt-4"
            description={t(`${TRANSLATE_SMS_ACTION_FORM}.messageDisclaimerCustomFields`)}
            type="info"
          />
        )}
      </div>
      <div className="mb-10">
        <Checkbox
          checked={messageSMS.track}
          control={control}
          disabled={disabled}
          id="sms_short-track"
          label={t(`${TRANSLATE_SMS_ACTION_FORM}.shortener`)}
          onChange={handleChangeShortUrl}
        />
        <Text className="pl-5" color="dark" fontWeight="normal" variant="text">
          {t(`${TRANSLATE_SMS_ACTION_FORM}.shortenerTracking`)}
        </Text>
      </div>
    </div>
  );
};

export default MessageInput;
