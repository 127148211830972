import { useSetAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { Node, NodeProps, useNodes } from 'reactflow';

import { Card } from '@/lib/v2/components';

import { atomCurrentFormType, atomToggleSidePanel } from '@/src/modules/RulesModule/atoms/rules';
import { useAddPlaceholderNode } from '@/src/modules/RulesModule/hooks/useAddPlaceholderNode';
import { AddOperationTypes, FormTypes, NodeData } from '@/src/modules/RulesModule/interfaces';

import TriggerNodeContent from './components/TriggerNodeContent';

const TriggerNode = ({ id }: NodeProps) => {
  const addPlaceholderNode = useAddPlaceholderNode();
  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);
  const setCurrentFormType = useSetAtom(atomCurrentFormType);

  const nodes = useNodes<NodeData>();

  const haveConditionNode = nodes.some(
    (node: Node<NodeData>) => node.data.type === AddOperationTypes.condition
  );

  const handleEdit = useCallback(() => {
    setCurrentFormType(FormTypes.TRIGGER);
    setToggleSidePanel(true);
    !haveConditionNode && addPlaceholderNode();
  }, [addPlaceholderNode, setCurrentFormType, setToggleSidePanel, haveConditionNode]);

  return (
    <Card
      className="w-72 !px-4 pb-0 hover:outline-none hover:ring-2 hover:ring-emblue-primary hover:ring-offset-2"
      id={id}
      onClick={handleEdit}
    >
      <TriggerNodeContent />
    </Card>
  );
};

export default memo(TriggerNode);
