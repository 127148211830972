import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, Text } from '@/lib/v2/components';

import { numberToCurrency } from '@/src/utils/currency';

interface AdditionalCostsProps {
  additionalPriceEmail: number;
  additionalPriceSms: number;
  additionalPriceOnsite: number;
}

const AdditionalCosts = ({
  additionalPriceEmail,
  additionalPriceSms,
  additionalPriceOnsite,
}: AdditionalCostsProps) => {
  const { t } = useTranslation();

  return (
    <Card noShadow color="gray">
      <CardSection>
        <Text fontWeight="medium">{t('MANAGE_PLAN.ADDITIONAL_COSTS.title')}</Text>
        <Text variant="text">
          {t('MANAGE_PLAN.ADDITIONAL_COSTS.additionalEmails', {
            price: numberToCurrency(additionalPriceEmail),
          })}{' '}
          |{' '}
          {t('MANAGE_PLAN.ADDITIONAL_COSTS.additionalSms', {
            price: numberToCurrency(additionalPriceSms),
          })}{' '}
          |{' '}
          {t('MANAGE_PLAN.ADDITIONAL_COSTS.additionalOnsite', {
            price: numberToCurrency(additionalPriceOnsite),
          })}
        </Text>
      </CardSection>
    </Card>
  );
};

export default memo(AdditionalCosts);
