import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formValidations } from '@/src/constants/FormValidations';

export const useEditActions = () => {
  const { inputLengths } = formValidations;
  const { t } = useTranslation();
  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
      },
    }),
    [t]
  );

  const VALIDATION_RULES_EVENTS = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        validate: {
          onsite: (value: string) => {
            return (
              !value.toLocaleLowerCase().startsWith('onsite') ||
              t(`AUTOMATION_MAIN_MODULE.MODALS.MODALS_FLOW.RENAME.INPUTS_ERROR.onsite`)
            );
          },
        },
      },
    }),
    [t]
  );

  return {
    VALIDATION_RULES,
    VALIDATION_RULES_EVENTS,
  };
};
