export const DistributeIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7589" id="Group_7589" transform="translate(-0.318 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.318 -0.163)"
          width="20"
        />
        <g id="Distribution" transform="translate(-2.313 -3.518)">
          <path
            d="M68.258,66.1a.138.138,0,0,0-.238-.089l-.536.536-1.28-1.28a.343.343,0,0,0-.506,0l-.863.864a.343.343,0,0,0,0,.506l1.28,1.28-.536.536a.138.138,0,0,0,.089.238l2.68.179a.141.141,0,0,0,.149-.149Z"
            data-name="Layout 3144"
            fill="#004dbc"
            id="Layout_3144"
            transform="translate(-47.728 -47.498)"
          />
          <path
            d="M4.839,66.217a.138.138,0,0,1,.238-.089l.566.566,1.28-1.28a.343.343,0,0,1,.506,0l.863.863a.343.343,0,0,1,0,.506l-1.28,1.28.536.536a.138.138,0,0,1-.089.238l-2.68.179a.141.141,0,0,1-.149-.149Z"
            data-name="Layout 3145"
            fill="#004dbc"
            id="Layout_3145"
            transform="translate(0 -47.613)"
          />
          <g data-name="Group 3383" id="Group_3383" transform="translate(4.631 5.354)">
            <path
              d="M68.258,8.268a.138.138,0,0,1-.238.089l-.536-.536L66.2,9.1a.343.343,0,0,1-.506,0l-.863-.863a.343.343,0,0,1,0-.506l1.28-1.28-.536-.536a.138.138,0,0,1,.089-.238l2.68-.179a.141.141,0,0,1,.149.149Z"
              data-name="Layout 3146"
              fill="#004dbc"
              id="Layout_3146"
              transform="translate(-52.358 -5.469)"
            />
            <path
              d="M4.839,8.153a.138.138,0,0,0,.238.089l.566-.566,1.28,1.28a.343.343,0,0,0,.506,0l.863-.863a.343.343,0,0,0,0-.506l-1.28-1.28.536-.536a.138.138,0,0,0-.089-.238L4.78,5.354a.141.141,0,0,0-.149.149Z"
              data-name="Layout 3147"
              fill="#004dbc"
              id="Layout_3147"
              transform="translate(-4.631 -5.354)"
            />
          </g>
          <g data-name="Group 7422" id="Group_7422" transform="translate(3.376 7.136)">
            <g data-name="Group 7422" id="Group_7422-2" transform="translate(7.932 6.318)">
              <circle
                cx="1.407"
                cy="1.407"
                data-name="Ellipse 1742"
                fill="#004dbc"
                id="Ellipse_1742"
                r="1.407"
                transform="translate(0 0)"
              />
            </g>
            <g data-name="Group 7423" id="Group_7423" transform="translate(7.12 9.105)">
              <path
                d="M-1233.151,97.395a2.758,2.758,0,0,0-1-1.544.219.219,0,0,0-.206,0,1.758,1.758,0,0,1-1.99,0,.219.219,0,0,0-.206,0,2.656,2.656,0,0,0-1,1.544s-.206.824.618.824h3.157C-1232.946,98.218-1233.151,97.395-1233.151,97.395Z"
                data-name="Layout 3933"
                fill="#004dbc"
                id="Layout_3933"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
          <g data-name="Group 7424" id="Group_7424" transform="translate(3.376 0.585)">
            <g data-name="Group 7422" id="Group_7422-3" transform="translate(7.932 6.318)">
              <circle
                cx="1.407"
                cy="1.407"
                data-name="Ellipse 1742"
                fill="#004dbc"
                id="Ellipse_1742-2"
                r="1.407"
                transform="translate(0 0)"
              />
            </g>
            <g data-name="Group 7423" id="Group_7423-2" transform="translate(7.12 9.105)">
              <path
                d="M-1233.151,97.395a2.758,2.758,0,0,0-1-1.544.219.219,0,0,0-.206,0,1.758,1.758,0,0,1-1.99,0,.219.219,0,0,0-.206,0,2.656,2.656,0,0,0-1,1.544s-.206.824.618.824h3.157C-1232.946,98.218-1233.151,97.395-1233.151,97.395Z"
                data-name="Layout 3933"
                fill="#004dbc"
                id="Layout_3933-2"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
          <g data-name="Group 7425" id="Group_7425" transform="translate(-1.062 4.039)">
            <g data-name="Group 7422" id="Group_7422-4" transform="translate(7.932 6.318)">
              <circle
                cx="1.407"
                cy="1.407"
                data-name="Ellipse 1742"
                fill="#004dbc"
                id="Ellipse_1742-3"
                r="1.407"
                transform="translate(0 0)"
              />
            </g>
            <g data-name="Group 7423" id="Group_7423-3" transform="translate(7.12 9.105)">
              <path
                d="M-1233.151,97.395a2.758,2.758,0,0,0-1-1.544.219.219,0,0,0-.206,0,1.758,1.758,0,0,1-1.99,0,.219.219,0,0,0-.206,0,2.656,2.656,0,0,0-1,1.544s-.206.824.618.824h3.157C-1232.946,98.218-1233.151,97.395-1233.151,97.395Z"
                data-name="Layout 3933"
                fill="#004dbc"
                id="Layout_3933-3"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
          <g data-name="Group 7426" id="Group_7426" transform="translate(7.938 4.039)">
            <g data-name="Group 7422" id="Group_7422-5" transform="translate(7.932 6.318)">
              <circle
                cx="1.407"
                cy="1.407"
                data-name="Ellipse 1742"
                fill="#004dbc"
                id="Ellipse_1742-4"
                r="1.407"
                transform="translate(0 0)"
              />
            </g>
            <g data-name="Group 7423" id="Group_7423-4" transform="translate(7.12 9.105)">
              <path
                d="M-1233.151,97.395a2.758,2.758,0,0,0-1-1.544.219.219,0,0,0-.206,0,1.758,1.758,0,0,1-1.99,0,.219.219,0,0,0-.206,0,2.656,2.656,0,0,0-1,1.544s-.206.824.618.824h3.157C-1232.946,98.218-1233.151,97.395-1233.151,97.395Z"
                data-name="Layout 3933"
                fill="#004dbc"
                id="Layout_3933-4"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
