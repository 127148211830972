export interface ICardActions {
  cardName: string;
  textInfo: string;
  helpLink: string;
  buttonName: string;
  postMessage: string;
  toolTip: string;
}
export interface ICardSender {
  cardName: string;
  puntaje: number;
  valor: string;
  helpLink: string;
  toolTip: string;
}

export interface IRestricted {
  allAllowed: boolean;
  elementsBlocked: {
    sendSms?: boolean;
    sendWithAttachment?: boolean;
    smsApi?: boolean;
    mailByApi?: boolean;
    triggers?: boolean;
  } | null;
}

export interface IDataResponse {
  type: string;
  messageName: string;
  date: number;
  shippingQuantity: number;
  openingQuantity: number;
  clickPercentage: number;
}

export interface ReportDataDTO {
  id: string;
  tipo: string;
  nombre: string;
  nombre_campania: string;
  fecha: string;
  Cantidad_Enviados: number;
  OR: number;
  CTR: number;
  estado: string;
}

export interface ReportData {
  element: ReportDataElement;
  details: ReportDataDetails;
  CTR: number;
  OR: number;
}

export interface ReportDataElement {
  id: string;
  name: string;
  campaignName: string;
  type: string;
  status: string;
}

export interface ReportDataDetails {
  deliveries: number;
  date: string;
}

export interface Column {
  id: 'tipo' | 'nombre' | 'fecha' | 'Cantidad_Enviados' | 'OR' | 'CTR';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

export interface ConfigGeneral {
  has_sender: string;
  has_contacts: string;
  sent_email: string;
  current_step: string;
}

// TODO: Move to a better place.
export function convertToReportDataDTO(data: ReportData): ReportDataDTO {
  const dataDTO: ReportDataDTO = {
    id: data.element.id,
    tipo: data.element.type,
    nombre: data.element.name,
    nombre_campania: data.element.campaignName,
    fecha: data.details.date,
    Cantidad_Enviados: data.details.deliveries,
    OR: data.OR,
    CTR: data.CTR,
    estado: data.element.status,
  };
  return dataDTO;
}
