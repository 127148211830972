import { datadogRum } from '@datadog/browser-rum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { CardActionProps } from '@/lib/v2/examples/CardAction/CardAction';
import {
  AbandonedContactIcon,
  AbandonedNewContactsIcon,
  ContactsToWakeUpIcon,
  ContactsWithBouncesLastDaysIcon,
  LastTag1DayIcon,
  LosingEngagementIcon,
  NewContactWithoutInteractionIcon,
  NoEngagementIcon,
  WebVisitorsWithoutInteractionIcon,
} from '@/lib/v2/icons/general';
import { HappyFace, NeutralFace, SadFace } from '@/lib/v2/icons/outline';

import { useUserData } from '@/src/application/hooks/useUserData';
import { ContactStatus } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import {
  ActivityField,
  ContactField,
  EmblueRecipesEnum,
  EmblueSegmentInfo,
  FieldsResponse,
  ISegmentFilter,
  ISegmentFilterActivity,
  ISegmentFilterDate,
  ISegmentFilterID,
  ISegmentFilterNumeric,
  ModalSegmentsCardsProps,
  SegmentFilterCondition,
  SegmentFilterSystemContactField,
  SegmentTypeEnum,
} from '@/modules/ContactsModule/types/Segments';

type EmblueRecipesCardsProps = {
  createSegment: (
    name: string,
    segment: ModalSegmentsCardsProps,
    segmentType: SegmentTypeEnum.emblueRecipe | SegmentTypeEnum.eCommerce
  ) => Promise<void>;
  emblueSegmentsInUse?: EmblueSegmentInfo[];
};

const getField = (
  contactFields: FieldsResponse,
  cfName: SegmentFilterSystemContactField
): ContactField | ActivityField => {
  const contactField = contactFields.activityFields.find((cf) => cf.name === cfName);
  if (contactField) {
    return contactField;
  }
  return contactFields.defaultFields.find((cf) => cf.name === cfName) as ContactField;
};

export const useEmblueRecipesCards = ({
  createSegment,
  emblueSegmentsInUse,
}: EmblueRecipesCardsProps) => {
  const { t } = useTranslation();
  const [tags] = useEmblue(ServiceMethods.getTagsDropDown, { identifier: 'TAGS_DROP_DOWN' });
  const [contactsFields] = useEmblue(ServiceMethods.getFieldsFilters);
  const [userData] = useUserData();

  const recipesFilters: ModalSegmentsCardsProps[] = useMemo(
    () =>
      !contactsFields?.success
        ? []
        : [
            {} as never, // This allows access to each index by its value in enum EmblueRecipesEnum
            {
              segmentNumber: EmblueRecipesEnum.lastTag1Day,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.lastTag1Day
              ),
              filters: [
                {
                  dataType: 'ID',
                  condition: 'equal',
                  operator: 'or',
                  field: {
                    source: 'relation',
                    name: 'tags',
                  } as SegmentFilterCondition,
                  values: tags?.list.map((tag) => tag.id.toString()) ?? [],
                  daysOld: 1,
                  allOptions: true,
                } as ISegmentFilterID,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.abandonedContact,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.abandonedContact
              ),
              filters: [
                {
                  dataType: 'ACTIVITY',
                  condition: 'without',
                  field: {
                    source: 'activity',
                    name: 'sendings',
                  } as SegmentFilterCondition,
                  lastDays: 60,
                } as ISegmentFilterActivity,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.newWithoutInteraction,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.newWithoutInteraction
              ),
              filters: [
                {
                  dataType: 'ID',
                  condition: 'equal',
                  operator: 'or',
                  field: {
                    source: 'relation',
                    name: 'rank',
                  } as SegmentFilterCondition,
                  values: [ContactStatus.new.toString()],
                } as ISegmentFilterID,
                {
                  dataType: 'ACTIVITY',
                  condition: 'without',
                  field: {
                    source: 'activity',
                    name: 'opens',
                  } as SegmentFilterCondition,
                  lastDays: 30,
                } as ISegmentFilterActivity,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.contactsToWakeUp,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.contactsToWakeUp
              ),
              filters: [
                {
                  dataType: 'ID',
                  condition: 'equal',
                  operator: 'or',
                  field: {
                    source: 'relation',
                    name: 'rank',
                  } as SegmentFilterCondition,
                  values: [ContactStatus.inactive.toString(), ContactStatus.asleep.toString()],
                } as ISegmentFilterID,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.webVisitorsWithoutInteraction,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.webVisitorsWithoutInteraction
              ),
              filters: [
                {
                  dataType: 'DATE',
                  condition: 'last-days',
                  field: getField(contactsFields.data as FieldsResponse, 'lastWebsiteVisit'),
                  values: ['30'],
                } as ISegmentFilterDate,
                {
                  dataType: 'ID',
                  condition: 'equal',
                  field: {
                    source: 'relation',
                    name: 'rank',
                  } as SegmentFilterCondition,
                  operator: 'or',
                  values: [
                    ContactStatus.discarded.toString(),
                    ContactStatus.inactive.toString(),
                    ContactStatus.asleep.toString(),
                  ],
                } as ISegmentFilterID,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.promoters,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.promoters
              ),
              filters: [
                {
                  dataType: 'NUMERIC',
                  condition: 'eq',
                  field: getField(contactsFields.data as FieldsResponse, 'nps'),
                  values: ['9', '10'],
                } as ISegmentFilterNumeric,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.passives,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.passives
              ),
              filters: [
                {
                  dataType: 'NUMERIC',
                  condition: 'eq',
                  field: getField(contactsFields.data as FieldsResponse, 'nps'),
                  values: ['7', '8'],
                } as ISegmentFilterNumeric,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.detractors,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.detractors
              ),
              filters: [
                {
                  dataType: 'NUMERIC',
                  condition: 'eq',
                  field: getField(contactsFields.data as FieldsResponse, 'nps'),
                  values: ['0', '1', '2', '3', '4', '5', '6'],
                } as ISegmentFilterNumeric,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.noEngagement,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.noEngagement
              ),
              filters: [
                {
                  dataType: 'ID',
                  condition: 'equal',
                  field: {
                    source: 'relation',
                    name: 'rank',
                  } as SegmentFilterCondition,
                  operator: 'or',
                  values: [ContactStatus.inactive.toString()],
                } as ISegmentFilterID,
                {
                  dataType: 'ACTIVITY',
                  condition: 'with',
                  field: {
                    source: 'activity',
                    name: 'sendings',
                  } as SegmentFilterCondition,
                  lastDays: 90,
                } as ISegmentFilterActivity,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.abandonedNewContacts,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.abandonedNewContacts
              ),
              filters: [
                {
                  dataType: 'DATE',
                  condition: 'last-days',
                  field: getField(contactsFields.data as FieldsResponse, 'creationDate'),
                  values: ['7'],
                } as ISegmentFilterDate,
                {
                  dataType: 'ACTIVITY',
                  condition: 'without',
                  field: {
                    source: 'activity',
                    name: 'sendings',
                  } as SegmentFilterCondition,
                  lastDays: 7,
                } as ISegmentFilterActivity,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.contactsWithBouncesLastDays,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.contactsWithBouncesLastDays
              ),
              filters: [
                {
                  dataType: 'ACTIVITY',
                  condition: 'with',
                  field: {
                    source: 'activity',
                    name: 'sendings',
                  } as SegmentFilterCondition,
                  lastDays: 90,
                } as ISegmentFilterActivity,
                {
                  dataType: 'ACTIVITY',
                  condition: 'with',
                  field: {
                    source: 'activity',
                    name: 'bounces',
                  } as SegmentFilterCondition,
                  lastDays: 90,
                } as ISegmentFilterActivity,
                {
                  dataType: 'ACTIVITY',
                  condition: 'with',
                  field: {
                    source: 'activity',
                    name: 'opens',
                  } as SegmentFilterCondition,
                  lastDays: 90,
                } as ISegmentFilterActivity,
              ] as ISegmentFilter[],
            },
            {
              segmentNumber: EmblueRecipesEnum.losingEngagement,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.emblueRecipe &&
                  s.segmentNumber === EmblueRecipesEnum.losingEngagement
              ),
              filters: [
                {
                  dataType: 'ACTIVITY',
                  condition: 'with',
                  field: {
                    source: 'activity',
                    name: 'sendings',
                  } as SegmentFilterCondition,
                  lastDays: 90,
                } as ISegmentFilterActivity,
                {
                  dataType: 'ACTIVITY',
                  condition: 'without',
                  field: {
                    source: 'activity',
                    name: 'bounces',
                  } as SegmentFilterCondition,
                  lastDays: 90,
                } as ISegmentFilterActivity,
                {
                  dataType: 'ACTIVITY',
                  condition: 'without',
                  field: {
                    source: 'activity',
                    name: 'opens',
                  } as SegmentFilterCondition,
                  lastDays: 90,
                } as ISegmentFilterActivity,
              ] as ISegmentFilter[],
            },
          ],
    [contactsFields, emblueSegmentsInUse, tags?.list]
  );

  const emblueRecipesCards: CardActionProps[] = useMemo(
    () =>
      !recipesFilters.length
        ? []
        : ([
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.LastTag1Day.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.LastTag1Day.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.lastTag1Day].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.LastTag1Day.title'),
                  recipesFilters[EmblueRecipesEnum.lastTag1Day],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: <IconSvg height="53px" svgComponent={<LastTag1DayIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.lastTag1Day].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.LastTag1Day.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.AbandonedContact.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.AbandonedContact.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.abandonedContact].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.AbandonedContact.title'),
                  recipesFilters[EmblueRecipesEnum.abandonedContact],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: <IconSvg height="53px" svgComponent={<AbandonedContactIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.abandonedContact].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.AbandonedContact.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.NewWithoutInteraction.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.NewWithoutInteraction.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.newWithoutInteraction].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.NewWithoutInteraction.title'),
                  recipesFilters[EmblueRecipesEnum.newWithoutInteraction],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: (
                <IconSvg
                  height="53px"
                  svgComponent={<NewContactWithoutInteractionIcon />}
                  width="53px"
                />
              ),
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.newWithoutInteraction].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.NewWithoutInteraction.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.ContactsToWakeUp.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.ContactsToWakeUp.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.contactsToWakeUp].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.ContactsToWakeUp.title'),
                  recipesFilters[EmblueRecipesEnum.contactsToWakeUp],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: <IconSvg height="53px" svgComponent={<ContactsToWakeUpIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.contactsToWakeUp].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.ContactsToWakeUp.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.WebVisitorsWithoutInteraction.title'),
              description: t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.WebVisitorsWithoutInteraction.description'
              ),
              buttonText: recipesFilters[EmblueRecipesEnum.webVisitorsWithoutInteraction].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.WebVisitorsWithoutInteraction.title'),
                  recipesFilters[EmblueRecipesEnum.webVisitorsWithoutInteraction],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: (
                <IconSvg
                  rounded
                  bgColor="gray"
                  height="53px"
                  svgComponent={<WebVisitorsWithoutInteractionIcon />}
                  width="53px"
                  withPadding="small"
                />
              ),
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.webVisitorsWithoutInteraction].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.WebVisitorsWithoutInteraction.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.Promoters.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.Promoters.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.promoters].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                datadogRum.addAction('click on Create Promoters recipe', {
                  email: userData.email,
                });
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.Promoters.title'),
                  recipesFilters[EmblueRecipesEnum.promoters],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: (
                <IconSvg
                  rounded
                  bgColor="success"
                  height="53px"
                  svgComponent={<HappyFace />}
                  width="53px"
                  withPadding="medium"
                />
              ),
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.promoters].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.Promoters.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.Passives.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.Passives.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.passives].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                datadogRum.addAction('click on Create Passives recipe', {
                  email: userData.email,
                });
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.Passives.title'),
                  recipesFilters[EmblueRecipesEnum.passives],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: (
                <IconSvg
                  rounded
                  bgColor="tertiary"
                  height="53px"
                  svgComponent={<NeutralFace />}
                  width="53px"
                  withPadding="medium"
                />
              ),
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.passives].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.Passives.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.Detractors.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.Detractors.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.detractors].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                datadogRum.addAction('click on Create Detractors recipe', {
                  email: userData.email,
                });
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.Detractors.title'),
                  recipesFilters[EmblueRecipesEnum.detractors],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: (
                <IconSvg
                  rounded
                  bgColor="destructive"
                  height="53px"
                  svgComponent={<SadFace />}
                  width="53px"
                  withPadding="medium"
                />
              ),
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.detractors].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.Detractors.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.noEngagement.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.noEngagement.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.noEngagement].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.noEngagement.title'),
                  recipesFilters[EmblueRecipesEnum.noEngagement],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: <IconSvg height="53px" svgComponent={<NoEngagementIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.noEngagement].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.noEngagement.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.abandonedNewContacts.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.abandonedNewContacts.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.abandonedNewContacts].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.abandonedNewContacts.title'),
                  recipesFilters[EmblueRecipesEnum.abandonedNewContacts],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: (
                <IconSvg height="53px" svgComponent={<AbandonedNewContactsIcon />} width="53px" />
              ),
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.abandonedNewContacts].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.abandonedNewContacts.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.contactsWithBouncesLastDays.title'),
              description: t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.contactsWithBouncesLastDays.description'
              ),
              buttonText: recipesFilters[EmblueRecipesEnum.contactsWithBouncesLastDays].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.contactsWithBouncesLastDays.title'),
                  recipesFilters[EmblueRecipesEnum.contactsWithBouncesLastDays],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: (
                <IconSvg
                  height="53px"
                  svgComponent={<ContactsWithBouncesLastDaysIcon />}
                  width="53px"
                />
              ),
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.contactsWithBouncesLastDays].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.contactsWithBouncesLastDays.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.RECIPES.losingEngagement.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.RECIPES.losingEngagement.description'),
              buttonText: recipesFilters[EmblueRecipesEnum.losingEngagement].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.RECIPES.losingEngagement.title'),
                  recipesFilters[EmblueRecipesEnum.losingEngagement],
                  SegmentTypeEnum.emblueRecipe
                );
              },
              icon: <IconSvg height="53px" svgComponent={<LosingEngagementIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.emBlueRecipes'),
              badgeColor: 'primary',
              disabled: recipesFilters[EmblueRecipesEnum.losingEngagement].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.RECIPES.losingEngagement.title'
              )}--recipeId`.replaceAll(' ', '_'),
            },
          ] as CardActionProps[]),
    [t, recipesFilters, createSegment]
  );

  return emblueRecipesCards;
};
