export enum EventListenerType {
  UserData = 'userData',
  // cspell:disable
  Action = 'accion',
  // cspell:disable
  Groups = 'grupos',
  Name = 'name',
  Sender = 'sender',
  Scoring = 'scoring',
  Reports = 'reports',
  Campaign = 'campaign',
  Language = 'language',
  ActualStep = 'ActualStep',
  FreeTrialBlock = 'freeTrialBlock',
  RefreshContacts = 'RefreshContacts',
  RefreshSegments = 'RefreshSegments',
  Loaded = 'Loaded',
  LoadedCampaign = 'LoadedCampaign',
  ErrorCampaign = 'ErrorCampaign',
  LoadedReport = 'LoadedReport',
  RedirectConfiguration = 'RedirectConfiguration',
  DeleteAction = 'DeleteAction',
  GoToConfiguration = 'goToConfiguration',
  OpenBeefree = 'openBeefree',
  OpenHtmlEditor = 'openHtmlEditor',
  LoadFlow = 'LoadFlow.end',
  AutomationGoBack = 'Automation.goBack',
}
