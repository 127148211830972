import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowBackIcon } from '@/lib/icon';
import { Button, IconSvg } from '@/lib/v2/components';

import { useActionData } from '@/src/modules/CampaignsModule/hooks/useActionData';

import { useCatalogTemplateLink } from './CatalogTemplateMenuBar.hooks';

const CatalogTemplateMenuBar = () => {
  const { links, linksMapper } = useCatalogTemplateLink();
  const { actionId } = useParams();
  const { action } = useActionData(actionId ? parseInt(actionId) : 0);
  const navigate = useNavigate();
  const goToBack = useCallback(() => {
    const actionId = action?.actionId ? action?.actionId.toString() : '';
    const campaignId = action?.campaignId ? action?.campaignId.toString() : '';
    const isMaster = action?.channel === 7;
    const route = isMaster ? 'master' : 'email';
    const newUrl = '/v2/campaigns/' + campaignId + '/' + route + '/' + actionId + '/editor';
    navigate(newUrl);
  }, [action, navigate]);

  return (
    <div className="relative z-10 flex h-[72px] w-full items-center justify-between bg-white px-4 py-10 shadow-md lg:gap-4 xl:gap-12 2xl:gap-16">
      <div className="flex h-full">
        <nav className="flex items-center lg:gap-2 xl:gap-10 2xl:gap-16">
          <Button outline standard className="w-4" size="small" onClick={() => goToBack()}>
            <IconSvg svgComponent={<ArrowBackIcon />} />
          </Button>
          <>{links?.map(linksMapper)}</>
        </nav>
      </div>
    </div>
  );
};

export default CatalogTemplateMenuBar;
