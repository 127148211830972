import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { ActionBar } from '@/lib/components/ActionBar';
import { openModal } from '@/lib/components/Modal/Modal';
import {
  DeleteIconSolid,
  DistributeContactsIcon,
  DropdownCellPhoneIcon,
  DropdownMailIcon,
  DropdownPush,
  DropdownSmsIcon,
  EditStrongIcon,
  EditTagCategoryIcon,
  MergeTagIcon,
} from '@/lib/icon';
import { Button } from '@/lib/v2/components/Button';
import { ExportUserIcon } from '@/lib/v2/icons/outline';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { ContactsDropDown } from '@/src/ContactsModule/components/ContactsDropDown';
import { DeleteModal } from '@/src/ContactsModule/components/ContactsModals';
import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

export const dropdownOptions = {
  sendingType: [
    {
      title: 'Send Email',
      icon: <DropdownMailIcon />,
      onAction: () => {},
    },
    {
      title: 'Send Sms',
      icon: <DropdownSmsIcon />,
      onAction: () => {},
    },
    {
      title: 'Push Notifications',
      icon: <DropdownPush />,
      onAction: () => {},
    },
  ],
};

interface ITagsActionBarProps {
  contactSelection: ITag[];
  distributeIsDisabled: boolean;
  setConfirmDelete: Dispatch<SetStateAction<boolean>>;
  setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  setOpenMergeModal: Dispatch<SetStateAction<boolean>>;
  setOpenDistributeModal: Dispatch<SetStateAction<boolean>>;
  setOpenEditCategoryModal: Dispatch<SetStateAction<boolean>>;
}

export const TagsActionBar = ({
  contactSelection,
  distributeIsDisabled,
  setConfirmDelete,
  setOpenEditModal,
  setOpenMergeModal,
  setOpenDistributeModal,
  setOpenEditCategoryModal,
}: ITagsActionBarProps) => {
  const actionBarDropdown = useFeatureFlag('actionBarDropdown');

  const iconsActions = [
    {
      icon: <MergeTagIcon />,
      onClick: () => setOpenMergeModal(true),
      tooltip: t('TAGS_FILTERS.mergeTags'),
      flagOn: useFeatureFlag('actionBarMergeTagIcon'),
      disabled: contactSelection.length <= 1,
      disabledTooltip: '',
      id: 'merge-tag',
    },
    {
      icon: <EditTagCategoryIcon />,
      onClick: () => setOpenEditCategoryModal(true),
      tooltip: t('MANAGE_CATEGORY_EDIT_MODAL.title'),
      flagOn: useFeatureFlag('actionBarEditTagCategoryIcon'),
      disabled: contactSelection.length <= 1,
      disabledTooltip: '',
      id: 'edit-tag-category',
    },
    {
      icon: <EditStrongIcon />,
      onClick: () => setOpenEditModal(true),
      tooltip: t('MANAGE_TAG_MODAL.edit.title'),
      flagOn: true,
      disabled: contactSelection.length !== 1,
      disabledTooltip: t('TAG_ACTION_BAR.editOnly1'),
      id: 'edit-tag',
    },
    {
      icon: <DistributeContactsIcon />,
      onClick: () => setOpenDistributeModal(true),
      tooltip: t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.title'),
      flagOn: true,
      disabled: distributeIsDisabled,
      disabledTooltip: '',
      id: 'distribute-contacts',
    },
    {
      icon: <ExportUserIcon />,
      onClick: () => alert('Export'),
      tooltip: 'Export CSV', //TO-DO: add translations
      flagOn: useFeatureFlag('actionBarExportUserIcon'),
      disabled: false,
      disabledTooltip: '',
      id: 'export-csv',
    },
    {
      icon: <DeleteIconSolid />,
      onClick: () =>
        //TO DO: change this openModal to new Modal component v2
        openModal({
          element: (
            <DeleteModal
              description={
                contactSelection.length === 1
                  ? t('TAG_DELETE_MODAL.description')
                  : t('TAG_DELETE_MODAL.descriptionPlural')
              }
              setConfirmDelete={setConfirmDelete}
              title={
                contactSelection.length === 1
                  ? t('TAG_DELETE_MODAL.title')
                  : t('TAG_DELETE_MODAL.titlePlural')
              }
            />
          ),
        }),
      tooltip:
        contactSelection.length === 1
          ? t('TAG_DELETE_MODAL.title')
          : t('TAG_DELETE_MODAL.titlePlural'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
      id: 'delete-tags',
    },
  ];

  return (
    <ActionBar>
      <div>
        <section className="flex h-full items-center">
          <div className="text-xs ml-10 text-white" style={{ fontSize: '12px' }}>
            {`${quantifier(contactSelection.length) ?? ''} ${t(
              'CONTACTS_ACTIONS_DROPDOWN.Selected'
            )}`}
          </div>
          <div className="ml-4">
            {actionBarDropdown && (
              <ContactsDropDown
                icon={<DropdownCellPhoneIcon />}
                title="Actions"
                {...dropdownOptions}
              />
            )}
          </div>
          <div className="ml-5 flex">
            {iconsActions.map((element) => {
              const {
                flagOn,
                icon,
                disabled,
                disabledTooltip,
                tooltip,
                onClick: handleOnClick,
                id,
              } = element;
              return (
                flagOn && (
                  <div key={id} className="mr-2">
                    <Button
                      {...(disabled && { disabled })}
                      fullWidth
                      outline
                      standard
                      iconLeft={icon}
                      id={id}
                      tooltip={disabled ? disabledTooltip : tooltip}
                      onClick={handleOnClick}
                    />
                  </div>
                )
              );
            })}
          </div>
        </section>
      </div>
    </ActionBar>
  );
};
