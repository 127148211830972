import { useEffect, useRef } from 'react';
import { Node, useReactFlow } from 'reactflow';

export const useFitView = (nodes: Node[]) => {
  const { setViewport } = useReactFlow();
  const fitViewOptions = { padding: 0.1 };
  const animationRef = useRef<number | null>(null);
  const hasAnimatedRef = useRef(false);

  useEffect(() => {
    if (!hasAnimatedRef.current) {
      const firstNode = nodes[0];
      if (firstNode && firstNode.width && firstNode.height) {
        const startX = firstNode.position.x;
        const startY = firstNode.position.y;
        const endX = -firstNode.position.x + window.innerWidth / 2 - firstNode.width / 2;
        const endY = -firstNode.position.y + 50;
        const duration = 1000;
        const startTime = performance.now();

        const animate = (currentTime: number) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);
          const easeInOutQuad = (t: number) =>
            t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

          const x = startX + (endX - startX) * easeInOutQuad(progress);
          const y = startY + (endY - startY) * easeInOutQuad(progress);

          setViewport({ x, y, zoom: 1 });

          if (progress < 1) {
            animationRef.current = requestAnimationFrame(animate);
          } else {
            animationRef.current = null;
            hasAnimatedRef.current = true;
          }
        };

        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }

        animationRef.current = requestAnimationFrame(animate);
      }

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
      };
    }
  }, [setViewport, nodes]);

  return { fitViewOptions };
};
