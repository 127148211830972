import { ReactNode, useRef, useState } from 'react';

import { DownArrowIcon } from '@/lib/icon/DownArrowIcon';
import { Button } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useOnClickOutside } from '@/src/application/hooks/useOnClickOutside';

export type SendingTypeProps = {
  title: string;
  icon?: ReactNode;
  onAction: () => void;
  disabled?: boolean;
};

export type ContactDropDownProps = {
  sendingType: Array<SendingTypeProps>;
  title: string;
  icon?: ReactNode;
};

export const ContactsDropDown = ({ sendingType, title, icon }: ContactDropDownProps) => {
  const [toggle, setToggle] = useState(false);
  const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(toggleRef, () => setToggle(false));

  // TO DO: update dropdown button v2
  return (
    <div ref={toggleRef} className="relative inline-block h-fit text-left">
      <div>
        <Button iconLeft={icon} onClick={() => setToggle(!toggle)}>
          <>
            <div className="ml-3">{title}</div>
            <DownArrowIcon color="white" />
          </>
        </Button>
      </div>
      {toggle && (
        <div
          aria-labelledby="menu-button"
          aria-orientation="vertical"
          className="border-1 absolute right-0 z-10 w-56 origin-top-right rounded-md bg-white"
          id="options"
          role="menu"
          style={{ border: '1px solid #D3DDEA', marginTop: '0.25rem' }}
        >
          <div className="py-1" role="none">
            <Flex column itemAlignment="start">
              {sendingType.map((element, i) => {
                return (
                  <button
                    key={i}
                    className="text-sm group flex w-full items-center px-4 py-2 text-gray-700 hover:bg-[#CCDEF8]"
                    disabled={element.disabled}
                    id="menu-item-0"
                    role="menuitem"
                    onClick={() => {
                      element.onAction();
                      setToggle(false);
                    }}
                  >
                    {element.icon}
                    <div className="ml-4">{element.title}</div>
                  </button>
                );
              })}
            </Flex>
          </div>
        </div>
      )}
    </div>
  );
};
