import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemSearch, Modal } from '@/lib/components';
import { Item, ItemListType } from '@/lib/components/ItemSearch/ItemSearch';
import { Button, FloatModal, Spinner } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { IContactActivityGroups, IGroup } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { useGroup } from '@/modules/ContactsModule/hooks/useGroup';
import { ContactTableSearchFilters } from '@/modules/ContactsModule/types/ContactTableSearchFilters';

const ButtonsSection = ({
  groups,
  setShowGroupsModal,
  setSelectedGroups,
  setUnselectedGroups,
  selectedGroupsNames,
  setSelectedGroupsNames,
  isUpdateCheckboxModal,
}: {
  groups: IGroup[];
  setShowGroupsModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedGroups?: Dispatch<SetStateAction<number[]>>;
  setUnselectedGroups?: Dispatch<SetStateAction<number[]>>;
  selectedGroupsNames: ItemListType;
  setSelectedGroupsNames: Dispatch<SetStateAction<ItemListType>>;
  isUpdateCheckboxModal: boolean;
}) => {
  const { t } = useTranslation();

  const [disableButtons, setDisableButtons] = useState(false);

  const checkDisableButtons = (selectedGroups: ItemListType) => {
    for (const group of selectedGroups) {
      if (typeof group !== 'string' && group.indeterminate === true) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    !isUpdateCheckboxModal && setDisableButtons(checkDisableButtons(selectedGroupsNames));
  }, [selectedGroupsNames]);

  const cleanInputs = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkbox: any = document.getElementsByClassName('_modal_checkbox_'); // inputs in <ItemSearch/>
    if (checkbox) {
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
      }
    }
  };

  return (
    <Flex withGap alignment="end" gapSize="medium">
      <Button
        outline
        disabled={disableButtons}
        onClick={() => {
          cleanInputs();
          setSelectedGroupsNames([]);
        }}
      >
        <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
          {t('CONTACT_GROUP_MODAL.clearGroup')}
        </div>
      </Button>
      <Button
        disabled={disableButtons}
        onClick={() => {
          const selectedGroupsIds = groups
            .filter((g) => {
              if (typeof selectedGroupsNames[0] === 'string') {
                return (selectedGroupsNames as string[]).includes(g.groupName);
              } else {
                return (selectedGroupsNames as Item[]).some((group) => group.name === g.groupName);
              }
            })
            .map((g) => Number(g.id));

          const unselectedGroupsIds = groups
            .filter((g) => {
              if (typeof selectedGroupsNames[0] === 'string') {
                return !(selectedGroupsNames as string[]).includes(g.groupName);
              } else {
                return !(selectedGroupsNames as Item[]).some((group) => group.name === g.groupName);
              }
            })
            .map((g) => Number(g.id));

          setSelectedGroups && setSelectedGroups(selectedGroupsIds);
          setUnselectedGroups && setUnselectedGroups(unselectedGroupsIds);
          //closeModal();
          setShowGroupsModal && setShowGroupsModal(false);
        }}
      >
        <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">{t('Save')}</div>
      </Button>
    </Flex>
  );
};

interface IContactsGroupModalProps {
  groups: IGroup[];
  selectedContacts?: number[];
  setSelectedGroups?: Dispatch<SetStateAction<number[]>>;
  setUnselectedGroups?: Dispatch<SetStateAction<number[]>>;
  setListSelectedGroupsNames?: Dispatch<SetStateAction<ItemListType>>;
  setListUnselectedGroupsNames?: Dispatch<SetStateAction<ItemListType>>;
  setShowGroupsModal?: Dispatch<SetStateAction<boolean>>;
  maxGroupsToAdd?: number;
  showModal?: boolean;
  contactGroups?: IContactActivityGroups[];
  isUpdateCheckboxModal?: boolean;
  onLastRefresh?: () => void;
}

export const ContactsGroupModal = ({
  contactGroups,
  groups,
  selectedContacts,
  setShowGroupsModal,
  setSelectedGroups,
  setUnselectedGroups,
  setListSelectedGroupsNames,
  setListUnselectedGroupsNames,
  maxGroupsToAdd,
  showModal = true,
  isUpdateCheckboxModal = false,
  onLastRefresh,
}: IContactsGroupModalProps) => {
  const { t } = useTranslation();

  const [groupsListValue, , isLoadingGroupList] = useEmblue(ServiceMethods.getGroupsByContacts, {
    contactsIds: [],
    allResults: false,
    filters: {} as ContactTableSearchFilters,
  });

  const [selectedGroupsNames, setSelectedGroupsNames] = useState<ItemListType>([]);
  const [unselectedGroupsNames, setUnselectedGroupsNames] = useState<ItemListType>([]);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const { createGroup } = useGroup();

  let groupsNamesList: ItemListType = [];
  if (contactGroups) {
    groupsNamesList = contactGroups.map((e: IContactActivityGroups) => ({
      name: e.name,
      indeterminate: false,
    }));
  } else if (selectedContacts && groupsListValue) {
    groupsNamesList = [];
  }

  useEffect(() => {
    if (setListSelectedGroupsNames && setListUnselectedGroupsNames) {
      setListSelectedGroupsNames(selectedGroupsNames);
      setListUnselectedGroupsNames(unselectedGroupsNames);
    }
  }, [selectedGroupsNames, unselectedGroupsNames]);

  useEffect(() => {
    if (groupsNamesList) {
      setSelectedGroupsNames(groupsNamesList);
      setUnselectedGroupsNames(groupsNamesList);
    }
  }, [groupsNamesList.length]);

  const handleOnCreate = useCallback(
    async (value: string) => {
      setIsLoadingCreate(true);
      const groupCreated = await createGroup(value);
      setIsLoadingCreate(false);
      if (groupCreated) onLastRefresh?.();
    },
    [createGroup, onLastRefresh]
  );

  return (
    <FloatModal>
      <Modal show={showModal}>
        <div className="h-full p-8">
          <Flex column withGap gapSize="medium" itemAlignment="stretch">
            <Modal.Title onAction={() => setShowGroupsModal && setShowGroupsModal(false)}>
              <Flex withGap>
                <p>{t('CONTACT_GROUP_MODAL.title')}</p>
                <p>({selectedGroupsNames.length})</p>
              </Flex>
            </Modal.Title>
            <div className="h-[86%]">
              <Modal.Body withBorder>
                <div className="w-[30vw] p-[5%]">
                  {isLoadingGroupList ? (
                    <Spinner withoutOverlay />
                  ) : (
                    <ItemSearch
                      SearchPlaceHolder={t('CONTACT_GROUP_MODAL.inputDescription')}
                      isLoading={isLoadingCreate}
                      itemList={groups.map((g) => g.groupName)}
                      maxItems={maxGroupsToAdd}
                      selectedItems={selectedGroupsNames}
                      setSelectedItems={setSelectedGroupsNames}
                      setUnselectedItems={setUnselectedGroupsNames}
                      subTitle={t('CONTACT_GROUP_MODAL.createGroup')}
                      title={t('CONTACT_GROUP_MODAL.chooseGroup')}
                      onCreate={handleOnCreate}
                    />
                  )}
                </div>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <ButtonsSection
                groups={groups}
                isUpdateCheckboxModal={isUpdateCheckboxModal}
                selectedGroupsNames={selectedGroupsNames}
                setSelectedGroups={setSelectedGroups}
                setSelectedGroupsNames={setSelectedGroupsNames}
                setShowGroupsModal={setShowGroupsModal}
                setUnselectedGroups={setUnselectedGroups}
              />
            </Modal.Footer>
          </Flex>
        </div>
      </Modal>
    </FloatModal>
  );
};
