import {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  isValidElement,
  LegacyRef,
  memo,
  ReactElement,
  useEffect,
  useRef,
} from 'react';

import { withController } from '@/lib/v2/HOCs/WithController';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string | ReactElement<Text>;
  children?: JSX.Element;
  name?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  value?: string | number | readonly string[];
  error?: boolean;
  message?: string;
  indeterminate?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      label,
      name,
      children,
      disabled,
      onChange,
      value,
      checked,
      error,
      message,
      indeterminate,
      ...restOfProps
    }: CheckboxProps,
    ref: LegacyRef<HTMLInputElement>
  ): JSX.Element => {
    const checkboxRef = useRef<HTMLInputElement | null>(null);

    if (label && typeof label !== 'string' && !isValidElement(label)) {
      throw new Error('The "label" prop must be a Text component v2');
    }

    useEffect(() => {
      if (checkboxRef.current && indeterminate !== undefined)
        checkboxRef.current.indeterminate = indeterminate;
    }, [indeterminate]);

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(checkboxRef.current);
        } else {
          (ref as React.MutableRefObject<HTMLInputElement | null>).current = checkboxRef.current;
        }
      }
    }, [checkboxRef, ref]);

    return (
      <div className="relative flex flex-col items-start" data-testid="checkbox-component">
        <div className="flex items-start">
          <div className="flex items-center pt-0.5">
            <input
              ref={checkboxRef}
              checked={checked}
              className="size-4 rounded border-gray-300 accent-emblue-primary focus:outline-none"
              disabled={disabled}
              id={id}
              name={name}
              type="checkbox"
              value={value}
              onChange={onChange}
              {...restOfProps}
            />
          </div>
          <div className="text-sm ml-1">
            {label ? (
              <label
                className="block h-full text-left text-14 font-normal text-emblue-text"
                htmlFor={id}
              >
                {label}
              </label>
            ) : (
              children
            )}
          </div>
        </div>
        {error && (
          <p className={'message-error'} id={`${name}-message`}>
            {message}
          </p>
        )}
      </div>
    );
  }
);

export default memo(withController(Checkbox));
