import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@/lib/components/Table';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyCampaignsIcon, EmptyCampaignsNoResultIcon } from '@/lib/v2/icons/general';

import { useCampaignRecurrentTableColumns } from './hooks/useCampaignRecurrentTableColumns';
import { useHandleEvents } from './hooks/useHandleEvents';

import {
  atomCampaignsRecurrentList,
  atomLoadingProcess,
  atomPagerCampaignsRecurrent,
  atomSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { EmptyTableWithoutData } from '@/modules/CampaignsModule/components/Campaigns/TableContainer/components/EmptyTableWithoutData';
import { defaultPager } from '@/modules/CampaignsModule/constants';

const CampaignRecurrentTable = () => {
  const { t } = useTranslation();

  const { recurrentMessageColumns } = useCampaignRecurrentTableColumns();
  const {
    handleClickEmptyScreen,
    handleClickEmptyScreenNoResult,
    handleChangeTablePage,
    handleRowClick,
  } = useHandleEvents();

  const totalsCampaignList = useAtomValue(atomTotalsProcess);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const recurrentMessageList = useAtomValue(atomCampaignsRecurrentList);
  const pager = useAtomValue(atomPagerCampaignsRecurrent);

  const setSelectedRows = useSetAtom(atomSelectedRows);

  const totalRecords = useMemo(
    () => Math.ceil(totalsCampaignList.partial / (pager?.limit || defaultPager.limit)),
    [totalsCampaignList.partial, pager?.limit]
  );

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        stickyHeader
        classNamePagination="px-4 mb-2"
        columns={recurrentMessageColumns}
        data={recurrentMessageList}
        defaultSize={pager?.limit}
        emptyScreen={{
          totalCount: totalsCampaignList.total,
          noResult: (
            <EmptyScreenTable
              actionText={t(
                'RECURRENT_MESSAGE.TABLE.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsActionText'
              )}
              description={t(
                'RECURRENT_MESSAGE.TABLE.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsDescription'
              )}
              id="noResults-campaigns"
              image={<EmptyCampaignsNoResultIcon />}
              title={t('RECURRENT_MESSAGE.TABLE.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsTitle')}
              onClickAction={handleClickEmptyScreenNoResult}
            />
          ),
          noData: (
            <EmptyScreenTable
              description={<EmptyTableWithoutData onClickAction={handleClickEmptyScreen} />}
              image={<EmptyCampaignsIcon />}
              title={t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsTitle')}
            />
          ),
        }}
        gotoPageIndex={pager?.page ? pager.page - 1 : 0}
        isLoading={loadingProcess}
        lastPageViewed={pager?.page}
        setSelectedRows={setSelectedRows}
        withCheckbox={true}
        withPagination={{
          totalPages: totalRecords,
          fetchData: handleChangeTablePage,
        }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default CampaignRecurrentTable;
