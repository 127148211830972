import { NodeProps } from 'reactflow';

import ActionNode from '@/src/modules/RulesModule/components/ActionNode';
import ConditionNode from '@/src/modules/RulesModule/components/ConditionNode';
import TriggerNode from '@/src/modules/RulesModule/components/TriggerNode';

import PlaceholderNode from '@/modules/RulesModule/components/PlaceholderNode';

export type NodeTypes = 'trigger' | 'placeholder' | 'action' | 'condition';

export const NODE_TYPES = {
  placeholder: (props: NodeProps) => <PlaceholderNode {...props} />,
  trigger: (props: NodeProps) => <TriggerNode {...props} />,
  condition: (props: NodeProps) => <ConditionNode {...props} />,
  action: (props: NodeProps) => <ActionNode {...props} />,
};
