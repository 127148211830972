import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AUTH_PATHS } from '@/src/modules/AuthModule/constants';
import LoginLayout from '@/src/modules/AuthModule/layouts/LoginLayout';
import BackOfficeLogin from '@/src/modules/AuthModule/screens/BackOfficeLogin';
import ChangePassword from '@/src/modules/AuthModule/screens/ChangePassword';
import Login from '@/src/modules/AuthModule/screens/Login';
import RecoverPassword from '@/src/modules/AuthModule/screens/RecoverPassword';
import SignUp from '@/src/modules/AuthModule/screens/SignUp';

export const AuthModuleRouter = () => {
  useEffect(() => {
    datadogRum.addAction('loginV2.view');
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoginLayout />} path={AUTH_PATHS.BACKOFFICE_PATH}>
          <Route index element={<BackOfficeLogin />} />
        </Route>

        <Route element={<LoginLayout />} path={AUTH_PATHS.LOGIN_PATH}>
          <Route index element={<Login />} />
          <Route element={<RecoverPassword />} path={AUTH_PATHS.RECOVER_PATH} />
          <Route element={<ChangePassword />} path={AUTH_PATHS.CHANGE_PATH} />
          <Route element={<SignUp />} path={AUTH_PATHS.SIGN_UP_PATH} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
