import { useMemo } from 'react';

export type Environments =
  | 'production'
  | 'staging'
  | 'development'
  | 'storybook'
  | 'migration'
  | 'na';

export const getEnv = (): Environments => {
  if (window.location.hostname === 'app.embluemail.com') {
    return 'production';
  } else if (window.location.hostname.match('ema-app-local')) {
    return 'development';
  } else if (window.location.href.match('story')) {
    return 'storybook';
  } else if (window.location.hostname.match('appmigra')) {
    return 'migration';
  } else if (window.location.hostname.match('app-na')) {
    return 'na';
  } else {
    return 'staging';
  }
};

export const useEnv = () => {
  const env: Environments = useMemo(getEnv, []);
  return { env };
};
