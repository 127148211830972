//cspell:ignore elementoId
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';
import { atomRuleData, atomRuleDataRaw } from '@/src/modules/RulesModule/atoms/rules';
import { IRulesV1Response } from '@/src/modules/RulesModule/interfaces';
import { parseRuleData } from '@/src/modules/RulesModule/utils/ruleDataHelper';

import { atomRecipientOptions } from '@/modules/CampaignsModule/atoms/actionsAtom';

interface ResponseData {
  statusOK: boolean;
  data?: IRulesV1Response;
}

export const useRuleData = (actionId: number) => {
  const { t } = useTranslation('rules');
  const [loading, setLoading] = useState(false);
  const [rulesDataRaw, setRulesDataRaw] = useAtom(atomRuleDataRaw);
  const [rulesData, setRulesData] = useAtom(atomRuleData);
  const recipientsOptions = useAtomValue(atomRecipientOptions);

  const rulesService = useRulesService();

  const getRuleData = useCallback(async () => {
    if (!actionId) return;

    setLoading(true);
    const response: ResponseData = await rulesService.getRuleById(actionId);

    if (response.statusOK && response.data) {
      const parsedData = parseRuleData(recipientsOptions, response.data, t);
      setRulesDataRaw(response.data);
      setRulesData(parsedData);
    }
    setLoading(false);
  }, [actionId, rulesService, recipientsOptions, setRulesDataRaw, setRulesData, t]);

  const refetch = useCallback(() => {
    void getRuleData();
  }, [getRuleData]);

  useEffect(() => {
    // TODO: Change elementoId to actionId when endpoint is migrated
    if (
      rulesDataRaw === null ||
      (rulesDataRaw?.elementoId ? rulesDataRaw.elementoId !== actionId : false)
    ) {
      void getRuleData();
    }
  }, [rulesDataRaw, actionId, getRuleData]);

  return {
    rule: rulesData,
    refetch,
    loading,
  };
};
