import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ModalSingleInput } from '@/lib/v2/examples';

import { useEditActions } from './hooks/useEditActions';
import { useHandleEvents } from './hooks/useHandleEvents';

import {
  atomSelectedRowActionName,
  atomSelectedRowCampaignId,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomToggleEditActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';

const EditActions = () => {
  const { t } = useTranslation();
  const { id: campaignID } = useParams();
  const { handleSubmitData, handleCloseModal, loading } = useHandleEvents();

  const toggleEditActionsModal = useAtomValue(atomToggleEditActionsModal);
  const actionName = useAtomValue(atomSelectedRowActionName);
  const selectedRowCampaignId = useAtomValue(atomSelectedRowCampaignId);

  const campaignId = campaignID ?? selectedRowCampaignId;

  const { VALIDATION_RULES } = useEditActions(actionName, Number(campaignId));

  return (
    <>
      <ModalSingleInput
        id="edit-action-table"
        isLoading={loading}
        isOpen={toggleEditActionsModal}
        rules={VALIDATION_RULES}
        title={t('CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.title')}
        value={actionName}
        onClose={handleCloseModal}
        onSubmit={handleSubmitData}
      />
    </>
  );
};

export default EditActions;
