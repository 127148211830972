import { useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Container } from '@/lib/v2/components';

import { atomLastBill } from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';
import { useBilling } from '@/src/modules/MyPlanModule/hooks/useBilling';
import { Billing, Consumption, PaginationDataResponse } from '@/src/modules/MyPlanModule/types';

import { useConsumptionAndBillingTables } from '@/modules/MyPlanModule/hooks/useConsumptionAndBillingTables';
import {
  BillingAndConsumptionTables,
  HeaderMyPlan,
  InformationMyPlan,
} from '@/modules/MyPlanModule/screens/BillingAndConsumption/components';
import BillingTable from '@/modules/MyPlanModule/screens/BillingAndConsumption/components/BillingTable';
import ConsumptionTable from '@/modules/MyPlanModule/screens/BillingAndConsumption/components/ConsumptionTable';

const BillingAndConsumption = () => {
  const { billingInformation, billingInformationIsLoading } = useBilling();
  const setLastBill = useSetAtom(atomLastBill);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    list: consumptionList,
    listIsLoading: consumptionListIsLoading,
    totalPages: consumptionListTotalPages,
    changeOrderBy: handleConsumptionChangeOrderBy,
    changeTablePage: handleConsumptionChangeTablePage,
  } = useConsumptionAndBillingTables<PaginationDataResponse<Consumption[]>>('listConsumption');

  const {
    list: billingList,
    listIsLoading: billingListIsLoading,
    totalPages: billingListTotalPages,
    changeOrderBy: handleBillingChangeOrderBy,
    changeTablePage: handleBillingChangeTablePage,
  } = useConsumptionAndBillingTables<PaginationDataResponse<Billing[]>>('listBilling');

  const { list: lastBillingList } =
    useConsumptionAndBillingTables<PaginationDataResponse<Billing[]>>('listBilling');

  useEffect(() => {
    if (lastBillingList) {
      setLastBill(lastBillingList.result[0]);
    }
  }, [lastBillingList, setLastBill]);

  const handleOnBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const billingAndConsumptionPaths = useMemo(
    () => [
      {
        name: t('BREADCRUMB.billingAndConsumption'),
      },
    ],
    [t]
  );

  return (
    <div className="min-h-[calc(100vh_-_64px)] w-full bg-emblueLightGray">
      <Breadcrumb
        withoutBackArrow
        customPaths={billingAndConsumptionPaths}
        handleOnBackClick={handleOnBackClick}
      />
      <Container noPaddingY fluid="screen">
        <HeaderMyPlan
          businessName={billingInformation?.businessName}
          contractId={billingInformation?.contractId}
          isLoading={billingInformationIsLoading}
        />
        <InformationMyPlan />
        <BillingAndConsumptionTables
          billingTable={
            <BillingTable
              billingList={billingList?.result}
              billingListIsLoading={billingListIsLoading}
              changeTableOrder={handleBillingChangeOrderBy}
              changeTablePage={handleBillingChangeTablePage}
              totalBillingList={billingList?.pager.countTotal || 0}
              totalPages={billingListTotalPages}
            />
          }
          consumptionTable={
            <ConsumptionTable
              changeTableOrder={handleConsumptionChangeOrderBy}
              changeTablePage={handleConsumptionChangeTablePage}
              consumptionList={consumptionList?.result}
              isLoadingConsumptionList={consumptionListIsLoading}
              totalConsumptionList={consumptionList?.pager.countTotal || 0}
              totalPages={consumptionListTotalPages}
            />
          }
        />
      </Container>
    </div>
  );
};

export default memo(BillingAndConsumption);
