import { t } from 'i18next';
import { IAnalysisResult } from 'src/presentation/types/interfaces/IAnalysisResult.interface';
import { ImportGroupProps } from 'src/presentation/types/interfaces/IImport.interface';

import { timeout } from '@/src/compat/util';
import { IImportRecentList } from '@/src/ContactsModule/components/ImportRecentTable/ImportRecentTable';
import { IContactCustomFields } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';
import {
  IContactActivity,
  IContactActivityGroups,
  IContactActivityItems,
  IContactActivityTags,
  IContactECommerceActivity,
  IContactExtendedData,
  IContactExtraInfo,
  IContactNPSInfo,
  IContactNPSInfoActivityList,
  IContactNPSInfoList,
  IGroup,
  IGroupByContactsQuery,
  IGroupByContactsResponse,
  IGroupList,
  IHappiness,
  ISegmentTableRow,
  IStatus,
  IStubs,
  ITableContact,
  ITag,
  ITagsByContactsQuery,
  ITagsByContactsResponse,
  TableContact,
} from '@/src/ContactsModule/interfaces/Interfaces';
import { ActivityChannelMock } from '@/src/ContactsModule/utils/mockActivityChannelData';
import { mockActivityFilterData } from '@/src/ContactsModule/utils/mockActivityFilterData';
import { ActivityTagsMock } from '@/src/ContactsModule/utils/mockActivityTagsData';
import { AnalysisResult } from '@/src/ContactsModule/utils/MockAnalysisResult';
import { contactCustomMock } from '@/src/ContactsModule/utils/mockCustomField';
import { listCustomField } from '@/src/ContactsModule/utils/mockCustomFields';
import { contactListMock } from '@/src/ContactsModule/utils/mockData';
import { countriesMock } from '@/src/ContactsModule/utils/mockData/CountriesMock';
import { itemMock } from '@/src/ContactsModule/utils/mockData/ItemsMock';
import { segmentMock } from '@/src/ContactsModule/utils/mockData/SegmentsMock';
import { NpsStatus } from '@/src/ContactsModule/utils/NpsStatus';
import { translateCustomFields } from '@/src/ContactsModule/utils/TranslateCustomsFields';
import { translateInterface } from '@/src/ContactsModule/utils/TranslateInterface';
import { wrapCustomFieldsList } from '@/src/ContactsModule/utils/wrapSegmentConditions';
import {
  BillingFormState,
  CreateCheckoutBody,
  GetContactActivityGroupsParams,
  SegmentByIdCountParams,
  SegmentSearchParams,
  SenderParams,
  StepThreeBody,
  StepTwoBody,
} from '@/src/infrastructure/interfaces/IRequestParams.interface';
import {
  AccountLimitResponse,
  CreateCampaignActionResponse,
  DealResponse,
  InvoiceDetailV2,
  PlanChanges,
  PlansResponse,
  ProrationResponse,
  SmsPhoneNumberResponse,
  UpcomingBilling,
  VerificationResponse,
} from '@/src/infrastructure/interfaces/IResponses';
import { ICodification } from '@/src/infrastructure/interfaces/ISenders.interface';
import { TagsMergeParams } from '@/src/infrastructure/interfaces/IV1RequestParams.interface';
import { IContractPaymentInfo } from '@/src/presentation/types/interfaces/IContractPaymentInfo';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';
import {
  IEmailData,
  IEmailVerified,
  ISenderCreatePayload,
  ISenderData,
  ISenderEmailCreateResponse,
  ISendersResponse,
  ISenderUpdatePayload,
} from '@/src/presentation/types/interfaces/ISenderData.interface';

import { IEmblueService } from './IEmblueService';
import {
  GenericServiceResponse,
  IGroupEmblueService,
  IMigratedImagesAdded,
  ITagsEmblueService,
} from './ServiceInterfaces';

import { FormStepOne } from '@/modules/AuthModule/components/SignUpFormOne/SignUpFormOne';
import {
  RecoverPasswordRequest,
  RequestParamsChangePassword,
  RequestParamsLogin,
} from '@/modules/AuthModule/types/Auth';
import { IBeefreeCatalog } from '@/modules/CampaignsModule/interfaces/Beefree';
import {
  IDuplicateCampaignActions,
  IDuplicateCampaignActionsResponse,
  IReportParams,
  IScheduledSMSDeliveryV1,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ActionTypeName,
  IAddresseesByAction,
  IContactsEmailTest,
  ICreateCampaign,
  ICreateCampaignResponse,
  IDuplicateCampaignsResponseV1,
  IDuplicateCampaignsV1,
  IGroupsEmailTest,
  IMoveCampaignPayload,
  IPauseEmailResponseV1,
  ISaveV1Message,
  ISmsInfoResponseV1,
  ISmsRecipientsResponseV1,
  ISmsReportActionResponseV1,
  IV1GetShippingTags,
  IV1GetShippingTagsResponse,
  TActionID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';
import { IFilters } from '@/modules/ContactsModule/interfaces/BackOfficeAccounts';
import {
  fieldsMock,
  SegmentFilterMock,
  valuesMock,
} from '@/modules/ContactsModule/mocks/SegmentsMock';
import {
  happinessStatsMock,
  npsContactListMockList,
  processesMock,
} from '@/modules/ContactsModule/screens/HappinessMain/mocks/mockData';
import {
  ITableNpsContact,
  NpsContactListQuery,
  NpsContacts,
  NpsCount,
  NpsRank,
  NpsStats,
  NpsTotalContactsCount,
  NpsTotalStats,
} from '@/modules/ContactsModule/screens/HappinessMain/types';
import {
  ConfigColumProps,
  ConfigurationResponse,
  CustomsFieldsProps,
} from '@/modules/ContactsModule/screens/ImportMain/V2ImportTypes';
import { ContactTableSearchFilters } from '@/modules/ContactsModule/types/ContactTableSearchFilters';
import { Note, NoteFilters, NotesResponse } from '@/modules/ContactsModule/types/Notes';
import {
  CreateSegmentResponse,
  DataResponse,
  ECommerceSegmentsEnum,
  EmblueRecipesEnum,
  EmblueSegmentInfo,
  FieldsResponse,
  ISegment,
  ISegmentFilter,
  SegmentTypeEnum,
  ValuesSegmentsFiltersContactsResponse,
} from '@/modules/ContactsModule/types/Segments';
import { IContractsResponse, ILogsResponse } from '@/modules/ContractsModule/interfaces/contracts';
import {
  contractsListDataMock,
  logsListDataMock,
} from '@/modules/ContractsModule/mocks/contractsMockData';
import {
  Billing,
  BillingInfo,
  Consumption,
  PaginationDataResponse,
  PaginationMyPlanFilters,
} from '@/modules/MyPlanModule/types';
import {
  ChangeWidgetStatusParams,
  DeleteWidgetParams,
  ITableWidget,
  NPSWidgets,
  RestoreWidgetParams,
} from '@/modules/NPSModule/interfaces';
import { responseScriptCodeMock } from '@/modules/NPSModule/mocks/mockData';
import { IDomainValidationData } from '@/modules/SendersModule/interfaces/Senders';
import {
  emailsDataMock,
  mockSenderResponse,
  senderCreateDataMock,
  senderEmailListDataMock,
  senderListDataMock,
} from '@/modules/SendersModule/mocks/SenderMock';

/**
 * Class handled by frontend teams.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class StubEmblueService implements IEmblueService {
  getProration(body: CreateCheckoutBody): Promise<ProrationResponse> {
    throw new Error('Method not implemented.');
  }

  getBillingPortalSession(language?: 'en' | 'es' | 'pt'): Promise<{ url: string }> {
    throw new Error('Method not implemented.');
  }
  getInvoiceData(month: string, year: string): Promise<InvoiceDetailV2> {
    throw new Error('Method not implemented.');
  }
  sendEventTrack(eventName: string, data: object): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  registerLogin(data: object): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  organizationExists(org: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  emailExists(email: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  setDeal(
    step: 1 | 2 | 3,
    data: FormStepOne | StepTwoBody | StepThreeBody,
    queryParams?: string
  ): Promise<DealResponse> {
    throw new Error('Method not implemented.');
  }
  getUpcomingBill(): Promise<UpcomingBilling> {
    throw new Error('Method not implemented.');
  }
  updateBillingInfo(billingInfo: BillingFormState): Promise<{ success: boolean }> {
    throw new Error('Method not implemented.');
  }
  cancelDowngrade(): Promise<{ success: boolean }> {
    throw new Error('Method not implemented.');
  }
  getDowngradeStatus(): Promise<PlanChanges[]> {
    throw new Error('Method not implemented.');
  }
  paymentCompleted(
    body: CreateCheckoutBody
  ): Promise<{ success: boolean; errorType?: 'invalidPermissions' | 'unknown' }> {
    throw new Error('Method not implemented.');
  }
  createCheckoutSession(body: CreateCheckoutBody): Promise<{ url: string }> {
    throw new Error('Method not implemented.');
  }
  getPlansInformation(): Promise<PlansResponse> {
    throw new Error('Method not implemented.');
  }
  getPlans(): Promise<PlansResponse> {
    throw new Error('Method not implemented.');
  }
  getListBilling(body: PaginationMyPlanFilters): Promise<PaginationDataResponse<Billing[]>> {
    throw new Error('Method not implemented.');
  }
  getListConsumption(
    body: PaginationMyPlanFilters
  ): Promise<PaginationDataResponse<Consumption[]>> {
    throw new Error('Method not implemented.');
  }
  getBillingInformation(): Promise<BillingInfo> {
    throw new Error('Method not implemented.');
  }
  createCampaignAction(
    actionName: string,
    actionType: ActionTypeName,
    campaignId: string
  ): Promise<CreateCampaignActionResponse> {
    throw new Error('Method not implemented.');
  }
  verifySmsCode(
    token: string,
    phoneNumber?: string | undefined
  ): Promise<{ success: boolean; message: string; mismatch?: boolean }> {
    throw new Error('Method not implemented.');
  }
  getAuditFilter(): Promise<{ success: boolean; country: string }> {
    throw new Error('Method not implemented.');
  }
  loginV2CheckCountry(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  getValidationDomain(domain: string): Promise<IDomainValidationData> {
    throw new Error('Method not implemented.');
  }

  async sendSmsCode(phoneNumber?: string | undefined): Promise<DataResponse<string>> {
    await timeout(1000);
    return { success: true, data: 'ok' };
  }
  getSmsPhoneNumber(): Promise<SmsPhoneNumberResponse> {
    throw new Error('Method not implemented.');
  }
  getSegmentsByIdCountOfSearch(query: SegmentByIdCountParams): Promise<{ count: number }> {
    throw new Error('Method not implemented.');
  }

  async getSmartFinder(): Promise<any> {
    await timeout(2000);
  }

  async searchMoreSmartFinder(type: string, search: string): Promise<any> {
    await timeout(2000);
  }

  async getAccountNameById(accountId: number): Promise<string> {
    await timeout(2000);
    return 'Account Name';
  }

  async switchAccount(accountId: number): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  async createSender(query: ISenderCreatePayload): Promise<any> {
    await timeout(2000);
    return mockSenderResponse;
  }

  async updateSender(query: ISenderUpdatePayload): Promise<any> {
    await timeout(2000);
    return mockSenderResponse;
  }

  async deleteSender(query: ISenderData): Promise<boolean> {
    await timeout(2000);
    return true;
  }
  async getSenders(query?: {
    search: string;
    limit: number;
    page: number;
  }): Promise<ISendersResponse> {
    await timeout(2000);
    return senderListDataMock;
  }
  async getContracts(filters?: {
    search: string | null;
    limit: number;
    page: number;
    statusIds: number[] | null;
    planIds: number[] | null;
  }): Promise<IContractsResponse> {
    await timeout(2000);
    return contractsListDataMock;
  }

  async getLogs(filters?: {
    search: string | undefined;
    limit: number;
    page: number;
  }): Promise<ILogsResponse> {
    await timeout(2000);
    return logsListDataMock;
  }

  async getSenderById(senderId: number): Promise<ISenderData> {
    await timeout(2000);
    return senderListDataMock.result[0];
  }

  async sendVerifyEmail(emailId: number, accountId: number): Promise<boolean> {
    await timeout(2000);
    return true;
  }
  getCodifications(): ICodification[] {
    return [];
  }

  async getSendersEmail(): Promise<IEmailVerified[]> {
    await timeout(2000);
    return senderEmailListDataMock;
  }

  async getContactsForEmailTest(): Promise<IContactsEmailTest[]> {
    await timeout(2000);
    return [];
  }

  async getGroupsForEmailTest(): Promise<IGroupsEmailTest[]> {
    await timeout(2000);
    return [];
  }

  async sendEmailTest(actionId: number, groupId: number, contactId: number): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  async createSendersEmail(): Promise<ISenderEmailCreateResponse> {
    await timeout(2000);
    return senderCreateDataMock;
  }

  async getListedEmailsSendersWithVerification(query: SenderParams): Promise<IEmailData[]> {
    await timeout(1000);
    return emailsDataMock;
  }
  async getAccountLimits(accountId: number): Promise<DataResponse<AccountLimitResponse>> {
    await timeout(2000);
    const data = { groupsLimit: 100, segmentsLimit: 100, tagsLimit: 100 };
    sessionStorage.setItem('account_limits', JSON.stringify(data));
    return { success: true, data: data };
  }

  deleteWidget(query: DeleteWidgetParams): Promise<{ status: string }> {
    throw new Error('Method not implemented.');
  }

  restoreWidget(query: RestoreWidgetParams): Promise<{ status: string }> {
    throw new Error('Method not implemented.');
  }

  changeStatusWidgetNPS(query: ChangeWidgetStatusParams): Promise<{ responseStatus: string }> {
    throw new Error('Method not implemented.');
  }

  recoverPassword(query: RecoverPasswordRequest): Promise<DataResponse<string>> {
    throw new Error('Method not implemented.');
  }
  checkToken(token: string, userId: string): Promise<DataResponse<string>> {
    throw new Error('Method not implemented.');
  }
  changePassword(query: RequestParamsChangePassword): Promise<DataResponse<string>> {
    throw new Error('Method not implemented.');
  }
  login(query: RequestParamsLogin): Promise<DataResponse<string>> {
    throw new Error('Method not implemented.');
  }
  logout(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  reactivateDiscardedUsersSelected(emailsIds: number[]): Promise<DataResponse<boolean>> {
    throw new Error('Method not implemented.');
  }
  getAllEmailsIdsDiscardedUsers(query: ContactTableSearchFilters): Promise<DataResponse<number[]>> {
    throw new Error('Method not implemented.');
  }

  getContactHappiness(): Promise<{ list: IHappiness[] }> {
    throw new Error('Method not implemented.');
  }
  createNote(note: Note): Promise<DataResponse<string | Note>> {
    throw new Error('Method not implemented.');
  }
  renameSegment(name: string, segmentId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  segmentTags(
    tags: number[],
    quantity: number,
    name: string
  ): Promise<{
    statusOk: boolean;
    groups: number[];
    errorType: { repeatedName: boolean; contentError: boolean };
  }> {
    throw new Error('Method not implemented.');
  }
  segmentContacts(
    groups: number[],
    quantity: number,
    name: string,
    type: string
  ): Promise<{
    statusOk: boolean;
    groups: number[];
    errorType: { repeatedName: boolean; contentError: boolean };
  }> {
    throw new Error('Method not implemented.');
  }
  segmentGroups(
    groups: number[],
    quantity: number,
    name: string
  ): Promise<{
    statusOk: boolean;
    groups: number[];
    errorType: { repeatedName: boolean; contentError: boolean };
  }> {
    throw new Error('Method not implemented.');
  }
  editNoteContactData(note: Note): Promise<DataResponse<string | Note>> {
    throw new Error('Method not implemented.');
  }
  deleteNoteContactData(noteId: number): Promise<DataResponse<string>> {
    throw new Error('Method not implemented.');
  }
  getUsersSegmentById(query: { category?: string | undefined; group: number }): void {
    throw new Error('Method not implemented.');
  }
  async getFieldsFilters(): Promise<DataResponse<string | FieldsResponse>> {
    await timeout(this.timeout);
    const response: DataResponse<FieldsResponse> = { success: true, data: fieldsMock };
    return response;
  }
  async getQuantityTemporarySegment(query: {
    name: string;
    v2SegmentFilters: ISegmentFilter[];
  }): Promise<string | number> {
    await timeout(this.timeout);
    return 11;
  }
  getFlowsByAccount(): Promise<{ list: any[] }> {
    throw new Error('Method not implemented.');
  }

  async getSegmentFilterById(segmentId: number): Promise<DataResponse<ISegment | string>> {
    await timeout(2000);
    return new Promise((resolve) => {
      resolve({ success: true, data: SegmentFilterMock });
    });
  }

  async getSegmentFilterDetailedValuesById(
    segmentId: number
  ): Promise<DataResponse<ISegment | string>> {
    await timeout(2000);
    return new Promise((resolve) => {
      resolve({ success: true, data: SegmentFilterMock });
    });
  }

  getNotesContactData(contactId: string, filters: NoteFilters): Promise<NotesResponse> {
    throw new Error('Method not implemented.');
  }

  verifyContactsExist(query: { email: string }): Promise<any> {
    throw new Error('Method not implemented.');
  }

  addTagsToGroup(query: { emailId: number; associatedTags: number[] }): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  getCustomFieldsByEmailId(query: { emailId: number }): Promise<CustomsFieldsProps[] | undefined> {
    throw new Error('Method not implemented.');
  }

  editCustomFields(query: { emailId: number; modifiedFields: string }): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  createContactsByEmail(query: { email: string }): Promise<number> {
    throw new Error('Method not implemented.');
  }

  async getItemsCountOfSearch(query: {
    search?: string | undefined;
    lastDays?: number | undefined;
    dataSource?: number[] | undefined;
  }): Promise<{ count: number }> {
    await timeout(this.timeout);
    return { count: 618 };
  }

  import(query: { importData: ImportGroupProps }): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async getTagCloudInfo(
    query: ITagsEmblueService
  ): Promise<{ list: { tag: ITag }[]; count: number }> {
    await timeout(this.timeout);
    /* cspell:disable */
    const tagList = [
      { tag: { id: 1, name: 'Tag1', contacts: 533 } },
      { tag: { id: 2, name: 'Tag2', contacts: 54 } },
      { tag: { id: 3, name: 'Tag3', contacts: 98 } },
      { tag: { id: 4, name: 'Tag4', contacts: 1 } },
      { tag: { id: 5, name: 'Tag5', contacts: 19473 } },
    ];
    return { list: tagList, count: 25 };
  }
  getContactDiscardedStatus(): Promise<{ list: IStatus[] }> {
    throw new Error('Method not implemented.');
  }
  getSegmentInfo(query: { segmentId: number }): Promise<{ segmentInfo: ISegmentTableRow }> {
    throw new Error('Method not implemented.');
  }
  addVerifiedEmail({ email }: { email: string }): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getSegmentConditionsInfo({
    segmentId,
    lastRefresh,
  }: {
    segmentId: number;
    lastRefresh: number;
  }): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async getValuesSegmentsFiltersContacts(): Promise<ValuesSegmentsFiltersContactsResponse> {
    await timeout(this.timeout);
    return valuesMock;
  }

  async getSegmentsCountOfSearch(query: SegmentSearchParams): Promise<{ count: number }> {
    await timeout(this.timeout);
    return { count: 1234 };
  }

  async getGroupsCountOfSearch(query: { search?: string | undefined }): Promise<{ count: number }> {
    await timeout(this.timeout);
    return { count: 1234 };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getContactCustomFields(query: {
    emailId: number;
  }): Promise<{ contactCustomFields: IContactCustomFields[] }> {
    await timeout(this.timeout);
    /* cspell:disable */
    const contact: IContactCustomFields[] = contactCustomMock.contactCustomFields;
    return { contactCustomFields: contact };
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getContactExtendedData(query: {
    emailId: number;
  }): Promise<{ contactExtendedData: IContactExtendedData }> {
    await timeout(this.timeout);
    /* cspell:disable */
    const contact: IContactExtendedData = {
      emailId: 8190464,
      created: new Date('2022-09-15T09:18:28.423'),
      lastUpdated: new Date('2022-09-15T09:18:35.5'),
      lastSend: null,
      lastOpening: null,
      lastClick: null,
      lastViral: null,
      lastActivity: null,
      lastWebsiteVisit: null,
      lastWebTag: null,
      lastWebItem: null,
      lastPushSubscription: null,
      lastPushSend: null,
      lastSendSMS: null,
    };
    return { contactExtendedData: contact };
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getContactExtraInfo(query: {
    emailId: number;
  }): Promise<{ contactExtraInfo: IContactExtraInfo }> {
    await timeout(this.timeout);
    /* cspell:disable */
    const contact: IContactExtraInfo = { emailId: 16, country: 'Argentina ', company: undefined };
    return { contactExtraInfo: contact };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getTagsByContacts(contactList: TableContact[][]): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return {
      '56456': [
        {
          id: 38792,
          name: 'Home',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 2921108,
          activity: 3722405,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 50966,
          name: 'Sorteos',
          category: 'SORTEOS',
          color: '#008b15',
          contacts: 311650,
          activity: 846225,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 51087,
          name: 'APP YPF',
          category: null,
          color: null,
          contacts: 563829,
          activity: 1293278,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 51165,
          name: 'Beneficios',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 3056685,
          activity: 3843406,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 52734,
          name: 'Promo Pelota',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 285800,
          activity: 407576,
          creationDate: null,
          lastSend: null,
        },
      ],
      '56457': [
        {
          id: 38792,
          name: 'Home',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 2921108,
          activity: 3722405,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 51087,
          name: 'APP YPF',
          category: null,
          color: null,
          contacts: 563829,
          activity: 1293278,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 51165,
          name: 'Beneficios',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 3056685,
          activity: 3843406,
          creationDate: null,
          lastSend: null,
        },
      ],
      '81691': [
        {
          id: 38792,
          name: 'Home',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 2921108,
          activity: 3722405,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 51165,
          name: 'Beneficios',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 3056685,
          activity: 3843406,
          creationDate: null,
          lastSend: null,
        },
      ],
      '564584': [
        {
          id: 38792,
          name: 'Home',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 2921108,
          activity: 3722405,
          creationDate: null,
          lastSend: null,
        },
        {
          id: 51165,
          name: 'Beneficios',
          category: 'CATÁLOGO',
          color: '#406499',
          contacts: 3056685,
          activity: 3843406,
          creationDate: null,
          lastSend: null,
        },
      ],
    };
  }
  stubs: IStubs = {
    getContacts: contactListMock,
  };
  timeout = 2000;

  static instance: StubEmblueService;

  static init() {
    return StubEmblueService.instance || (StubEmblueService.instance = new StubEmblueService());
  }

  async migratedImages(accounts: number[]): Promise<IMigratedImagesAdded> {
    await timeout(this.timeout);
    return {
      itemsAdded: 20,
      itemsSkipped: 1,
    };
  }

  async syncSizeImages(accounts: number[]): Promise<boolean> {
    await timeout(this.timeout);
    return true;
  }

  async getBeefreeCatalog(filters: IFilters): Promise<IBeefreeCatalog | null> {
    await timeout(this.timeout);
    return null;
  }

  async getFreeTrialBlock(): Promise<IFreeTrialDemo> {
    await timeout(this.timeout);

    return { isBlocked: true, counter: 15, showExtendsDemoButton: true, showCounter: false };
  }

  async downloadContactsDiscarded(processId: number): Promise<boolean> {
    await timeout(this.timeout);
    return true;
  }

  SetExtraDaysForFreeTrialDemo(): void {
    /* cspell:disable */
    console.log('Set Extra days');
  }

  async getPaymentInfo(): Promise<IContractPaymentInfo> {
    await timeout(this.timeout);

    return {
      isBlocked: true,
      isSuspended: false,
      showAlreadyPaidButton: true,
      amount: 12345,
      country: 'Argentina',
      currency: 'ARS',
      currencySymbol: '$',
      payUrl: 'www.google.com',
      administrationEmail: 'administrationEmail@gmail.com',
      contractId: 1207,
      businessName: 'my business name',
    };
  }
  setAlreadyPaidBill(): void {
    console.log('Already paid bill notified');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getGroups(query: IGroupEmblueService): Promise<IGroupList> {
    await timeout(this.timeout);
    /* cspell:disable */
    return {
      list: [
        { group: { id: 1785153, groupName: ' Country Banco Provincia', contacts: 20 } },
        { group: { id: 1992522, groupName: 'activar-bl2', contacts: 263764 } },
        { group: { id: 1916094, groupName: 'Aires Acondicionados Cyber', contacts: 519 } },
        { group: { id: 2145230, groupName: 'Base leads hotsale 2022 al 27/5 ', contacts: 523 } },
        { group: { id: 2140645, groupName: 'Base San Fernando', contacts: 32 } },
        { group: { id: 2148932, groupName: 'Base Zona Norte 2', contacts: 4167 } },
        { group: { id: 2066156, groupName: 'BBDD - CABA/AMBA', contacts: 14489 } },
        { group: { id: 2066155, groupName: 'BBDD - CABA/GBA sin nombre', contacts: 193509 } },
        { group: { id: 2255058, groupName: 'Botmaker', contacts: 1259 } },
        { group: { id: 1815853, groupName: 'Caducos Agosto 2021', contacts: 106643 } },
      ],
    };
  }

  async getGroupInfo({ groupId }: { groupId: number }) {
    await timeout(this.timeout);
    return {
      groupInfo: {
        id: groupId,
        groupName: 'Group Test',
        contacts: 250,
      },
    };
  }

  async getTagInfo({ tagId }: { tagId: number }) {
    await timeout(this.timeout);
    return {
      tagInfo: {
        id: tagId,
        name: 'Tag Test',
        /* cspell:disable */
        category: 'ENTRETENIMIENTO',
        color: '#c800ff',
        contacts: 21226,
        activity: 27589,
        creationDate: undefined,
        lastSend: undefined,
      },
    };
  }

  async getTags(query: {
    page: number;
    search: string;
    tagsCategoriesFilter?: number[];
  }): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return {
      list: [
        {
          tag: {
            id: 31926,
            name: 'Paseo La Plaza',
            category: 'ENTRETENIMIENTO',
            color: '#c800ff',
            contacts: 21226,
            activity: 27589,
            creationDate: null,
            lastSend: null,
          },
        },
        {
          tag: {
            id: 32085,
            name: 'Boxes Escalonado',
            category: 'BOXES',
            color: '#247f7e',
            contacts: 300688,
            activity: 440905,
            creationDate: null,
            lastSend: null,
          },
        },
      ],
    };
  }

  async getItems(): Promise<any> {
    await timeout(this.timeout);
    return itemMock;
  }

  // eslint-disable-next-line no-empty-pattern
  async getItemProps({}: { itemName: string }) {
    await timeout(this.timeout);
    return {
      name: 'LG Air Conditioner IQA24K',
      image:
        'https://www.lg.com/levant_en/images/air-conditioning-units/md07547825/gallery/DZ-1.jpg',
      url: 'https://www.lg.com/levant_en/air-conditioning-units/lg-iqa24k',
    };
  }

  async getSegments(): Promise<any> {
    await timeout(this.timeout);
    return segmentMock;
  }

  async recalculateSegment(query: { segmentId: number; lastRefresh: number }): Promise<any> {
    await timeout(this.timeout);
    return NaN;
  }
  recalculateTag(query: { tagIdList: number[] }): Promise<any> {
    throw new Error('Method not implemented.');
  }
  async createTag(
    tagName: string,
    tagCategoryId?: number,
    contentType?: number,
    content?: string,
    addDefault?: boolean,
    tagUrl?: string,
    encoding?: number
  ): Promise<any> {
    await timeout(this.timeout);
    const query = {
      name: tagName,
      category: tagCategoryId,
      contentType: contentType,
      content: content,
      url: tagUrl,
      encoding: encoding,
    };
    alert(JSON.stringify(query));
    return true;
  }

  async editTag(query: {
    tagId: number;
    tagName: string;
    tagCategoryId?: number;
    contentType: number;
    content?: string;
    addDefault?: boolean;
    tagUrl?: string;
    encoding?: number;
  }): Promise<any> {
    await timeout(this.timeout);
    alert(JSON.stringify(query));
    return true;
  }

  async updateTagCategory(query: { tags: number[]; toCategory: number }): Promise<any> {
    await timeout(this.timeout);
    alert(JSON.stringify(query));
    return true;
  }

  async getTagContent(query: { id: number }): Promise<any> {
    await timeout(this.timeout);
    console.log('tag content');
    return null;
  }

  async mergeTags(query: TagsMergeParams): Promise<any> {
    await timeout(this.timeout);
    alert(JSON.stringify(query));
    return true;
  }

  async createGroup(groupName: string): Promise<any> {
    await timeout(this.timeout);
    alert(`name: ${groupName}`);
    return true;
  }

  async editGroup(query: { groupId: number; groupName: string }): Promise<boolean> {
    await timeout(this.timeout);
    alert(JSON.stringify(query));
    return true;
  }
  getUserData(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  sendUpgradeInterest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  finishDownloadReport(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getGetCurrentStep(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  async getLastReports() {
    await timeout(this.timeout);
    /* cspell:disable */
    return {
      aaData: [
        {
          OR: '100',
          destinatarios: 2,
          estado: 'Finalizado',
          fecha: '2022-08-08T16:35:28',
          id: 3061271,
          nombre: 'test-alberto-M899Z',
          nombre_campania: 'testjavi2',
          refuerzo: false,
          reporte: true,
          snapshot: '8839e18a5aeeb8ee78be1a5e62219493.jpg',
          campaniaId: 249453,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '50',
          Cantidad_Enviados: '2',
          Index: 0,
        },
        {
          OR: '50',
          destinatarios: 2,
          estado: 'Finalizado',
          fecha: '2022-08-08T13:49:11',
          id: 3061645,
          nombre: 'test sre',
          nombre_campania: 'testjavi2',
          refuerzo: false,
          reporte: true,
          snapshot: '6f5a34e0eaa1e0ed6f24ef3fef7bee4e.jpg',
          campaniaId: 249453,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '150',
          Cantidad_Enviados: '2',
          Index: 0,
        },
        {
          OR: '100',
          destinatarios: 2,
          estado: 'Finalizado',
          fecha: '2022-08-08T07:24:22',
          id: 3060377,
          nombre: 'test-alberto-M899T',
          nombre_campania: 'testjavi2',
          refuerzo: false,
          reporte: true,
          snapshot: 'a33306b4e2d71cd58890b1c20f451134.jpg',
          campaniaId: 249453,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '50',
          Cantidad_Enviados: '2',
          Index: 0,
        },
        {
          OR: '100',
          destinatarios: 2,
          estado: 'Finalizado',
          fecha: '2022-08-07T12:36:16',
          id: 3053557,
          nombre: 'test-alberto-2_o',
          nombre_campania: 'testjavi2',
          refuerzo: false,
          reporte: true,
          snapshot: '9e5733d52df065462ad1b531ac504f8d.jpg',
          campaniaId: 249453,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '0',
          Cantidad_Enviados: '2',
          Index: 0,
        },
        {
          OR: '50',
          destinatarios: 2,
          estado: 'Finalizado',
          fecha: '2022-08-07T12:34:06',
          id: 3053445,
          nombre: 'test-alberto-2_b',
          nombre_campania: 'testjavi2',
          refuerzo: false,
          reporte: true,
          snapshot: '2b379108b37caf7e128e8cd0da76e8ac.jpg',
          campaniaId: 249453,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '0',
          Cantidad_Enviados: '2',
          Index: 0,
        },
        {
          OR: '0',
          destinatarios: 2,
          estado: 'Finalizado',
          fecha: '2022-08-07T12:31:20',
          id: 3054053,
          nombre: 'test-alberto-3_d',
          nombre_campania: 'testjavi',
          refuerzo: false,
          reporte: true,
          snapshot: '44e09ff5a1924d7665918b17daaf171e.jpg',
          campaniaId: 239243,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '0',
          Cantidad_Enviados: '2',
          Index: 0,
        },
        {
          OR: '200',
          destinatarios: 1,
          estado: 'Finalizado',
          fecha: '2022-08-06T18:32:31',
          id: 3053470,
          nombre: 'test-alberto-2_e',
          nombre_campania: 'testjavi2',
          refuerzo: false,
          reporte: true,
          snapshot: 'f03f7fb9962afe7327e3cf8a14742a20.jpg',
          campaniaId: 249453,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '100',
          Cantidad_Enviados: '1',
          Index: 0,
        },
        {
          OR: '50',
          destinatarios: 1,
          estado: 'Inactivo incompleto',
          fecha: '2022-08-02T00:01:32',
          id: 3041129,
          nombre: 'Trigger con fecha-dani',
          nombre_campania: '1Prueba-Soporte3',
          refuerzo: false,
          reporte: true,
          snapshot: '6265e9edf22c0171e78fe6edb98b5bdb.jpg',
          campaniaId: 400953,
          tipo: 'Trigger',
          tieneMensaje: true,
          CTR: '0',
          Cantidad_Enviados: '6',
          Index: 0,
        },
        {
          OR: '75',
          destinatarios: 87,
          estado: 'Finalizado',
          fecha: '2022-07-27T12:00:00',
          id: 3039962,
          nombre: 'Envio programado-dani',
          nombre_campania: '1Prueba-Soporte3',
          refuerzo: false,
          reporte: true,
          snapshot: '6c8b8dbfbf7fd8e9c172f51e3e478869.jpg',
          campaniaId: 400953,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '2',
          Cantidad_Enviados: '87',
          Index: 0,
        },
        {
          OR: '67',
          destinatarios: 3,
          estado: 'Finalizado',
          fecha: '2022-07-26T17:22:36',
          id: 2865656,
          nombre: 'TEST_DELTA_STAGING',
          nombre_campania: 'TEST_DELTA_STAGING',
          refuerzo: false,
          reporte: true,
          snapshot: '7dbe28b8adfc1fe76725cb2a41222281.jpg',
          campaniaId: 422198,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '0',
          Cantidad_Enviados: '3',
          Index: 0,
        },
        {
          OR: '88',
          destinatarios: 8,
          estado: 'Finalizado',
          fecha: '2022-07-26T16:30:51',
          id: 3038841,
          nombre: 'Con tags',
          nombre_campania: '1Prueba-Soporte3',
          refuerzo: false,
          reporte: true,
          snapshot: '7038e936a51a93294d53005439f49515.jpg',
          campaniaId: 400953,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '25',
          Cantidad_Enviados: '8',
          Index: 0,
        },
        {
          OR: '76',
          destinatarios: 92,
          estado: 'Finalizado',
          fecha: '2022-07-26T15:30:54',
          id: 3038614,
          nombre: 'Envio para borrar grupos-dani',
          nombre_campania: '1Prueba-Soporte3',
          refuerzo: false,
          reporte: true,
          snapshot: 'f443ceb1771440a6849f684e1c4fad7b.jpg',
          campaniaId: 400953,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '0',
          Cantidad_Enviados: '92',
          Index: 0,
        },
        {
          OR: '100',
          destinatarios: 1,
          estado: 'Finalizado',
          fecha: '2022-07-25T14:47:04',
          id: 3035929,
          nombre: 'Prueba Test Tag2',
          nombre_campania: 'TP Induccion Leonel',
          refuerzo: false,
          reporte: true,
          snapshot: 'e083a0d7bd197a45688bbdbc4c665519.jpg',
          campaniaId: 257174,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '100',
          Cantidad_Enviados: '1',
          Index: 0,
        },
        {
          OR: '200',
          destinatarios: 1,
          estado: 'Finalizado',
          fecha: '2022-07-25T14:43:31',
          id: 3035911,
          nombre: 'Prueba Test Tag',
          nombre_campania: 'TP Induccion Leonel',
          refuerzo: false,
          reporte: true,
          snapshot: 'c9eb5d4bea2bc4f8e02fd78c3b36b12c.jpg',
          campaniaId: 257174,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '100',
          Cantidad_Enviados: '1',
          Index: 0,
        },
        {
          OR: '200',
          destinatarios: 1,
          estado: 'Finalizado',
          fecha: '2022-07-25T13:50:00',
          id: 3035727,
          nombre: 'DL-308',
          nombre_campania: 'TESTDELTA',
          refuerzo: false,
          reporte: true,
          snapshot: 'bc2c0f09dbf1ac35bf28c33ab989a646.jpg',
          campaniaId: 402852,
          tipo: 'Envio simple',
          tieneMensaje: true,
          CTR: '0',
          Cantidad_Enviados: '1',
          Index: 0,
        },
      ],
      iTotalDisplayRecords: '15',
      iTotalRecords: '271',
      sEcho: null,
    };
  }
  async getSenderScore() {
    await timeout(this.timeout);
    /* cspell:disable */
    return [
      { fecha: '2022-08-14T05:00:00', score: 7.66262727101361 },
      { fecha: '2022-08-11T05:00:00', score: 9.1212831195138424 },
      { fecha: '2022-08-09T05:00:00', score: 9.0940456722246914 },
      { fecha: '2022-08-07T05:00:00', score: 9.0882685265911078 },
    ];
  }

  async exportContacts(segmentId: number, fields: any[]): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return {
      content: 589395,
      id_notificacion: 'REPORTE_CREADO_OK',
    };
  }

  async exportGroupContacts(groupId: number, fields: any[]): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return {
      content: 589396,
      id_notificacion: 'REPORTE_CREADO_OK',
    };
  }

  async getStatusContactsExport(): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return {
      estado: 'Pedido',
      filename: '',
      id: 589426,
      reporte: 'PERFIL',
      token: '',
      url: null,
    };
  }

  async getContactStatus(): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return [
      { state: 'inactive', total: 1309939 },
      { state: 'valid', total: 589702 },
      { state: 'deciduous', total: 2422276 },
      { state: 'asleep', total: 845256 },
      { state: 'excluded', total: 1813288 },
      { state: 'recent', total: 1176542 },
      { state: 'new', total: 39483 },
    ];
  }

  async getContactsCount(query: { lastRefresh: number }): Promise<{ count: number }> {
    await timeout(this.timeout);
    return { count: 1000 };
  }

  async getContactsCountOfSearch(query: {
    search?: string;
    tagsFilter?: number[];
    statusFilter?: string[];
    groupsFilter?: number[];
    segmentsFilter?: number;
    lastRefresh: number;
  }): Promise<{ count: number }> {
    await timeout(this.timeout);
    return { count: 1234 };
  }

  async getTotalDiscardedContacts(query: {
    isDiscardedView: boolean;
    lastRefresh: number;
  }): Promise<{
    count: number;
  }> {
    await timeout(this.timeout);
    return { count: 1234 };
  }

  async getContacts({ page }: { page: number }) {
    await timeout(this.timeout);
    return {
      list: this.stubs.getContacts.slice((page - 1) * 10, page * 10),
    };
  }

  async getContactInfo({ emailId }: { emailId: number; lastRefresh?: number }) {
    await timeout(this.timeout);
    const list: ITableContact[] = this.stubs.getContacts;
    // eslint-disable-next-line eqeqeq
    const currentContact = list.map((x) => x.contact).find((contact) => contact.emailId == emailId);
    const [info]: [info: TableContact] = currentContact
      ? [currentContact]
      : [{ emailId: 0, email: '' }];
    return {
      contactInfo: info,
    };
  }

  // eslint-disable-next-line no-empty-pattern
  async getContactActivitySummary({}: { emailId: number }) {
    await timeout(this.timeout);
    return {
      messages: 50,
      openings: 30,
      clicks: 20,
    };
  }

  setNextStep(companyId: string, step: number): number {
    console.log('throw error');
    throw new Error('Method not implemented.');
  }

  async getContactActivityChannel(query: {
    emailId: number;
    searchActionName?: string;
    activityTypes?: number[];
    channels?: number[];
    orderByColumn?: string;
    orderByDirection?: string;
    pageNumber?: number;
    rowsPerPage?: number;
    initialDate?: Date;
    lastDate?: Date;
  }): Promise<{ list: IContactActivity[] }> {
    await timeout(this.timeout);
    const pageIndex = query.pageNumber ? query.pageNumber : 1;
    const pageSize = query.pageNumber ? query.pageNumber : 10;
    const activity: IContactActivity[] = ActivityChannelMock.slice(
      (pageIndex - 1) * pageSize,
      pageIndex * pageSize
    );
    return {
      list: activity,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getContactActivityChannelCount(query: {
    emailId: number;
    searchActionName?: string;
    activityTypes?: number[];
    channels?: number[];
    orderByColumn?: string;
    orderByDirection?: string;
    pageNumber?: number;
    rowsPerPage?: number;
    initialDate?: Date;
    lastDate?: Date;
  }): Promise<{ count: number }> {
    await timeout(this.timeout);
    return { count: 1000 };
  }

  async getContactActivityFilter(): Promise<any> {
    await timeout(this.timeout);
    const data: any = mockActivityFilterData;
    return {
      channels: data.channels,
      activities: data.activities,
    };
  }

  async getContactActivityTags(query: {
    emailId: number;
    pageNumber?: number;
    rowsPerPage?: number;
    search?: string;
  }): Promise<{ list: IContactActivityTags[]; total: number }> {
    await timeout(this.timeout);
    const pageIndex = query.pageNumber ? query.pageNumber : 1;
    const pageSize = query.pageNumber ? query.pageNumber : 10;
    const activity: IContactActivityTags[] = ActivityTagsMock.slice(
      (pageIndex - 1) * pageSize,
      pageIndex * pageSize
    );
    const total = 0;
    return {
      list: activity,
      total: total,
    };
  }

  async getContactActivityItems(query: {
    emailId: number;
    pageNumber?: number;
    rowsPerPage?: number;
    search?: string;
  }): Promise<{ list: IContactActivityItems[]; total: number }> {
    await timeout(this.timeout);
    return {
      list: [{ id: 1, date: new Date(2018, 11, 24, 10, 33), item: 'item', source: 'Click' }],
      total: 1,
    };
  }

  async getContactActivityGroups(
    query: GetContactActivityGroupsParams
  ): Promise<{ list: IContactActivityGroups[]; total: number }> {
    await timeout(this.timeout);
    return {
      list: [{ id: 1, type: 'group', name: 'name', contacts: 1 }],
      total: 1,
    };
  }

  async addVerifiedSender({ email }: { email: string }): Promise<VerificationResponse> {
    await timeout(this.timeout);
    return {
      id_notification: 'SENDER_EMAIL_VERIFY_AND_ADDED',
      content: 60777,
    };
  }

  async addContactsToGroup(query: {
    contactsIds: number[];
    groupsToAssign: number[];
    groupsToUnassign?: number[];
  }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Action complete');
    return true;
  }
  async addAllContactsToGroup(
    query: ContactTableSearchFilters & { groupsToAssign: number[]; groupsToUnassign?: number[] }
  ): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Action complete');
    return true;
  }

  async getGroupsByContacts({
    contactsIds,
    allResults,
    filters,
  }: IGroupByContactsQuery): Promise<IGroupByContactsResponse> {
    await timeout(this.timeout);
    console.log(`list groups by contacts: ${contactsIds}`);
    return { allInCommon: [123456], partiallyInCommon: [786542] };
  }

  async getTagsListByContacts({
    contactsIds,
    allResults,
    filters,
  }: ITagsByContactsQuery): Promise<ITagsByContactsResponse> {
    await timeout(this.timeout);
    console.log(`list tags by contacts: ${contactsIds}`);
    return { allInCommon: [123456], partiallyInCommon: [786542] };
  }

  async addFlowsToContacts(query: { flowId: number; contactsIds: number[] }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Action complete');
    return true;
  }

  async getRestrictions() {
    await timeout(this.timeout);
    console.log('Action complete');
    return {
      allAllowed: true,
      elementsBlocked: null,
    };
  }

  async addFlowsToAllContacts(
    query: ContactTableSearchFilters & { flowId: number }
  ): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Action complete');
    return true;
  }

  async addGroupsToFlow(query: { flowId: number; groupsId: number[] }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Action complete');
    return true;
  }
  async associateTagsToContacts(query: {
    contactsIds: number[];
    tagsIds: number[];
  }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Action complete');
    return true;
  }
  async associateTagsToAllContacts(
    query: ContactTableSearchFilters & { tagsToAssign: number[]; tagsToUnassign: number[] }
  ): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Action complete');
    return true;
  }

  async deleteAllSelectedContacts(query: ContactTableSearchFilters): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Delete all complete');
    return true;
  }
  async deleteSelectedContacts(query: { contactsIds: number[] }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Delete complete');
    return true;
  }

  async suspendAllSelectedContacts(query: ContactTableSearchFilters): Promise<boolean> {
    await timeout(this.timeout);
    console.log('suspend all complete');
    return true;
  }
  async suspendSelectedContacts(query: { contactsIds: number[] }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('suspend complete');
    return true;
  }

  async getGroupsInUse(query: {
    groupsIds: number[];
  }): Promise<{ groupId: number; name?: string }[]> {
    await timeout(this.timeout);
    return [
      { groupId: 0, name: 'Test group 1' },
      { groupId: 10, name: 'Most important group' },
    ];
  }

  async deleteSelectedGroups(query: { groupsIds: number[] }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Delete complete');
    return true;
  }

  async deleteSelectedTags(query: { tagsIds: number[] }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('Delete complete');
    return true;
  }

  async getTagsCategories(query: { identifier: string }): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return { list: [{ id: 1, Name: 'Category Name' }] };
  }

  async getTagsDropDown(query: { identifier: string; lastRefresh?: number }): Promise<any> {
    await timeout(this.timeout);
    /* cspell:disable */
    return { list: [{ id: 1, Name: 'Category Name' }] };
  }

  async getTagsCountOfSearch(query: {
    search?: string | undefined;
    tagsCategoriesFilter?: number[] | undefined;
  }): Promise<{ count: number }> {
    await timeout(this.timeout);
    return { count: 1234 };
  }

  updateContactEditCustomFields(query: { emailId: number; customData: {} }): Promise<any> {
    const data: any = ['ok'];
    return data;
  }

  getCountries(): Promise<{ list: { id: string; name: string }[] }> {
    const data: any = countriesMock(t);
    return data;
  }

  async getImportConfiguration(): Promise<DataResponse<ConfigurationResponse>> {
    await timeout(this.timeout);
    if (this.stubs.getImportConfiguration) {
      const data = this.stubs.getImportConfiguration;
      const onSuccessResponse: ConfigColumProps = {
        columns: data.columnas.map((element: any) => {
          return {
            idCustomField: element.id_campo_personalizado,
            value: element.value,
          };
        }),
        quantityColumns: data.columnas.length,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        quantityContacts: data.columnas[data.indiceColumnaMail].value.length + data.registros,
        suggestedDelimiter: data.delimitadorsugerido === '\t' ? 't' : data.delimitadorsugerido,
        headers: data.cabeceras,
      };
      return { success: true, data: { onSuccess: onSuccessResponse } };
    } else {
      return { success: false, data: { onError: {} } };
    }
  }

  async getListCustomFields(): Promise<Array<CustomsFieldsProps>> {
    await timeout(this.timeout);
    if (this.stubs.getListCustomFields) {
      const data = this.stubs.getListCustomFields;
      /* cspell:disable */
      const translations = {
        cantidadContactos: 'quantityContacts',
        esFijoEmblue: 'itIsFixedEmblue',
        id: 'id',
        integracion_id: 'integration',
        nombre: 'name',
        numero: 'number',
        solo_lectura: 'readOnly',
        tipoCampo: 'fieldType',
        tipoOpcion: 'optionType',
        tipoDato: 'dataType',
        valor: 'value',
        valores: 'values',
      };

      const wrappedFieldType = wrapCustomFieldsList(data).data;

      for (let i = 0; i < wrappedFieldType.length; i++) {
        data[i].tipoDato = (wrappedFieldType[i] as { typeData: string }).typeData;
      }
      /* cspell:enable */
      let listCustomField = [];

      for (const item of data) {
        const interfaceInEnglish = {};
        listCustomField.push(translateInterface(item, interfaceInEnglish, translations));
      }

      listCustomField = translateCustomFields(listCustomField);

      return listCustomField;
    } else {
      const data: Array<CustomsFieldsProps> = listCustomField;
      return data;
    }
  }
  async getContactStatusCount(query: { lastRefresh: number }) {
    await timeout(this.timeout);
    return {
      list: [
        { state: 'inactive', total: 65895 },
        { state: 'discarded', total: 34135 },
        { state: 'asleep', total: 1 },
        { state: 'new', total: 10 },
      ] as any,
    };
  }

  async getContactDiscardedStatusCount(query: { lastRefresh: number }) {
    await timeout(this.timeout);
    return {
      list: [
        { discarded: 'suspended', total: 30069 },
        { discarded: 'blocked', total: 3965 },
        { discarded: 'unsafe', total: 101 },
      ] as any,
    };
  }

  async analysisResult(): Promise<IAnalysisResult> {
    await timeout(this.timeout);
    /* cspell:disable */
    const data = {
      error: AnalysisResult.Error,
      state: AnalysisResult.Estado,
      processIdentifier: AnalysisResult.ProcessIdentifier,
      quantityDiscarded: {
        quantity: AnalysisResult.cantidadDescartados.cantidad,
        detail: {
          garbage: AnalysisResult.cantidadDescartados.detalle.basuras,
          insufficient: AnalysisResult.cantidadDescartados.detalle.insuficientes,
          invalids: AnalysisResult.cantidadDescartados.detalle.invalidos,
          repeated: AnalysisResult.cantidadDescartados.detalle.repetidos,
        },
      },
      detectedContacts: AnalysisResult.contactosDetectados,
      importedContacts: {
        quantity: AnalysisResult.contactosImportados.cantidad,
        detail: {
          updated: AnalysisResult.contactosImportados.detalle.actualizados,
          updatedCorrected: AnalysisResult.contactosImportados.detalle.actualizados_corregidos,
          new: AnalysisResult.contactosImportados.detalle.nuevos,
          newCorrected: AnalysisResult.contactosImportados.detalle.nuevos_corregidos,
          reactivated: AnalysisResult.contactosImportados.detalle.reactivados,
          reactivatedCorrected: AnalysisResult.contactosImportados.detalle.reactivados_corregidos,
          noModifications: AnalysisResult.contactosImportados.detalle.sin_modificar,
        },
      },
    } as IAnalysisResult;

    return data;
  }

  async importRecent(): Promise<IImportRecentList[]> {
    await timeout(this.timeout);

    const recentFiles = [
      {
        mapping: {
          file: 'file name.csv',
          uploaded: '05/01/2023',
          size: '128 Bytes',
        },
      },
      {
        mapping: {
          file: 'file 2 name.csv',
          uploaded: '03/01/2023',
          size: '128 Bytes',
        },
      },
    ] as IImportRecentList[];

    return recentFiles;
  }

  async analyze(): Promise<any> {
    await timeout(this.timeout);
    throw new Error('Method not implemented.');
  }

  async fileUploader(
    query: { formData: FormData },
    onUploadProgress?: (percentCompleted: number) => void
  ): Promise<DataResponse<{ fileTooLarge?: boolean; invalidMfaToken?: boolean }>> {
    await timeout(this.timeout);
    return { success: true, data: {} };
  }

  async importStatus(query: { idProcess: number }): Promise<any> {
    await timeout(this.timeout);
    throw new Error('Method not implemented.');
  }

  async getContactTagsById(query: { ids: number[] }): Promise<{ list: any }> {
    await timeout(this.timeout);
    throw new Error('Method not implemented.');
  }
  async updateContactsToGroup(query: {
    contactsIds: number[];
    groupsToAssign: number[];
    groupsToUnassign: number[];
  }): Promise<boolean> {
    await timeout(this.timeout);
    throw new Error('Method not implemented.');
  }
  async addContactsToTags(query: {
    contactsIds: number[];
    tagsToAssign: number[];
    tagsToUnassign?: number[];
  }): Promise<boolean> {
    await timeout(this.timeout);
    throw new Error('Method not implemented.');
  }
  async updateContactsToTags(query: {
    contactsIds: number[];
    tagsToAssign: number[];
    tagsToUnassign: number[];
  }): Promise<boolean> {
    await timeout(this.timeout);
    throw new Error('Method not implemented.');
  }

  async getNpsScriptInstallationCode({
    accountIdEmblue,
  }: {
    accountIdEmblue: string;
  }): Promise<string | undefined> {
    await timeout(this.timeout);
    const data = {
      result: responseScriptCodeMock,
    };
    const scriptCode = data.result;
    return scriptCode;
  }

  async getNpsList(
    {
      search,
    }: {
      search?: string;
      orderColumn?: string;
      orderDirection?: string;
      pageNumber?: number;
      rowsPerPage?: number;
      lastRefresh: number;
    },
    typeOfList: 'list' | 'recycle_bin'
  ): Promise<NPSWidgets | undefined> {
    await timeout(this.timeout);
    const data: NPSWidgets = {
      list: [
        {
          widget: {
            id: 12314,
            name: 'Widget NPS pruebaProducción5971',
            views: 0,
            actions: 0,
            ctr: 0,
            creationDays: 42,
            status: 'Active',
          },
        },
      ],
      countPartial: 1,
      countTotal: 15,
      typeOfList: typeOfList ?? 'list',
    };

    const list: ITableWidget[] = data.list;

    return {
      list: list,
      countPartial: 1,
      countTotal: 15,
      typeOfList: typeOfList ?? 'list',
    };
  }

  async getContactNPSInfo(query: {
    emails: string[];
    includeNps: boolean;
  }): Promise<{ list: IContactNPSInfoList[] } | undefined> {
    if (query.includeNps) {
      await timeout(this.timeout);

      let data: any = {};
      query.emails.map((email: string) => {
        data[email] = {
          widgetName: 'NPS Campaign',
          value: Math.floor(Math.random() * 11),
          date: new Date().toISOString(),
        };
      });

      data = Object.entries(data).map(([email, npsData]: [string, any]) => {
        const status =
          npsData.value >= 9
            ? 'promoter'
            : npsData.value >= 7 && npsData.value <= 8
            ? 'passive'
            : 'detractor';
        return { email, ...(npsData as IContactNPSInfo), status };
      });

      return {
        list: data,
      };
    }
  }

  async getContactNPSActivity({
    email,
    search,
    startDate,
    endDate,
    qualificationFilter,
  }: {
    email: string;
    search: string;
    startDate: Date;
    endDate: Date;
    qualificationFilter: string[];
    orderColumn: string;
    orderDirection: string;
    pageNumber: number;
    rowsPerPage: number;
  }): Promise<IContactNPSInfoActivityList | undefined> {
    await timeout(this.timeout);
    let data: any = [];
    for (let i = 0; i < 20; i++) {
      data.push({
        npsList: {
          widgetName: `NPS Campaign ${i}`,
          value: Math.floor(Math.random() * 11),
          date: new Date().toISOString(),
        },
        totalCount: 2,
        partialCount: 2,
      });
    }
    if (search) {
      data = data.filter((x: any) => x.widgetName.toLowerCase().includes(search.toLowerCase()));
    }
    if (startDate) {
      data = data.filter((x: any) => new Date(x.date) >= new Date(startDate));
    }
    if (endDate) {
      data = data.filter((x: any) => new Date(x.date) <= new Date(endDate));
    }
    if (qualificationFilter) {
      data = data.filter((x: any) => NpsStatus(x.value)) === qualificationFilter[0];
    }
    const npsList = data.npsList.map(
      ({ widgetName: npsCampaign, value: npsScore, date: npsDate }: IContactNPSInfo) => ({
        npsCampaign,
        npsScore,
        npsDate,
        npsStatus: NpsStatus(npsScore),
      })
    );

    return { npsList, totalCount: data.totalCount, partialCount: data.partialCount };
  }

  async getContactsActivityECommerceVtex({
    integrationId,
    email,
  }: {
    integrationId: number;
    email: string;
  }): Promise<{ list: IContactECommerceActivity[] } | undefined> {
    await timeout(this.timeout);
    let data: any = {};
    data = [
      {
        converted: false,
        description: 'Chair office 2023',
        date: new Date().toISOString(),
        amount: '$120',
        statusInvoice: 'invoiced',
      },
    ];

    return {
      list: data,
    };
  }

  async getContactsActivityECommerce({
    integrationId,
    email,
  }: {
    integrationId: number;
    email: string;
  }): Promise<any> {
    await timeout(this.timeout);
    let data: any = {};
    data = [
      {
        converted: false,
        description: 'Chair office 2023',
        date: new Date().toISOString(),
        amount: '$120',
        statusInvoice: 'invoiced',
      },
    ];

    return {
      list: data,
    };
  }

  async deleteSegments(query: { ids: number[] }): Promise<boolean> {
    await timeout(this.timeout);
    console.log('delete segments:', query.ids);
    return true;
  }

  async duplicateSegment(query: {
    id: number;
    name: string;
  }): Promise<DataResponse<CreateSegmentResponse>> {
    await timeout(this.timeout);
    console.log('duplicate segment:', query);
    return { success: true, data: { segmentId: query.id } };
  }

  async getECommerceGroups(): Promise<IGroup[]> {
    await timeout(500);
    return [
      { id: 12, groupName: 'RFM LOW', contacts: 111 },
      { id: 123, groupName: 'RFM MED', contacts: 423 },
      { id: 1234, groupName: 'RFM HIGH', contacts: 42453 },
      { id: 12345, groupName: 'Clientes', contacts: 42345 },
      { id: 123456, groupName: 'Compraron al menos una vez', contacts: 0 },
      { id: 1234567, groupName: 'Compraron en el último mes', contacts: 1 },
      { id: 12345678, groupName: 'Compraron en los últimos 10 días', contacts: 43456 },
      { id: 123456789, groupName: 'Nunca Compraron', contacts: 434567 },
    ];
  }

  async getEmblueSegmentsInUse(): Promise<EmblueSegmentInfo[]> {
    await timeout(this.timeout);
    return [
      {
        id: 1234,
        contacts: 12345,
        name: 'Abandoned Contact',
        segmentNumber: EmblueRecipesEnum.abandonedContact,
        segmentType: SegmentTypeEnum.emblueRecipe,
      },
      {
        id: 12321,
        contacts: 120,
        name: 'RFM High',
        segmentNumber: ECommerceSegmentsEnum.rfmHigh,
        segmentType: SegmentTypeEnum.eCommerce,
      },
    ];
  }

  async createV2Segment(query: {
    name: string;
    v2SegmentFilters: ISegmentFilter[];
    asEmblueSegment?: {
      segmentType: SegmentTypeEnum.emblueRecipe | SegmentTypeEnum.eCommerce;
      segmentNumber?: EmblueRecipesEnum | ECommerceSegmentsEnum;
    };
  }): Promise<DataResponse<CreateSegmentResponse>> {
    await timeout(this.timeout);
    alert(JSON.stringify(query));
    return { success: true, data: { segmentId: 1 } };
  }
  async editV2Segment(query: {
    v2Segment: ISegment;
  }): Promise<DataResponse<CreateSegmentResponse>> {
    await timeout(this.timeout);
    alert(JSON.stringify(query.v2Segment));
    return { success: true, data: { segmentId: query.v2Segment.id } };
  }

  ///// ***** GLOBAL LastRefreshCache SECTION ***** \\\\\

  //Contacts
  lastRefreshContactsValue = 0;
  getLastRefreshContactsValue(): number {
    return this.lastRefreshContactsValue;
  }
  incrementLastRefreshContactsValue(): void {
    this.lastRefreshContactsValue++;
  }
  //Groups
  lastRefreshGroupsValue = 0;
  getLastRefreshGroupsValue(): number {
    return this.lastRefreshGroupsValue;
  }
  incrementLastRefreshGroupsValue(): void {
    this.lastRefreshGroupsValue++;
  }
  //Tags
  lastRefreshTagsValue = 0;
  getLastRefreshTagsValue(): number {
    return this.lastRefreshTagsValue;
  }
  incrementLastRefreshTagsValue(): void {
    this.lastRefreshTagsValue++;
  }
  //Segments
  lastRefreshSegmentsValue = 0;
  getLastRefreshSegmentsValue(): number {
    return this.lastRefreshSegmentsValue;
  }
  incrementLastRefreshSegmentsValue(): void {
    this.lastRefreshSegmentsValue++;
  }
  //Nps
  lastRefreshNpsValue = 0;
  getLastRefreshNpsValue(): number {
    return this.lastRefreshNpsValue;
  }
  incrementLastRefreshNpsValue(): void {
    this.lastRefreshNpsValue++;
  }

  ///// ***** End GLOBAL LastRefreshCache SECTION ***** \\\\\

  // Happiness dashboard
  async getNpsRank(_: { status: string }): Promise<NpsRank | undefined> {
    await timeout(this.timeout);
    return processesMock[0];
  }
  async getNpsCount(_: { status: string; lastRefresh?: number }): Promise<NpsCount | undefined> {
    await timeout(this.timeout);
    return {
      count: 5,
    };
  }
  async getNpsStats(_: { status: string }): Promise<NpsStats | undefined> {
    await timeout(this.timeout);
    return {
      detractors: 100,
      passives: 600,
      promoters: 300,
      totalResponses: 1000,
    };
  }
  async getNpsGeneralValue(_: { status: string }): Promise<number | undefined> {
    await timeout(this.timeout);
    return 23;
  }
  async getNpsTotalStats(_: { status: string }): Promise<NpsTotalStats | undefined> {
    await timeout(this.timeout);
    return happinessStatsMock[0];
  }
  async getNpsTotalContactsCount(_: {
    status: string;
  }): Promise<NpsTotalContactsCount | undefined> {
    await timeout(this.timeout);
    return {
      totalContacts: 255,
    };
  }
  async getNpsContactList(query: NpsContactListQuery): Promise<NpsContacts | undefined> {
    await timeout(this.timeout);
    const data: NpsContacts = npsContactListMockList[0];
    const list: ITableNpsContact[] = data.list;

    if (query.orderByName === 'desc') {
      list?.sort((a, b) => a.contact.name!.localeCompare(b.contact.name!));
    }
    if (query.orderByName === 'asc') {
      list?.sort((a, b) => b.contact.name!.localeCompare(a.contact.name!));
    }

    if (query?.search?.includes('@')) {
      const filteredListBySearch = list?.filter((a) => a.contact.email?.includes(query.search!));
      return {
        list: filteredListBySearch,
        count: filteredListBySearch.length,
      };
    }

    if (query?.search) {
      const filteredListBySearch = list?.filter((a) => a.contact.name?.includes(query.search!));
      return {
        list: filteredListBySearch,
        count: filteredListBySearch.length,
      };
    }

    if (query.happiness.length === 3) {
      return {
        list: list,
        count: data.count,
      };
    }

    if (query.happiness[0] === 'promoters') {
      const promoters = list.filter((x) => x.contact.happiness! >= 9);
      return {
        list: promoters,
        count: promoters.length,
      };
    }
    if (query.happiness[0] === 'detractors') {
      const detractors = list.filter((x) => x.contact.happiness! >= 0 && x.contact.happiness! <= 6);
      return {
        list: detractors,
        count: detractors.length,
      };
    }
    if (query.happiness[0] === 'passives') {
      const passives = list.filter((x) => x.contact.happiness! >= 7 && x.contact.happiness! <= 8);
      return {
        list: passives,
        count: passives.length,
      };
    }

    return {
      list: list,
      count: data.count,
    };
  }

  setOnsiteToken(token: string): void {}

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async createV1Campaigns(payload: ICreateCampaign): Promise<ICreateCampaignResponse> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ createV1Campaigns:', payload);
      return { statusOK: true, campaignId: 0, actionId: 0 };
    } catch (error) {
      return { statusOK: false, campaignId: 0, actionId: 0 };
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async deleteV1Campaigns(campaignsID: number[]): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ deleteV1Campaigns:', campaignsID);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  async duplicateV1Campaigns(
    payload: IDuplicateCampaignsV1
  ): Promise<IDuplicateCampaignsResponseV1> {
    try {
      await timeout(2000);
      return { statusOK: true, campaignIdDuplicate: payload.campaignId };
    } catch (error) {
      console.error(`❌ EM_Error duplicateV1Campaigns:`, error);
      return { statusOK: false, campaignIdDuplicate: 0 };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async deleteV1Actions(actionsId: number[]): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ deleteV1Actions:', actionsId);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async moveV1Action(payload: IMoveCampaignPayload): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ moveV1Action:', payload);
      return { statusOK: true };
    } catch (error) {
      console.error(`❌ EM_Error moveV1Action:`, error);
      return { statusOK: false };
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async duplicateV1Actions(
    payload: IDuplicateCampaignActions
  ): Promise<IDuplicateCampaignActionsResponse> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ duplicateV1Actions');
      return { statusOK: true, actionIdDuplicate: payload.campaignActionId };
    } catch (error) {
      console.error(`❌ EM_Error duplicateV1Actions:`, error);
      return { statusOK: false, actionIdDuplicate: 0 };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async saveV1Message(payload: ISaveV1Message): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ saveV1Message', payload);
      return { statusOK: true };
    } catch (error) {
      console.error(`❌ EM_Error saveV1Message:`, error);
      return { statusOK: false };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getTotalAddresseesByAction(payload: IAddresseesByAction): Promise<number | null> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ saveV1Message', payload);
      return 23;
    } catch (error) {
      console.error(`❌ EM_Error getTotalAddresseesByAction:`, error);
      return null;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setPauseEmailActionV1(actionId: TActionID): Promise<IPauseEmailResponseV1 | null> {
    try {
      await timeout(2000);
      return { content: null, id_notificacion: 'ELEMENTO_PAUSADO_OK' };
    } catch (error) {
      console.error(`❌ EM_Error setPauseEmailActionV1:`, error);
      return null;
    }
  }
  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getV1ShippingTags(): Promise<IV1GetShippingTagsResponse> {
    try {
      await timeout(2000);
      return { statusOK: true, tags: {} as IV1GetShippingTags };
    } catch (error) {
      console.error(`❌ EM_Error setV1DataMessage:`, error);
      return { statusOK: false, tags: {} as IV1GetShippingTags };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getSmsInfo(actionId: TActionID): Promise<ISmsInfoResponseV1> {
    try {
      await timeout(2000);
      return {} as ISmsInfoResponseV1;
    } catch (error) {
      console.error(`❌ EM_Error getSmsInfo:`, error);
      return {} as ISmsInfoResponseV1;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getSmsRecipientsV1(actionId: TActionID): Promise<ISmsRecipientsResponseV1 | null> {
    try {
      await timeout(2000);
      return {} as ISmsRecipientsResponseV1;
    } catch (error) {
      console.error(`❌ EM_Error getSmsRecipientsV1:`, error);
      return null;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async confirmElementV1(actionId: TActionID): Promise<GenericServiceResponse> {
    try {
      await timeout(2000);
      return { success: true };
    } catch (error) {
      console.error(`❌ EM_Error confirmElementV1:`, error);
      return { success: false };
    }
  }
  /* cspell:enable */

  async getContractCountry(): Promise<string | null> {
    try {
      await timeout(2000);
      return 'AR';
    } catch (error) {
      console.error(`❌ EM_Error getContractCountry:`, error);
      return null;
    }
  }

  /* cspell:disable */
  async getSetInfoSms(query: object): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error(`❌ EM_Error getSetInfoSms:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setSmsRecipientsV1(query: object): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error(`❌ EM_Error setSmsRecipientsV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setImmediateSMSDeliveryV1(actionId: TActionID): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error(`❌ EM_Error setImmediateSMSDeliveryV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setScheduledSMSDeliveryV1(query: IScheduledSMSDeliveryV1): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error(`❌ EM_Error setScheduledSMSDeliveryV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setConfirmSMSV1(actionId: TActionID): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error(`❌ EM_Error setConfirmSMSV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setPauseSMSV1(actionId: TActionID): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error(`❌ EM_Error setPauseSMSV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getSmsInfoReport(actionId: TActionID): Promise<ISmsReportActionResponseV1> {
    try {
      await timeout(2000);
      return {} as ISmsReportActionResponseV1;
    } catch (error) {
      console.error(`❌ EM_Error getSmsInfoReport:`, error);
      return {} as ISmsReportActionResponseV1;
    }
  }
  /* cspell:enable */

  /**
   * @returns {Promise<boolean>}
   * @description download report SMS
   */
  //TODO: Temporal service. Migrate service to V2 in Monorepo
  async downloadReport(payload: IReportParams[]): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }
}
