import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';

import { ECustomFieldsByType } from '@/src/application/hooks/interfaces/ICustomField';
import { useCustomField } from '@/src/application/hooks/useCustomField';

import {
  TRANSLATE_RULES_EXECUTION_OPTIONS,
  TRANSLATE_RULES_FREQUENCY_OPTIONS,
  TRANSLATE_RULES_PERIOD_OPTIONS,
  TRANSLATE_RULES_TRIGGER_OPTIONS,
} from '@/modules/RulesModule/constants';
import { DataTypeCustomField } from '@/modules/RulesModule/interfaces';

/* cspell:disable*/
export const useTriggerOptions = () => {
  const { t } = useTranslation('rules');

  const {
    events: { getCustomFieldsByType, translateCustomField },
  } = useCustomField();

  const triggerOptions = [
    {
      id: 'fechadesencadenante',
      name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.fechadesencadenante`)}`,
      value: 'Fecha desencadenante',
      disabled: false,
    },
    {
      id: 2,
      name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.tagAssociated`)}`,
      value: 'triggerId_2',
      disabled: true,
    },
    {
      id: 3,
      name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.externalCallForUrl`)}`,
      value: 'triggerId_3',
      disabled: true,
    },
    {
      id: 4,
      name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.fromApiQuickCampaigner`)}`,
      value: 'triggerId_4',
      disabled: true,
    },
    {
      id: 5,
      name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.fromWebForm`)}`,
      value: 'triggerId_5',
      disabled: true,
    },
    {
      id: 6,
      name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.flowTemplate`)}`,
      value: 'triggerId_6',
      disabled: true,
    },
  ];

  const frequencyOptions = [
    { id: 'anio', name: `${t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.anio`)}`, value: 'anio' },
    { id: 'mes', name: `${t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.mes`)}`, value: 'mes' },
    { id: 'dia', name: `${t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.dia`)}`, value: 'dia' },
  ];

  const executionOptions = [
    { id: 1, name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.sameDay`), value: 'mismo' },
    { id: 2, name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.before`), value: 'before the' },
    { id: 3, name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.after`), value: 'after the' },
  ];

  const periodOptions = [
    { id: 'dia', name: `${t(`${TRANSLATE_RULES_PERIOD_OPTIONS}.dia`)}`, value: 'dia' },
    { id: 'mes', name: `${t(`${TRANSLATE_RULES_PERIOD_OPTIONS}.mes`)}`, value: 'mes' },
  ];

  const hourOptions = Array.from({ length: 24 }, (_, i) => ({
    id: i,
    name: i.toString().padStart(2, '0'),
    value: i.toString().padStart(2, '0'),
  }));

  const minuteOptions = Array.from({ length: 12 }, (_, i) => ({
    id: i * 5,
    name: (i * 5).toString().padStart(2, '0'),
    value: (i * 5).toString().padStart(2, '0'),
  }));

  const dayOptions = Array.from({ length: 31 }, (_, i) => ({
    id: (i + 1).toString(),
    name: (i + 1).toString(),
    value: (i + 1).toString(),
  }));

  const customFieldOptions = useMemo(() => {
    const fields = getCustomFieldsByType({ type: ECustomFieldsByType.ALL });
    const filteredFieldsByTypeDate = fields.filter(
      (field) => field.type === DataTypeCustomField.DATE
    );

    return filteredFieldsByTypeDate.map(
      (field): Option => ({
        id: field.id,
        name: translateCustomField(field.name),
        value: field.id,
      })
    );
  }, [getCustomFieldsByType, translateCustomField]);

  return {
    triggerOptions,
    customFieldOptions,
    frequencyOptions,
    executionOptions,
    periodOptions,
    hourOptions,
    minuteOptions,
    dayOptions,
  };
};
