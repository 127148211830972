import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Table } from '@/lib/components/Table';
import { Button, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyContactsIcon, EmptyGroupIcon } from '@/lib/v2/icons/general';
import { CalendarIcon } from '@/lib/v2/icons/outline';
import { RecalculateIcon } from '@/lib/v2/icons/outline/RecalculateIcon';

import { BulletListIcon } from '@/src/ContactsModule/Icons/BulletListIcon';
import { ContactsIcon } from '@/src/ContactsModule/Icons/ContactsIcon';
import { ReloadIcon } from '@/src/ContactsModule/Icons/ReloadIcon';
import { ISegmentTableProps, ISegmentTableRow } from '@/src/ContactsModule/interfaces/Interfaces';
import { SegmentsSwitcherEnum } from '@/src/ContactsModule/utils/enums/SegmentsSwitcher';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

import { DateFormat, formatDate } from '@/modules/CampaignsModule/utils';

export function SegmentsTable({
  segmentList,
  totalSegmentCount,
  changeTablePage,
  changeTableOrder,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
  recalculateSegment,
  isRecalculateSegment,
  segmentsSwitcher,
  isVTEXEnabled,
  setSelectedSegments,
}: ISegmentTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const segmentId = useRef(0);

  const [tableOrderByList, setTableOrderByList] = useState<
    Array<{ id: string; isAsc: boolean | undefined }>
  >([
    {
      id: 'Name',
      isAsc: true,
    },
  ]);

  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <BulletListIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('ITEMS_TABLE.name')} </span>
        <SortTableArrow
          elementName={'Name'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'segment',
    width: '40%',
    Cell: ({ cell: { value } }: { cell: { value: ISegmentTableRow } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span className="self-start font-medium text-[#364365]"> {value.name} </span>
        </Flex>
      </Flex>
    ),
  };
  const contactColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ContactsIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('ITEMS_TABLE.contact')} </span>
      </Flex>
    ),
    accessor: 'segment',
    id: 'contacts',
    width: '20%',
    Cell: ({ cell: { value } }: { cell: { value: ISegmentTableRow } }) => (
      <Flex withGap alignment="start" gapSize="small">
        {value.contacts ? (
          <span className="self-start font-medium text-[#364365]">
            {` ${quantifier(value.contacts) ?? ''} `}
          </span>
        ) : (
          <span className="opacity-50"> {t('SEGMENTS_TABLE.noContact')} </span>
        )}
      </Flex>
    ),
  };
  const recalculateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <RecalculateIcon tableSize />
        <span className="text-[#004DBC]"> {t('SEGMENTS_MAIN.recalculateSegment')} </span>
      </Flex>
    ),
    accessor: 'segment',
    id: 'segmentId',
    width: '20%',
    Cell: ({ cell: { value } }: { cell: { value: ISegmentTableRow } }) => (
      <Flex withGap alignment="start" gapSize="small">
        <Button
          outline
          secondary
          disabled={isRecalculateSegment && value.id !== segmentId.current}
          iconLeft={<ReloadIcon />}
          id="recalculate-button"
          isLoading={isRecalculateSegment && value.id === segmentId.current}
          size={'medium'}
          onClick={(e) => {
            segmentId.current = value.id;
            recalculateSegment(e, value.id);
          }}
        >
          <div className="ml-3">
            {isRecalculateSegment && value.id === segmentId.current
              ? t('SEGMENTS_MAIN.recalculatingSegment')
              : t('SEGMENTS_MAIN.recalculateSegment')}
          </div>
        </Button>
      </Flex>
    ),
  };

  const lastUpdateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <CalendarIcon />
        <span className="text-[#004DBC]"> {t('SEGMENTS_TABLE.lastUpdate')} </span>
      </Flex>
    ),
    accessor: 'segment',
    id: 'lastTotalContactsUpdate',
    width: '20%',
    Cell: ({ cell: { value } }: { cell: { value: ISegmentTableRow } }) => {
      const formattedDate = value.lastTotalContactsUpdate
        ? formatDate(value.lastTotalContactsUpdate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start" gapSize="small">
          <span className="self-start font-medium text-[#364365]">{formattedDate}</span>
        </Flex>
      );
    },
  };

  const segmentsTableColumns: Column<object>[] = [
    nameColumn,
    contactColumn,
    lastUpdateColumn,
    recalculateColumn,
  ].filter((x) => x) as Column<object>[];

  const goToSegmentDetail = (segment: ISegmentTableRow) => {
    navigate(`../segments/${segment.id}`);
  };

  const screenHandler = useCallback(() => {
    if (segmentsSwitcher === SegmentsSwitcherEnum.ECommerce && isVTEXEnabled) {
      return (
        <EmptyScreenTable
          description={
            <>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyECommerceEnabledDescription1')}
              </Text>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyECommerceEnabledDescription2')}
                <button
                  className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
                  data-testid="nps-screen-empty-table-create-link"
                  role="link"
                  onClick={callToAction && callToAction[2]}
                >
                  {t('EMPTY_SCREEN_TABLE.emptyECommerceEnabledAction')}
                </button>
              </Text>
            </>
          }
          image={<EmptyGroupIcon />}
          title={t('EMPTY_SCREEN_TABLE.emptyECommerceEnabledTitle')}
        />
      );
    }

    if (segmentsSwitcher === SegmentsSwitcherEnum.ECommerce && !isVTEXEnabled) {
      return (
        <EmptyScreenTable
          description={
            <>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyECommerceEnabledDescription1')}
              </Text>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyECommerceEnabledDescription2')}
                <button
                  className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
                  data-testid="nps-screen-empty-table-create-link"
                  role="link"
                  onClick={callToAction && callToAction[1]}
                >
                  {t('EMPTY_SCREEN_TABLE.emptyECommerceDisabledAction')}
                </button>
              </Text>
            </>
          }
          image={<EmptyGroupIcon />}
          title={t('EMPTY_SCREEN_TABLE.emptyECommerceDisabledTitle')}
        />
      );
    }

    if (segmentsSwitcher === SegmentsSwitcherEnum.EmBlueRecipes) {
      return (
        <EmptyScreenTable
          description={
            <>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyEmBlueRecipesDescription1')}
              </Text>
              <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                {t('EMPTY_SCREEN_TABLE.emptyEmBlueRecipesDescription2')}
                <button
                  className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
                  data-testid="nps-screen-empty-table-create-link"
                  role="link"
                  onClick={callToAction && callToAction[1]}
                >
                  {t('EMPTY_SCREEN_TABLE.emptyEmBlueRecipesAction')}
                </button>
              </Text>
            </>
          }
          image={<EmptyGroupIcon />}
          title={t('EMPTY_SCREEN_TABLE.emptyEmBlueRecipesTitle')}
        />
      );
    }

    return (
      <EmptyScreenTable
        description={
          <>
            <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
              {t('EMPTY_SCREEN_TABLE.emptySegmentsDescription1')}
            </Text>
            <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
              {t('EMPTY_SCREEN_TABLE.emptySegmentsDescription2')}
              <button
                className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
                data-testid="nps-screen-empty-table-create-link"
                role="link"
                onClick={callToAction && callToAction[1]}
              >
                {t('EMPTY_SCREEN_TABLE.emptySegmentsAction')}
              </button>
            </Text>
          </>
        }
        image={<EmptyGroupIcon />}
        title={t('EMPTY_SCREEN_TABLE.emptySegmentsTitle')}
      />
    );
  }, [segmentsSwitcher, isVTEXEnabled, t, callToAction]);

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        paginationWithPadding
        columns={segmentsTableColumns}
        data={segmentList}
        emptyScreen={{
          totalCount: totalSegmentCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.segments')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: screenHandler(),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        setSelectedRows={setSelectedSegments}
        withCheckbox={true}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
        onRowClick={(value) => {
          goToSegmentDetail(value.segment);
        }}
      />
    </div>
  );
}
