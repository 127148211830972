import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { TFirstProgressStep } from '@/src/ContactsModule/interfaces/Steps';

/**
 * @desc Atom of progress for first step of import contacts
 */
export const atomFirstStepInternal = atom<TFirstProgressStep>('readyToSelectFile');
atomFirstStepInternal.debugLabel = 'atomFirstStepInternal';

/**
 * @desc Atom of progress for first step of import contacts
 */
export const atomFirstStepUploadPercentage = atom<number>(0);
atomFirstStepUploadPercentage.debugLabel = 'atomFirstStepUploadPercentage';

/**
 * @desc Atom of ready for start import
 */
export const atomReadyToStartImport = atomWithReset<boolean>(false);
atomReadyToStartImport.debugLabel = 'atomReadyToStartImport';
