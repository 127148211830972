import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@/lib/components/Table';
import { Text } from '@/lib/v2/components';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyCampaignsIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';

import { useActionsTableColumns } from '@/src/modules/CampaignsModule/components/CampaignActions/ActionsTable/hooks/useActionsTableColumns';

import { useHandleEvents } from './hooks/useHandleEvents';

import { atomTotalsProcess } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomSelectedRowsAction } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { useRecurrentActionsData } from '@/modules/CampaignsModule/hooks/useRecurrentActionsData';

const ActionsTable = () => {
  const { t } = useTranslation();

  const totalsActionList = useAtomValue(atomTotalsProcess);
  const { isActionActive, actionsList, loadingProcess, pager } = useRecurrentActionsData();

  const setSelectedRows = useSetAtom(atomSelectedRowsAction);

  const { actionsColumns } = useActionsTableColumns();
  const { handleCreateActionByCampaign, handleClickEmptyScreenNoResult, handleChangeTablePage } =
    useHandleEvents();

  const totalRecords = useMemo(
    () => Math.ceil(totalsActionList.partial / (pager?.limit || 10)),
    [totalsActionList.partial, pager?.limit]
  );

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        classNamePagination="px-4 mb-2"
        columns={actionsColumns}
        data={actionsList}
        defaultSize={pager?.limit}
        emptyScreen={{
          totalCount: totalsActionList.total || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t(
                'RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsActionText'
              )}
              description={t(
                'RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsDescription'
              )}
              id="noResults-actions"
              image={<EmptyContactsIcon />}
              title={t('RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsTitle')}
              onClickAction={handleClickEmptyScreenNoResult}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={
                <Text className="text-center !text-14 text-emblue-text" fontWeight="normal">
                  {!isActionActive
                    ? t(
                        'RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_INACTIVE.emptyCampaignsDescriptionAlt'
                      )
                    : ''}
                </Text>
              }
              description={
                !isActionActive
                  ? t(
                      'RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_INACTIVE.emptyCampaignsDescription'
                    )
                  : t('RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_ACTIVE.emptyCampaignsDescription')
              }
              image={<EmptyCampaignsIcon />}
              title={t('RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_INACTIVE.emptyCampaignsTitle')}
            />
          ),
        }}
        gotoPageIndex={pager?.page ? pager.page - 1 : 0}
        isLoading={loadingProcess}
        lastPageViewed={pager?.page}
        setSelectedRows={setSelectedRows}
        withCheckbox={true}
        withPagination={{
          totalPages: totalRecords,
          fetchData: handleChangeTablePage,
        }}
      />
    </div>
  );
};

export default ActionsTable;
