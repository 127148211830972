import { memo, ReactNode } from 'react';

type Falsy = false | 0 | '' | null | undefined;
type Condition = boolean | Falsy;

interface IfProps {
  condition: Condition;
  children: ReactNode | JSX.Element;
  fallback?: ReactNode;
}

const If = ({ condition, children, fallback }: IfProps) => {
  if (condition) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
};

export default memo(If);
