import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectExternalSearch, Text } from '@/lib/v2/components';

import { IFormProps } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { IConditionForm } from '@/src/modules/RulesModule/components/FormCondition/hooks/useFormCondition';

import { useRecipientsRules } from './useRecipientsRules.hook';

import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';

interface IRecipientDropdownSmsProps {
  loading?: boolean;
  form: IFormProps<IConditionForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const RecipientDropdownRules = ({
  form,
  loading,
  rules,
  disabled = false,
}: IRecipientDropdownSmsProps) => {
  const { t } = useTranslation('rules');

  const {
    events: { handleChangeRecipients, handleCloseRecipients, handleLoadLastRecipients },
    setters: { setRecipientsSearch },
    state: { recipientsOptions, loading: loadingRecipients },
  } = useRecipientsRules({ setValue: form.setValue });

  const { control } = form;

  return (
    <>
      <Text color="dark" fontWeight="normal" variant="text">
        {t(`${TRANSLATE_RULES_LABELS}.segmentsGroups`)}
      </Text>
      <SelectExternalSearch
        control={control}
        disabled={disabled}
        id="create-action-recipients-select"
        isLoading={loading}
        isSearching={loadingRecipients}
        name="recipients"
        options={recipientsOptions}
        placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.segmentsGroups`)}
        rules={rules.recipients}
        searchPlaceholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.searchSelect`)}
        onChange={handleChangeRecipients}
        onChangeSearch={setRecipientsSearch}
        onClose={handleCloseRecipients}
        onLastItem={handleLoadLastRecipients}
      />
    </>
  );
};

export default RecipientDropdownRules;
