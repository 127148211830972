import { useCallback, useEffect, useState } from 'react';

import { RulesOptions } from '@/src/modules/RulesModule/interfaces';

const useIsTriggerFormComplete = (rulesData: RulesOptions) => {
  const [isComplete, setIsComplete] = useState(false);

  const isTriggerFormComplete = useCallback((formValues: RulesOptions | null): boolean => {
    if (!formValues) return false;

    const { trigger, customField, frequency, execution, days, period, hours, minutes } = formValues;

    return Boolean(
      trigger?.value &&
        customField?.value &&
        frequency?.value &&
        execution?.value &&
        days?.value &&
        period?.value &&
        hours?.value &&
        minutes?.value
    );
  }, []);

  useEffect(() => {
    setIsComplete(isTriggerFormComplete(rulesData));
  }, [rulesData, isTriggerFormComplete]);

  return isComplete;
};

export default useIsTriggerFormComplete;
