import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { closeModal, openModal } from '@/lib/components/Modal/Modal';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { Accordion, FloatModal, IconSvg, Spinner, toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EditPencilIcon } from '@/lib/v2/icons/outline';

import { ContactsGroupModal } from '@/src/ContactsModule/components/ContactsModals';
import { IContactActivityGroups } from '@/src/ContactsModule/interfaces/Interfaces';
import { sortItemsByProperty } from '@/src/ContactsModule/utils/sortItemsByProperty';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';

export const ContactsProfileDetailGroups = ({
  permanentEmblue,
  emailId,
}: {
  permanentEmblue: boolean;
  emailId: number;
}) => {
  const { t } = useTranslation();

  const service = useService();

  const [groups] = useEmblue(ServiceMethods.getGroups, { identifier: 'GROUPS_DROP_DOWN' });
  const groupsListAux = groups?.list.filter((g) => g && g.group && g.group.id).map((g) => g.group);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [isModalOpen] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [unselectedGroups, setUnselectedGroups] = useState<number[]>([]);

  const [totalGroupsContacts] = useState<number>();
  const { pageSize, pageIndex } = useTablePagination(totalGroupsContacts);
  const [activityGroupsListValue] = useEmblue(ServiceMethods.getContactActivityGroups, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    search: '',
    orderByDirection: 'asc',
  });

  const [groupsList, setGroupList] = useState<
    | {
        list: IContactActivityGroups[];
        total: number;
      }
    | undefined
  >();

  useEffect(() => {
    if (selectedGroups.length >= 1 || unselectedGroups.length >= 1) {
      updateContactsToGroups().catch(() => {});
    }
  }, [selectedGroups, unselectedGroups]);

  const updateContactsToGroups = async () => {
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: (
        <FloatModal>
          <Spinner withoutOverlay />
        </FloatModal>
      ),
    });
    const result = await service?.updateContactsToGroup({
      contactsIds: [emailId],
      groupsToAssign: selectedGroups,
      groupsToUnassign: unselectedGroups,
    });
    closeModal(); // spinner
    toast({
      variant: result ? 'success' : 'error',
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
      autoCloseDelay: 5000,
    });
    const groupsUpdated = await service?.getContactActivityGroups({
      emailId,
      pageNumber: pageIndex,
      rowsPerPage: pageSize,
      search: '',
      orderByDirection: 'asc',
    });
    setGroupList(groupsUpdated);
  };

  useEffect(() => {
    if (activityGroupsListValue) {
      setGroupList(activityGroupsListValue);
    }
  }, [activityGroupsListValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRead = () => {
    setShowGroupsModal(true);
  };

  const groupsListSorted: IContactActivityGroups[] = sortItemsByProperty<
    IContactActivityGroups,
    'name'
  >(groupsList?.list, 'name');

  const groupsCountText: string = groupsList ? ` (${groupsList.total.toString()})` : ' (0)';

  return (
    <div className="static w-full">
      <Accordion
        btnActive={isModalOpen}
        btnIcon={
          <IconSvg
            strokeColor={!isModalOpen ? 'secondary' : 'primary'}
            svgComponent={<EditPencilIcon />}
          />
        }
        btnText={t('CONTACTS_PROFILE.edit')}
        startOpen={permanentEmblue}
        title={t('GROUPS_MAIN.group') + groupsCountText}
        onClickButton={() => handleRead()}
      >
        <div className="visible-scrollbar max-h-[32rem] overflow-auto bg-white pr-2">
          <Flex textAlignment="left">
            <div className="py-2 pl-8 pr-4">
              {groupsListSorted &&
                groupsListSorted.map((g, key) => {
                  return (
                    <div key={key} className="pb-2">
                      {g.name}
                    </div>
                  );
                })}
            </div>
          </Flex>
        </div>
      </Accordion>
      {showGroupsModal && (
        <ContactsGroupModal
          contactGroups={groupsList && groupsList.list}
          groups={groupsListAux === undefined ? [{ id: 0, groupName: '' }] : groupsListAux}
          isUpdateCheckboxModal={true}
          setSelectedGroups={setSelectedGroups}
          setShowGroupsModal={setShowGroupsModal}
          setUnselectedGroups={setUnselectedGroups}
        />
      )}
    </div>
  );
};
