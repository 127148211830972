import Modal from '@/lib/v2/components/Modal/Modal';
import Selectable, { ISelectable } from '@/lib/v2/components/Selectable';

interface IModalSelectable {
  wordings: ISelectable['wordings'];
  items: ISelectable['items'];
  onClose: (value: boolean) => void;
  onAccept: ISelectable['onAccept'];
  show: boolean;
  isAcceptDisabled?: boolean;
  limitSelection?: number;
  withCreateItem?: boolean;
  labelInputCreate?: string;
  onCreate?: (toCreate: string) => Promise<void>;
}

const ModalSelectable = (props: IModalSelectable) => {
  return (
    <Modal noPadding withAuto externalClose={true} open={props.show} onClose={props.onClose}>
      <Selectable
        className="p-4"
        isAcceptDisabled={props.isAcceptDisabled}
        items={props.items}
        labelInputCreate={props.labelInputCreate}
        limitSelection={props.limitSelection || 5}
        withCreateItem={props.withCreateItem}
        wordings={props.wordings}
        onAccept={props.onAccept}
        onClose={props.onClose}
        onCreate={props.onCreate}
      />
    </Modal>
  );
};

export default ModalSelectable;
