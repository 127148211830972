import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { useMemo } from 'react';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  SmsCountries,
  SMSVolumeSteps,
  Volume,
  VolumeSteps,
} from '@/src/infrastructure/interfaces/IResponses';
import {
  atomBillingInformation,
  atomContractDetail,
} from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';

import { useCountriesOptions } from './useCountriesOptions';
import { usePlanAdditionalInfo } from './usePlanAdditionalInfo';
import { useSelectedPlanData } from './useSelectedPlanData';

const BILLING_DAY = 2;

/**
 * Retrieves the billing cycle anchor for the next billing period.
 * The billing cycle anchor is calculated by adding one month to the current date and setting the day to the specified billing day.
 *
 * @returns The billing cycle anchor in seconds.
 */
const getBillingCycleAnchor = (): number => {
  const nextBillingDate = new Date();
  nextBillingDate.getDate() > 1 && nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);
  nextBillingDate.setDate(BILLING_DAY);
  return Math.floor(nextBillingDate.getTime() / 1000);
};

/**
 * Calculate the proration amount based on the remaining days in the billing cycle.
 * The proration amount is calculated by dividing the price per month by the number of days in the month and multiplying it by the remaining days in the billing cycle.
 * The result is rounded to two decimal places.
 * This is the better approximation of the proration amount that is used in the Stripe API.
 * @param pricePerMonth
 * @returns
 */
const calculateProrationAmount = (pricePerMonth: number): number => {
  const startDate = new Date();
  const startTimestamp = startDate.getTime();
  const billingCycleAnchorTimestamp = getBillingCycleAnchor() * 1000;

  const daysInMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();

  const remainingDays = Math.ceil(
    (billingCycleAnchorTimestamp - startTimestamp) / (1000 * 60 * 60 * 24)
  );

  const proratedAmount = (pricePerMonth / daysInMonth) * remainingDays;

  return parseFloat(proratedAmount.toFixed(2));
};

export const usePlanPriceInformation = (country?: SmsCountries) => {
  const { planAdditionalInfo } = usePlanAdditionalInfo();
  const billingInformation = useAtomValue(atomBillingInformation);
  const contractDetail = useAtomValue(atomContractDetail);
  const { getCountrySymbol } = useCountriesOptions();
  const ffProratedPrice = useFeatureFlag('stripeProration');

  const {
    plan,
    emailAmount,
    smsAmount,
    onsiteAmount,
    frequency,
    handleFrequency,
    frequencyOptions,
    setSelectedPlanData,
    isFreemiumPlan,
  } = useSelectedPlanData();

  const isCurrentPlan = plan?.actualPlan;

  let increaseEmail = 0,
    increaseSms = 0,
    increaseOnsite = 0;

  if (contractDetail && isCurrentPlan) {
    const { email, sms, onsite } = contractDetail.planDetail;
    increaseEmail = emailAmount - email;
    increaseSms = smsAmount - sms;
    increaseOnsite = onsiteAmount - onsite;
  }

  const getRangePrice = (
    range: number | string,
    typeRange: keyof Volume,
    isIncrease: boolean = false
  ) => {
    let currentRange;

    const volumeSteps = plan?.volume[typeRange].volumeSteps;

    if (Array.isArray(volumeSteps)) {
      currentRange = _.find(volumeSteps, (item: VolumeSteps) => +range <= item.max);
    }

    if (!currentRange) return;
    let cpm = 0;

    let amountTotalRange = 0;
    const min = volumeSteps?.length ? volumeSteps[0].min : 0;
    if (+range > min && !isIncrease) {
      amountTotalRange = +range - min;
    }
    if (isIncrease) amountTotalRange = +range;

    if (typeRange !== 'sms') {
      cpm = (currentRange as VolumeSteps).prePurchasePrice ?? 0;
    } else {
      const countrySymbol = country ?? getCountrySymbol(billingInformation?.country ?? '');
      cpm = (currentRange as SMSVolumeSteps).prePurchasePrice[countrySymbol] ?? 0;
    }
    let totalPrice = amountTotalRange * cpm;

    if (frequency.value === 'annually') {
      totalPrice = totalPrice * 0.8;
    }

    return totalPrice;
  };

  const isAnnuallySelected = frequency.value === 'annually';
  const monthSuffixText = frequencyOptions[0].priceSuffix;
  const planNameSelected = plan && planAdditionalInfo[plan?.name].name;
  const priceEmails = getRangePrice(emailAmount, 'email') ?? 0;
  const priceSms = getRangePrice(smsAmount, 'sms') ?? 0;
  const priceOnsite = getRangePrice(onsiteAmount, 'onsite') ?? 0;
  const priceIncreaseEmail = getRangePrice(increaseEmail, 'email', true) ?? 0;
  const priceIncreaseSms = getRangePrice(increaseSms, 'sms', true) ?? 0;
  const priceIncreaseOnsite = getRangePrice(increaseOnsite, 'onsite', true) ?? 0;
  const planPrice = plan?.price[frequency.value].perMonth ?? 0;
  const planPriceForYear = plan?.price[frequency.value].fullYear ?? 0;
  const monthlyTotal = planPrice + priceEmails + priceSms + priceOnsite;
  const annuallyTotal = planPriceForYear + priceEmails * 12 + priceSms * 12 + priceOnsite * 12;

  const proratedPrices = useMemo(() => {
    if (
      ffProratedPrice &&
      (contractDetail?.planName === 'Free Trial' || contractDetail?.planName === 'Free')
    ) {
      const proratedPlanPrice = calculateProrationAmount(planPrice);
      const proratedEmailPrice = calculateProrationAmount(priceEmails);
      const proratedSmsPrice = calculateProrationAmount(priceSms);
      const proratedOnsitePrice = calculateProrationAmount(priceOnsite);
      const proratedTotal =
        proratedPlanPrice + proratedEmailPrice + proratedSmsPrice + proratedOnsitePrice;
      const proratedPrices = {
        plan: proratedPlanPrice,
        email: proratedEmailPrice,
        sms: proratedSmsPrice,
        onsite: proratedOnsitePrice,
        total: proratedTotal,
      };
      return proratedPrices;
    }
  }, [contractDetail?.planName, ffProratedPrice, planPrice, priceEmails, priceOnsite, priceSms]);

  const currentTotalPayment = contractDetail?.planPrice ?? 0;
  const discount = +(contractDetail?.contractDiscount ?? 0);
  const currentYearPriceWithDiscount = (
    Number(currentTotalPayment) *
    12 *
    ((100 - discount) / 100)
  ).toFixed(2);
  const currentFrequency = contractDetail?.billingPeriod;
  const showAlertRefund =
    !isAnnuallySelected && currentFrequency === 'monthly'
      ? monthlyTotal < Number(currentTotalPayment)
      : annuallyTotal < Number(currentYearPriceWithDiscount);
  const hasProrations =
    currentFrequency === frequency.value &&
    !showAlertRefund &&
    contractDetail?.planName !== 'Free' &&
    billingInformation?.hasStripeSubscription &&
    !isCurrentPlan;

  return {
    isFreemiumPlan,
    annuallyTotal,
    monthlyTotal,
    isAnnuallySelected,
    monthSuffixText,
    planNameSelected,
    frequencyOptions,
    frequency,
    handleFrequency,
    planPrice,
    emailAmount,
    smsAmount,
    onsiteAmount,
    setSelectedPlanData,
    priceEmails,
    priceSms,
    priceOnsite,
    priceIncreaseEmail,
    priceIncreaseSms,
    priceIncreaseOnsite,
    increaseEmail,
    increaseSms,
    increaseOnsite,
    proratedPrices,
    showAlertRefund,
    currentFrequency,
    hasProrations,
  };
};
