import { useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useHtmlEditorService } from '@/src/infrastructure/Protocol/HtmlEditor/useHtmlEditorService';
import {
  useAlertMessages,
  useHtml,
} from '@/src/modules/CampaignsModule/components/HtmlUpload/hooks';
import {
  assembleHtml,
  prepareHtml,
  verifyHttpInLinks,
} from '@/src/modules/CampaignsModule/components/HtmlUpload/util/htmlModifiers';

import {
  editorRefAtom,
  htmlEditorAtom,
  isLoadingSaveAtom,
} from '@/modules/CampaignsModule/atoms/htmlEditorAtom';
import { useHtmlEditorLogger } from '@/modules/CampaignsModule/hooks/useHtmlEditorLogger';
import {
  IAddLinksIdToHtmlEditor,
  IGetHtmlEditor,
} from '@/modules/CampaignsModule/interfaces/HTMLEditor';
/* cspell:disable */

export const useSaveHtml = () => {
  const navigate = useNavigate();
  const serviceHtmlEditor = useHtmlEditorService();
  const { showSaveMessage } = useAlertMessages();
  const { campaignId, actionType } = useParams();
  const { cleanedFileString, sendElementID, emptyEditor, accountId, actionId } = useHtml();
  const setHtml = useSetAtom(htmlEditorAtom);
  const { logger } = useHtmlEditorLogger();

  const editorRef = useAtomValue(editorRefAtom);
  const setIsLoading = useSetAtom(isLoadingSaveAtom);

  const { originalBodyTag } = prepareHtml(cleanedFileString);

  const updateHtml = useCallback(
    async (params: IAddLinksIdToHtmlEditor) => {
      const { html, actionId } = params;
      const htmlVerified = verifyHttpInLinks(html);

      const response = await serviceHtmlEditor.updateElementHtml({
        actionId,
        htmlBody: htmlVerified,
      });

      const error = response?.error ? true : false;

      if (!error) {
        await logger('saveHtml', 'action', {
          actionId: actionId,
        });
      } else {
        await logger('saveHtml', 'error', actionId);
      }

      return response;
    },
    [serviceHtmlEditor]
  );

  const saveHtml = useCallback(async () => {
    if (editorRef) {
      const htmlEditedString = editorRef.current.value;
      const htmlAssembled = assembleHtml(htmlEditedString, originalBodyTag, emptyEditor);

      const response = await updateHtml({
        html: htmlAssembled,
        actionId,
      });

      const isSaveLinksIdToHtml = response && response.status === 200;

      if (!isSaveLinksIdToHtml) {
        showSaveMessage(false);
        return;
      }

      setHtml(RESET);

      return isSaveLinksIdToHtml;
    }
  }, [
    accountId,
    editorRef,
    actionId,
    emptyEditor,
    originalBodyTag,
    sendElementID,
    serviceHtmlEditor,
  ]);

  const exportEditorContent = async () => {
    setIsLoading(true);
    const isSuccess = await saveHtml();
    setIsLoading(false);
    if (isSuccess) {
      showSaveMessage(true);
      navigate(`/v2/campaigns/${String(campaignId)}/${String(actionType)}/${String(actionId)}`);
    } else {
      showSaveMessage(false);
    }
  };

  const getHtmlByUrl = async ({ url, actionId, code }: IGetHtmlEditor) => {
    const response = await serviceHtmlEditor.getUrlAndUpdateElementHtml({
      url,
      actionId,
      code,
    });
    return response;
  };

  const handlePreview = useCallback(async () => {
    const isSuccess = await saveHtml();
    if (isSuccess) {
      navigate(
        `/v2/campaigns/${String(campaignId)}/${String(actionType)}/${String(actionId)}/preview`
      );
    }
  }, [actionId, actionType, campaignId, navigate, saveHtml]);

  return { exportEditorContent, getHtmlByUrl, handlePreview, updateHtml };
};
