import { useSetAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Flex } from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import {
  atomModalTypeProcess,
  atomToggleDeleteModal,
  atomToggleDuplicateCampaignModal,
  atomToggleEditCreateCampaignModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

const ActionButtons = ({ toggleModal }: { toggleModal: () => void }) => {
  const { t } = useTranslation();

  const showDuplicateCampaignAction = useFeatureFlag('showDuplicateCampaignAction');
  const showLogCampaign = useFeatureFlag('activityLogCampaign');
  const duplicateCampaignV2 = useFeatureFlag('duplicateCampaign');
  const setToggleDuplicateCampaignModal = useSetAtom(atomToggleDuplicateCampaignModal);
  const setToggleDeleteModal = useSetAtom(atomToggleDeleteModal);
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);

  const handleDuplicateCampaign = useCallback(() => {
    if (duplicateCampaignV2) {
      setModalTypeProcess(ModalType.DUPLICATE);
      setToggleEditCategoryModal(true);
    } else {
      setToggleDuplicateCampaignModal(true);
    }
  }, [
    duplicateCampaignV2,
    setModalTypeProcess,
    setToggleDuplicateCampaignModal,
    setToggleEditCategoryModal,
  ]);

  const handleDeleteCampaign = useCallback(() => {
    setToggleDeleteModal(true);
  }, [setToggleDeleteModal]);

  const listDropdownActions: DropdownItemProps[] = useMemo(() => {
    const actions = [
      {
        id: 'edit-campaign',
        value: 'edit-campaign',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.edit'),
        onClick: toggleModal,
      },
      {
        id: 'delete-campaign',
        value: 'delete-campaign',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.delete'),
        onClick: handleDeleteCampaign,
      },
    ];

    if (showDuplicateCampaignAction) {
      actions.splice(1, 0, {
        id: 'duplicate-campaign',
        value: 'duplicate-campaign',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.duplicate'),
        onClick: handleDuplicateCampaign,
      });
    }

    return actions;
  }, [t, toggleModal, handleDuplicateCampaign, handleDeleteCampaign, showDuplicateCampaignAction]);

  return (
    <Flex withGap alignment="end" gapSize="small">
      {showLogCampaign && (
        <Button
          outline
          id="log-actions-button"
          size="medium"
          onClick={() => console.log('Activity log')}
        >
          {t('CAMPAIGN_ACTIONS_MAIN.HEADER.log')}
        </Button>
      )}
      <Dropdown
        menuOnTheLeft
        color="primary"
        dropdownItems={listDropdownActions}
        id="options-actions-dropdown"
        label={t('CAMPAIGN_ACTIONS_MAIN.HEADER.options')}
        size="small"
      />
    </Flex>
  );
};

export default memo(ActionButtons);
