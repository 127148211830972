import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ModalSingleInput } from '@/lib/v2/examples';

import { formValidations } from '@/src/constants/FormValidations';

import {
  atomActionsList,
  atomSelectedActionsRowsWithID,
  atomSelectedRowActionName,
  atomSelectedRowActionType,
  atomSelectedRowsAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomLoadingDetail,
  atomRefetchAllData,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ButtonActions } from '@/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/components/ButtonActions';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import { useDeprecatedBanner } from '@/modules/CampaignsModule/hooks/useDeprecatedBanner';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { getCampaignIDByActionSelected } from '@/modules/CampaignsModule/utils';

const TRANSLATE_MODAL_DUPLICATE = 'CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS';

interface IDuplicateActionsProps {
  onClose: (value?: boolean) => void;
}

const DuplicateActions = ({ onClose }: IDuplicateActionsProps) => {
  const { t } = useTranslation();
  const { id: campaignID } = useParams();
  const { duplicateAction } = useCampaignActions();
  const { fetchServiceValidName } = useValidNameResource();
  const { inputLengths, formRegExp } = formValidations;

  // TODO remove this when the feature flag showDragDropRemovalNotice is removed
  const { showDeprecatedDragDropForRow } = useDeprecatedBanner();

  const [toggleDuplicateActionsModal] = useAtom(atomToggleDuplicateActionsModal);

  const loadingDetail = useAtomValue(atomLoadingDetail);
  const selectedActionsRowsWithID = useAtomValue(atomSelectedActionsRowsWithID);
  const actionName = useAtomValue(atomSelectedRowActionName);
  const actionType = useAtomValue(atomSelectedRowActionType);
  const selectedRowsAction = useAtomValue(atomSelectedRowsAction);
  const actionsList = useAtomValue(atomActionsList);

  const setRefetchAllData = useSetAtom(atomRefetchAllData);

  const handleCloseModal = useCallback(() => onClose(), [onClose]);

  const VALIDATION_RULES = {
    input: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
      validate: async (value: string) => {
        const isValidName = await fetchServiceValidName({
          name: value,
          resource: EResourceType.ACTION,
          campaignId:
            Number(campaignID) ||
            getCampaignIDByActionSelected({ rowAction: selectedRowsAction, actionsList }),
        });

        return isValidName || t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign');
      },
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
    },
  };

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      await duplicateAction({
        actionName: nameValue,
        campaignActionId: Number(selectedActionsRowsWithID),
        actionType: Number(actionType),
      });
      handleCloseModal();
      setRefetchAllData(true);
    },
    [duplicateAction, selectedActionsRowsWithID, actionType, handleCloseModal, setRefetchAllData]
  );

  return (
    <ModalSingleInput
      alert={
        !showDeprecatedDragDropForRow
          ? {
              description: t(`${TRANSLATE_MODAL_DUPLICATE}.description`),
              type: 'info',
            }
          : {
              description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.deprecated.featured'),
              type: 'warning',
            }
      }
      id="duplicate-action-modal"
      isLoading={loadingDetail}
      isOpen={toggleDuplicateActionsModal}
      rules={VALIDATION_RULES}
      title={t(`${TRANSLATE_MODAL_DUPLICATE}.title`)}
      value={`${String(actionName)} ${t(`${TRANSLATE_MODAL_DUPLICATE}.copy`)}`}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData}
    />
  );
};

export default DuplicateActions;
