import { datadogRum } from '@datadog/browser-rum';
import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Table } from '@/lib/components/Table';
import { Flex, Switch, Text } from '@/lib/v2/components';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { LoadingIcon } from '@/lib/v2/icons/animated';
import { EmptyRecycleBinIcon, EmptySearchIcon, EmptyWidgetsIcon } from '@/lib/v2/icons/general';
import {
  ActivityIcon,
  CalendarIcon,
  ClicksIcon,
  StatusIcon,
  ViewsIcon,
} from '@/lib/v2/icons/outline';
import { ListIcon } from '@/lib/v2/icons/solid';

import { useUserData } from '@/src/infrastructure/services/useUserData';
import { useNpsStateContext } from '@/src/modules/NPSModule/contexts/NpsContext';
import { useChangeWidgetStatus } from '@/src/modules/NPSModule/hooks/useChangeWidgetStatus';
import { ITableNPSProps, ITableWidgetNPS } from '@/src/modules/NPSModule/interfaces';

export function WidgetsTable({
  npsWidgets,
  changeTableOrder,
  changeTablePage,
  setCreateNpsOnsite,
  totalPages,
  isLoading,
  gotoPageIndex,
  setSelectedWidgets,
  selectAllResults,
  tableOrderByList,
  setTableOrderByList,
  goToReportNps,
  onsiteToken,
  resetWidgetsList,
  lastRefreshIncrement,
}: ITableNPSProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userData] = useUserData();
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);
  const { isRecycleBin } = useNpsStateContext();

  const accountIdEmblue = `emblue${userData.companyId}`;
  const { changeWidgetStatus } = useChangeWidgetStatus(accountIdEmblue, onsiteToken);

  const convertStringToBoolean = (status: string): boolean => {
    return status === 'Active' || status !== 'Inactive';
  };

  const handleSwitchChange = async (
    event: MouseEvent<HTMLButtonElement>,
    status: string,
    widgetId: number
  ) => {
    event.stopPropagation();
    setIsLoadingChangeStatus(true);
    const booleanNewStatus = !convertStringToBoolean(status);
    const newStatusNps = booleanNewStatus ? 1 : 0;
    const { responseStatus } = await changeWidgetStatus(newStatusNps, widgetId);

    if (responseStatus === 'OK') {
      lastRefreshIncrement();
      setIsLoadingChangeStatus(false);
      navigate('/v2/nps');
    }
    setIsLoadingChangeStatus(false);
  };

  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ListIcon />
        <span className="text-emblue-primary"> {t('NPS_SCREEN.nameColumn')} </span>
        <SortTableArrow
          elementName="name"
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'widget',
    id: 'widget',
    Cell: ({ cell: { value } }: { cell: { value: ITableWidgetNPS } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span
            className="self-start truncate text-emblue-subheadings md:max-w-[200px] 2xl:max-w-[300px]"
            title={value.name}
          >
            {value.name}
          </span>
        </Flex>
      </Flex>
    ),
  };
  const viewsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ViewsIcon />
        <span className="text-emblue-primary"> {t('NPS_SCREEN.viewsColumn')} </span>
        <SortTableArrow
          elementName="views"
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'widget.views',
    id: 'views',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span className="self-start truncate text-emblue-subheadings md:max-w-[200px] 2xl:max-w-[300px]">
            {value}
          </span>
        </Flex>
      </Flex>
    ),
  };
  const actionsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ActivityIcon />
        <span className="text-emblue-primary"> {t('NPS_SCREEN.actionsColumn')} </span>
        <SortTableArrow
          elementName={'actions'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'widget.actions',
    id: 'actions',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span className="self-start truncate text-emblue-subheadings md:max-w-[200px] 2xl:max-w-[300px]">
            {value}
          </span>
        </Flex>
      </Flex>
    ),
  };
  const ctrColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ClicksIcon />
        <span className="text-emblue-primary"> {t('NPS_SCREEN.ctrColumn')} </span>
        <SortTableArrow
          elementName={'ctr'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'widget.ctr',
    id: 'ctr',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span className="self-start truncate text-emblue-subheadings md:max-w-[200px] 2xl:max-w-[300px]">
            {value}
          </span>
        </Flex>
      </Flex>
    ),
  };
  const creationDaysColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <CalendarIcon />
        <span className="text-emblue-primary"> {t('NPS_SCREEN.daysColumn')} </span>
        <SortTableArrow
          elementName={'creationDays'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'widget.creationDays',
    id: 'creationDays',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span className="self-start truncate text-emblue-subheadings md:max-w-[200px] 2xl:max-w-[300px]">
            {value}
          </span>
        </Flex>
      </Flex>
    ),
  };
  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <StatusIcon />
        <span className="text-emblue-primary"> {t('NPS_SCREEN.statusColumn')} </span>
        <SortTableArrow
          elementName={'status'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: ({ widget }: { widget: ITableWidgetNPS }) => ({
      status: widget.status,
      id: widget.id,
    }),
    id: 'status',
    Cell: ({ cell: { value } }: { cell: { value: ITableWidgetNPS } }) => (
      <Flex withGap alignment="start" gapSize="small">
        {isLoadingChangeStatus ? (
          <div className="h-[26px] w-full">
            <LoadingIcon />
          </div>
        ) : (
          <button onClick={(event) => void handleSwitchChange(event, value.status, value.id)}>
            <Switch
              key={value.id}
              checked={convertStringToBoolean(value.status)}
              id={value.id.toString()}
              withLabels={false}
            />
          </button>
        )}
      </Flex>
    ),
  };

  const npsTableColumns: Column<object>[] = [
    nameColumn,
    viewsColumn,
    actionsColumn,
    ctrColumn,
    creationDaysColumn,
    !isRecycleBin && statusColumn,
  ].filter((x) => x) as Column<object>[];

  const EmptyScreenTableDescription = useMemo(() => {
    const onClickEmptyScreenAction = () => {
      datadogRum.addAction('click on NPS Module empty table CTA', {
        email: userData.email,
      });
      setCreateNpsOnsite(true);
    };
    return (
      <>
        <Text className="text-center !text-20 font-normal text-emblue-text">
          {t('NPS_SCREEN.emptyScreenDescription1')}
        </Text>
        <Text className="text-center !text-20 font-normal text-emblue-text">
          {t('NPS_SCREEN.emptyScreenDescription2')}
        </Text>
        <Text className="text-center !text-20 font-normal text-emblue-text">
          {t('NPS_SCREEN.emptyScreenDescription3')}
        </Text>
        <Text className="text-center !text-20 font-normal text-emblue-text">
          {t('NPS_SCREEN.emptyScreenDescription4')}
          <button
            className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
            data-testid="nps-screen-empty-table-create-link"
            role="link"
            onClick={onClickEmptyScreenAction}
          >
            {t('NPS_SCREEN.emptyScreenCTA')}
          </button>
        </Text>
      </>
    );
  }, [navigate, t, userData.email]);

  return (
    <div className="h-full w-full pt-6 text-base text-emblue-subheadings 2xl:text-14">
      <Table
        noNegativeMargin
        classNamePagination="pr-4"
        columns={npsTableColumns}
        data={npsWidgets?.list ?? []}
        emptyScreen={{
          totalCount: npsWidgets?.countTotal ?? 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.widgets')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptySearchIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResultsWidgets')}
              onClickAction={resetWidgetsList}
            />
          ),
          noData: (
            <EmptyScreenTable
              description={isRecycleBin ? <></> : EmptyScreenTableDescription}
              image={isRecycleBin ? <EmptyRecycleBinIcon /> : <EmptyWidgetsIcon />}
              title={
                isRecycleBin
                  ? t('NPS_SCREEN.emptyScreenRecycleBin')
                  : t('NPS_SCREEN.emptyScreenTitle')
              }
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        setSelectedRows={setSelectedWidgets}
        toggleAllRowsSelectedValue={selectAllResults}
        withCheckbox={true}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
        onRowClick={(value) => !isRecycleBin && goToReportNps(value)}
      />
    </div>
  );
}
