import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/lib/v2/components';
import { ModalSingleInput } from '@/lib/v2/examples';

import { formValidations } from '@/src/constants/FormValidations';

import {
  atomDetailCampaign,
  atomFiltersCampaign,
  atomHistorySearchPagerCampaign,
  atomLoadingDetail,
  atomPagerCampaign,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateCampaignModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { IFormInput } from '@/modules/CampaignsModule/interfaces/Campaigns';

const TRANSLATE_MODAL = 'CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS';
const TRANSLATE_MODAL_DUPLICATE = 'CAMPAIGNS_MAIN.DUPLICATE_CAMPAIGNS';

interface IDuplicateCampaignsProps {
  onClose: (value?: boolean) => void;
}

const DuplicateCampaign = ({ onClose }: IDuplicateCampaignsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { duplicateCampaign } = useCampaign();
  const detailCampaign = useAtomValue(atomDetailCampaign);

  const loadingDetail = useAtomValue(atomLoadingDetail);
  const toggleDuplicateCampaignModal = useAtomValue(atomToggleDuplicateCampaignModal);
  const filters = useAtomValue(atomFiltersCampaign);
  const { inputLengths } = formValidations;
  const pager = useAtomValue(atomPagerCampaign);
  const [historySearchPagerCampaign, setHistorySearchPagerCampaign] = useAtom(
    atomHistorySearchPagerCampaign
  );

  const { fetchServiceValidName } = useValidNameResource();

  const VALIDATION_RULES = {
    input: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
      pattern: {
        value: formValidations.formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
      validate: async (value: string) => {
        const checkIsValidName = await fetchServiceValidName({
          name: value,
          resource: EResourceType.CAMPAIGN,
        });
        if (!checkIsValidName) {
          return t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign');
        }
      },
    },
  };

  const handleCloseModal = useCallback(() => onClose(), [onClose]);

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      const campaignIdDuplicate = await duplicateCampaign({
        name: nameValue,
        campaignId: Number(detailCampaign.campaignId),
      } as IFormInput);
      handleCloseModal();
      if (campaignIdDuplicate) {
        if (!historySearchPagerCampaign) {
          setHistorySearchPagerCampaign({
            filters: { ...filters },
            pager: {
              page: pager?.page || defaultPager.page,
              limit: pager?.limit || defaultPager.limit,
            },
          });
        }
        navigate(`/v2/campaigns/${campaignIdDuplicate}`);
      }
    },
    [
      duplicateCampaign,
      detailCampaign.campaignId,
      handleCloseModal,
      historySearchPagerCampaign,
      navigate,
      setHistorySearchPagerCampaign,
      filters,
      pager,
    ]
  );

  return loadingDetail ? (
    <Spinner />
  ) : (
    <ModalSingleInput
      alert={{
        description: t(`${TRANSLATE_MODAL_DUPLICATE}.description`),
        type: 'info',
      }}
      id="duplicate-campaign-modal"
      isOpen={toggleDuplicateCampaignModal}
      rules={VALIDATION_RULES}
      title={t(`${TRANSLATE_MODAL_DUPLICATE}.title`)}
      value={`${String(detailCampaign.name)} ${t(`${TRANSLATE_MODAL}.copy`)}`}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData}
    />
  );
};
export default DuplicateCampaign;
