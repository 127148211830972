import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DateInput,
  Flex,
  Input,
  InputTextArea,
  Modal,
  Select,
  Spinner,
  Text,
} from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';
import { Option } from '@/lib/v2/components/Select/Select';

import { ButtonActions } from './components/ButtonActions';
import { useCampaignForm } from './hooks/useCampaignForm';
import { useHandleEvents } from './hooks/useHandleEvents';
import {
  MODAL_TITLE_KEYS,
  STRATEGY_MAP_BY_ID,
  SUBMIT_BUTTON_KEYS,
  TRANSLATE_MODAL,
  TRANSLATE_MODAL_INPUTS,
} from './constants';

import {
  atomDetailCampaign,
  atomLoadingDetail,
  atomRefetchStrategyList,
  atomStrategyList,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomModalTypeProcess,
  atomToggleEditCreateCampaignModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useStrategies } from '@/modules/CampaignsModule/hooks/useStrategies';
import {
  IDefaultFormToCompareProps,
  IFormInput,
  ModalType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';
import { strategyOrderOtherOption } from '@/modules/CampaignsModule/utils';

interface ICreateEditCampaignsProps {
  onClose: (value?: boolean) => void;
}

const CreateEditDuplicateCampaigns = ({ onClose }: ICreateEditCampaignsProps) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    formState: { isValid },
  } = useForm<IFormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 500,
  });

  const toggleEditCategoryModal = useAtomValue(atomToggleEditCreateCampaignModal);
  const strategyList = useAtomValue(atomStrategyList);
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const loadingDetail = useAtomValue(atomLoadingDetail);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);
  const [refetchStrategyList, setRefetchStrategyList] = useAtom(atomRefetchStrategyList);

  const [defaultFormToCompare, setDefaultFormToCompare] = useState<
    IDefaultFormToCompareProps | undefined
  >(undefined);
  const [hasOtherStrategy, setHasOtherStrategy] = useState<boolean>(false);
  const [strategiesList, setStrategiesList] = useState<DropdownItemProps[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    detailCampaign?.startDate ? new Date(detailCampaign.startDate) : null,
    detailCampaign?.endDate ? new Date(detailCampaign.endDate) : null,
  ]);
  const [startDate, endDate] = dateRange;

  const { maxDate, minDateEmptyDefault, isUserStrategy, VALIDATION_RULES } = useCampaignForm({
    strategiesList,
  });
  const { getStrategies } = useStrategies();

  const { loading, handleChangeStrategy, handleCloseModal, handleSubmitData, handleOnChangeDate } =
    useHandleEvents({
      setHasOtherStrategy,
      setError,
      setDateRange,
      onClose,
      defaultFormToCompare,
    });

  const { otherStrategy: otherStrategyValue } = watch();

  const modalTitle = MODAL_TITLE_KEYS[modalTypeProcess] || `${TRANSLATE_MODAL}.titleModal`;
  const textSubmitButton =
    SUBMIT_BUTTON_KEYS[modalTypeProcess] || `${TRANSLATE_MODAL}.successButton`;

  useEffect(() => {
    if (otherStrategyValue) setValue('otherStrategy', otherStrategyValue);
  }, [setValue, otherStrategyValue]);

  useEffect(() => {
    if (refetchStrategyList) {
      void getStrategies();
      setRefetchStrategyList(RESET);
    }
  }, [refetchStrategyList, setRefetchStrategyList, getStrategies]);

  useEffect(() => {
    const hasOtherStrategyOption = true;
    const strategiesListCast = strategyOrderOtherOption(strategyList, hasOtherStrategyOption);
    setStrategiesList(strategiesListCast);
  }, [strategyList]);

  useEffect(() => {
    if (
      modalTypeProcess === ModalType.EDIT ||
      (modalTypeProcess === ModalType.DUPLICATE && Object.keys(detailCampaign).length > 0)
    ) {
      const { name, goal, startDate, endDate } = detailCampaign;

      const defaultFormToCompare: IDefaultFormToCompareProps = {
        name: name ?? '',
        objective: goal ?? '',
      };

      if (modalTypeProcess === ModalType.DUPLICATE) {
        setValue('name', `${String(name)} ${t(`${TRANSLATE_MODAL}.copy`)}`);
      } else {
        setValue('name', name ?? '');
      }

      setValue('objective', goal ?? '');

      if (startDate && endDate) {
        setDateRange([new Date(startDate), new Date(endDate)]);
        setValue('date', [new Date(startDate), new Date(endDate)]);
        defaultFormToCompare.date = [new Date(startDate), new Date(endDate)];
      }

      if (isUserStrategy?.id) {
        const strategyObject = {
          id: isUserStrategy?.id ?? '',
          name: t(
            `CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${STRATEGY_MAP_BY_ID[Number(isUserStrategy?.id)]}`,
            isUserStrategy?.label
          ),
          value: isUserStrategy?.value ?? '',
        };
        setValue('strategy', strategyObject);
        defaultFormToCompare.strategy = strategyObject;
      }

      setDefaultFormToCompare(defaultFormToCompare);
    }
    return () => {
      setDateRange([null, null]);
      reset();
    };
  }, [detailCampaign, isUserStrategy, setValue, t, reset, modalTypeProcess]);

  return (
    <Modal open={toggleEditCategoryModal} onClose={handleCloseModal}>
      <div className="flex flex-col gap-2 divide-y">
        <header>
          <Text as="h3" fontWeight="medium" variant="sub-headline">
            {t(modalTitle)}
          </Text>
        </header>
        <section className="flex w-full flex-col pt-6">
          {!loadingDetail ? (
            <form
              autoComplete="off"
              id="createEditCampaigns"
              onSubmit={handleSubmit(handleSubmitData)}
            >
              <div className="grid gap-2">
                <Input
                  isRequired
                  control={control}
                  id="name"
                  label={t(`${TRANSLATE_MODAL_INPUTS}.name.label`)}
                  name="name"
                  placeHolder={t(`${TRANSLATE_MODAL_INPUTS}.name.placeholder`)}
                  rules={VALIDATION_RULES.name}
                  type="text"
                />
                <div className="w-full">
                  <InputTextArea
                    isRequired
                    control={control}
                    id="objective"
                    label={t(`${TRANSLATE_MODAL_INPUTS}.objective.label`)}
                    name="objective"
                    placeHolder={t(`${TRANSLATE_MODAL_INPUTS}.objective.placeholder`)}
                    rows={3}
                    rules={VALIDATION_RULES.objective}
                  />
                </div>
                <Select
                  isRequired
                  control={control}
                  id="strategy"
                  label={t(`${TRANSLATE_MODAL_INPUTS}.strategy.label`)}
                  name="strategy"
                  options={strategiesList
                    .map(
                      (strategy): Option => ({
                        ...strategy,
                        name: t(
                          `CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${
                            STRATEGY_MAP_BY_ID[Number(strategy.id)]
                          }`,
                          strategy.label
                        ),
                        value: strategy.value,
                      })
                    )
                    .sort((a, b) => {
                      if (a.value === 'other') return 1;
                      if (b.value === 'other') return -1;
                      return String(a.name).localeCompare(String(b.name));
                    })}
                  placeholder={t(`${TRANSLATE_MODAL_INPUTS}.strategy.placeholder`)}
                  rules={VALIDATION_RULES.strategy}
                  onChange={handleChangeStrategy}
                />
                {hasOtherStrategy && (
                  <Input
                    isRequired
                    control={control}
                    id="otherStrategy"
                    label={t(`${TRANSLATE_MODAL_INPUTS}.otherStrategy.label`)}
                    name="otherStrategy"
                    placeHolder={t(`${TRANSLATE_MODAL_INPUTS}.otherStrategy.placeholder`)}
                    rules={VALIDATION_RULES.otherStrategy}
                    type="text"
                  />
                )}
                <DateInput
                  fullWidth
                  isRequired
                  selectsRange
                  control={control}
                  endDate={endDate}
                  id="date"
                  label={t(`${TRANSLATE_MODAL_INPUTS}.date.label`)}
                  maxDate={maxDate}
                  minDate={
                    modalTypeProcess === ModalType.CREATE
                      ? new Date()
                      : detailCampaign?.startDate
                      ? new Date(detailCampaign?.startDate)
                      : minDateEmptyDefault
                  }
                  name="date"
                  placeholder={t(`${TRANSLATE_MODAL_INPUTS}.date.placeholder`)}
                  rules={VALIDATION_RULES.date}
                  startDate={startDate}
                  onChange={handleOnChangeDate}
                />
              </div>
              <ButtonActions
                disabledSubmitButton={!isValid}
                isLoading={loading}
                textCancelButton={t(`${TRANSLATE_MODAL}.cancelButton`)}
                textSubmitButton={t(textSubmitButton)}
                onClose={handleCloseModal}
              />
            </form>
          ) : (
            <Flex alignment="center" className="min-h-[372px]">
              <Spinner withoutOverlay />
            </Flex>
          )}
        </section>
      </div>
    </Modal>
  );
};
export default CreateEditDuplicateCampaigns;
