/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { ChangeEvent, useCallback, useRef } from 'react';

import {
  currentErrorsSelector,
  currentFormSelector,
  currentPristineSelector,
  currentValidSelector,
  IField,
  IFormConfig,
  IRegister,
  TValue,
} from '@/lib/v2/atoms/Form.atoms';
type CallbackFunction<K> = (data: IField<K>) => void;

export const useForm = <T>(name: string, config: IFormConfig<T>) => {
  // form data
  const selector = currentFormSelector({ name, config });
  const [formData, setFormData] = useAtom(selector);
  // validation of form
  const selectorIsValid = currentValidSelector({ name, config });
  const [isValid] = useAtom(selectorIsValid);
  // errors
  const selectorErrors = currentErrorsSelector({ name, config });
  const [errors] = useAtom(selectorErrors);
  // errors
  const selectorPristine = currentPristineSelector({ name });
  const [pristine] = useAtom(selectorPristine);

  const referenceCallbacks = useRef<Record<string, any>>({});

  const handleChange = useCallback(
    <T>(fieldName: string, e: ChangeEvent<HTMLInputElement> | T) => {
      const eAux = e as ChangeEvent<HTMLInputElement>;

      if (
        typeof e === 'object' &&
        e !== null &&
        'target' in e &&
        typeof e.target.value === 'undefined' &&
        typeof e.target.checked === 'undefined'
      ) {
        return;
      }

      const value =
        eAux?.target?.value !== undefined
          ? eAux.target.value
          : typeof e === 'string'
          ? e
          : e || undefined;
      const checked = eAux?.target?.checked !== undefined ? eAux.target.checked : undefined;

      const field = formData?.[fieldName as keyof typeof formData] || {};
      const item = {
        ...(config?.[fieldName] ?? {}),
        ...(field ?? {}),
      } as IField<T>;

      if (item?.maxLength && typeof value === 'string' && value.length > item.maxLength)
        return item;

      const payload = {
        [fieldName]: {
          ...item,
        },
      };

      if (eAux instanceof Date && value) {
        payload[fieldName].date = value as TValue;
      } else if (eAux.target?.type === 'checkbox') {
        payload[fieldName].checked = checked || false;
      } else {
        payload[fieldName].value = value as T;
      }

      setFormData(payload);
      watchDispatcher<T>(fieldName, payload[fieldName]);
    },
    [config, formData, setFormData]
  );

  const register = useCallback(
    <B>(fieldName: string): IRegister<B> => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { pattern, required, validate, ...field } = (formData?.[fieldName] ?? {}) as IField<B>;
      const onChange = (evt: ChangeEvent<HTMLInputElement> | B) => handleChange<B>(fieldName, evt);

      if (!field) {
        const {
          pattern,
          validate,
          checked,
          value,
          onChange: onFilter,
          defaultValue,
          ...element
        } = config?.[fieldName] ?? {};

        const props: IRegister<B> = {
          ...element,
          onChange: (evt) => {
            const result =
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              typeof onFilter === 'function' ? onFilter(evt, config?.[fieldName]) : evt;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            onChange(result);
          },
          defaultValue: defaultValue as B,
        };

        if (typeof checked === 'boolean' || typeof defaultValue === 'boolean') {
          props.checked = (
            typeof checked !== 'undefined' ? checked : defaultValue ?? ''
          ) as boolean;
          props.value = (value ? value : undefined) as B;
        } else {
          props.value = (typeof value !== 'undefined' ? value : defaultValue ?? '') as B;
        }

        return props;
      }

      const errorField = errors?.[fieldName as keyof typeof errors] as unknown as
        | string
        | undefined;

      const { defaultValue, checked, value, onChange: onFilter } = field;

      const props: IRegister<B> = {
        ...field,
        name: fieldName,
        placeholder: config?.[fieldName]?.placeholder,
        onChange: (evt) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const result = typeof onFilter === 'function' ? onFilter(evt, field) : evt;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onChange(result);
        },
        error: errorField ? true : false,
        message: typeof errorField === 'string' ? errorField : '',
      };

      if (typeof checked === 'boolean' || typeof defaultValue === 'boolean') {
        props.checked = (typeof checked !== 'undefined' ? checked : defaultValue ?? '') as boolean;
        props.value = (value ? value : undefined) as B;
      } else {
        props.value = (typeof value !== 'undefined' ? value : defaultValue ?? '') as B;
      }

      return props;
    },
    [config, errors, formData, handleChange]
  );

  const getFieldValue = useCallback(
    <K>(key: string) => {
      if (typeof formData[key]?.checked === 'boolean') return formData[key]?.checked as K;

      if (formData[key]?.date instanceof Date) return formData[key]?.date as K;

      return (
        typeof formData[key]?.value !== 'undefined'
          ? formData[key]?.value
          : formData[key]?.defaultValue
      ) as K;
    },
    [formData]
  );

  const setFieldValue = useCallback(
    <K>(key: string, value: K | TValue) => {
      const payload = {
        [key]: {
          ...formData[key],
        },
      };
      if (typeof payload[key]?.checked === 'boolean') {
        payload[key].checked = value;
        return setFormData(payload);
      }

      if (payload[key]?.date instanceof Date) {
        payload[key].date = value;
        return setFormData(payload);
      }

      payload[key].value = value;
      setFormData(payload);
    },
    [formData, setFormData]
  );

  const watch = useCallback((fieldName: string, callback: CallbackFunction<any>) => {
    referenceCallbacks.current[fieldName] = callback;
  }, []);

  const watchDispatcher = <K>(fieldName: string, data: IField<K>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const callback = referenceCallbacks.current[fieldName];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    typeof callback === 'function' && callback(data);
  };

  const clear = () => {
    setFormData(RESET);
  };

  return {
    formData,
    register,
    isValid,
    getFieldValue,
    setFieldValue,
    watch,
    clear,
    errors,
    pristine,
  };
};
