import { Dispatch, memo, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { InsightCardProps } from '@/lib/v2/examples/InsightCard/InsightCard';
import { EmBlueIcon, SegmentationIcon, ShoppingCartIcon } from '@/lib/v2/icons/solid';

import { IStatusList } from '@/src/ContactsModule/interfaces/Interfaces';
import { SegmentsSwitcherEnum } from '@/src/ContactsModule/utils/enums/SegmentsSwitcher';

import { FilterCardsList } from '@/modules/ContactsModule/components';
import { SegmentTotalCards } from '@/modules/ContactsModule/hooks/useSegmentMain';

interface CardsFilterSegmentsProps {
  emblueSegments: SegmentTotalCards;
  statusList?: IStatusList[];
  onAction: Dispatch<SetStateAction<string>> | ((value: string) => void);
}

const CardsFilterSegments = ({
  statusList = [],
  emblueSegments,
  onAction,
}: CardsFilterSegmentsProps) => {
  const { t } = useTranslation();

  const { segmentsCustomTotal, segmentsEcommerceTotal, segmentsRecipeTotal } = emblueSegments;

  const cardList = useMemo<(InsightCardProps & { filter: string })[]>(
    () => [
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="success"
            svgComponent={<SegmentationIcon />}
            withPadding="medium"
          />
        ),
        description: t('SEGMENTS_CARDS.customized'),
        title: segmentsCustomTotal.total,
        tooltip: t('SEGMENTS_CARDS.customized'),
        isLoading: segmentsCustomTotal.isLoading,
        filter: SegmentsSwitcherEnum.Customized,
        onClick: () => {
          onAction(SegmentsSwitcherEnum.Customized);
        },
        id: 'customized-card',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="primary"
            svgComponent={<EmBlueIcon />}
            withPadding="medium"
          />
        ),
        description: t('SEGMENTS_CARDS.emBlueRecipes'),
        title: segmentsRecipeTotal.total,
        tooltip: t('SEGMENTS_CARDS.emBlueRecipes'),
        isLoading: segmentsRecipeTotal.isLoading,
        filter: SegmentsSwitcherEnum.EmBlueRecipes,
        onClick: () => {
          onAction(SegmentsSwitcherEnum.EmBlueRecipes);
        },
        id: 'emBlueRecipes-card',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="sleepy"
            svgComponent={<ShoppingCartIcon />}
            withPadding="medium"
          />
        ),
        description: t('SEGMENTS_CARDS.ecommerce'),
        title: segmentsEcommerceTotal.total,
        tooltip: t('SEGMENTS_CARDS.ecommerce'),
        isLoading: segmentsEcommerceTotal.isLoading,
        filter: SegmentsSwitcherEnum.ECommerce,
        onClick: () => {
          onAction(SegmentsSwitcherEnum.ECommerce);
        },
        id: 'eCommerce-card',
      },
    ],
    [
      onAction,
      segmentsCustomTotal.isLoading,
      segmentsCustomTotal.total,
      segmentsEcommerceTotal.isLoading,
      segmentsEcommerceTotal.total,
      segmentsRecipeTotal.isLoading,
      segmentsRecipeTotal.total,
      t,
    ]
  );

  return (
    <FilterCardsList
      cardsList={cardList}
      className="lg:!grid-cols-3"
      keyFilter="filter"
      statusList={statusList}
      titleIsLoading={
        segmentsCustomTotal.isLoading ||
        segmentsRecipeTotal.isLoading ||
        segmentsEcommerceTotal.isLoading
      }
    />
  );
};

export default memo(CardsFilterSegments);
