import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Position } from 'reactflow';

import { IconSvg, Text } from '@/lib/v2/components';
import { CubeIcon } from '@/lib/v2/icons/outline';

import useIsConditionFormComplete from '@/src/modules/RulesModule/components/ConditionNode/hooks/useIsConditionNodeComplete';
import InitialNode from '@/src/modules/RulesModule/components/InitialNode';
import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PRESENCE_OPTIONS,
} from '@/src/modules/RulesModule/constants';
import { RulesOptions } from '@/src/modules/RulesModule/interfaces';

import { atomRuleData } from '@/modules/RulesModule/atoms/rules';

const ConditionNodeContent = () => {
  const { t } = useTranslation('rules');

  const rulesData: RulesOptions = useAtomValue(atomRuleData);
  const isComplete = useIsConditionFormComplete(rulesData);

  if (Object.keys(rulesData).length === 0) {
    return (
      <div className="mb-2 flex w-full animate-pulse flex-col gap-2 pt-1">
        <div className="h-3 w-20 rounded-full bg-gray-200"></div>
        <hr />
        <div className="h-3 w-20 rounded-full bg-gray-200"></div>
        <div className="h-3 w-36 rounded-full bg-gray-200"></div>
      </div>
    );
  }

  if (!isComplete) {
    return <InitialNode title={t(`${TRANSLATE_RULES_LABELS}.condition`)} />;
  }

  const presenceText = rulesData?.presenceCondition?.value
    ? t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.group`)
    : t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.segment`);

  const conditionName = rulesData?.presenceCondition?.name || '';
  const groupOrSegmentName = rulesData?.selectedGroupOrSegment?.[0]?.name?.toString() || '';

  return (
    <>
      <div className="mb-2 flex items-center">
        <IconSvg
          fillColor="primary"
          height="24px"
          id="cube"
          svgComponent={<CubeIcon />}
          width="24px"
        />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {t(`${TRANSLATE_RULES_LABELS}.condition`)}
        </Text>
      </div>
      <hr className="w-full min-w-64" />
      <div className="flex flex-col justify-center pb-4 pt-2">
        <Text fontWeight="medium" variant="text">
          {presenceText}
        </Text>
        <Text fontWeight="medium" variant="text-sm">
          {`${conditionName} : ${groupOrSegmentName}`}
        </Text>
      </div>
      <Handle className="handle" position={Position.Top} type="target" />
      <Handle className="handle" position={Position.Bottom} type="source" />
    </>
  );
};

export default memo(ConditionNodeContent);
