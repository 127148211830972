import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { ColorsIcon } from '@/lib/icon/types';
import { IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { InsightCard } from '@/lib/v2/examples';
import { CalculatorIcon, CartIcon, RfmCartIcon } from '@/lib/v2/icons/solid';
import { EcommerceVtexIcon } from '@/lib/v2/icons/solid/EcommerceVtexIcon';

import { useECommerceSummary } from '@/src/application/hooks/useECommerceSummary';
import { useIntegrationTabVisibility } from '@/src/application/hooks/useIntegrationTabVisibility';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { IContactCustomFields } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';
import { IContactECommerceActivity } from '@/src/ContactsModule/interfaces/Interfaces';
import { ECommerceEnum } from '@/src/ContactsModule/utils/enums/Enums';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useContactInfo } from '@/src/infrastructure/services/useContactInfo';

import { ContactsProfileECommerceTable } from './ContactsProfileECommerceTable';

import { BannerCardsProps } from '@/modules/ContactsModule/screens/ContactProfile/components/ContactsProfileActivityTables/ContactsProfileChannelTable/ContactsProfileActivityChannelTable';

export const ContactsProfileActivityECommerceTable = ({ emailId }: { emailId: number }) => {
  const { integrationId } = useIntegrationTabVisibility([
    ECommerceEnum.VTEX,
    ECommerceEnum.PRESTASHOP,
    ECommerceEnum.MAGENTO2,
    ECommerceEnum.SHOPIFY,
  ]);
  const [contactInfo] = useContactInfo(Number(emailId));
  const [activityVtexListValue] = useEmblue(ServiceMethods.getContactsActivityECommerceVtex, {
    integrationId: ECommerceEnum.VTEX,
    email: contactInfo && contactInfo.email,
  });
  const [activityECommerceListValue] = useEmblue(ServiceMethods.getContactsActivityECommerce, {
    integrationId: integrationId,
    email: contactInfo && contactInfo.email,
  });
  const [activityECommerceList, setActivityECommerceList] = useState<{
    list: IContactECommerceActivity[] | [];
  }>();
  const [customFields] = useEmblue(ServiceMethods.getContactCustomFields, {
    emailId: Number(emailId),
  });

  useEffect(() => {
    if (activityVtexListValue && integrationId === ECommerceEnum.VTEX) {
      setActivityECommerceList(activityVtexListValue);
    }
    if (activityECommerceListValue && integrationId !== ECommerceEnum.VTEX) {
      setActivityECommerceList(activityECommerceListValue);
    }
  }, [activityVtexListValue, activityECommerceListValue, integrationId]);

  const cleanBoxRef = useRef<CleanBoxRef>(null);

  const resetActivityList = () => {
    cleanBoxRef?.current?.cleanBox();
  };

  const { t } = useTranslation();

  const getRFMValues = (item: IContactCustomFields[]) => {
    const rfmFields =
      item &&
      item.filter((e) => e.name === 'recency' || e.name === 'frequency' || e.name === 'amount');
    return rfmFields && rfmFields.map((e) => (e.value ? e.value : 0)).join('/');
  };

  const ecommerceClassification =
    customFields &&
    customFields?.contactCustomFields.find((e) => e.name === 'eCommerceClassification');

  const { totalPurchases, averageAmount } = useECommerceSummary(activityECommerceList);

  const statusList: BannerCardsProps[] = useMemo(() => {
    const buildTotalPurchasesCard = () => {
      return {
        value: totalPurchases,
        description: t('PROFILE_ECOMMERCE_TABLE.totalPurchases'),
        icon: <IconSvg height={'28px'} svgComponent={<CalculatorIcon />} width={'28px'} />,
        iconBgColor: 'success' as Exclude<ColorsIcon, 'white'>,
        tooltip: '',
      };
    };

    const buildAveragePurchasesCard = () => {
      return {
        value: averageAmount,
        description: t('PROFILE_ECOMMERCE_TABLE.averagePurchases'),
        icon: <IconSvg height={'28px'} svgComponent={<CartIcon />} width={'28px'} />,
        iconBgColor: 'success' as Exclude<ColorsIcon, 'white'>,
        tooltip: '',
      };
    };

    const buildVtexIntegrationCard = () => {
      return {
        value: ecommerceClassification?.value
          ? ecommerceClassification?.value
          : t('PROFILE_ECOMMERCE_TABLE.withoutCategory'),
        description: t('PROFILE_ECOMMERCE_TABLE.integratedByVtex'),
        icon: <IconSvg height={'28px'} svgComponent={<EcommerceVtexIcon />} width={'28px'} />,
        iconBgColor: 'insecure' as Exclude<ColorsIcon, 'white'>,
        tooltip: t('PROFILE_ECOMMERCE_TABLE.tooltipRF'),
      };
    };

    const buildRfmCard = () => {
      return {
        value: 'RFM',
        description: getRFMValues(customFields?.contactCustomFields || []),
        icon: <IconSvg height={'28px'} svgComponent={<RfmCartIcon />} width={'28px'} />,
        iconBgColor: 'primary' as Exclude<ColorsIcon, 'white'>,
        tooltip: t('PROFILE_ECOMMERCE_TABLE.rfmMoreInformation'),
      };
    };

    const commonItems = [buildTotalPurchasesCard(), buildAveragePurchasesCard()];
    if (integrationId === ECommerceEnum.VTEX) {
      return [buildVtexIntegrationCard(), buildRfmCard(), ...commonItems];
    }
    return commonItems;
  }, [
    integrationId,
    totalPurchases,
    t,
    averageAmount,
    ecommerceClassification?.value,
    customFields?.contactCustomFields,
  ]);

  return (
    <>
      {
        <div className="h-full w-full pb-5 pt-2 text-base text-[#364365] 2xl:text-14">
          <div
            className={`grid w-full grid-cols-1 flex-wrap gap-2 sm:grid-cols-2 md:grid-cols-2 ${
              integrationId !== ECommerceEnum.VTEX ? 'xl:grid-cols-2' : 'xl:grid-cols-4'
            } `}
          >
            {statusList.map((card) => (
              <InsightCard
                key={card.description}
                noShadow
                description={card.description}
                icon={
                  <IconSvg
                    rounded
                    bgColor={card.iconBgColor}
                    height="60px"
                    svgComponent={card.icon}
                    width="60px"
                    withPadding="large"
                  />
                }
                id={card.description}
                isLoading={typeof card.value === 'undefined'}
                title={quantifier(card.value) ?? 0}
                titleColor="primary"
                tooltipInterrogation={card.tooltip}
              />
            ))}
          </div>
        </div>
      }
      <Flex column>
        {!activityECommerceList && (
          <span className="h-full w-full">
            <TableSkeleton />
          </span>
        )}
      </Flex>
      {activityECommerceList && (
        <ContactsProfileECommerceTable
          activityCount={activityECommerceList ? activityECommerceList.list.length : 0}
          activityECommerceList={activityECommerceList ? activityECommerceList.list : []}
          callToAction={[resetActivityList]}
        />
      )}
    </>
  );
};
