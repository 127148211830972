import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomRecipientOptions } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  EGroupAndListsTypeV1MapSms,
  EGroupAndListsTypeV1MapString,
  IAddresseesList,
  IAddresseesPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { IConditionForm } from '@/src/modules/RulesModule/components/FormCondition/hooks/useFormCondition';

interface IRecipientsRulesProps {
  setValue?: UseFormSetValue<IConditionForm>;
}

const PAYLOAD_RECIPIENTS: IAddresseesPayload = {
  offset: 0,
  groupQuantity: 20,
  filterType: {
    user: true,
    profile: true,
    integration: true,
    interest: true,
  },
};

export const useRecipientsRules = ({ setValue }: IRecipientsRulesProps = {}) => {
  const [loading, setLoading] = useState(false);
  const [recipientsOptions, setRecipientOptions] = useAtom(atomRecipientOptions);
  const [recipientListPayload, setRecipientListPayload] =
    useState<IAddresseesPayload>(PAYLOAD_RECIPIENTS);
  const [recipientsSearch, setRecipientsSearch] = useState<string>('');
  const [hasMoreRecipient, setHasMoreRecipient] = useState<boolean>(false);

  const campaignService = useCampaignsService();

  const getRecipientsOptions = useCallback(
    ({ addresseesList }: { addresseesList: IAddresseesList[] }): OptionExternal[] => {
      return addresseesList.map((address) => ({
        id: Number(address.groupId),
        name: String(address.name).trim() || '-',
        value: address.type,
        metadata: {
          alt: `(${address.contactCount})`,
        },
      }));
    },
    []
  );

  const getRecipients = useCallback(
    async (payload: IAddresseesPayload) => {
      if (!payload) return;

      setLoading(true);

      const newOffset = payload.offset || 0;
      const response = await campaignService.getAddressees(payload);
      const addresseesOptionsDropdown = getRecipientsOptions({ addresseesList: response });

      setRecipientOptions((prevOptions) =>
        newOffset === 0 ? addresseesOptionsDropdown : [...prevOptions, ...addresseesOptionsDropdown]
      );

      setHasMoreRecipient(response.length === payload.groupQuantity);
      setLoading(false);
    },
    [campaignService, getRecipientsOptions, setRecipientOptions]
  );

  //cspell:disable
  const handleChangeRecipients = useCallback(
    (value: OptionExternal | OptionExternal[]) => {
      const selectedItems = Array.isArray(value) ? value : [value];
      const groups = selectedItems.map((item) => ({
        id: Number(item.id),
        nombre: String(item.name),
        tipo: EGroupAndListsTypeV1MapSms[item.value as keyof typeof EGroupAndListsTypeV1MapSms],
      }));

      const recipients = groups
        .map((group) => {
          const groupType =
            EGroupAndListsTypeV1MapString[
              group.tipo.toUpperCase() as keyof typeof EGroupAndListsTypeV1MapString
            ];
          return {
            id: group.id,
            name: String(group.nombre).trim() || '-',
            value: groupType,
          };
        })
        .filter(
          (group) =>
            group.value === EGroupAndListsTypeV1MapString.USUARIO ||
            group.value === EGroupAndListsTypeV1MapString.PERFIL
        );

      setValue && setValue('selectedGroupOrSegment', recipients || []);
    },
    [setValue]
  );
  //cspell:enable

  const handleLoadLastRecipients = useCallback(
    async (option: OptionExternal) => {
      if (!hasMoreRecipient) return;

      if (option.id === recipientsOptions[recipientsOptions.length - 1].id) {
        const payloadOffset = {
          ...recipientListPayload,
          offset: recipientsOptions.length,
        };

        await getRecipients(payloadOffset);
        setRecipientListPayload(payloadOffset);
      }
    },
    [getRecipients, hasMoreRecipient, recipientListPayload, recipientsOptions]
  );

  const handleCloseRecipients = useCallback(() => {
    if (recipientsSearch.length === 0) return;

    const payloadOffset = { ...recipientListPayload, search: '', offset: 0 };

    void getRecipients(payloadOffset);
    setRecipientListPayload(payloadOffset);
    setHasMoreRecipient(true);
  }, [getRecipients, recipientListPayload, recipientsSearch.length]);

  return {
    events: {
      getRecipients,
      handleChangeRecipients,
      handleCloseRecipients,
      handleLoadLastRecipients,
    },
    setters: {
      setRecipientsSearch,
    },
    state: {
      loading,
      recipientsOptions,
      recipientListPayload,
    },
  };
};
