import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';

import { BlockModal, Button } from '@/lib/components';
import { ArrowCircleIcon, LogoutIcon } from '@/lib/icon';
import { RocketBlockModalIcon } from '@/lib/icon/RocketBlockModalIcon';
import { FloatModal } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import configData from '@/src/config.json';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';

import { useCanChangePlan } from '@/modules/MyPlanModule/hooks/useCanChangePlan';

export const FreeTrialDemo = ({
  showExtendsDemoButton,
  setBlockModal,
  navigate,
}: {
  showExtendsDemoButton: boolean;
  setBlockModal: Dispatch<SetStateAction<IFreeTrialDemo>>;
  navigate: NavigateFunction;
}) => {
  const { t } = useTranslation();
  const service = useService();
  const { canChangePlan } = useCanChangePlan('Free Trial');
  const [userData] = useUserData();

  const logoutHandler = useCallback(async () => {
    await service?.logout();
    setTimeout(() => {
      navigate('/Account/login');
      navigate(0);
    }, 500);
  }, [service, navigate]);

  const choosePlan = useCallback(() => {
    navigate('/v2/my_plan/manage_plan');
  }, [navigate]);

  const setExtraDay = useCallback(async () => {
    await service?.SetExtraDaysForFreeTrialDemo();
    setBlockModal((prevState) => ({ ...prevState, isBlocked: false }));
    document.body.style.overflow = 'scroll';
  }, [service, setBlockModal]);

  useEffect(() => {
    window.parent.scroll({ top: 0, behavior: 'smooth' });
    document.body.style.overflow = 'hidden';
  }, []);

  if (window.location.pathname.includes('v2/my_plan/manage_plan')) {
    document.body.style.overflow = 'scroll';
    return null;
  }

  const mailtoHandler = () => {
    const subject = t('MODAL_BLOCK_FREE_TRIAL_DEMO.MailSubject');
    window.open(`mailto:${configData.HOME.supportEmail}?Subject=${subject} ${userData.contractId}`);
  };

  return (
    <FloatModal>
      <BlockModal>
        <BlockModal.Body>
          <div className="h-full px-12">
            <Flex withGap gapSize="large">
              <div className="w-[90%] max-w-[300px]">
                <RocketBlockModalIcon />
              </div>
              <div>
                <BlockModal.Title>{t('MODAL_BLOCK_FREE_TRIAL_DEMO.Title')}</BlockModal.Title>
                <div className="size-full text-[1.3em]">
                  <p className="contents w-11/12 text-[#919296]">
                    {t('MODAL_BLOCK_FREE_TRIAL_DEMO.Body')}
                  </p>
                  <span className="contents text-emblue-blue-dark"> emBlue </span>
                  <p className="contents text-[#919296]">
                    {' '}
                    {t('MODAL_BLOCK_FREE_TRIAL_DEMO.Body2')}
                  </p>
                  <p className="contents w-11/12 text-emblue-blue-dark">
                    {' '}
                    {t('MODAL_BLOCK_FREE_TRIAL_DEMO.Body3')}
                  </p>
                  <p className="contents text-[#919296]">
                    {' '}
                    {t('MODAL_BLOCK_FREE_TRIAL_DEMO.Body4')}
                  </p>
                </div>
              </div>
            </Flex>
          </div>
        </BlockModal.Body>
        <BlockModal.SideBar>
          <button
            className="absolute right-8 top-5 cursor-pointer hover:bg-slate-300"
            onClick={logoutHandler}
          >
            <LogoutIcon />
          </button>
          <div className="pt-2 text-center text-21 font-medium">
            {t('MODAL_BLOCK_FREE_TRIAL_DEMO.SubTitle')}
            <p className="text-emblue-blue-dark">emBlue</p>
          </div>
          <div className="w-64">
            {canChangePlan ? (
              <Button fullWidth Icon={<ArrowCircleIcon />} onAction={choosePlan}>
                {t('MODAL_BLOCK_FREE_TRIAL_DEMO.ChoosePlanBtn')}
              </Button>
            ) : (
              <p className="pt-4">
                {t('MODAL_BLOCK_FREE_TRIAL_DEMO.contractReview')}
                <a
                  className="cursor-pointer text-emblue-blue-dark hover:underline"
                  onClick={() => mailtoHandler()}
                >
                  {' '}
                  {configData.HOME.supportEmail}.
                </a>
              </p>
            )}
          </div>
          <>
            {showExtendsDemoButton && (
              <button
                className="text-emblue-blue-dark underline"
                onClick={() => void setExtraDay()}
              >
                {t('MODAL_BLOCK_FREE_TRIAL_DEMO.ExtraDaysButton')}
              </button>
            )}
          </>
        </BlockModal.SideBar>
      </BlockModal>
    </FloatModal>
  );
};
