/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable prefer-regex-literals */
/* eslint-disable regexp/no-unused-capturing-group */
/* cspell:disable */

import he from 'he';
import parse from 'html-react-parser';

import { removeScriptHTML } from './removeScriptHTML';

export const stringKey = '<param id="emblueHtmlUploader">';

export const prepareHtml = (html: string) => {
  const BODY_REGEX = /(<body)(>)|(<body)(.+)(>)/gi;
  const HEAD_BODY_REGEX = /<\/head\b[^<]*<body/gi;
  let originalBodyTag: RegExpMatchArray | null = null;
  let htmlModify = html;

  if (html && html.includes('##')) {
    htmlModify = cleanLinks(html, htmlModify);
  }

  if (HEAD_BODY_REGEX.test(htmlModify)) {
    originalBodyTag = htmlModify.match(BODY_REGEX);
    htmlModify = htmlModify.replace(HEAD_BODY_REGEX, `</head> ${stringKey} <body`);
  }

  return { preparedHtml: htmlModify, originalBodyTag: originalBodyTag ? originalBodyTag[0] : null };
};

export const assembleHtml = (html: string, originalBodyTag: string | null, emptyEditor: string) => {
  const MIDLE_LABEL = /\w(<param id="emblueHtmlUploader">)/gi;
  const isEmptyEditor = emptyEditor === 'true';

  if ((isEmptyEditor && MIDLE_LABEL.test(html)) || !html.includes(stringKey)) {
    html = html.replace(stringKey, '');
    html = stringKey + html;
  }
  // add border-color to tables display html
  if (html.includes('<table ')) {
    html = addBorderToTable(html);
  }

  if (html.includes(stringKey)) {
    html = removeScriptHTML(html);
    // Replace tag <param id="emblueHtmlUploader"> with </head> and originalBodyTag
    if (originalBodyTag) {
      html = html.replace(stringKey, `</head>${originalBodyTag}`);
    } else {
      html = html.replace(stringKey, '</head><body>');
    }
    // Complete html structure
    html = `<head>${html}</body>`;
  } else {
    html = he.encode(html, {
      useNamedReferences: true,
      allowUnsafeSymbols: true,
    });
    html = `<head></head><body>${html}</body>`;
  }
  return html;
};

export const stringToReactDOM = (string: string) => parse(string);

export const replaceHexCharacters = (html: string) => {
  return html.replaceAll(/&amp;/g, '&').replaceAll(/&#x9;/g, '');
};

export const verifyHttpInLinks = (html: string) => {
  const htmlVerified = replaceHexCharacters(html);
  return htmlVerified.replace(
    /href="(?!mailto:)(?!http:)(?!https:)(?!tel:)(?!\$)([^"]+)"/g,
    (match, url) => {
      const verify = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/.test(url);
      if (verify) {
        return `href="mailto:${url}"`;
      } else if (!url.startsWith('$') && !url.startsWith('tel:')) {
        return `href="http://${url}"`;
      }
      return match;
    }
  );
};

export const cleanLinks = (html: string, htmlModify: string) => {
  html = html.replace(/##/g, '');
  const aTag = [...html.matchAll(new RegExp('<a ', 'gi'))].map((a) => a.index);
  htmlModify = html;
  aTag.forEach((element) => {
    const link = html.substring(element!, html.indexOf('</a>', element) + 4);
    const id = link.match(/id=(.\d*)/);
    if (id) {
      const idTransform = id[1].substring(1);
      const regex = `,${idTransform}`;
      const finalLink = link.replace(regex, '');
      htmlModify = htmlModify.replace(link, finalLink);
    }
  });

  return htmlModify;
};

export const addBorderToTable = (html: string) => {
  return html.replace(
    /<td[^>]+style="[^"]*border-color:([^;]+);[^"]*"[^>]*>/g,
    (match, borderColor) =>
      match.replace(/border-color:[^;]+;/, `border: 1px solid ${borderColor};`)
  );
};

export const cleanerHTML = (html: string) => {
  const el = document.createElement('div');
  el.innerHTML = html;

  const texto = el.textContent || '';
  el.remove();
  return texto;
};
