import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import { Card, IconSvg, Text } from '@/lib/v2/components';
import { EmailIcon } from '@/lib/v2/icons/solid';

import {
  TRANSLATE_RULES_ACTION_FIELD_TRIGGER,
  TRANSLATE_RULES_ACTION_TYPES,
} from '@/src/modules/RulesModule/constants';

const ActionNode = ({ id }: NodeProps) => {
  const { t } = useTranslation('rules');

  return (
    <Card className="w-72 !px-4 pb-0 hover:outline-none hover:ring-2 hover:ring-emblue-primary hover:ring-offset-2">
      <div className="mb-3 flex items-center gap-6 pl-3">
        <IconSvg
          height="32px"
          id="email-icon"
          svgComponent={<EmailIcon />}
          width="32px"
          withPadding="small"
        />
        <div className="flex flex-col">
          <Text fontWeight="semibold" variant="text">
            {t(`${TRANSLATE_RULES_ACTION_TYPES}.sendEmail`)}
          </Text>
          <Text fontWeight="medium" variant="text-sm">
            {t(`${TRANSLATE_RULES_ACTION_FIELD_TRIGGER}.nextDueDate`)}
          </Text>
        </div>
      </div>
      <Handle className="handle" position={Position.Top} type="target" />
    </Card>
  );
};

export default memo(ActionNode);
