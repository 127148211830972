import { Outlet } from 'react-router-dom';

import { Container, If } from '@/lib/v2/components';
import { SecondaryNavigationMenu } from '@/lib/v2/components/SecondaryNavigationMenu';
import { useNavigationBar } from '@/lib/v2/hooks/useNavigationBar';

import { DeprecatedBanner } from '@/src/modules/CampaignsModule/components/DeprecatedBanner';
import { useDeprecatedBanner } from '@/src/modules/CampaignsModule/hooks/useDeprecatedBanner';

const CampaignsLayout = () => {
  const { campaignsNavigationLinks } = useNavigationBar();

  // TODO remove this when the feature flag showDragDropRemovalNotice is removed
  const { showDeprecatedDragDropForPath } = useDeprecatedBanner();

  return (
    <>
      <SecondaryNavigationMenu links={campaignsNavigationLinks} />
      <If condition={showDeprecatedDragDropForPath}>
        <DeprecatedBanner isNegativeMargin />
      </If>
      <Container fullHeight fluid="screen">
        <Outlet />
      </Container>
    </>
  );
};

export default CampaignsLayout;
