import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Button, Card, CardSection, Flex, IconSvg, Text } from '@/lib/v2/components';
import { RenameItem } from '@/lib/v2/examples';
import { useRenameModal } from '@/lib/v2/examples/RenameItem/hooks/useRenameModal';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';
import { EditPencilIcon } from '@/lib/v2/icons/outline';
import { CampaignsIcon, ReportIcon as ReportIconSolid } from '@/lib/v2/icons/solid';

import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import useMessage from '@/src/compat/useMessage';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomHistorySearchPagerActions } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { useEditActions } from '@/src/modules/CampaignsModule/components/CampaignsModal/EditActions/hooks/useEditActions';
import { CAMPAIGNS_PATH } from '@/src/modules/CampaignsModule/constants';
import { useCampaignActions } from '@/src/modules/CampaignsModule/hooks/useCampaignActions';
import { ActionTypeName } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export type OutletContextActionsLayoutProps = {
  showSpinnerByAction: boolean;
  showErrorCampaign: boolean;
  campaignId: string;
};

const ActionsLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();
  const service = useCampaignsService();
  const showRecurrentMessage = useFeatureFlag('showRecurrentMessage');

  const isActionsPath = useMemo(() => {
    const validRoutes: ActionTypeName[] = [
      'master',
      'email',
      'email-attachment',
      'email-trigger',
      'mail-api',
      'sms',
      'sms-api',
    ];

    return validRoutes.some((route) => route === actionType);
  }, [actionType]);

  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const historySearchPagerAction = useAtomValue(atomHistorySearchPagerActions);

  const [showSpinnerByAction, setShowSpinnerByAction] = useState(true);
  const [showErrorCampaign, setShowErrorCampaign] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasReport, setHasReport] = useState(false);

  const { VALIDATION_RULES, isLoadingDebounce } = useEditActions(
    infoCampaignV1?.action?.name,
    Number(campaignId)
  );

  const { editAction, loading } = useCampaignActions();

  const { position, editNameModalIsOpen, setEditNameModalIsOpen, handleOpenEditNameModal } =
    useRenameModal();

  const haveData = useMemo(() => infoCampaignV1?.campaign?.name, [infoCampaignV1]);

  const handleApplyRename = useCallback(
    async (nameValue: string) => {
      const statusOK = await editAction({ name: nameValue, actionId: Number(actionId) });

      if (statusOK) {
        setInfoCampaignV1({
          ...infoCampaignV1,
          action: {
            id: Number(actionId),
            name: nameValue,
          },
        });
      }
      setEditNameModalIsOpen(false);
    },
    [actionId, editAction, infoCampaignV1, setEditNameModalIsOpen, setInfoCampaignV1]
  );

  useMessage(EventListenerType.Loaded, (_) => {
    showSpinnerByAction && setIsLoaded(true);
  });

  useEffect(() => {
    const type = actionType as ActionTypeName;
    const isAutomatic = type === 'master' || infoCampaignV1?.campaign?.isAutomatic;
    isLoaded &&
      showSpinnerByAction &&
      SendActionsPostMessage('Campaign', 'ViewCampaign', {
        id: isAutomatic ? campaignId : actionId,
        type,
        isAutomatic: isAutomatic,
        campaignName: infoCampaignV1?.campaign?.name,
      });
  }, [showSpinnerByAction, isLoaded, actionId, actionType, infoCampaignV1, campaignId]);

  useMessage(EventListenerType.LoadedCampaign, (_, payload) => {
    if (isLoaded && showSpinnerByAction) {
      if (!haveData) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        // cspell:disable
        setInfoCampaignV1({
          ...infoCampaignV1,
          campaign: {
            id: parseInt(campaignId || '0'),
            name: payload.campaniaNombre,
          },
          action: {
            id: Number(actionId),
            name: payload.nombre,
          },
        });
      }
      setShowSpinnerByAction(false);
    }
  });

  useMessage(EventListenerType.ErrorCampaign, (_) => {
    setShowSpinnerByAction(false);
    setShowErrorCampaign(true);
  });

  useMessage(EventListenerType.RedirectConfiguration, (_) => {
    navigate && navigate(`/v2/configuration`);

    SendActionsPostMessage('Campaign', 'ViewGroupTest', {});
  });

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useMessage(EventListenerType.DeleteAction, async (_) => {
    if (!actionId) return;
    await service.deleteMailContent(+actionId);
    SendActionsPostMessage('Action', 'SuccessDeleteAction', {});
  });

  useMessage(EventListenerType.GoToConfiguration, (_) => {
    navigate && navigate(`/v2/configuration`);

    SendActionsPostMessage('goToConfiguration', 'redirected');
  });

  useMessage(EventListenerType.OpenBeefree, (_) => {
    navigate && navigate(`${window.location.href.replace(window.location.origin, '')}/editor`);
  });

  useMessage(EventListenerType.OpenHtmlEditor, (_) => {
    navigate && navigate(`${window.location.href.replace(window.location.origin, '')}/html-editor`);
  });

  useEffect(() => {
    setTimeout(() => {
      SendActionsPostMessage('Campaign', 'VerifyLoaded');
    }, 500);
  }, []);

  const getAction = useCallback(async () => {
    const id: number = parseInt(actionId || '0');

    if (actionType?.includes('sms')) return;

    const response = await service.getActionByID(id);

    if (response.action?.report) setHasReport(true);
  }, [actionId, actionType, service]);

  useEffect(() => {
    if (actionType?.includes('sms') && hasReport) setHasReport(false);

    void getAction();
  }, [actionType, getAction, hasReport]);

  const handleBack = useCallback(() => {
    if (actionType === 'master') {
      showRecurrentMessage
        ? navigate(`/v2/campaigns/recurrent/${campaignId}/`)
        : navigate(`/v2/campaigns/${campaignId}`);
    } else {
      navigate(`${infoCampaignV1?.route ?? '/v2/campaigns'}`, {
        replace: true,
        state: { from: 'action-detail', data: { ...historySearchPagerAction } },
      });
    }
  }, [historySearchPagerAction, infoCampaignV1, navigate]);

  const handleReport = useCallback(() => {
    navigate(`/v2/campaigns/${campaignId}/${actionType}/${actionId}/report`);
  }, [actionId, actionType, campaignId, navigate]);

  const actionsButtons = useMemo(
    () => (
      <>
        <Button
          outline
          secondary
          iconLeft={
            <IconSvg
              fillColor="gray-dark"
              height="16px"
              svgComponent={<ReportIconSolid />}
              width="16px"
            />
          }
          id="report-button"
          size="small"
          onClick={() => handleReport()}
        >
          {t('REPORT')}
        </Button>
      </>
    ),
    [handleReport, t]
  );

  if (!isActionsPath) {
    return <Navigate to={CAMPAIGNS_PATH} />;
  }

  const [right, top] = position;

  return (
    <Card>
      <CardSection noPadding>
        <Flex column>
          <SectionHeader
            backButton
            sticky
            actionsButtons={hasReport ? actionsButtons : null}
            icon={!showErrorCampaign ? <CampaignsIcon /> : null}
            isLoading={showSpinnerByAction}
            titleNode={
              !showErrorCampaign && (
                <Text
                  as="h3"
                  className="!2xl:text-20 !text-16"
                  fontWeight="medium"
                  variant="title-1"
                >
                  <Flex withGap>
                    {infoCampaignV1?.campaign?.name}
                    {actionType !== 'master' && (
                      <>
                        {' / '}
                        <Text color="primary">{infoCampaignV1?.action?.name}</Text>
                        <Button
                          link
                          className="!h-auto !p-0"
                          iconLeft={
                            <IconSvg height="16px" svgComponent={<EditPencilIcon />} width="16px" />
                          }
                          id="back-to-campaigns-button"
                          onClick={handleOpenEditNameModal}
                        />
                      </>
                    )}
                  </Flex>
                </Text>
              )
            }
            onBack={handleBack}
          />
          <Outlet
            context={
              {
                showSpinnerByAction,
                showErrorCampaign,
                campaignId,
              } as OutletContextActionsLayoutProps
            }
          />
        </Flex>
      </CardSection>
      {editNameModalIsOpen && (
        <RenameItem
          actionButtonText={t('ITEMS_FILTERS.apply')}
          id="rename-action"
          isLoading={loading}
          isLoadingExternal={isLoadingDebounce}
          isOpen={editNameModalIsOpen}
          placeholder={t('CAMPAIGNS_ACTIONS.renameAction')}
          rightClick={right}
          rules={VALIDATION_RULES}
          title={t('ITEMS_FILTERS.rename')}
          topClick={top}
          value={infoCampaignV1?.action?.name}
          onActionButton={handleApplyRename}
          onClose={() => setEditNameModalIsOpen(false)}
        />
      )}
    </Card>
  );
};

export default ActionsLayout;
