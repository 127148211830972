import { atom } from 'jotai';

import { FormTypes, IRulesV1Response, RulesOptions } from '@/src/modules/RulesModule/interfaces';

export const atomToggleSidePanel = atom<boolean>(false);
atomToggleSidePanel.debugLabel = 'atomToggleSidePanel';

export const atomCurrentFormType = atom<FormTypes | null>(null);
atomCurrentFormType.debugLabel = 'atomCurrentFormType';

export const atomRuleData = atom<RulesOptions>({});
atomRuleData.debugLabel = 'atomRuleData';

export const atomRuleDataRaw = atom<IRulesV1Response | null>(null);
atomRuleDataRaw.debugLabel = 'atomRuleDataRaw';
