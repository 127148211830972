import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import { useAtomValue } from 'jotai';
import { memo, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Card, CardSection, IconSvg, If, Text } from '@/lib/v2/components';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  CreateCheckoutBody,
  NewBillingPeriod,
} from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { PlanType } from '@/src/infrastructure/interfaces/IResponses';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { numberToCurrency } from '@/src/utils/currency';

import { DownGradeStatus } from './DownGradeStatus';
import ModalInformation from './ModalInformation';

import {
  atomBillingInformation,
  atomContractDetail,
} from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { BILLING_FORM_DATA_KEY } from '@/modules/MyPlanModule/constants';
import { useDowngradeStatus } from '@/modules/MyPlanModule/hooks/useDowngradeStatus';

interface HeaderMyPlanProps {
  contractId?: number;
  businessName?: string;
  isLoading?: boolean;
}

const HeaderMyPlan = ({ contractId, businessName, isLoading }: HeaderMyPlanProps) => {
  const service = useService();
  const { t } = useTranslation();
  const { paymentInfo } = useStateUserContext();
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [moreInformationIsOpen, setMoreInformationIsOpen] = useState(false);
  const { downgradeIsPending } = useDowngradeStatus();
  const contractDetail = useAtomValue(atomContractDetail);
  const billingInformation = useAtomValue(atomBillingInformation);
  const autoDebitFF = useFeatureFlag('automaticDebitStripeButton');

  const handleClickMoreInformation = useCallback(() => {
    setMoreInformationIsOpen(true);
  }, []);

  const canAddToAutoDebit = useMemo(() => {
    if (contractDetail && contractDetail?.planName && !billingInformation?.hasStripeSubscription) {
      if (contractDetail.planName === 'Advance' || contractDetail.planName === 'Starter') {
        return autoDebitFF && true;
      }
    }
    return false;
  }, [billingInformation?.hasStripeSubscription, contractDetail, autoDebitFF]);

  const handleDebit = useCallback(async () => {
    setIsLoadingCheckout(true);
    const mapPlanNameByPlanId = {
      Starter: 'plan-starter',
      Advance: 'plan-advance',
    };

    const planId = (contractDetail?.planName as 'Starter' | 'Advance') ?? 'Starter';
    const body: CreateCheckoutBody = {
      mode: 'subscription',
      billingData: {
        businessName: billingInformation?.businessName ?? '',
        fantasyName: billingInformation?.fantasyName ?? '',
        rut: billingInformation?.rut ?? '',
        address: billingInformation?.address ?? '',
        city: billingInformation?.city ?? '',
        country: Number(billingInformation?.country),
        billingContact: billingInformation?.billingContact ?? '',
        billingEmail: billingInformation?.billingEmail ?? '',
      },
      planId: mapPlanNameByPlanId[planId],
      planName: contractDetail?.planName as PlanType,
      embedded: false,
      period: contractDetail?.billingPeriod as NewBillingPeriod,
      volume: {
        email: contractDetail?.planDetail.email ?? 10000,
        onsite: contractDetail?.planDetail.onsite ?? 10000,
        sms: contractDetail?.planDetail.sms ?? 0,
      },
    };
    const { url } = await service.createCheckoutSession(body);
    localStorage.setItem(BILLING_FORM_DATA_KEY, JSON.stringify(body));
    setIsLoadingCheckout(false);
    window.open(url, '_self');
  }, [
    billingInformation?.address,
    billingInformation?.billingContact,
    billingInformation?.billingEmail,
    billingInformation?.businessName,
    billingInformation?.city,
    billingInformation?.country,
    billingInformation?.fantasyName,
    billingInformation?.rut,
    contractDetail?.billingPeriod,
    contractDetail?.planDetail.email,
    contractDetail?.planDetail.onsite,
    contractDetail?.planDetail.sms,
    contractDetail?.planName,
    service,
  ]);

  return (
    <>
      <Card>
        <CardSection paddingX="medium">
          <div className="flex items-center justify-between gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                {!isLoading ? (
                  <Text fontWeight="medium" variant="sub-headline">
                    {businessName}
                  </Text>
                ) : (
                  <div className="my-1 h-4 w-32 animate-pulse rounded-full bg-gray-200"></div>
                )}
                {paymentInfo && paymentInfo?.amount === 0 && (
                  <div className="flex items-center gap-1">
                    <IconSvg fillColor="success" svgComponent={<CheckCircleIcon />} />
                    <Text isItalic variant="text">
                      {t('MY_PLAN.BILLING.noDebts')}
                    </Text>
                  </div>
                )}
                {paymentInfo && paymentInfo?.amount !== 0 && (
                  <div className="flex items-center gap-1">
                    <IconSvg fillColor="passive" svgComponent={<ExclamationIcon />} />
                    <Text isItalic variant="text">
                      <Trans
                        components={{
                          l: (
                            <Text
                              link
                              color="primary"
                              fontWeight="medium"
                              href={paymentInfo.payUrl}
                              isItalic={false}
                              target="_blank"
                              variant="text"
                            />
                          ),
                        }}
                        i18nKey={'MY_PLAN.BILLING.debitAlert'}
                        values={{
                          amount: `${paymentInfo.currency} ${numberToCurrency(paymentInfo.amount)}`,
                        }}
                      ></Trans>
                    </Text>
                  </div>
                )}
              </div>
              <If condition={!isLoading}>
                <Text fontWeight="medium" variant="text">
                  {t('MY_PLAN.BILLING.contractName', {
                    contractId,
                    company: billingInformation?.fantasyName,
                  })}
                </Text>
              </If>
              <If condition={isLoading}>
                <div className="my-1 h-[13px] w-40 animate-pulse rounded-full bg-gray-200"></div>
              </If>
            </div>
            <div className="flex items-center gap-8">
              <If condition={downgradeIsPending}>
                <DownGradeStatus />
              </If>
              <div className="flex flex-none gap-4">
                <If condition={canAddToAutoDebit}>
                  <Button isLoading={isLoadingCheckout} onClick={handleDebit}>
                    {t('MY_PLAN.BILLING.debitAuto')}
                  </Button>
                </If>
                <Button
                  outline
                  secondary
                  classNameContainer="flex-none"
                  disabled={isLoading}
                  onClick={handleClickMoreInformation}
                >
                  {t('MY_PLAN.BILLING.moreInfo')}
                </Button>
              </div>
            </div>
          </div>
        </CardSection>
      </Card>
      <ModalInformation isOpen={moreInformationIsOpen} onClose={setMoreInformationIsOpen} />
    </>
  );
};

export default memo(HeaderMyPlan);
