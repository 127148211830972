import { useAtom } from 'jotai';
import { memo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { TopBar } from '@/lib/components';
import { Button, IconSvg } from '@/lib/v2/components';
import { DeleteIcon } from '@/lib/v2/icons/outline';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { atomFreeTrial } from '@/src/atoms/FreeTrial';
import { FreeTrialDemo } from '@/src/presentation/components/FreeTrialDemo';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';

export interface AppLayoutProps {
  userData: UserData;
}

const AppLayout = ({ userData }: AppLayoutProps) => {
  const showBlockModalFreeTrialDemo = useFeatureFlag('showBlockModalFreeTrialDemo');
  const [freeTrialBlockState, setFreeTrialBlockState] = useAtom<IFreeTrialDemo>(atomFreeTrial);
  const navigate = useNavigate();
  const cookieFEC = document.cookie
    .split('; ')
    .find((row) => row.startsWith('FEC='))
    ?.split('=')[1];

  const deleteCommitId = () => {
    document.cookie = 'FEC=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.embluemail.com';
    window.location.href.includes('UI=')
      ? window.location.replace(window.location.origin)
      : window.location.reload();
  };

  return (
    <>
      <TopBar
        accountId={userData.companyId}
        companyName={userData.companyName}
        days={freeTrialBlockState.counter}
        email={userData.email}
        name={userData.name}
      />
      {cookieFEC && (
        <Button
          destructive
          className="!h-12 !w-12 rounded-full"
          classNameContainer="fixed bottom-3 left-3 z-[1000]"
          iconLeft={
            <IconSvg
              className="!h-6 !w-6"
              fillColor="white"
              strokeColor="destructive"
              svgComponent={<DeleteIcon />}
            />
          }
          id="delete-commit-id"
          tooltip={`You have an active commit version ${cookieFEC}, to see the latest version click here.`}
          onClick={deleteCommitId}
        />
      )}
      <Outlet />
      {showBlockModalFreeTrialDemo && freeTrialBlockState?.isBlocked && (
        <FreeTrialDemo
          navigate={navigate}
          setBlockModal={setFreeTrialBlockState}
          showExtendsDemoButton={freeTrialBlockState.showExtendsDemoButton}
        />
      )}
    </>
  );
};

export default memo(AppLayout);
