import { useAtom, useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, If } from '@/lib/v2/components';

import {
  atomBillingFormLocal,
  atomCurrentStep,
  atomPlansInformation,
} from '@/src/modules/MyPlanModule/atoms/ManagePlan';
import { BILLING_FORM_DATA_KEY } from '@/src/modules/MyPlanModule/constants';
import { useBilling } from '@/src/modules/MyPlanModule/hooks/useBilling';
import { usePlansInformation } from '@/src/modules/MyPlanModule/hooks/usePlansInformation';
import { useQueryParamsCheckoutCompleted } from '@/src/modules/MyPlanModule/hooks/useQueryParamsCheckoutCompleted';
import { BillingDataPayment } from '@/src/modules/MyPlanModule/types';

import { StepOneManagePlan } from './components/StepOneManagePlan';
import {
  HeaderManagePlan,
  PaymentStatus,
  StepThreeManagePlan,
  StepTwoManagePlan,
} from './components';

const ManagePlan = () => {
  const { success, cancel } = useQueryParamsCheckoutCompleted();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useBilling();
  const [currentStep, setCurrentStep] = useAtom(atomCurrentStep);
  const { planInformationIsLoading, plans } = usePlansInformation();
  const setPlansInformation = useSetAtom(atomPlansInformation);
  const setBillingFormLocal = useSetAtom(atomBillingFormLocal);

  useEffect(() => {
    if (!planInformationIsLoading && plans) {
      setPlansInformation(plans);
    }
  }, [planInformationIsLoading, plans, setPlansInformation]);

  const handleOnBackClick = useCallback(() => {
    localStorage.removeItem(BILLING_FORM_DATA_KEY);
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    const formData = localStorage.getItem(BILLING_FORM_DATA_KEY);
    if ((success || cancel) && formData) {
      setBillingFormLocal(JSON.parse(formData) as BillingDataPayment);
      setCurrentStep(0);
    } else {
      setCurrentStep(1);
    }
  }, [cancel, setBillingFormLocal, setCurrentStep, success]);

  const ManagePlanPaths = useMemo(
    () => [
      {
        name: t('BREADCRUMB.billingAndConsumption'),
        to: {
          path: '/v2/my_plan/billing_and_consumption',
        },
      },
      {
        name: t('BREADCRUMB.managePlan'),
      },
    ],
    [t]
  );

  return (
    <div className="min-h-[calc(100vh_-_64px)] w-full bg-emblue-white">
      <Breadcrumb customPaths={ManagePlanPaths} handleOnBackClick={handleOnBackClick} />
      <If condition={currentStep !== 0}>
        <HeaderManagePlan />
      </If>
      <div className="bg-emblue-white p-6">
        <If condition={currentStep === 1}>
          <StepOneManagePlan />
        </If>
        <If condition={currentStep === 2}>
          <StepTwoManagePlan />
        </If>
        <If condition={currentStep === 3}>
          <StepThreeManagePlan />
        </If>
        <If condition={currentStep === 0 && (success || cancel)}>
          <PaymentStatus success={success} />
        </If>
      </div>
    </div>
  );
};

export default memo(ManagePlan);
