import { timeout } from '@/src/compat/util';
import { GenericServiceResponse } from '@/src/infrastructure/Protocol/ServiceInterfaces';

import {
  AddresseesInfoMock,
  AddresseesListMock,
  EmailInfoMock,
  EmailPreviewMock,
} from './Campaigns.mocks';
import { ICampaignsService } from './ICampaignsService';

import {
  ConfirmEmailErrors,
  IAddresseesInfoResponse,
  IAddresseesList,
  IAddresseesPayload,
  ICalendarActionsPayload,
  ICalendarCampaignActions,
  ICampaignActionsListPayload,
  ICampaignsActionsResponse,
  ICheckNameResource,
  IDeliveryActionPayload,
  IDeliveryActionResponse,
  IDuplicateCampaignActions,
  IDuplicateCampaignActionsResponse,
  IEditActionPayload,
  IEditActionResponse,
  IEmailInfoPayload,
  IEmailInfoResponse,
  IEmailPreview,
  ISetDeliveryActionPayload,
  ISetDeliveryActionResponse,
  ITouchRulesResponse,
  IUpdateStatusAction,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  IActionByIdResponse,
  ICampaignDetailResponse,
  ICampaignListPayload,
  ICampaignRecurrentCreated,
  ICampaignsListResponse,
  ICampaignsRecurrentListResponse,
  ICampaignsStrategiesResponse,
  ICreateEditCampaigns,
  ICreateEditCampaignsRecurrentResponse,
  ICreateEditCampaignsResponse,
  IDuplicateCampaigns,
  IDuplicateCampaignsResponse,
  IListSearchCampaigns,
  ISearchCampaignsGlobal,
  TActionID,
  TCampaignID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

export class StubCampaignsService implements ICampaignsService {
  static create(): ICampaignsService {
    return new StubCampaignsService();
  }

  /**
   * @param query: { campaign: ICreateEditCampaigns }
   * @returns {Promise<ICreateEditCampaignsResponse>}
   * @description Create new campaign
   */
  async createCampaign(query: {
    campaign: ICreateEditCampaigns;
  }): Promise<ICreateEditCampaignsResponse> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param query: { campaign: ICreateEditCampaigns }
   * @returns {Promise<ICreateEditCampaignsResponse>}
   * @description Create new recurrent campaign
   */
  async createCampaignRecurrent(query: {
    campaignRecurrent: ICreateEditCampaigns;
  }): Promise<ICreateEditCampaignsRecurrentResponse> {
    try {
      await timeout(2000);
      return { statusOK: true, recurrentCreated: {} as ICampaignRecurrentCreated };
    } catch (error) {
      return { statusOK: false, recurrentCreated: {} as ICampaignRecurrentCreated };
    }
  }

  /**
   * @param query: { campaign: ICreateEditCampaigns }
   * @returns {Promise<ICreateEditCampaignsResponse>}
   * @description Create new campaign
   */
  async getStrategiesByAccount(): Promise<ICampaignsStrategiesResponse> {
    try {
      await timeout(2000);
      return { statusOK: true, strategies: [] };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param {}
   * @returns {Promise<any>}
   * @description Get list campaigns
   */
  async getCampaign(query: ICampaignListPayload): Promise<ICampaignsListResponse> {
    try {
      await timeout(2000);
      return { campaigns: [] };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param {}
   * @returns {Promise<any>}
   * @description Get list recurrent messages
   */
  async getRecurrentMessage(query: ICampaignListPayload): Promise<ICampaignsRecurrentListResponse> {
    try {
      await timeout(2000);
      return { campaigns: [] };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param {}
   * @returns {Promise<any>}
   * @description Get list campaigns
   */

  async getCampaignByID(campaignId: TCampaignID): Promise<ICampaignDetailResponse> {
    try {
      await timeout(2000);
      return { statusOK: false, campaign: {} };
    } catch (error) {
      return { statusOK: false, campaign: {} };
    }
  }

  //TODO: Next sprint implement search, filters & pagination
  async getActionsByCampaign(
    query: ICampaignActionsListPayload
  ): Promise<ICampaignsActionsResponse> {
    try {
      await timeout(2000);
      return { actions: [], total: 10, partial: 10 };
    } catch (error) {
      return { statusOK: false, actions: [] };
    }
  }

  async editAction(query: IEditActionPayload): Promise<IEditActionResponse> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  async getActionByID(campaignID: TActionID): Promise<IActionByIdResponse> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param query: {ICheckNameResource}
   * @returns {Promise<boolean>}
   * @description Validate name if exist in campaign, action or segment
   */
  async checkNameResource(query: ICheckNameResource): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<ITouchRulesResponse>}
   * @description Retrieve the configured touch rules information for an account
   */
  async getTouchRules(): Promise<ITouchRulesResponse> {
    try {
      await timeout(2000);
      return { days: 2, emails: 4 };
    } catch (error) {
      return { days: 0, emails: 0 };
    }
  }

  /**
   * @returns {Promise<IEmailInfoResponse>}
   * @description Retrieve the configured touch rules information for an account
   */
  async getEmailInfo(actionID: TActionID): Promise<IEmailInfoResponse | null> {
    try {
      await timeout(2000);
      return EmailInfoMock;
    } catch (error) {
      return null;
    }
  }
  /**
   * @returns {Promise<IAddresseesInfoResponse>}
   * @description Retrieve the configured touch rules information for an account
   */
  async getAddresseesInfo(actionID: TActionID): Promise<IAddresseesInfoResponse | null> {
    try {
      await timeout(2000);
      return AddresseesInfoMock;
    } catch (error) {
      return null;
    }
  }

  /**
   * @returns {Promise<IDeliveryActionResponse>}
   * @description Retrieve the configured delivery information for an action
   */
  async getDeliveryAction(query: IDeliveryActionPayload): Promise<IDeliveryActionResponse> {
    try {
      await timeout(2000);
      return {
        isImmediateShipping: true,
        shippingDate: '2022-01-01',
        reinforcement: {
          isReinforcementShipment: true,
          startDate: '2024-02-26T06:00:00.000Z',
          subject: 'Hello ${1#name} $RT{3#3º most recent} $T{52564#manager} $RT{2#2º most recent}',
        },
      };
    } catch (error) {
      return {
        isImmediateShipping: false,
        shippingDate: '',
        reinforcement: { isReinforcementShipment: false, startDate: '', subject: '' },
      };
    }
  }

  /**
   * @returns {Promise<ISetDeliveryActionResponse>}
   * @description Retrieve the message of the configured delivery information for an action
   */
  async setDeliveryAction(query: ISetDeliveryActionPayload): Promise<ISetDeliveryActionResponse> {
    try {
      await timeout(2000);
      return {
        message: 'The action was saved',
        campaignElementId: 1,
        shippingElementId: 1,
      };
    } catch (error) {
      return {
        message: "The action wasn't saved",
        campaignElementId: 0,
        shippingElementId: 0,
      };
    }
  }

  /**
   * @returns {Promise<IAddresseesPayload>}
   * @description Return segments, groups and tags by account
   */
  async getAddressees(query: IAddresseesPayload): Promise<IAddresseesList[]> {
    try {
      await timeout(2000);
      return AddresseesListMock;
    } catch (error) {
      return [];
    }
  }

  /**
   * @returns {Promise<IAddresseesPayload>}
   * @description Return segments, groups and tags by account
   */
  async setInfoAction(query: IEmailInfoPayload, actionID: TActionID): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<IAddresseesPayload>}
   * @description Return segments, groups and tags by account
   */
  async setStateAction(query: IUpdateStatusAction): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description confirm mail to send/scheduling
   */
  async confirmMail(actionID: TActionID): Promise<GenericServiceResponse<ConfirmEmailErrors>> {
    try {
      await timeout(2000);
      return { success: true };
    } catch (error) {
      return { success: false };
    }
  }

  /**
   * @returns {Promise<IAddresseesPayload>}
   * @description delete mail content
   */
  async deleteMailContent(id: number): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description pause mail to scheduling
   */
  async pauseMail(actionID: TActionID): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }
  /**
   * @returns {Promise<ICalendarCampaignActions[]>}
   * @description get campaign & actions by date
   */
  async getCalendarActions(payload: ICalendarActionsPayload): Promise<ICalendarCampaignActions[]> {
    try {
      await timeout(2000);
      return {} as ICalendarCampaignActions[];
    } catch (error) {
      return {} as ICalendarCampaignActions[];
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description search campaigns or actions by name or id
   */
  async globalSearchCampaigns(query: ISearchCampaignsGlobal): Promise<IListSearchCampaigns[]> {
    try {
      await timeout(2000);
      return {} as IListSearchCampaigns[];
    } catch (error) {
      return {} as IListSearchCampaigns[];
    }
  }

  /**
   * @returns {Promise<IEmailPreview>}
   * @description returns the preview of an email based on a test contact's email ID
   */
  async getPreviewWithEmailID(actionID: TActionID, emailID: number): Promise<IEmailPreview | null> {
    try {
      await timeout(2000);
      return EmailPreviewMock;
    } catch (error) {
      return null;
    }
  }

  /**
   * @returns {Promise<IDuplicateCampaignActionsResponse>}
   * @description duplicate actions
   */
  async duplicateActions(
    payload: IDuplicateCampaignActions
  ): Promise<IDuplicateCampaignActionsResponse> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ duplicateActions');
      return { statusOK: true, actionIdDuplicate: payload.campaignActionId };
    } catch (error) {
      console.error(`❌ EM_Error duplicateActions:`, error);
      return { statusOK: false, actionIdDuplicate: 0 };
    }
  }

  /**
   * @returns {Promise<IDuplicateCampaignsResponse>}
   * @description duplicate campaigns
   */
  async duplicateCampaigns(payload: IDuplicateCampaigns): Promise<IDuplicateCampaignsResponse> {
    try {
      await timeout(2000);
      console.log('🚀 ~ StubEmblueService ~ duplicateCampaigns');
      return { statusOK: true, campaignId: payload.campaignId };
    } catch (error) {
      console.error(`❌ EM_Error duplicateCampaigns:`, error);
      return { statusOK: false, campaignId: 0 };
    }
  }
}
