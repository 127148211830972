import { atom } from 'jotai';

import { SmartFinderView } from '@/lib/v2/components/SmartFinder/SmartFinder.interface';

export const atomShowSmartFinder = atom<boolean>(false);

export const atomShowModalSignal = atom<string>('');

export const atomSmartFinderView = atom<SmartFinderView>('principal');

export const atomFoundsSmartFinder = atom({});
