import { Dispatch, MouseEventHandler, ReactElement, SetStateAction } from 'react';

import { IconSvgProps } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select/Select';

import { ICampaignActions } from './CampaignActions';

export type ActionTypeName =
  | 'master'
  | 'email'
  | 'email-trigger'
  | 'sms'
  | 'email-attachment'
  | 'mail-api'
  | 'sms-api';

export type ActionTypeId = 1 | 3 | 8 | 9 | 4 | 5 | 7;

export enum EFilterKeysCampaign {
  Search = 'search',
  State = 'state',
  Strategy = 'strategy',
  Date = 'date',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum EFilterKeysAction {
  Search = 'search',
  Status = 'status',
  Channel = 'channel',
  Date = 'date',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum EFilterKeysRecurrentMessage {
  Search = 'search',
  State = 'state',
  Date = 'date',
  StartDate = 'startDate',
  EndDate = 'endDate',
  CampaignTypeId = 'campaignTypeId',
}

export interface IFilterValueTypes {
  [EFilterKeysCampaign.Search]: string;
  [EFilterKeysCampaign.State]: number[];
  [EFilterKeysCampaign.Strategy]: number[];
  [EFilterKeysCampaign.Date]?: Date[];
}

export interface IFilterValueTypesAction {
  [EFilterKeysAction.Search]: string;
  [EFilterKeysAction.Status]: number[];
  [EFilterKeysAction.Channel]: number[];
  [EFilterKeysAction.Date]?: Date[];
}

export interface IFilterValueTypesRecurrentMessage {
  [EFilterKeysRecurrentMessage.Search]: string;
  [EFilterKeysRecurrentMessage.State]: number[];
  [EFilterKeysRecurrentMessage.Date]?: Date[];
}

export type ITablePager = {
  pageIndex: number;
  pageSize: number;
};

//TODO: Update naming convention
export interface IChildProps {
  children: React.ReactElement;
}

export type TCampaignID = number;
export type TActionID = number;

export interface ICampaign {
  campaignId: TCampaignID;
  name: string;
  strategy: string | null;
  strategyId: number | null;
  state: number;
  creationDate: string;
  lastUpdate: string;
  duration: number;
  audience: string | number | null;
  actions: number;
  campaignTypeId: ECampaignsTypeOptions;
}

export interface ICampaignRecurrentCreated {
  message: string;
  campaignId: TCampaignID;
  actionId: TActionID;
}

export interface IRecurrenceMessage {
  campaignId: TCampaignID;
  name: string;
  state: number;
  creationDate: string;
  audience: string | number | null;
  campaignTypeId: ECampaignsTypeOptions;
  recurrenceId: number;
  executionDate: string;
}

export interface ITableCampaignsRecurrent {
  campaign: IRecurrenceMessage;
}

export interface IActionCampaign {
  campaignId: TCampaignID;
  name: string;
  channel: number;
  audience: string | number | null;
  actionId: TActionID;
  report: boolean;
  status: number;
}

export interface ITotalsCampaign {
  total: number;
  partial: number;
}

export interface IFiltersTableCampaign {
  [key: string]: string | number | Date | number[] | undefined;
  search?: string;
  state?: number[];
  strategy?: number[];
  startDate?: Date;
  endDate?: Date;
}

export interface IPager {
  page: number;
  limit: number;
}

interface ICreatorUser {
  email: string;
  id: number;
  lastName: string;
  name: string;
}

export interface ICampaignDetail {
  name?: string;
  goal?: string | undefined;
  strategyName?: string | undefined;
  state?: number;
  creatorUser?: ICreatorUser;
  startDate?: string;
  endDate?: string;
  campaignId?: TCampaignID;
  campaignTypeId?: number;
}

export interface ICampaignDetailV1 {
  campaign: {
    id: number;
    name: string;
    isAutomatic?: boolean;
  };
  action?: {
    id: number;
    name: string;
    channel?: string;
  };
  route?: string;
}

export interface ICampaignDetailResponse {
  statusOK?: boolean;
  campaign: ICampaignDetail;
}

export interface ICampaignDetailResult {
  result: ICampaignDetail[];
}

export interface IStrategy {
  strategyId: number;
  name: string;
}
export interface IStrategyDropdown {
  id: number;
  label: string;
  value: number;
}
export interface ICampaignInfoMain {
  count: number;
  loading: boolean;
}

export interface ITableCampaigns {
  campaign: ICampaign;
}
export interface ICampaignsListResponse {
  statusOK?: boolean;
  campaigns?: ITableCampaigns[];
  total?: number;
  partial?: number;
}

export interface ICampaignsRecurrentListResponse {
  statusOK?: boolean;
  campaigns?: ITableCampaignsRecurrent[];
  total?: number;
  partial?: number;
}

export interface ICampaignsStrategiesResponse {
  statusOK?: boolean;
  strategies?: IStrategy[];
}

export enum EStrategyOptionsName {
  MARKETING = 'Marketing',
  SALES = 'Sales',
  REMARKETING = 'Remarketing',
  NURTURING = 'Nurturing',
  LEAD_GENERATION = 'Lead-generation',
  LEAD_RECOVERY = 'Lead-recovery',
  CROSS_SELLING = 'Cross-selling',
  UP_SELLING = 'Up-selling',
  LAUNCH = 'Launch',
}

export enum EStates {
  ACTIVE = 1,
  INACTIVE = 0,
}

export interface IStateOption {
  id: number;
  label: string;
  value: number;
}

export interface IGroupedStateOption extends Omit<IStateOption, 'value'> {
  values: number[];
}

export enum EActionStatusAvailable {
  CONFIRMABLE_DRAFT = 2,
  DRAFT = 1,
}

export enum ECampaignsTypeOptions {
  MANUAL = 1,
  AUTOMATIC = 2,
}

export enum EMaxMinLength {
  MIN_LENGTH = 2,
  NAME_MAX = 60,
  OBJECTIVE_MAX = 150,
  OTHER_STRATEGY_MAX = 30,
}

export enum EChannelsID {
  EMAIL = 1,
  SMS = 8,
  EMAIL_TRIGGER = 3,
  EMAIL_ATTACHMENT = 9,
  EMAIL_API = 4,
  SMS_API = 5,
}

export interface IActionState {
  type: 'draft' | 'active' | 'finished' | 'inactive';
  values: number[];
  badge: 'inactive' | 'success' | 'frequent';
  background: 'inactive' | 'success_new' | 'frequent';
}

export interface CreateCampaignForm {
  name: string;
  objective: string;
  strategy: Option | Option[];
  otherStrategy: string;
  date: Date[];
}

export interface IFormInput extends IFormCampaign {
  campaignId?: number;
  name: string;
  type: number;
  objective: string;
  strategy: Option | Option[];
  otherStrategy: string;
  date: Date[];
}

export interface IDefaultFormToCompareProps {
  name?: string;
  objective?: string;
  date?: [Date | null, Date | null];
  strategy?: Option;
}

export interface IFormAction {
  name: string;
}

export interface IFormCampaign {
  campaignId?: number;
}

export interface ICreateEditCampaigns {
  campaignId?: number;
  name: string;
  campaignStatesId: number;
  deletionDate?: Date | undefined;
  campaignTypeId: number;
  flowId: number | null;
  autoConfirmation: number;
  notifyGroupId: number;
  strategyId: number | null;
  strategyName: string | null;
  goal: string | null;
  startDate: Date | null;
  endDate: Date | null;
}

export interface ICreateCampaign {
  name: string;
  templateId: number;
  campaignType: number;
}

export interface ICreateCampaignResponse {
  statusOK: boolean;
  campaignId: number;
  actionId: number;
}

export interface IDuplicateCampaignsV1 {
  campaignId: number;
  name: string;
}

export interface IDuplicateCampaigns {
  campaignId: number;
  name: string;
  strategyId: number | null;
  strategyName: string;
  goal: string;
  startDate: Date | null;
  endDate: Date | null;
}

export interface IDuplicateCampaignsResponse {
  statusOK: boolean;
  campaignId: number;
}

export interface ICreateEditCampaignsResponse {
  statusOK: boolean;
}

export interface ICreateEditCampaignsRecurrentResponse {
  statusOK: boolean;
  recurrentCreated: ICampaignRecurrentCreated;
}

export interface IActionByIdResponse {
  statusOK?: boolean;
  action?: ICampaignActions;
}

export interface IDuplicateCampaignsResponseV1 {
  campaignIdDuplicate: number;
  statusOK: boolean;
}

export interface ICampaignsInfo {
  count: number | undefined;
  loading: boolean;
  total: number;
  partial: number;
}

export interface IOrderByCell {
  id: string;
  isAsc: boolean | undefined;
}

export interface ICampaignListPayload {
  page?: number;
  limit?: number;
  search?: string;
  state?: number[];
  strategy?: number[];
  startDate?: Date;
  endDate?: Date;
  sortField?: string;
  sortOrder?: string;
  campaignTypeId?: number[] | null;
}

export interface IOrderByTable {
  sortField: string;
  sortOrder: string;
}

export interface IFilterByTable {
  search?: string;
  state?: number[];
  strategy?: number[];
  startDate?: Date;
  endDate?: Date;
}

export interface IActionIcon {
  id: string;
  icon: ReactElement<IconSvgProps>;
  tooltip?: string;
  hide?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}

export interface ICampaignsListInfo extends ICampaignsInfo {
  list: ITableCampaigns[];
  reFetchData: () => void;
}

export enum ModalType {
  EDIT = 'edit',
  CREATE = 'create',
  DUPLICATE = 'duplicate',
}

export enum ScreenType {
  MAIN = 'main',
  DETAIL = 'detail',
}

export interface IMutationContext {
  changeTablePage: ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => void;
  resetPagination: () => void;
  setOrderBy: (orderBy: IOrderByTable) => void;
  setFilterBy: Dispatch<SetStateAction<IFilterByTable>>;
  setClearFilterBy: Dispatch<SetStateAction<boolean>>;
}

export interface IStateContext {
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  gotoPageIndex: number;
  filterBy: IFilterByTable;
  clearFilterBy: boolean;
  orderBy: IOrderByTable;
}

export interface ChannelInfo {
  icon: ReactElement<IconSvgProps>;
  route: string;
}

export interface ICampaignDropdown {
  id: number;
  label: string;
  value: number;
}

/** cspell:disable */
export interface IMoveCampaignPayload {
  campaniaId: TCampaignID;
  elementosId: number[];
}
/** cspell:enable */

export interface IHistorySearchCampaign {
  filters: IFiltersTableCampaign;
  pager: IPager;
}

type valueColumn = `${number}%` | `${number}px`;

export interface ITableColumnWidth {
  report: valueColumn;
}

/** cspell:disable */
export interface ISaveV1Message {
  body: string | null;
  elementoId: number;
  origenId: number;
}

interface AddresseesGrupo {
  id: number;
  tipo: string;
}

export interface IAddresseesByAction {
  grupos: AddresseesGrupo[];
  tipoAccion: number;
}

//TODO: Temporal interface for V1.
export interface IConfirmEmailResponseV1 {
  content: string | null;
  id_notificacion: string;
}

//TODO: Temporal interface for V1.
export interface IPauseEmailResponseV1 {
  content: string | null;
  id_notificacion: string;
}

//TODO: Temporal interface for V1.
export interface IStaticTag {
  activityAmount: number;
  frequencyAmount: number;
  userCount: number;
  layerId: number;
  categoryName: string | null;
  color: string | null;
  containerId: number | null;
  content: string | null;
  contentType: string | null;
  name: string;
  defaultOrder: number | null;
  tagCategoryId: number | null;
  tagId: number;
  lastUse: string;
}

export interface IRuleTag {
  name: string;
  ruleId: number;
}
export interface IV1GetShippingTags {
  staticTags: IStaticTag[];
  rulesTags: IRuleTag[];
}
export interface IV1GetShippingTagsResponse {
  tags: IV1GetShippingTags;
  statusOK: boolean;
}
export interface IV1GetShippingTagsApiResponse {
  TagsEstaticos: IStaticTag[];
  TagsRegla: IRuleTag[];
}

//TODO: update this interface when migrate service to V2
export interface ISmsInfoResponseV1 {
  elementoId: number;
  dia: string;
  mes: string;
  anio: string;
  hora: string;
  minutos: string;
  inmediato: boolean;
  refuerzo: boolean;
  desde: string;
  destinatarios: null | unknown[];
  mensaje: string;
  campo: number;
}

//TODO: update this interface when migrate service to V2
interface GroupRecipientsV1 {
  id: string;
  nombre: string;
  tipo: string;
}

export interface ISmsRecipientsResponseV1 {
  elementoId: number;
  grupos: GroupRecipientsV1[];
  listas: unknown[];
}

/** cspell:enable */

export interface ISearchCampaignsGlobal {
  page: number;
  limit: number;
  search: string;
  sortField: string;
  sortOrder: string;
}

export enum ISearchSourceType {
  CAMPAIGN = 'campaign',
  ACTION = 'action',
}

export interface IListSearchCampaigns {
  id: number;
  name: string;
  type: number;
  status: number;
  source: ISearchSourceType;
  actionId: number;
}

export interface IPayloadSearchCampaigns {
  page: number;
  limit: number;
  search: string;
  sortField: string;
  sortOrder: string;
}

export interface colorDetails {
  name: 'black' | 'primary';
  hexadecimal: string;
}

export interface MessageStateSMS {
  original: string;
  shortened: string;
  track: boolean;
  length: number;
  count: number;
  customFields: number;
}

export type UpdateTypeStateSMS = Partial<Omit<MessageStateSMS, 'length' | 'count'>>;

/** cspell:disable */
export interface ISmsReportActionResponseV1 {
  elementoId: number;
  elementoEnvioId: number;
  configuracion: boolean;
  activo: boolean;
  estado: string;
  elementoSmsId: number;
  agruparContactos: boolean;
  campoId: number;
  campoNombre: string;
  grupoId: number;
  grupoNombre: string;
  codigoPais: string;
  enviados: string;
  fecha_envio: string;
  envio_inmediato: boolean;
  destinatarios: boolean;
  tiene_mensaje: boolean;
  fallidos: string;
  depurados: string;
  cantidad_clicks: string;
  pendings: string;
  total: string;
  sent: string;
  delivery_rate: string;
  tipo: string;
  remarketing: boolean;
  contieneErrores: boolean;
  enviarActualizado: boolean;
  touch_rules: boolean;
  nombre: string;
  campaniaNombre: string;
  campaniaId: number;
}
/** cspell:enable */

/** cspell:disable */
export interface IGroupsEmailTestResponseV1 {
  id: number;
  nombre: string;
  cantidad: number;
}

export interface IGroupsEmailTest {
  id: number;
  count: number;
  name: string;
}
export interface IContactsEmailTestResponseV1 {
  EmailId: number;
  EmailContactoPruebaId: number;
  Email: string;
  EmpresaId: number;
}

export interface IContactsEmailTest {
  id: number;
  contactId: number;
  email: string;
  accountId: number;
}
/** cspell:enable */
