import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useBeefreeService } from '@/src/infrastructure/Protocol/Beefree/useBeefreeService';
import {
  atomFiltersCatalog,
  atomFiltersCatalogPage,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { ChannelInfoByID } from '@/src/modules/CampaignsModule/components/CampaignActions/ActionsTable/hooks/useActionsTableColumns';
import { IEmailOfCatalog } from '@/src/modules/CampaignsModule/interfaces/Beefree';

import { useActionData } from './useActionData';
import { useBeefreeLogger } from './useBeefreeLogger';
import { useModalPreview } from './useModalPreview';

export const useCatalogEmails = () => {
  const masonryRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const [catalog, setCatalog] = useState<IEmailOfCatalog[] | null>(null);
  const { actionId } = useParams();
  const { action } = useActionData(parseInt(actionId || '0'));
  const { t } = useTranslation();
  const serviceBeefree = useBeefreeService();
  const { setShowModalPreview } = useModalPreview();
  const navigate = useNavigate();
  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const [showModalDelete, setShowModalDelete] = useState<IEmailOfCatalog | null>(null);
  const [filters, setFilters] = useAtom(atomFiltersCatalog);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useAtom(atomFiltersCatalogPage);
  const { logger } = useBeefreeLogger();

  const getCatalog = useCallback(
    async (initial = false) => {
      const newPage = initial ? 0 : page + 1;
      const response = await serviceBeefree.getTemplatesEmblue({
        ...filters,
        page: newPage,
      });

      if (response === null) return;

      const { data, pagination } = response;

      setCatalog((prevCatalog) => (initial ? data : (prevCatalog ?? []).concat(data)));
      const totalPages = Math.ceil(pagination.total / filters.limit);

      setPage(newPage);

      if (newPage >= totalPages) {
        hasMore && setHasMore(false);
      } else {
        !hasMore && setHasMore(true);
      }
    },
    [filters, hasMore, page, serviceBeefree, setPage]
  );

  const updateActionData = useCallback(() => {
    const payload = {
      campaign: {
        id: action?.campaignId || 0,
        name: action?.campaignName ?? '',
      },
      action: {
        id: action?.actionId || 0,
        name: action?.name || '',
        channel: action?.channel as unknown as string,
      },
    };
    setInfoCampaignV1(payload);
  }, [action, setInfoCampaignV1]);

  const handleUseEmail = useCallback(
    async (id: number) => {
      if (!actionId) return;

      const response = await serviceBeefree.useTemplate(id, parseInt(actionId));

      if (response) {
        await logger('useTemplateEmblue', 'action', { id });
      } else {
        await logger('useTemplateEmblue', 'error', { id });
      }

      if (response && typeof infoCampaignV1.action?.channel === 'number') {
        const { route } =
          ChannelInfoByID[infoCampaignV1.action?.channel as keyof typeof ChannelInfoByID];
        navigate(
          `/v2/campaigns/${infoCampaignV1.campaign.id}/${route}/${infoCampaignV1.action?.id}/editor`
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionId, infoCampaignV1, navigate, serviceBeefree]
  );
  const handleDeleteEmail = useCallback(
    async (id: number) => {
      const success = (await serviceBeefree.deleteTemplateEmblue(id)) || false;

      if (success) {
        setFilters(RESET);
        setShowModalDelete(null);
        await logger('deleteEmailEmblue', 'action', { id });
        await getCatalog(true);
      } else {
        await logger('deleteEmailEmblue', 'error', { id });
      }

      toast({
        title: success
          ? t('CATALOG_MODAL_DELETE_NOTIFY.success.title')
          : t('CATALOG_MODAL_DELETE_NOTIFY.error.title'),
        body: success
          ? t('CATALOG_MODAL_DELETE_NOTIFY.success.body')
          : t('CATALOG_MODAL_DELETE_NOTIFY.error.body'),
        variant: success ? 'success' : 'error',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCatalog, serviceBeefree, setFilters, t]
  );

  const handlePreviewEmail = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (template: IEmailOfCatalog) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(template.htmlData, 'text/html');
      const styleElement = doc.createElement('style');
      styleElement.textContent = 'body, html { scrollbar-width: none; }';
      doc.body.prepend(styleElement);

      setShowModalPreview({
        show: true,
        html: doc.documentElement.outerHTML,
      });
    },
    [setShowModalPreview]
  );

  useEffect(() => {
    if (!action) return;
    void updateActionData();
  }, [action, updateActionData]);

  useEffect(() => {
    void getCatalog(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return {
    catalog,
    masonryRef,
    loadingRef,
    handleUseEmail,
    handleDeleteEmail,
    handlePreviewEmail,
    showModalDelete,
    setShowModalDelete,
    hasMore,
    getCatalog,
  };
};
