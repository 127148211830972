import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Option } from '@/lib/v2/components';

import { useSaveDataRules } from './useSaveDataRules';
import { useTriggerOptions } from './useTriggerOptions';

import { atomRuleData, atomToggleSidePanel } from '@/modules/RulesModule/atoms/rules';

interface ITriggerForm {
  trigger: Option;
  customField: Option;
  frequency: Option;
  execution: Option;
  days: Option;
  period: Option;
  hours: Option;
  minutes: Option;
}

export const useFormTrigger = () => {
  const {
    triggerOptions,
    customFieldOptions,
    frequencyOptions,
    executionOptions,
    dayOptions,
    periodOptions,
    hourOptions,
    minuteOptions,
  } = useTriggerOptions();

  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);
  const [rulesData, setRulesData] = useAtom(atomRuleData);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ITriggerForm>({
    defaultValues: {
      trigger: rulesData?.trigger || triggerOptions[0],
      customField: rulesData?.customField || customFieldOptions[0],
      frequency: rulesData?.frequency || frequencyOptions[0],
      execution: rulesData?.execution || executionOptions[0],
      days: rulesData?.days || dayOptions[0],
      period: rulesData?.period || periodOptions[0],
      hours: rulesData?.hours || hourOptions[0],
      minutes: rulesData?.minutes || minuteOptions[0],
    },
  });

  const { saveRule } = useSaveDataRules();

  const onSubmit: SubmitHandler<ITriggerForm> = useCallback(
    async (triggerValues) => {
      setRulesData((prevRulesData) => ({ ...prevRulesData, ...triggerValues }));
      await saveRule(triggerValues);
      setToggleSidePanel(false);
    },
    [saveRule, setToggleSidePanel]
  );

  const RULES = useMemo(
    () => ({
      trigger: {
        required: 'is required',
      },
      customField: {
        required: 'is required',
      },
      frequency: {
        required: 'is required',
      },
      execution: {
        required: 'is required',
      },
      days: {
        required: 'is required',
      },
      period: {
        required: 'is required',
      },
      hours: {
        required: 'is required',
      },
      minutes: {
        required: 'is required',
      },
    }),
    []
  );

  const handleCancel = useCallback(() => {
    setToggleSidePanel(false);
  }, [setToggleSidePanel]);

  return {
    control,
    watch,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    RULES,
    handleCancel,
  };
};
