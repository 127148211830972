/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import './i18next.config';

import { createStore, Provider } from 'jotai';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { ToastContainer } from '@/lib/v2/components';

import ErrorBoundary from './application/components/ErrorBoundary';
import { getEnv } from './application/hooks/util/useEnv';
import { App } from './App';
import Auth from './Auth';
import configData from './config.json';

import '@/lib/app.css';
import './index.css';

const env = getEnv();

const { JWT_DEV_TOKEN } = configData.endpoints[env];
const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : window.__JWTToken;

const onsiteEnabled = window.__onsiteEnabled || false;

const appRoot = onsiteEnabled
  ? document.getElementById('root-onsite')
  : document.getElementById('root');
appRoot.setAttribute('notranslate', true);

const root = createRoot(appRoot);
const myStore = createStore();

root.render(
  <ErrorBoundary>
    <Provider store={myStore}>
      {!jwtToken ? <Auth /> : <App />}
      <ToastContainer />
    </Provider>
  </ErrorBoundary>,
  appRoot
);
