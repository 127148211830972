//cspell:disable
import { useAtomValue } from 'jotai';

import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';

import { atomRuleDataRaw } from '@/modules/RulesModule/atoms/rules';
import { IRulesV1Response, RulesOptions } from '@/modules/RulesModule/interfaces';

export const useSaveDataRules = () => {
  const rulesService = useRulesService();
  const ruleDataRaw = useAtomValue(atomRuleDataRaw);

  const updateRuleData = (formData: RulesOptions): IRulesV1Response | undefined => {
    if (!ruleDataRaw) return;

    const fechaEnvio =
      formData.days?.value &&
      formData.frequency?.value &&
      formData.execution?.value &&
      formData.period?.value
        ? `${String(formData.days?.value)}|${String(formData.frequency?.value)}|${String(
            formData.execution?.value
          )}|${String(formData.period?.value)}`
        : ruleDataRaw.fechaEnvio;

    const horaEnvio =
      formData.hours?.value && formData.minutes?.value
        ? `${String(formData.hours?.value)}:${String(formData.minutes?.value)}`
        : ruleDataRaw.horaEnvio;

    return {
      ...ruleDataRaw,
      campoDTO: {
        ...ruleDataRaw.campoDTO,
        id: Number(formData.customField?.id) || ruleDataRaw.campoDTO.id,
        nombre: formData.customField?.name?.toString() || ruleDataRaw.campoDTO.nombre,
      },
      desencadenante: String(formData.trigger?.value ?? ruleDataRaw.desencadenante),
      fechaEnvio,
      horaEnvio,
      group_rules: Number(formData.selectedGroupOrSegment?.[0].id) || ruleDataRaw.group_rules,
      group_rules_in: formData.presenceCondition?.id === 0 ? 1 : 0,
      group_rules_type:
        formData.selectedGroupOrSegment?.[0].value?.toString() ||
        ruleDataRaw.group_rules_type.toString(),
    };
  };

  const saveRule = async (formData: RulesOptions) => {
    const updatedRuleData = updateRuleData(formData);

    if (updatedRuleData) {
      await rulesService.saveRule(updatedRuleData);
    }

    if (formData.touchRules !== undefined) {
      await rulesService.setTouchRules({
        active: Boolean(formData.touchRules),
        actionId: Number(ruleDataRaw?.elementoId),
      });
    }
  };

  return { saveRule };
};
//cspell:enable
