import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Select, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';

import {
  TRANSLATE_RULES_CREATE_RULES,
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
  TRANSLATE_RULES_SEQUENCE_SETTINGS,
} from '@/src/modules/RulesModule/constants';

import { useFormTrigger } from './hooks/useFormTrigger';
import { useTriggerOptions } from './hooks/useTriggerOptions';

const FormTrigger = () => {
  const { t } = useTranslation('rules');
  const {
    executionOptions,
    frequencyOptions,
    minuteOptions,
    periodOptions,
    hourOptions,
    triggerOptions,
    customFieldOptions,
    dayOptions,
  } = useTriggerOptions();

  const { control, handleSubmit, watch, errors, RULES, handleCancel } = useFormTrigger();

  const { execution } = watch();

  const actionsButtons = (
    <>
      <Button outline secondary id="header-action__cancel" size="small" onClick={handleCancel}>
        {t(`${TRANSLATE_RULES_CREATE_RULES}.cancel`)}
      </Button>
      <Button id="header-action__save" size="small" type="submit">
        {t(`${TRANSLATE_RULES_CREATE_RULES}.save`)}
      </Button>
    </>
  );

  return (
    <>
      {/*eslint-disable-next-line @typescript-eslint/no-misused-promises*/}
      <form onSubmit={handleSubmit}>
        <div className="pl-4 pr-2">
          <SectionHeader
            sticky
            withoutDivide
            actionsButtons={actionsButtons}
            isLoading={false}
            titleNode={
              <Text as="h3" className="!2xl:text-20 !text-16" fontWeight="medium" variant="title-1">
                <Flex withGap>{t(`${TRANSLATE_RULES_LABELS}.trigger`)}</Flex>
              </Text>
            }
          />
          <hr className="w-full" />
        </div>
        <div className="px-8 pb-8 pt-6">
          <div className="mb-5 mr-8">
            <Controller
              control={control}
              name="trigger"
              render={({ field }) => <Select {...field} options={triggerOptions} />}
              rules={RULES.trigger}
            />
            {errors.trigger && (
              <Text color="alert" variant="text-sm">
                {errors?.trigger?.message}
              </Text>
            )}
          </div>
          <div className="mb-5 mr-8">
            <Controller
              control={control}
              name="customField"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t(`${TRANSLATE_RULES_LABELS}.customFieldTrigger`)}
                  options={customFieldOptions}
                  placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.customField`)}
                />
              )}
              rules={RULES.customField}
            />
            {errors.customField && (
              <Text color="alert" variant="text-sm">
                {errors?.customField?.message}
              </Text>
            )}
          </div>
          <div className="mb-5 flex items-center">
            <Text className="mr-2 whitespace-nowrap" variant="text">
              {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.sendFrequency`)}
            </Text>
            <div>
              <Controller
                control={control}
                name="frequency"
                render={({ field }) => <Select {...field} options={frequencyOptions} />}
                rules={RULES.frequency}
              />
              {errors.frequency && (
                <Text color="alert" variant="text-sm">
                  {errors?.frequency?.message}
                </Text>
              )}
            </div>
          </div>
          <div className="mb-5 flex items-center">
            <Text className="mr-2 whitespace-nowrap" variant="text">
              {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.executionTime`)}
            </Text>
            <div>
              <Controller
                control={control}
                name="execution"
                render={({ field }) => <Select {...field} options={executionOptions} />}
                rules={RULES.execution}
              />
              {errors.execution && (
                <Text color="alert" variant="text-sm">
                  {errors?.execution?.message}
                </Text>
              )}
            </div>
            {execution?.id !== 1 && (
              <Text className="ml-2 whitespace-nowrap" variant="text">
                {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.executionDate`)}
              </Text>
            )}
          </div>
          <div className="flex items-center">
            {execution?.id !== 1 && (
              <>
                <div>
                  <Controller
                    control={control}
                    name="days"
                    render={({ field }) => <Select {...field} options={dayOptions} />}
                    rules={RULES.days}
                  />
                  {errors.days && (
                    <Text color="alert" variant="text-sm">
                      {errors?.days?.message}
                    </Text>
                  )}
                </div>
                <div className="mx-2">
                  <Controller
                    control={control}
                    name="period"
                    render={({ field }) => <Select {...field} options={periodOptions} />}
                    rules={RULES.period}
                  />
                  {errors.period && (
                    <Text color="alert" variant="text-sm">
                      {errors?.period?.message}
                    </Text>
                  )}
                </div>
              </>
            )}
            <Text className="whitespace-nowrap" variant="text">
              {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.atTime`)}
            </Text>
            <div className="mx-2">
              <Controller
                control={control}
                name="hours"
                render={({ field }) => <Select {...field} options={hourOptions} />}
                rules={RULES.hours}
              />
              {errors.hours && (
                <Text color="alert" variant="text-sm">
                  {errors?.hours?.message}
                </Text>
              )}
            </div>
            <Text variant="text">:</Text>
            <div className="mx-2">
              <Controller
                control={control}
                name="minutes"
                render={({ field }) => <Select {...field} options={minuteOptions} />}
                rules={RULES.minutes}
              />
              {errors.minutes && (
                <Text color="alert" variant="text-sm">
                  {errors?.minutes?.message}
                </Text>
              )}
            </div>
            <Text className="whitespace-nowrap" variant="text">
              {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.timeUnit`)}
            </Text>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormTrigger;
