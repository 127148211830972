export const RULES_PATHS = {
  RULES_PATH: ':rulesId',
};

export const TRANSLATE_RULES_COLUMNS = 'RULES.COLUMNS';
export const TRANSLATE_RULES_TRIGGER_TYPES = 'RULES.TRIGGER_TYPES';
export const TRANSLATE_RULES_ACTION_TYPES = 'RULES.ACTION_TYPES';
export const TRANSLATE_RULES_ACTION_FIELD_TRIGGER = 'RULES.ACTION_FIELD_TRIGGER';

export const TRANSLATE_RULES_CALL_TO_ACTION = 'RULES.CALL_TO_ACTION';
export const TRANSLATE_RULES_CREATE_RULES = 'RULES.CREATE_RULES';

export const TRANSLATE_RULES_SEQUENCE_SETTINGS = 'RULES.SEQUENCE_SETTINGS';
export const TRANSLATE_RULES_LABELS = 'RULES.LABELS';
export const TRANSLATE_RULES_PLACEHOLDERS = 'RULES.PLACEHOLDERS';

export const TRANSLATE_RULES_TRIGGER_OPTIONS = 'RULES.TRIGGER_OPTIONS';
export const TRANSLATE_RULES_FREQUENCY_OPTIONS = 'RULES.FREQUENCY_OPTIONS';
export const TRANSLATE_RULES_EXECUTION_OPTIONS = 'RULES.EXECUTION_OPTIONS';
export const TRANSLATE_RULES_PERIOD_OPTIONS = 'RULES.PERIOD_OPTIONS';
export const TRANSLATE_RULES_PRESENCE_OPTIONS = 'RULES.PRESENCE_OPTIONS';
