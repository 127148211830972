import { CalendarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import es from 'date-fns/locale/es';
import _ from 'lodash';
import {
  forwardRef,
  LegacyRef,
  memo,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps, registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { IconSvg, Select } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select/Select';
import { withController } from '@/lib/v2/HOCs/WithController';

import DateInputContainer from './DateInputContainer';

import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.tailwind.css';

registerLocale('es', es);

export type eventOnChangeDateInput = (date: Date | [Date | null, Date | null] | null) => void;

export interface DateInputProps {
  id?: string;
  disabled?: boolean;
  date?: Date | null;
  name?: string;
  label?: string;
  dateFormat?: string;
  placeholder?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  error?: boolean;
  message?: string;
  onChange?: eventOnChangeDateInput;
  onDelete?: (indexDate: number) => void;
  selectsRange?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  dates?: Date[] | string[];
  multiDates?: boolean;
  readOnly?: boolean;
  iconColor?: 'secondary' | 'primary';
  isRequired?: boolean;
  /** show skeleton loading */
  isLoading?: boolean;
  fullWidth?: boolean;
  showTimeSelect?: boolean;
  showTimeInput?: boolean;
  showTimeSelectOnly?: boolean;
  timeFormat?: string;
}

const DateInput = forwardRef(
  (
    {
      date,
      dates,
      onChange,
      onDelete,
      id,
      label,
      name,
      dateFormat = 'dd/MM/yyyy',
      placeholder = 'dd/MM/yyyy',
      disabled = false,
      error,
      message,
      selectsRange = false,
      startDate,
      endDate,
      minDate,
      maxDate,
      multiDates,
      readOnly = false,
      iconColor = 'secondary',
      isRequired,
      isLoading,
      fullWidth = false,
      showTimeSelect,
      showTimeInput,
      showTimeSelectOnly,
      timeFormat,
    }: DateInputProps,
    ref?: LegacyRef<DatePicker<never, boolean>>
  ): JSX.Element => {
    const [selected, setSelected] = useState(false);

    const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const { t, i18n } = useTranslation();

    const handleOnChange = (dateValue: Date) => {
      onChange?.(dateValue);
      multiDates && setSelected(false);
    };

    const classesContainer = classNames('date-input--container', {
      '!w-full': fullWidth,
    });

    const classesMessage = classNames({ 'message-error': error });
    const classesContainerInput = classNames('relative rounded-md', {
      'date-input--container-error': error,
      readOnly: readOnly,
    });
    const classesDiv = classNames('eb-date-input--multi-dates', 'relative overflow-x-hidden', {
      '!border-emblue-primary': selected,
    });
    const iconDisabledClasses = classNames({ 'opacity-50': disabled });

    const optionsYears = useMemo(() => {
      return rangeYear(maxDate, minDate);
    }, [maxDate, minDate]);

    const deleteValue = useCallback(
      (index: number) => (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDelete?.(index);
      },
      [onDelete]
    );

    const multiDatesElements =
      Array.isArray(dates) &&
      dates.map((val, index) => (
        <span
          key={index}
          className="ml-0.5 inline-flex items-center rounded-full bg-blue-50 pl-2 pr-0.5
                text-base font-medium text-emblue-primary"
        >
          {val instanceof Date ? val.toISOString().split('T')[0] : val}
          <button
            className="ml-0.5 inline-flex size-4 shrink-0 items-center justify-center rounded-full text-emblue-primary
                    hover:bg-indigo-200 hover:text-emblue-primary-hover focus:bg-blue-400
                    focus:text-white focus:outline-none"
            id="delete-value-button"
            type="button"
            onClick={deleteValue(index)}
          >
            <span className="sr-only">Remove small option</span>
            <svg className="size-2" fill="none" stroke="currentColor" viewBox="0 0 8 8">
              <path d="M1 1l6 6m0-6L1 7" strokeLinecap="round" strokeWidth="1.5" />
            </svg>
          </button>
        </span>
      ));

    const months: Option[] = useMemo(
      () => [
        { id: 0, name: t('MONTHS.january') },
        { id: 1, name: t('MONTHS.february') },
        { id: 2, name: t('MONTHS.march') },
        { id: 3, name: t('MONTHS.april') },
        { id: 4, name: t('MONTHS.may') },
        { id: 5, name: t('MONTHS.june') },
        { id: 6, name: t('MONTHS.july') },
        { id: 7, name: t('MONTHS.august') },
        { id: 8, name: t('MONTHS.september') },
        { id: 9, name: t('MONTHS.october') },
        { id: 10, name: t('MONTHS.november') },
        { id: 11, name: t('MONTHS.december') },
      ],
      [t]
    );

    const CustomHeader = ({
      date: dateCurrent,
      changeMonth,
      changeYear,
    }: ReactDatePickerCustomHeaderProps) => {
      const selectedYear = () => {
        return optionsYears.find((year) => year.name === dateCurrent.getFullYear());
      };

      return (
        <div className="flex flex-row gap-1" data-testid="header-date-input-component">
          <div className="basis-3/5">
            <Select
              classNameMenuContainer="!max-h-44"
              name="month"
              options={months}
              value={months[dateCurrent.getMonth()]}
              onChange={(value: Option | Option[]) => {
                changeMonth(Number((value as Option).id));
              }}
            />
          </div>
          <div className="basis-2/5">
            <Select
              classNameMenuContainer="!max-h-44"
              name="year"
              options={optionsYears}
              value={selectedYear()}
              onChange={(value: Option | Option[]) => {
                changeYear(Number((value as Option).name));
              }}
            />
          </div>
        </div>
      );
    };

    if (isLoading) {
      return (
        <div className="flex w-full animate-pulse flex-col" data-testid="select-component">
          <div className="mt-1.5 h-2.5 w-28 rounded-full bg-gray-200"></div>
          <div className="my-1 h-[38px] w-full rounded bg-gray-200"></div>
        </div>
      );
    }

    return (
      <div className={classesContainer} {...(id && { id })} data-testid="date-input-component">
        {label && (
          <label className="label" htmlFor={name}>
            {label} {isRequired && <span className="text-red-500"> * </span>}
          </label>
        )}
        <div ref={divRef} className={classesContainerInput} id="date-input-group">
          {multiDates && (
            <div
              aria-hidden
              className={classesDiv}
              role="textbox"
              onClick={() => setSelected((prevSelected) => !prevSelected)}
            >
              {!readOnly && (
                <div
                  className={`${iconDisabledClasses} absolute inset-y-0 left-0 flex items-center pl-3`}
                  style={{ zIndex: 1 }}
                >
                  <IconSvg
                    height="20px"
                    strokeColor={iconColor}
                    svgComponent={
                      <CalendarIcon aria-hidden="true" className="size-5 text-gray-400" />
                    }
                    width="20px"
                  />
                </div>
              )}
              <div className="eb-multi-dates--div flex flex-row text-[#A7B1CC]">
                {dates && dates?.length > 0 ? multiDatesElements : placeholder}
              </div>
            </div>
          )}
          {!multiDates && !readOnly && (
            <div
              className={`${iconDisabledClasses} absolute inset-y-0 left-0 flex items-center pl-3`}
              style={{ zIndex: 1 }}
            >
              <IconSvg
                height="20px"
                strokeColor={iconColor}
                svgComponent={<CalendarIcon aria-hidden="true" className="size-5 text-gray-400" />}
                width="20px"
              />
            </div>
          )}
          {!multiDates && (
            <DatePicker
              ref={ref}
              renderCustomHeader={CustomHeader}
              {...(!selectsRange && { selected: date })}
              closeOnScroll
              calendarContainer={DateInputContainer}
              dateFormat={dateFormat}
              disabled={disabled}
              locale={i18n.language}
              name={name}
              placeholderText={placeholder}
              selectsRange={selectsRange}
              onChange={handleOnChange}
              {...(selectsRange && { startDate })}
              {...(selectsRange && { endDate })}
              autoComplete="off"
              className={label ? 'my-1' : ''}
              maxDate={maxDate}
              minDate={minDate}
              readOnly={readOnly}
              showTimeInput={showTimeInput}
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={showTimeSelectOnly}
              timeFormat={timeFormat}
            />
          )}
          {multiDates && selected && (
            <div
              className="z-10"
              style={{
                position: 'absolute',
                inset: '0px auto auto 0px',
                transform: 'translate(0px,46px)',
              }}
            >
              <DatePicker
                ref={ref}
                renderCustomHeader={CustomHeader}
                {...(!selectsRange && { selected: date })}
                closeOnScroll
                calendarContainer={DateInputContainer}
                dateFormat={dateFormat}
                disabled={disabled}
                locale={i18n.language}
                name={name}
                placeholderText={placeholder}
                selectsRange={selectsRange}
                onChange={handleOnChange}
                {...(selectsRange && { startDate })}
                {...(selectsRange && { endDate })}
                autoComplete="off"
                inline={multiDates}
                maxDate={maxDate}
                minDate={minDate}
                readOnly={readOnly}
              />
            </div>
          )}
        </div>
        {error && (
          <p className={classesMessage} id={`${name}-message`}>
            {message}
          </p>
        )}
      </div>
    );
  }
);

const rangeYear = (maxDate?: Date | null, minDate?: Date | null) => {
  const max = maxDate ? maxDate.getFullYear() : new Date().getFullYear();
  const min = minDate ? minDate.getFullYear() : max - 60;
  const years = _.range(min, max + 1, 1);
  const yearsOptions = years.reverse().map((year, index): Option => ({ id: index, name: year }));
  return yearsOptions;
};

export default memo(withController(DateInput));
