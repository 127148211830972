import { memo, MouseEventHandler, ReactElement, useCallback, useState } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { IconSvg, If, Text } from '@/lib/v2/components';
import { ArrowBackIcon, EditPencilIconNew } from '@/lib/v2/icons/solid';

interface To {
  path: string;
  params?: NavigateOptions;
}

interface CustomPaths {
  name: string;
  to?: To;
}

interface BreadcrumbProps {
  actionButtons?: ReactElement;
  customPaths: CustomPaths[];
  handleOnBackClick?: () => void;
  withoutBackArrow?: boolean;
  itemId?: number;
  isEditable?: boolean;
  onEditableClick?: MouseEventHandler<HTMLElement>;
}

const Breadcrumb = ({
  actionButtons,
  customPaths,
  handleOnBackClick,
  withoutBackArrow = false,
  itemId,
  isEditable,
  onEditableClick,
}: BreadcrumbProps) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (to?: To) => {
      if (to) {
        navigate(to.path, to.params);
      }
    },
    [navigate]
  );

  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };

  const customPathItems = customPaths.map((item: CustomPaths, index: number) => {
    const { name, to } = item;
    const isLast = index === customPaths.length - 1;
    const title = name && name.charAt(0).toUpperCase() + name.slice(1);
    return (
      <If key={item.name} condition={!!title}>
        <div className="ml-1 flex flex-row items-center">
          <Text
            className="flex flex-row items-center bg-transparent font-normal hover:font-bold"
            fontWeight={isLast ? 'bold' : 'normal'}
            variant="text"
            onClick={() => handleOnClick(to)}
          >
            {index === 0 ? truncateString(title, 25) : `/ ${truncateString(title, 25)}`}
          </Text>
        </div>
      </If>
    );
  });

  return (
    <div className="mb-6 flex h-[48px] w-full flex-row place-content-between items-center bg-white px-8 shadow-md">
      <div className="flex flex-row items-center">
        <If condition={!withoutBackArrow}>
          <IconSvg
            height="26px"
            svgComponent={<ArrowBackIcon />}
            width="22px"
            onClick={handleOnBackClick}
          />
        </If>
        <div className="ml-2 flex flex-row items-center">
          {customPathItems}
          <If condition={isEditable}>
            <IconSvg
              className="ml-3"
              fillColor="gray-dark"
              height="16px"
              strokeColor="gray-dark"
              svgComponent={<EditPencilIconNew />}
              width="16px"
              onClick={onEditableClick}
            />
          </If>
          <If condition={!!itemId}>
            <Text className="ml-3 flex flex-row items-center" fontWeight={'normal'} variant="text">
              - ID {itemId}
            </Text>
          </If>
        </div>
      </div>
      <If condition={!!actionButtons}>
        <div className="flex flex-row">{actionButtons}</div>
      </If>
    </div>
  );
};

export default memo(Breadcrumb);
