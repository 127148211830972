import { useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkbox, Input, TimeInput } from '@/lib/v2/components';
import DateInput, { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { eventOnChangeTimeInput } from '@/lib/v2/components/TimeInput/TimeInput';

import {
  atomGlobalLoadingAction,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomCallbackStep,
  atomDeliveryInformation,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import useSchedulingForm, {
  SchedulingFormState,
} from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/hooks/useSchedulingForm';
import SubjectButtons from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons';
import { ISubjectChangeButtonsProps } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import {
  IDeliveryActionResponse,
  ISetDeliveryActionPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EActionStatusAvailable,
  EChannelsID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';
import { DateFormat, formatDate } from '@/modules/CampaignsModule/utils';
import { formatTime } from '@/modules/CampaignsModule/utils/formatDate';

interface IDeliveryFormProps {
  disabled?: boolean;
}

const ImmediateDeliveryForm = ({ disabled = false }: IDeliveryFormProps) => {
  const { t } = useTranslation();
  const { campaignId, actionId } = useParams();
  const navigate = useNavigate();

  const deliveryInformation = useAtomValue(atomDeliveryInformation);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);

  const { control, RULES, handleSubmit, setValue, getValues, resetField, reset } =
    useSchedulingForm();
  const { saveDeliveryAction, setActionState } = useActionEmailData(Number(actionId));

  const [reinforcementDateState, setReinforcementDateState] = useState<Date | undefined>(undefined);
  const [reinforcementTimeState, setReinforcementTimeState] = useState<Date | undefined>(undefined);
  const [reinforcementMinDateTime, setReinforcementMinDateTime] = useState<Date | undefined>(
    undefined
  );
  const [reinforcementCheckbox, setReinforcementCheckbox] = useState<boolean>(
    deliveryInformation?.reinforcement?.isReinforcementShipment || false
  );

  useEffect(() => {
    const {
      reinforcement: { subject, startDate: reinforcementStartDate },
    } = deliveryInformation as IDeliveryActionResponse;

    const newReinforcementDate = new Date(reinforcementStartDate);

    setValue(
      'reinforcementCheckbox',
      deliveryInformation?.reinforcement?.isReinforcementShipment || false
    );
    setValue('reinforcementSubject', subject || '');
    setValue('reinforcementDate', reinforcementStartDate ? newReinforcementDate : null);
    setValue('reinforcementTime', reinforcementStartDate ? newReinforcementDate : null);

    setReinforcementDateState(reinforcementStartDate ? newReinforcementDate : undefined);
    setReinforcementTimeState(reinforcementStartDate ? newReinforcementDate : undefined);

    if (newReinforcementDate.getDate() === new Date().getDate()) {
      if (newReinforcementDate.getHours() > new Date().getHours()) {
        setReinforcementMinDateTime(new Date());
      } else {
        setReinforcementMinDateTime(newReinforcementDate);
      }
    } else {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      setReinforcementMinDateTime(currentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setGlobalLoadingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeReinforcement = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setReinforcementCheckbox(checked);
      if (!checked) reset();
    },
    [reset]
  );

  const onChangeReinforcementDate: eventOnChangeDateInput = useCallback(
    (event) => {
      const currentDate = new Date();
      const currentDateMin = new Date();
      const inputDate = event as Date;

      currentDateMin.setDate(currentDateMin.getDate() + 1);

      inputDate?.getDate() === currentDateMin.getDate()
        ? inputDate.setHours(currentDate.getHours(), Math.floor(currentDate.getMinutes() / 10) * 10)
        : currentDateMin.setHours(0, 0, 0, 0);

      setReinforcementDateState(inputDate);
      setReinforcementTimeState(undefined);
      setReinforcementMinDateTime(currentDateMin);
      resetField('reinforcementTime');
    },
    [resetField]
  );

  const onChangeReinforcementTime: eventOnChangeTimeInput = useCallback((event) => {
    setReinforcementTimeState(event as Date);
  }, []);

  const setProcessingAction = useSetAtom(atomProcessingAction);

  const handleSubmitData = useCallback(
    async (formData: SchedulingFormState) => {
      setProcessingAction(true);

      const { reinforcementCheckbox, reinforcementSubject, reinforcementDate, reinforcementTime } =
        formData;

      const ReinforcementAsDate = new Date(reinforcementDate as Date);
      const ReinforcementAsTime = new Date(reinforcementTime as Date);

      const ReinforcementYear = ReinforcementAsDate?.getFullYear();
      const ReinforcementMonth = ReinforcementAsDate?.getMonth();
      const ReinforcementDay = ReinforcementAsDate?.getDate();
      const ReinforcementHour = ReinforcementAsTime?.getHours();
      const ReinforcementMinutes = ReinforcementAsTime?.getMinutes();

      const reinforcementDatePayload = new Date(
        ReinforcementYear,
        ReinforcementMonth,
        ReinforcementDay,
        ReinforcementHour,
        ReinforcementMinutes
      );

      const payload: ISetDeliveryActionPayload = {
        actionType: EChannelsID.EMAIL,
        actionId: Number(actionId),
        isImmediateShipment: true,
        reinforcement: {
          subject: reinforcementCheckbox ? reinforcementSubject : null,
          isReinforcementShipment: reinforcementCheckbox ?? false,
          date: reinforcementCheckbox
            ? formatDate(String(reinforcementDatePayload), DateFormat.ShortDateServer)
            : null,
          hour: reinforcementCheckbox
            ? formatTime(String(reinforcementDatePayload), DateFormat.TimeOnly)
            : null,
        },
      };

      await saveDeliveryAction(payload);
      await setActionState({
        actionId: Number(actionId),
        statusId: EActionStatusAvailable.CONFIRMABLE_DRAFT,
      });

      setProcessingAction(false);
      navigate(`/v2/campaigns/${Number(campaignId)}/email/${Number(actionId)}/summary`);
    },
    [actionId, campaignId, saveDeliveryAction, setActionState, setProcessingAction, navigate]
  );

  const handleChangeSubjectButton = ({ item }: ISubjectChangeButtonsProps) => {
    const currentSubject = getValues('reinforcementSubject');
    setValue('reinforcementSubject', `${currentSubject} ${item}`);
  };

  return (
    <form
      id="immediate-form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit(handleSubmitData);
      }}
    >
      <div className="mb-5 flex items-center gap-3">
        <Checkbox
          checked={reinforcementCheckbox}
          color="#D3DDEA"
          control={control}
          disabled={disabled}
          id="reinforcementCheckbox"
          label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.reinforcement')}
          name="reinforcementCheckbox"
          size={16}
          onChange={handleChangeReinforcement}
        />
      </div>
      <div className="mb-4 flex w-3/4 gap-2">
        <Input
          control={control}
          disabled={!reinforcementCheckbox || disabled}
          id="reinforcementSubject"
          name="reinforcementSubject"
          placeHolder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.subjectPlaceholder`)}
          rules={RULES.reinforcementSubject}
        />
        <div className="mt-1">
          <SubjectButtons
            actionId={Number(actionId)}
            disabled={!reinforcementCheckbox || disabled}
            id="email-immediate-buttons"
            searchPlaceholder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.searchDropdown`)}
            onChangeButtons={handleChangeSubjectButton}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex w-[200px]">
          <DateInput
            fullWidth
            isRequired
            control={control}
            date={reinforcementDateState}
            disabled={!reinforcementCheckbox || disabled}
            id="reinforcementDate"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            minDate={reinforcementMinDateTime}
            name="reinforcementDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.reinforcementDate}
            onChange={onChangeReinforcementDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            isRequired
            control={control}
            date={reinforcementTimeState}
            disabled={!reinforcementCheckbox || disabled}
            id="reinforcementTime"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={reinforcementMinDateTime}
            name="reinforcementTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.reinforcementTime}
            onChange={onChangeReinforcementTime}
          />
        </div>
      </div>
    </form>
  );
};

export default ImmediateDeliveryForm;
