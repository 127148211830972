import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalConfirmation } from '@/lib/v2/examples';

import { ContactsFound, SegmentFilters } from './components';

import {
  useMutationCreateSegmentContext,
  useStateCreateSegmentContext,
} from '@/modules/ContactsModule/contexts/CreateSegmentContext';

const CreateSegment = () => {
  const { numberOfContacts, isLoadingData, segmentName, isEditPath, isLoadingEditData } =
    useStateCreateSegmentContext();
  const {
    createSegmentFilters,
    filtersIsValid,
    clearFilters,
    setFilters,
    getQuantityTemporaryFilters,
    editSegmentFilters,
    setAmountContactsFilter,
  } = useMutationCreateSegmentContext();

  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);

  const handleOpenSaveModal = useCallback(() => setSaveModalIsOpen(true), []);

  const handleSaveSegment = () => {
    isEditPath ? void editSegmentFilters() : void createSegmentFilters();
    setSaveModalIsOpen(false);
  };

  useEffect(() => {
    return () => {
      clearFilters();
      setAmountContactsFilter(0);
    };
  }, [setAmountContactsFilter, clearFilters]);

  const { t } = useTranslation();

  const handleClearSegmentFilters = useCallback(() => {
    setFilters([]);
    setAmountContactsFilter(0);
  }, [setAmountContactsFilter, setFilters]);

  const handleRecalculate = useCallback(() => {
    void getQuantityTemporaryFilters();
  }, [getQuantityTemporaryFilters]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <SegmentFilters
          filterIsValid={filtersIsValid()}
          isEditPath={isEditPath}
          isLoadingEditData={isLoadingEditData}
          isLoadingSaveData={isLoadingData}
          segmentName={segmentName}
          onClearSegmentFilters={handleClearSegmentFilters}
          onCreateSegmentFilters={handleOpenSaveModal}
          onRecalculate={handleRecalculate}
        />
        <ContactsFound isLoading={isLoadingData} numberOfContacts={numberOfContacts} />
      </div>
      <ModalConfirmation
        isOpen={saveModalIsOpen}
        textCancelButton={t('SEGMENT_FILTERS.cancel')}
        textConfirmButton={t('SEGMENT_FILTERS.save')}
        title={
          isEditPath ? t('SEGMENT_FILTERS.editModalTitle') : t('SEGMENT_FILTERS.saveModalTitle')
        }
        onClose={setSaveModalIsOpen}
        onConfirmButton={handleSaveSegment}
      />
    </>
  );
};

export default CreateSegment;
