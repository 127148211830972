import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconSvg } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/Badges';
import { ColorsBadge } from '@/lib/v2/components/Badges/types';
import { ColorsIcon } from '@/lib/v2/components/IconSvg/types';
import { LoadingIcon } from '@/lib/v2/icons/animated';

import { useSearchCampaignsItems } from './useSearchCampaignsItems.hooks';

import {
  IListSearchCampaigns,
  ISearchSourceType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

interface ISearchCampaignsItemsProps {
  list: IListSearchCampaigns[];
}

const SearchCampaignsItems = ({ list }: ISearchCampaignsItemsProps) => {
  const { t } = useTranslation();

  const {
    events: {
      getStateLabelColor,
      getLabelByType,
      getLinkByType,
      getColorsByType,
      handleDatadogClick,
      handleScroll,
    },
    constants: { CAMPAIGN_STATE_ICONS, ACTION_TYPE_ICONS },
    state: { listRef, loadingMore },
  } = useSearchCampaignsItems();

  return (
    <div
      ref={listRef}
      className="eb-card visible-scrollbar relative max-h-[220px] w-[532px] cursor-pointer flex-col overflow-y-auto bg-white py-2"
      onScroll={handleScroll}
    >
      {(list?.length ?? 0) > 0 ? (
        <>
          {list?.map((item: IListSearchCampaigns) => {
            const actionState = getStateLabelColor(item.status, item.source);
            const source =
              item.source === ISearchSourceType.CAMPAIGN
                ? 'CAMPAIGNS_MAIN'
                : 'CAMPAIGN_ACTIONS_MAIN';
            const textState = t(`${source}.STATES.${actionState?.text as string}`);
            const link = getLinkByType(item);
            const colors = getColorsByType(item);
            return (
              <Link
                key={`${item.source}_${item.id}`}
                className="flex flex-row items-center justify-between border-b border-gray-200 px-6 py-3 last:border-b-0"
                id="list-general-search"
                to={link}
                onClick={handleDatadogClick}
              >
                <div className="flex flex-1 gap-4">
                  <IconSvg
                    rounded
                    bgColor={colors?.background as Exclude<ColorsIcon, 'white'>}
                    fillColor={colors?.background as ColorsIcon}
                    height="40px"
                    svgComponent={
                      item.source === ISearchSourceType.CAMPAIGN
                        ? CAMPAIGN_STATE_ICONS[item.type]
                        : ACTION_TYPE_ICONS[item.type]
                    }
                    width="40px"
                    withPadding="small"
                  />
                  <div className="w-full max-w-[280px] text-left text-14">
                    <p className="emblue-subheadings w-full truncate font-medium">{item.name}</p>
                    <span className="emblue-text block font-normal">
                      {getLabelByType(item.type, item.source)}
                    </span>
                  </div>
                </div>
                <div>
                  <BadgeText
                    text={textState}
                    variantColor={actionState?.variantColor as ColorsBadge}
                  />
                </div>
              </Link>
            );
          })}
          {loadingMore && (
            <div className="flex size-full items-center justify-center pt-2">
              <LoadingIcon />
            </div>
          )}
        </>
      ) : (
        <div className="emblue-subheadings py-3 text-14">{t('CAMPAIGNS_MAIN.SEARCH.empty')}</div>
      )}
    </div>
  );
};

export default SearchCampaignsItems;
