import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  EChannelsID,
  ICampaignActions,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

export const useTemplates = () => {
  const navigate = useNavigate();
  const [, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);

  const openTemplates = useCallback(
    (action: ICampaignActions | null) => {
      if (action === null) return;

      const { name, channel, actionId } = action;

      if (!actionId) return;

      setInfoCampaignV1({
        campaign: {
          id: Number(action.campaignId),
          name: action.campaignName ?? '',
          isAutomatic: channel === EChannelsID.MASTER,
        },
        action: {
          id: actionId,
          name: name,
        },
      });
      navigate('/v2/campaigns/catalogs/email/' + (actionId ? actionId.toString() : ''));
    },
    [navigate, setInfoCampaignV1]
  );

  return {
    openTemplates,
  };
};
