// cspell:disable-next-line
import { IEntityContentJson } from '@mailupinc/bee-plugin/dist/types/bee';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { ReactElement, ReactNode } from 'react';

import { IOptionAccordion } from '@/lib/v2/components/Accordion/Accordion2';
import { Option } from '@/lib/v2/components/Select/Select';

import { ICategory, ICollection, ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import {
  IBeefreeSaveResponse,
  IEmailOfCatalog,
} from '@/src/modules/CampaignsModule/interfaces/Beefree';

// cspell:disable
export interface EditorMailData {
  contentHtml: string;
  contentHtmlEditable: IEntityContentJson;
  actionId: number;
  envioId?: number;
  accountAutoId: number;
  defaultTemplateId: number;
}

export interface IModalTemplateCatalog {
  tags: ITag[];
  categories: ICategory[];
  collections: ICollection[];
}

export interface IFiltersTemplateCatalog {
  search?: string;
  tags: IOptionAccordion[] | null;
  categories: IOptionAccordion[] | null;
  collections: IOptionAccordion[] | null;
  page: number;
  limit: number;
}

export interface IFiltersMyTemplateCatalog {
  page: number;
  limit: number;
}

// cspell:enable
export interface EditorMailResponse extends Omit<EditorMailData, 'contentHtmlEditable'> {
  contentHtmlEditable: string;
}
export interface ITagOption extends Object {
  id: number | string;
  name: string | number;
  value?: string | number | boolean;
}
export interface ICategoryOption extends Object {
  id: number | string;
  name: string | number;
  value?: string | number | boolean;
}

export const atomBeefreeSaving = atom(false);

export const atomBeefreeSaved = atom(false);

export const atomBeefreePristine = atom(false);

export const atomBeefreeAuthorize = atom(false);

export const atomBeefreePreview = atom(false);

export const atomEditorMail = atom<EditorMailData | null>(null);

export const atomModalTemplate = atom<boolean>(false);

export const atomModalCatalog = atom<IModalTemplateCatalog>({
  tags: [],
  categories: [],
  collections: [],
});

export const atomFiltersCatalogData = atom<IFiltersTemplateCatalog>({
  tags: null,
  categories: null,
  collections: null,
  page: 0,
  limit: 10,
});

export const atomFiltersMyCatalog = atomWithReset<IFiltersMyTemplateCatalog>({
  page: 0,
  limit: 10,
});

export const atomFiltersCatalog = atomWithReset<IFiltersTemplateCatalog>({
  search: '',
  tags: [],
  categories: [],
  collections: [],
  limit: 10,
  page: 0,
});

export const atomFiltersCatalogPage = atomWithReset<number>(0);

export const atomInitTemplateSave = atom<boolean>(false);
export const atomTemplateDataSave = atom<IBeefreeSaveResponse>({
  jsonData: '',
  html: '',
});

export const atomSelectedTags = atom<ITagOption[]>([]);

export const atomSelectedCategories = atom<Option | undefined>(undefined);

export const atomMyCatalog = atomWithReset<IEmailOfCatalog[] | null>(null);

export const atomBeefreeCatalog = atom<null | IEmailOfCatalog[]>(null);

export const atomFiltersCategories = atom<ICategory[] | null>(null);
export const atomFiltersCollections = atom<ICollection[] | null>(null);

interface ICollectionSelected {
  [key: string]: ICollection[];
}

export const atomFiltersCollectionsSelected = atom<ICollectionSelected | null>(null);

export interface IModalSaveRow {
  open: boolean;
  onSuccess: (name: string, isEmblueRow: boolean) => void;
  onFail: () => void;
}
export const atomContentDialogSaveRows = atomWithReset<IModalSaveRow>({
  open: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSuccess: (name: string, isEmblueRow: boolean) => {
    throw new Error('Not assigned a callback success');
  },
  onFail: () => {
    throw new Error('Not assigned a callback error');
  },
});
