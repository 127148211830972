import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import {
  IExecutedTrigger,
  IGetDetailExecutedCountResponse,
} from '@/src/infrastructure/interfaces/IAutomation.interface';
import { ITableCampaignActions } from '@/src/modules/AutomationModule/interfaces/automation';
import { IFilterHistorical } from '@/src/modules/AutomationModule/types/events';

import {
  IFlowData,
  IFlowRowTable,
  IFlowsTableFilters,
  IPager,
  ITotalsFlows,
} from '@/modules/AutomationModule/types/flows';
import { formatDateToString } from '@/modules/CampaignsModule/utils/formatDate';

export const atomFlows = atomWithReset<IFlowRowTable[]>([]);

export const atomExecutionsEvents = atomWithReset<IGetDetailExecutedCountResponse['result']>([]);
export const atomExecutedEvents = atomWithReset<IExecutedTrigger[]>([]);

export interface IOrderByTable {
  sortField: string;
  sortOrder: string;
}

export const atomOrderBy = atom<IOrderByTable>({ sortField: 'name', sortOrder: 'asc' });

export const atomPagerFlows = atomWithReset<IPager>({
  page: 1,
  limit: 10,
});
atomPagerFlows.debugLabel = 'atomPagerFlows';

export const getDefaultFilterHistorical = () => {
  const today = new Date();
  const dateFrom = new Date(today);
  dateFrom.setHours(today.getHours() - 72);
  return {
    dateFrom: formatDateToString(dateFrom),
    dateTo: formatDateToString(today, true),
  };
};

export const atomFlowsFilters = atomWithReset<IFlowsTableFilters>({
  pageIndex: 1,
  pageSize: 10,
  status: [],
  triggers: [],
  search: '',
  types: [],
  orderBy: {
    sortField: 'name',
    sortOrder: 'asc',
  },
});

export const atomTotalsProcess = atomWithReset<ITotalsFlows>({ total: 0 });
atomTotalsProcess.debugLabel = 'atomTotalsProcess';

export const atomFlowsLoadingProcess = atomWithReset<boolean>(true);
atomFlowsLoadingProcess.debugLabel = 'atomFlowsLoadingProcess';

export const atomExecutedCountIsLoading = atomWithReset<boolean>(false);
atomExecutedCountIsLoading.debugLabel = 'atomExecutedCountIsLoading';

export const atomFilterStatus = atomWithReset<number[]>([]);
atomFilterStatus.debugLabel = 'atomFilterStatus';

export const atomFilterTriggers = atomWithReset<number[]>([]);
atomFilterTriggers.debugLabel = 'atomFilterTriggers';

export const atomSelectedRows = atom<number[]>([]);
atomSelectedRows.debugLabel = 'atomSelectedRows';

export const atomFilterHistorical = atomWithReset<null | IFilterHistorical>(
  getDefaultFilterHistorical()
);

export const atomSearchFlows = atomWithReset<string>('');

export const atomOpenCreateFlowModal = atomWithReset<boolean>(false);

export const atomActionsList = atomWithReset<ITableCampaignActions[] | undefined>(undefined);
atomActionsList.debugLabel = 'atomActionsList';

export const atomSelectedRowsAction = atom<number[]>([]);
atomSelectedRowsAction.debugLabel = 'atomSelectedRowsAction';

export const atomSelectedRowActionName = atom((get) => {
  const selectedRows = get(atomSelectedRowsAction);
  const lastIndex = selectedRows.length - 1;
  const selectedActionName = get(atomActionsList)?.[selectedRows[lastIndex]]?.action?.name;
  return selectedActionName;
});
atomSelectedRowActionName.debugLabel = 'atomSelectedRowActionName';

export const atomRefetchAllData = atomWithReset<boolean>(false);
atomRefetchAllData.debugLabel = 'atomRefetchAllData';

export const atomFlowModalDelete = atom(false);

export const atomFlowForDelete = atom<null | IFlowData>(null);

export const atomShowDetailFlowsExecution = atomWithReset<boolean>(false);
export const atomItemDetailFlowsExecution = atomWithReset<IFlowData | null>(null);
