import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SelectPhoneNumber } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import Header from './Header';

interface GetPhoneNumberProps {
  onNext: (phoneNumber: string) => void;
  onSkip: () => void;
  onChange: (phoneNumber?: string) => void;
  phoneNumber: string;
  isLoading?: boolean;
  canSkip?: boolean;
  defaultCountry?: string;
}

const GetPhoneNumber = ({
  onNext,
  isLoading,
  phoneNumber,
  onChange,
  onSkip,
  defaultCountry,
  canSkip,
}: GetPhoneNumberProps) => {
  const [numberIsValid, setNumberIsValid] = useState(false);
  const { t } = useTranslation();

  const hiddenSkipButton = useFeatureFlag('hiddenSkipButton');

  const handleNextClick = useCallback(() => {
    onNext(phoneNumber);
  }, [onNext, phoneNumber]);

  const handleOnChangeNumber = useCallback(
    (phoneNumber?: string, isValid?: boolean) => {
      setNumberIsValid(isValid ?? false);
      onChange(phoneNumber);
    },
    [onChange]
  );

  return (
    <div className="flex w-full flex-col items-center gap-8">
      <Header
        description={t('AUTH.TWO_AUTHENTICATE.get_number_description')}
        title={t('AUTH.TWO_AUTHENTICATE.two_step')}
      />
      <SelectPhoneNumber
        defaultCountry={
          defaultCountry && defaultCountry.toUpperCase() !== 'UNKNOWN' ? defaultCountry : undefined
        }
        label={t('AUTH.TWO_AUTHENTICATE.phoneNumber')}
        value={phoneNumber}
        onChange={handleOnChangeNumber}
      />
      <Button
        fullWidth
        disabled={!numberIsValid}
        id="next-btn"
        isLoading={isLoading}
        onClick={handleNextClick}
      >
        {t('AUTH.TWO_AUTHENTICATE.next')}
      </Button>
      {canSkip && !hiddenSkipButton && (
        <Button fullWidth id="skip-btn" onClick={onSkip}>
          {t('AUTH.TWO_AUTHENTICATE.skip')}
        </Button>
      )}
    </div>
  );
};

export default GetPhoneNumber;
