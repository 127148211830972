import { useFlag } from '@unleash/proxy-client-react';

import { getEnv } from './useEnv';

const allFlags = {
  // BETA:
  showNpsModule: true,
  createSimpleEmail: true,
  createSimpleSMS: true,
  showVideoContactsV2: true,
  showFinishedActionsDashboard: true,
  showRecurrentMessage: true,
  hideMainContainerV1: false,
  defaultSortDateFinishActions: true,
  showCampaignInfoCards: false,
  campaignsShowTablePreview: true,
  insertWidgetNpsInHTML: true,
  showCreateEmailActionCard: true,
  showCampaignCalendar: true,
  campaignSearchAll: false,
  showDeprecateCampaignsV1: true,
  configMenuApiManager: true,
  showDragDropRemovalNotice: true,
  duplicateAction: true,
  showRulesTypeModalActions: true,
  duplicateCampaign: true,
  // DELTA
  showBillingPortal: true,
  contactMenuBarImport: true,
  showCampaignToLegacy: true,
  backToContactsLegacy: false,
  showBlockModalFreeTrialDemo: true,
  SkipButtonStepper: false,
  createContactsV2: false,
  distributeSegment: true,
  recentImportIsEnabled: true,
  showSmartFinder: true,
  discardedReasonsEnabled: true,
  showStatusCards: true,
  contactDownload: true,
  showMyPlan: true,
  showPlanComparison: true,
  showSendersV2: true,
  hiddenSkipButton: false,
  templateEmailCreator: true,
  withModeSubscription: true,
  automaticDebitStripeButton: true,
  stripeProration: true,
  showAutomation: true,
  showAutomationToLegacy: true,
  confirmEmailV2: true,
  // Features no implemented
  showCampaigns: true,
  showDuplicateCampaignAction: true,
  channelDateFilter: true,
  actionBarDropdown: true,
  actionBarGroupsFlowsIcon: true,
  actionBarSegmentsFlowsIcon: true,
  actionBarExportUserIcon: true,
  actionBarMergeTagIcon: true,
  actionBarEditTagCategoryIcon: true,
  activityLogCampaign: false,
  // Heavy operations that could be useful keep the control with the FF
  actionBarTagAddIcon: true,
  actionBarGroupAddIcon: true,
  actionBarFlowsIcon: true,
  tagsByContacts: true,
  groupsByContacts: true,
  // 4 DEVELOPERS
  showCrashButton: true,
  loginAuditStep: true,
  loginV2CheckCountry: false,
  MFA: true,
  // Inbox: chat-staging-na.embluemail.com
  showInboxModule: false,
};

type AppFlagKeys = keyof typeof allFlags;

export const useFeatureFlag = (flag: AppFlagKeys) => {
  const env = getEnv();
  const isFlagEnabled = useFlag(flag);

  if (env === 'development' || env === 'storybook') {
    return allFlags[flag];
  }
  return isFlagEnabled;
};
