import { useAtomValue } from 'jotai';
import { isValidElement, ReactElement, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { chatShow } from '@/lib/components/SupportCard';
import { Alert, Button, ButtonProps, Card, CardSection, If, Text } from '@/lib/v2/components';

import {
  EmailPriceComponent,
  OnsitePriceComponent,
  PlanPriceComponent,
  SmsPriceComponent,
  TotalPriceComponent,
} from './IndividualPlanPriceInformation';

import {
  atomBillingInformation,
  atomContractDetail,
} from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { RadioSwitch } from '@/modules/MyPlanModule/components';
import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { usePlanPriceInformation } from '@/modules/MyPlanModule/hooks/usePlanPriceInformation';
import { useSelectedPlanData } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';

interface PlanPriceInformationProps {
  checkoutButton?: ReactElement<ButtonProps>;
  isStepThree?: boolean;
  prorationAmount?: number;
  prorationAmountIsLoading?: boolean;
}

const PlanPriceInformation = ({
  checkoutButton,
  isStepThree = false,
  prorationAmount,
  prorationAmountIsLoading,
}: PlanPriceInformationProps) => {
  if (checkoutButton && (!isValidElement(checkoutButton) || checkoutButton.type !== Button)) {
    throw new Error('The "checkoutButton" prop must be a Button component v2');
  }

  const { t } = useTranslation();

  const {
    annuallyTotal,
    frequencyOptions,
    frequency,
    handleFrequency,
    planNameSelected,
    planPrice,
    monthSuffixText,
    emailAmount,
    priceEmails,
    isFreemiumPlan,
    smsAmount,
    priceSms,
    onsiteAmount,
    isAnnuallySelected,
    priceOnsite,
    monthlyTotal,
    priceIncreaseEmail,
    priceIncreaseOnsite,
    priceIncreaseSms,
    proratedPrices,
    showAlertRefund,
    hasProrations,
  } = usePlanPriceInformation();

  const { plan } = useSelectedPlanData();

  const { getCountryOption } = useCountriesOptions();
  const billingInformation = useAtomValue(atomBillingInformation);
  const contractDetail = useAtomValue(atomContractDetail);

  const countryName = billingInformation?.country
    ? getCountryOption(billingInformation?.country.toString()).name
    : 'America Central';

  useEffect(() => {
    if (plan?.actualPlan) {
      if (contractDetail?.billingPeriod === 'annually') {
        handleFrequency(frequencyOptions[1]);
      } else {
        handleFrequency(frequencyOptions[0]);
      }
    }
  }, [contractDetail?.billingPeriod, frequencyOptions, handleFrequency, plan?.actualPlan]);

  return (
    <Card isHeightAuto noShadow color="gray">
      <CardSection>
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full justify-center">
            <RadioSwitch
              disabled={plan?.actualPlan || contractDetail?.billingPeriod === 'annually'}
              options={frequencyOptions}
              value={frequency}
              onChange={handleFrequency}
            />
          </div>
          <div className="mt-4 flex justify-between">
            <PlanPriceComponent
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              planNameSelected={planNameSelected}
              planPrice={planPrice}
              proratedPlanPrice={proratedPrices?.plan}
            />
          </div>
          <div className="mt-4 flex justify-between">
            <EmailPriceComponent
              emailAmount={emailAmount}
              emailPrice={priceEmails}
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              priceIncreaseEmail={priceIncreaseEmail}
              proratedEmailPrice={proratedPrices?.email}
            />
          </div>
          <div className={`flex justify-between ${isFreemiumPlan ? 'opacity-50' : ''}`}>
            <SmsPriceComponent
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              priceIncreaseSms={priceIncreaseSms}
              proratedSmsPrice={proratedPrices?.sms}
              smsAmount={smsAmount}
              smsPrice={priceSms}
            />
          </div>
          <div className={`flex justify-between ${isFreemiumPlan ? 'opacity-50' : ''}`}>
            <OnsitePriceComponent
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              onsiteAmount={onsiteAmount}
              onsitePrice={priceOnsite}
              priceIncreaseOnsite={priceIncreaseOnsite}
              proratedOnsitePrice={proratedPrices?.onsite}
            />
          </div>
          <div className="mt-4 w-full border-b" />
          <div className="flex flex-col py-4">
            <TotalPriceComponent
              annuallyTotal={annuallyTotal}
              isAnnuallySelected={isAnnuallySelected}
              isFreemiumPlan={isFreemiumPlan}
              monthSuffixText={monthSuffixText}
              monthlyTotal={monthlyTotal}
              proratedTotalPrice={proratedPrices?.total}
              prorationAmount={prorationAmount}
              prorationAmountIsLoading={prorationAmountIsLoading}
            />
          </div>
          {checkoutButton}
          <If condition={!isFreemiumPlan}>
            <div className="flex flex-col gap-4">
              <If condition={hasProrations && isStepThree}>
                <Alert description={t('PlanPriceInformation.hasProrations')} type="info" />
              </If>
              <Text isItalic variant="text-sm">
                <Trans
                  components={{
                    li: (
                      <Text
                        isItalic
                        link
                        color="primary"
                        fontWeight="medium"
                        id="link-chat"
                        variant="text-sm"
                        onClick={chatShow}
                      />
                    ),
                  }}
                  i18nKey="MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.warning"
                  values={{ countryName: countryName }}
                />
              </Text>
              <If condition={showAlertRefund}>
                <Alert description={t('PlanPriceInformation.descriptionRefund')} type="info" />
              </If>
            </div>
          </If>
        </div>
      </CardSection>
    </Card>
  );
};

export default PlanPriceInformation;
