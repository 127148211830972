import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/lib/components/Button/Button';
import { Flex, Grid } from '@/lib/v2/components/Layout/Stack';

import withDeprecated from '@/src/application/HOCs/WithDeprecated';

const _Input = ({
  placeHolder,
  register,
  name,
  conditions,
}: {
  placeHolder: string;
  name: string;
  register: any;
  conditions?: any;
}) => {
  return (
    <Flex column>
      <input
        className="focus:shadow-outline h-6 w-full appearance-none rounded border px-3 py-2 text-12 leading-tight text-gray-700 shadow focus:outline-none"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...register(name, { required: conditions.required })}
        placeholder={placeHolder}
      />
    </Flex>
  );
};

/**
 * @deprecated use Input v2 -> import { Input } from @/lib/v2/components;
 */
const Input = withDeprecated(_Input, 'use Input v2 -> import { Input } from @/lib/v2/components');

const Field = ({
  column,
  placeHolder,
  name,
  label,
  registerAux,
  errors,
  conditions,
  textAlignment,
}: {
  column?: boolean;
  placeHolder: string;
  name: string;
  label: string;
  registerAux: any;
  errors?: any;
  conditions?: any;
  textAlignment?: 'start' | 'center' | 'end' | undefined;
}) => {
  return (
    <Grid withGap column={column} gapSize="small">
      <Flex column itemAlignment={textAlignment}>
        <label>{label}</label>
      </Flex>
      <Flex column>
        <Input
          conditions={conditions}
          name={name}
          placeHolder={placeHolder}
          register={registerAux}
        />
        {errors[name] && (
          <Flex column itemAlignment={textAlignment !== 'end' ? textAlignment : 'start'}>
            <small className="text-red-400">{conditions.errorMessage}</small>
          </Flex>
        )}
      </Flex>
    </Grid>
  );
};

export type FormConditionsProps = {
  required: boolean;
  errorMessage: string;
};

export type FieldsProps = {
  name: string;
  label: string;
  placeHolder: string;
  conditions: FormConditionsProps;
  column?: boolean;
};

export type FormProps = {
  fieldList: Array<FieldsProps>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onAction: Function;
  order?: 'column' | 'horizontal';
  gapSize?: 'small' | 'medium' | 'large';
  textAlignment?: 'start' | 'center' | 'end';
  children?: ReactNode;
};

export const Form = ({ formProps }: { formProps: FormProps }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();
  const { t } = useTranslation();

  const onAction = handleSubmit((values) => {
    formProps.onAction(values);
  });

  return (
    <form>
      <Grid column withGap gapSize={formProps.gapSize !== undefined ? formProps.gapSize : 'small'}>
        {formProps.fieldList.map((element) => {
          return (
            <Field
              key={element.name}
              column={formProps.order === 'column' ? true : false}
              conditions={element.conditions}
              errors={errors}
              label={element.label}
              name={element.name}
              placeHolder={element.placeHolder}
              registerAux={register}
              textAlignment={formProps.textAlignment}
            />
          );
        })}
        <Flex column itemAlignment="end">
          <div className="w-content">
            <Button typeButton="submit" onAction={onAction}>
              {t('Save')}
            </Button>
          </div>
        </Flex>
      </Grid>
    </form>
  );
};
