import { atomWithReset } from 'jotai/utils';

export const atomContactsDownload = atomWithReset({
  show: false,
  error: false,
  disabled: false,
  file: {
    url: '',
    token: '',
    name: '',
  },
  wordings: {
    message: '',
    action: '',
  },
  status: 'loading',
});
