import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardFooter, CardSection, IconSvg, Text } from '@/lib/v2/components';
import { ModalConfirmation } from '@/lib/v2/examples';
import { useRenameModal } from '@/lib/v2/examples/RenameItem/hooks/useRenameModal';
import { EditPencilIcon, RecalculateIcon, SegmentsIcon } from '@/lib/v2/icons/outline';

import { RenameSegment } from '@/src/modules/ContactsModule/screens/CreateSegment/components/RenameSegment';

import SegmentFiltersContainer from './SegmentFiltersContainer';

import { SectionHeader } from '@/modules/ContactsModule/components';

interface SegmentFiltersProps {
  segmentName?: string;
  onCreateSegmentFilters: () => void;
  onClearSegmentFilters: () => void;
  onRecalculate: () => void;
  isLoadingEditData?: boolean;
  isLoadingSaveData?: boolean;
  filterIsValid?: boolean;
  isEditPath?: boolean;
}

const SegmentFilters = ({
  segmentName,
  onCreateSegmentFilters,
  onClearSegmentFilters,
  onRecalculate,
  isLoadingSaveData,
  isLoadingEditData,
  filterIsValid,
  isEditPath,
}: SegmentFiltersProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);

  const { position, editNameModalIsOpen, setEditNameModalIsOpen, handleOpenEditNameModal } =
    useRenameModal();

  const title = useMemo(
    () => (
      <>
        <Text fontWeight="medium" variant="title-1">
          {t('SEGMENT_FILTERS.segments')} -{' '}
        </Text>
        <Text color="primary" fontWeight="medium" variant="title-1">
          {isEditPath ? t('SEGMENT_FILTERS.edit') : t('SEGMENT_FILTERS.create')} - {segmentName}
        </Text>
      </>
    ),
    [isEditPath, segmentName, t]
  );

  const actions = useMemo(
    () => (
      <Button
        link
        className="!h-auto !p-0"
        iconLeft={<IconSvg height="20px" svgComponent={<EditPencilIcon />} width="20px" />}
        id="renameSegmentBtn"
        tooltip={t('SEGMENT_FILTERS.renameSegment')}
        onClick={handleOpenEditNameModal}
      />
    ),
    [handleOpenEditNameModal, t]
  );

  const icon = useMemo(
    () => <IconSvg height="24px" svgComponent={<SegmentsIcon />} width="24px" />,
    []
  );

  const onHandleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [right, top] = position;

  return (
    <Card>
      <CardSection noPadding>
        <div className="flex flex-col">
          <SectionHeader
            backButton
            actionsButtons={actions}
            icon={icon}
            isLoading={isEditPath && isLoadingEditData}
            title={title}
            onBack={() => setSaveModalIsOpen(true)}
          />
          <SegmentFiltersContainer isLoadingEditData={isLoadingEditData ?? false} />
        </div>
      </CardSection>
      <CardFooter withDivide>
        <div className="mx-6 my-2 flex flex-row justify-end gap-2">
          <Button outline secondary onClick={onClearSegmentFilters}>
            {t('SEGMENT_FILTERS.cleanFilters')}
          </Button>
          <Button
            outline
            secondary
            disabled={!filterIsValid}
            iconLeft={<IconSvg fillColor="secondary" svgComponent={<RecalculateIcon />} />}
            isLoading={isLoadingSaveData}
            onClick={onRecalculate}
          >
            {t('SEGMENT_FILTERS.recalculate')}
          </Button>
          <Button
            disabled={!filterIsValid}
            isLoading={isLoadingSaveData}
            onClick={() => onCreateSegmentFilters()}
          >
            {t('SEGMENT_FILTERS.save')}
          </Button>
        </div>
      </CardFooter>
      <ModalConfirmation
        description={t('SEGMENT_FILTERS.bodyComeBackEdit')}
        isOpen={saveModalIsOpen}
        textCancelButton={t('SEGMENT_FILTERS.cancel')}
        textConfirmButton={t('IMPORT_MAIN.next')}
        title={t('SEGMENT_FILTERS.titleComeBackEdit')}
        onClose={setSaveModalIsOpen}
        onConfirmButton={onHandleBack}
      />
      <RenameSegment
        isOpen={editNameModalIsOpen}
        rightClick={right}
        topClick={top}
        onClose={() => setEditNameModalIsOpen(false)}
      />
    </Card>
  );
};

export default memo(SegmentFilters);
