import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table } from '@/lib/components';
import { Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyContactsIcon, EmptySearchIcon } from '@/lib/v2/icons/general';

import { BulletListIcon } from '@/src/ContactsModule/Icons/BulletListIcon';

export type ImportRecentProps = {
  file: string;
  size: string;
  uploaded: string;
};

export interface IImportRecentTableProps {
  importRecentList: IImportRecentList[];
  onRowClick: (rowSelected: ImportRecentProps) => void;
}
export interface IImportRecentList {
  mapping: ImportRecentProps;
}

export const ImportRecentTable = memo(
  ({ importRecentList, onRowClick }: IImportRecentTableProps) => {
    const { t } = useTranslation();

    const fileColum = {
      Header: () => (
        <Flex withGap alignment="start">
          <BulletListIcon stroked tableSize />
          <span className="text-[#004DBC]">{t('IMPORT_MAIN.file')}</span>
        </Flex>
      ),
      accessor: 'mapping.file',
      id: 'file',
      Cell: ({ cell: { value } }: { cell: { value: string } }) => (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <span className="self-start font-medium text-[#364365]">{value}</span>
          </Flex>
        </Flex>
      ),
    };

    const sizeColum = {
      Header: () => (
        <Flex withGap alignment="start">
          <BulletListIcon stroked tableSize />
          <span className="text-[#004DBC]">{t('IMPORT_MAIN.size')}</span>
        </Flex>
      ),
      accessor: 'mapping.size',
      id: 'size',
      Cell: ({ cell: { value } }: { cell: { value: string } }) => (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <span className="self-start font-medium text-[#364365]">{value}</span>
          </Flex>
        </Flex>
      ),
    };

    const uploadedColum = {
      Header: () => (
        <Flex withGap alignment="start">
          <BulletListIcon stroked tableSize />
          <span className="text-[#004DBC]">{t('IMPORT_MAIN.uploaded')}</span>
        </Flex>
      ),
      accessor: 'mapping.uploaded',
      id: 'uploaded',
      Cell: ({ cell: { value } }: { cell: { value: string } }) => (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <span className="self-start font-medium text-[#364365]">{value}</span>
          </Flex>
        </Flex>
      ),
    };

    const importRecentColum: Column<object>[] = [fileColum, sizeColum, uploadedColum].filter(
      (x) => x
    ) as Column<object>[];

    const EmptyScreenTableNoDataDescription = useMemo(
      () => (
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('EMPTY_SCREEN_TABLE.emptyFilesDescription')}
        </Text>
      ),
      [t]
    );
    return (
      <div className="w-full text-base text-[#364365] 2xl:text-14">
        <Table
          isPaginateOnOverflow
          columns={importRecentColum}
          data={importRecentList}
          emptyScreen={{
            totalCount: importRecentList.length,
            noData: (
              <EmptyScreenTable
                description={EmptyScreenTableNoDataDescription}
                image={<EmptySearchIcon />}
                title={t('EMPTY_SCREEN_TABLE.emptyFilesTitle')}
              />
            ),
            noResult: (
              <EmptyScreenTable
                description={t('EMPTY_SCREEN_TABLE.callToAction')}
                image={<EmptyContactsIcon />}
                title={t('EMPTY_SCREEN_TABLE.emptyFilesTitle')}
              />
            ),
          }}
          onRowClick={(value) => {
            onRowClick(value as unknown as ImportRecentProps);
          }}
        />
      </div>
    );
  }
);
