import { Button, Modal, Text } from '@/lib/v2/components';

import { IEmailOfCatalog } from '@/src/modules/CampaignsModule/interfaces/Beefree';

interface IModalDelete {
  open: boolean;
  data: IEmailOfCatalog | null;
  onCancel: () => Promise<void>;
  onAccept: (id: number) => Promise<void>;
  wordings: {
    title: string;
    message: string;
    actions: {
      cancel: string;
      accept: string;
    };
  };
}

export const ModalDelete = (props: IModalDelete) => {
  const { open, wordings, onCancel, onAccept, data } = props;

  const handleCancel = () => {
    void onCancel();
  };

  const handleAccept = () => {
    if (data?.catalogId) void onAccept(data.catalogId);
    if (!data?.catalogId && data?.id) void onAccept(data.id);
  };

  return (
    <Modal noPadding withAuto open={open} onClose={handleCancel}>
      <div className="flex w-96 flex-col p-4 py-6">
        <Text variant="headline">{wordings.title}</Text>
        <Text className="py-2" variant="text">
          {wordings.message}
        </Text>

        <div className="mt-4 flex justify-center gap-4">
          <Button outline size="small" onClick={handleCancel}>
            {wordings.actions.cancel}
          </Button>
          <Button size="small" onClick={handleAccept}>
            {wordings.actions.accept}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
