import { ComponentType, PropsWithChildren } from 'react';

import { Container, If } from '@/lib/v2/components';

import { DeprecatedBanner } from '@/src/modules/CampaignsModule/components/DeprecatedBanner';
import { useDeprecatedBanner } from '@/src/modules/CampaignsModule/hooks/useDeprecatedBanner';

const CampaignWithWrapperLayout = (WrappedComponent: ComponentType<PropsWithChildren<object>>) => {
  const WrapperComponent = (props: PropsWithChildren<object>) => {
    // TODO remove this when the feature flag showDragDropRemovalNotice is removed
    const { showDeprecatedDragDropForPath } = useDeprecatedBanner();

    return (
      <>
        <If condition={showDeprecatedDragDropForPath}>
          <DeprecatedBanner />
        </If>

        <Container fullHeight>
          <WrappedComponent {...props} />
        </Container>
      </>
    );
  };

  return WrapperComponent;
};

export default CampaignWithWrapperLayout;
