import axios, { AxiosProgressEvent, ResponseType } from 'axios';

interface IOptions {
  withCredentials?: boolean;
  responseType?: ResponseType;
  headers?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export class APIService {
  token?: string;
  baseURL: string;

  constructor(baseURL: string, token?: string) {
    this.token = token;
    this.baseURL = baseURL;
  }

  async get<T>(
    url: string,
    body?: object,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await axios.get(url, {
      withCredentials:
        options?.withCredentials !== undefined ? options.withCredentials : !this.token,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
      },
      responseType: options?.responseType || 'json',
      params: { ...(body || {}) },
      baseURL: this.baseURL,
    });
  }

  async post<T>(
    url: string,
    body?: object,
    multipart?: boolean,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await axios.post(
      url,
      { ...body },
      {
        withCredentials:
          options?.withCredentials !== undefined ? options.withCredentials : !this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': '*',
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
          ...(options?.headers ? options.headers : {}),
        },
      }
    );
  }

  async patch<T>(
    url: string,
    body?: object,
    multipart?: boolean,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await axios.patch(
      url,
      { ...body },
      {
        withCredentials:
          options?.withCredentials !== undefined ? options.withCredentials : !this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': '*',
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        },
      }
    );
  }

  // Temporary fix!
  async postWithCredentials<T>(
    url: string,
    body?: object,
    multipart?: boolean
  ): Promise<{ data: T; status: number }> {
    return await axios.post(
      url,
      { ...body },
      {
        withCredentials: !!this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': '*',
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        },
      }
    );
  }

  async put<T>(
    url: string,
    body?: object,
    multipart?: boolean
  ): Promise<{ data: T; status: number }> {
    return await axios.put(
      url,
      { ...body },
      {
        withCredentials: !this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': '*',
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        },
      }
    );
  }

  async delete<T>(url: string, body?: object): Promise<{ data: T; status: number }> {
    return await axios.delete(url, {
      withCredentials: !this.token,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
      },
      data: { ...(body || {}) },
      baseURL: this.baseURL,
    });
  }

  async postFormData<T>(
    url: string,
    formData: FormData,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<{ data: T; status: number }> {
    return await axios.post(url, formData, {
      withCredentials: !this.token,
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
      },
      onUploadProgress,
    });
  }

  async postRaw<T>(
    url: string,
    body: string,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await axios.post(url, body, {
      withCredentials: !this.token,
      data: body,
      baseURL: this.baseURL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        ...(options?.headers ?? {}),
      },
    });
  }

  /* cspell:disable */
  async postOnsite<T>(
    url: string,
    token: string,
    body?: object
  ): Promise<{ data: T; status: number }> {
    return await axios.post(
      url,
      { ...body },
      {
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          ...(token ? { Authorization: `Onsite ${token}` } : {}),
        },
      }
    );
  }
}
