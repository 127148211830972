import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { LogoutIcon } from '@/lib/icon';
import { EmblueIcon } from '@/lib/icon/EmblueIcon';
import { IconSvg, Text } from '@/lib/v2/components';

import { getEnv } from '@/src/application/hooks/util/useEnv';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

const TopNav = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  /** cspell:disable */
  const cookiePreProd = document.cookie
    .split('; ')
    .find((row) => row.startsWith('EMA-APP-PREPROD='))
    ?.split('=')[1];
  /** cspell:enable */
  const service = useService();
  let background = 'bg-emblue-primary';
  const environment = getEnv();
  if (environment === 'production' && cookiePreProd === '1') {
    background = 'bg-emblue-success';
  }

  if (getEnv() === 'staging') {
    background = 'bg-emblue-sleepy';
  }

  const onClose = async () => {
    await service.logout();
    setTimeout(() => {
      navigate('/Account/admin');
      navigate(0);
    }, 500);
  };

  return (
    <>
      <div
        className={`top-bar flex h-16 w-full items-center justify-between pl-4 pr-6 text-white md:text-12 lg:text-14 2xl:text-16 ${background}`}
      >
        <EmblueIcon />

        <Text className="flex items-center gap-2" color="white" onClick={onClose}>
          <IconSvg fillColor="white" svgComponent={<LogoutIcon />} /> {t('BACKOFFICE.menu.logout')}
        </Text>
      </div>
      <Outlet />
    </>
  );
};

export default TopNav;
