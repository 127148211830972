import classNames from 'classnames';
import { memo, useMemo } from 'react';

import InsightCard, { InsightCardProps } from '@/lib/v2/examples/InsightCard/InsightCard';

import { IStatusList } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

export interface FilterCardsListProps {
  /** status list items */
  statusList: IStatusList[];
  /** key status list filter (filter or discarded) */
  keyFilter?: 'filter' | 'discarded';
  /** list cards filter */
  cardsList: Array<InsightCardProps & { filter: string }>;
  /** title is loading */
  titleIsLoading?: boolean;
  /** change grid layouts or class */
  className?: string;
}

const FilterCardsList = ({
  statusList,
  cardsList,
  className,
  keyFilter = 'filter',
  titleIsLoading = false,
}: FilterCardsListProps) => {
  const list = useMemo(() => {
    return (
      statusList &&
      statusList.map((x: IStatusList) => ({
        ...x,
        filter: x.state,
        number: x.total,
      }))
    );
  }, [statusList]);

  const filterCardsList = useMemo(() => {
    cardsList.map((cardList) => {
      if (!list) return cardList;
      if (titleIsLoading !== undefined) cardList.isLoading = titleIsLoading;
      const findElement = list.find((x) => x[keyFilter] === cardList.filter);
      if (findElement) {
        cardList.title = quantifier(findElement.number)?.toString();
      } else {
        if (list.length === 0) return cardList;
        cardList.title = '0';
      }
      return cardsList;
    });

    return cardsList;
  }, [cardsList, keyFilter, list, titleIsLoading]);

  const classes = classNames(
    'mb-6 grid grid-cols-2 gap-1 sm:grid-cols-3 lg:grid-cols-4 lg:gap-6',
    className
  );

  return (
    <div className={classes}>
      {filterCardsList.map((item) => {
        const { filter, ...restOfProps } = item;
        return <InsightCard key={filter} {...restOfProps} />;
      })}
    </div>
  );
};

export default memo(FilterCardsList);
