import { FunctionComponentElement, MouseEventHandler } from 'react';
import { createRoot } from 'react-dom/client';

import { CrossSimpleIcon } from '@/lib/icon/CrossSimpleIcon';
import { IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

export type ModalProps = {
  children?: React.ReactNode;
  isFloat?: boolean;
  secondaryBg?: boolean;
  show?: boolean;
  id?: string;
};

/**
 * WARNING: DO NOT USE THIS FUNCTION
 * use v2 modal instead
 * @deprecated
 */
export const openModal = ({
  element,
}: {
  element: FunctionComponentElement<any> | FunctionComponentElement<any>[];
}) => {
  let modal = document.getElementById('modal') as HTMLElement;
  if (!modal) {
    modal = document.createElement('div');
    modal.id = 'modal';
    document.body.append(modal);
  }
  createRoot(document.getElementById('modal') as HTMLElement).render(element);
};

export const closeModal = () => {
  const modal = document.getElementById('modal') as HTMLElement;
  if (modal) {
    modal.remove();
  }
};

export const Modal = ({
  children,
  secondaryBg,
  isFloat = false,
  show = true,
  id = '',
}: ModalProps) => {
  return show ? (
    <div className={`relative ${isFloat ? 'z-[9999]' : 'z-20'}`}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div
        className="fixed inset-0 z-10 overflow-y-auto"
        data-testid={`v1modal-component--${id}`}
        id={id}
      >
        <Flex alignment="center" itemAlignment="center">
          <div
            className={`relative z-10 ${
              secondaryBg ? 'bg-emblueLightGray' : 'bg-white'
            } rounded-lg text-left shadow-xl transition-all`}
          >
            {children}
          </div>
        </Flex>
      </div>
    </div>
  ) : (
    <></>
  );
};

Modal.Title = ({
  children,
  onAction,
  buttonClose = true,
}: {
  children?: JSX.Element[] | JSX.Element | string;
  onAction?: MouseEventHandler<HTMLButtonElement>;
  buttonClose?: boolean;
}) => (
  <Flex noGrow>
    <div className="text-12 text-[#63799b] lg:text-14 xl:text-16 2xl:text-18">{children}</div>
    {buttonClose && (
      <button onClick={onAction}>
        <IconSvg
          fillColor={'gray'}
          height={'24px'}
          id="crossCloseIconBtn"
          strokeColor={'gray'}
          svgComponent={<CrossSimpleIcon />}
          width={'24px'}
        />
      </button>
    )}
  </Flex>
);

Modal.Body = ({
  children,
  withBorder,
  secondaryBg,
}: {
  children?: JSX.Element[] | JSX.Element | string;
  withBorder?: boolean;
  secondaryBg?: boolean;
}) => (
  <main
    className={` border-1 right-0 h-full w-full origin-top-right rounded-md ${
      secondaryBg ? 'bg-emblueLightGray' : 'bg-white'
    }
    ${withBorder ? 'border border-gray-300' : ''}
    `}
  >
    {children}
  </main>
);

Modal.Footer = ({ children }: { children?: JSX.Element[] | JSX.Element | string }) => (
  <div>{children}</div>
);

export const FloatModal = ({
  children,
  id = '',
}: {
  children: JSX.Element[] | JSX.Element | string;
  id?: string;
}) => (
  <div className="relative z-[9999]">
    <div className="bg-opacity/75 fixed inset-0 bg-gray-500 transition-opacity"></div>
    <div
      className="fixed inset-0 z-10 overflow-y-auto"
      data-testid={`v1modal-component--${id}`}
      id={id}
    >
      <Flex alignment="center" itemAlignment="center">
        {typeof children === 'string' ? <span>{children}</span> : children}
      </Flex>
    </div>
  </div>
);
