import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TableSkeleton } from '@/lib/components';
import { Table } from '@/lib/components/Table';
import { Flex, Text } from '@/lib/v2/components';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyCampaignsIcon } from '@/lib/v2/icons/general';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { LAST_ACTION_QUANTITY } from '@/src/presentation/components/LastActionsReport/constants';
import {
  ChannelInfoByID,
  useActionsTableColumns,
} from '@/src/presentation/components/LastActionsReport/hooks/useActionsTableColumns';

import { atomActionsList } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomTotalsProcess } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { ITableCampaignActions } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const TableContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actionsList = useAtomValue(atomActionsList);
  const totalsActionList = useAtomValue(atomTotalsProcess);
  const { actionsColumns } = useActionsTableColumns();
  const createSimpleEmail = useFeatureFlag('createSimpleEmail');
  const createSimpleSMS = useFeatureFlag('createSimpleSMS');

  const handleRowClick = useCallback(
    (row: ITableCampaignActions) => {
      const { channel } = row.action;
      const { route } = ChannelInfoByID[channel as keyof typeof ChannelInfoByID];

      const hasSummaryPath =
        (route === 'sms' && createSimpleSMS) || (route === 'email' && createSimpleEmail);

      const summaryPath = hasSummaryPath ? '/summary' : '';
      const pathNavigate = `${CAMPAIGNS_PATH}/${row.action.campaignId}/${route}/${row.action.actionId}${summaryPath}`;

      navigate(pathNavigate);
    },
    [createSimpleEmail, createSimpleSMS, navigate]
  );

  return (
    <>
      {actionsList ? (
        <Flex column withGap itemAlignment="stretch">
          <Table
            noNegativeMargin
            classNamePagination="px-4 mb-2"
            columns={actionsColumns}
            data={actionsList}
            defaultSize={LAST_ACTION_QUANTITY}
            emptyScreen={{
              totalCount: totalsActionList.total || 0,
              noResult: null,
              noData: (
                <EmptyScreenTable
                  actionText={
                    <Text className="text-center !text-14 text-emblue-text" fontWeight="normal">
                      {t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescriptionAlt')}
                      <button
                        className="cursor-pointer !text-14 font-medium text-emblue-primary"
                        data-testid="nodata-actions"
                        id="nodata-actions"
                        role="link"
                      >
                        {t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsAction')}
                      </button>
                    </Text>
                  }
                  description={t(
                    'CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescription'
                  )}
                  image={<EmptyCampaignsIcon />}
                  title={t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsTitle')}
                />
              ),
            }}
            gotoPageIndex={0}
            lastPageViewed={1}
            onRowClick={handleRowClick}
          />
        </Flex>
      ) : (
        <div className="size-full py-10">
          <TableSkeleton columns={5} />
        </div>
      )}
    </>
  );
};

export default TableContainer;
