export const DropdownCellPhoneIcon = () => {
  return (
    <svg height="20" id="check" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path d="M0,0H20V20H0Z" data-name="Trazado 3061" fill="none" id="Trazado_3061" />
      <path
        d="M16.616,15.611a6.65,6.65,0,0,1-2.544-.507.628.628,0,0,0-.727.213l-1.568,1.956a12.408,12.408,0,0,1-4.7-5.849L8.648,9.46a1.028,1.028,0,0,0,.178-.9A12.4,12.4,0,0,1,8.42,5.389.818.818,0,0,0,7.707,4.5H5.213a.818.818,0,0,0-.713.889C4.5,13.736,9.924,20.5,16.616,20.5a.818.818,0,0,0,.713-.889V16.5A.818.818,0,0,0,16.616,15.611ZM10.914,4.5v8.889l2.138-2.667h4.276V4.5Z"
        data-name="Icon material-perm-phone-msg"
        fill="#fff"
        id="Icon_material-perm-phone-msg"
        transform="translate(-0.913 -2.5)"
      />
    </svg>
  );
};
