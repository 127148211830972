import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomModalTypeProcess,
  atomScreenTypeProcess,
} from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ICheckNameResource } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ECampaignsTypeOptions,
  ICampaignListPayload,
  ICreateEditCampaigns,
  IDuplicateCampaigns,
  IDuplicateCampaignsV1,
  ModalType,
  ScreenType,
  TCampaignID,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import {
  atomCampaignsRecurrentList,
  atomCampaignsRecurrentListAll,
  atomDetailCampaignRecurrent,
  atomLoadingDetail,
  atomLoadingProcess,
  atomSelectedRowsWithID,
  atomTotalSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';

const TRANSLATE_MODAL_DELETE = 'RECURRENT_MESSAGE.DELETE_RECURRENT.TOAST_MESSAGES';
const TRANSLATE_MODAL_DUPLICATE = 'RECURRENT_MESSAGE.DUPLICATE_RECURRENT.TOAST_MESSAGES';

export const useCampaignRecurrent = () => {
  const { t } = useTranslation();
  const service = useCampaignsService();
  const serviceV1 = useService();

  const [detailCampaignRecurrent, setDetailCampaignRecurrent] = useAtom(
    atomDetailCampaignRecurrent
  );
  const setTotalsCampaignRecurrentList = useSetAtom(atomTotalsProcess);
  const setRecurrentMessageList = useSetAtom(atomCampaignsRecurrentList);
  const setRecurrentMessageListAll = useSetAtom(atomCampaignsRecurrentListAll);
  const setLoadingProcess = useSetAtom(atomLoadingProcess);
  const setLoadingDetail = useSetAtom(atomLoadingDetail);
  const selectedRowsWithID = useAtomValue(atomSelectedRowsWithID);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);
  const screenTypeProcess = useAtomValue(atomScreenTypeProcess);

  const duplicateCampaignV2 = useFeatureFlag('duplicateCampaign');

  const [loading, setLoading] = useState<boolean>(false);

  const TOAST_MESSAGES = `RECURRENT_MESSAGE.CREATE_RECURRENT.TOAST_MESSAGES`;

  const getList = useCallback(
    async (payload: ICampaignListPayload) => {
      setLoadingProcess(true);

      const response = await service.getRecurrentMessage(payload);

      setRecurrentMessageList(response?.campaigns);
      setTotalsCampaignRecurrentList({
        total: response?.total || 0,
        partial: response?.partial || 0,
      });

      setLoadingProcess(false);
    },
    [service, setRecurrentMessageList, setTotalsCampaignRecurrentList, setLoadingProcess]
  );

  const getListAll = useCallback(
    async (payload: ICampaignListPayload) => {
      setLoading(true);

      const response = await service.getRecurrentMessage(payload);
      setRecurrentMessageListAll(response?.campaigns);

      setLoading(false);
    },
    [service, setRecurrentMessageListAll, setLoading]
  );

  const createCampaignRecurrent = useCallback(
    async (name: string) => {
      setLoading(true);

      const campaignRecurrentData: ICreateEditCampaigns = {
        name: name.replace(/\s+/g, ' ').trim(),
        campaignStatesId: 1,
        campaignTypeId: Number(ECampaignsTypeOptions.AUTOMATIC),
        flowId: null,
        autoConfirmation: 1,
        notifyGroupId: 0,
        strategyId: null,
        strategyName: null,
        goal: null,
        startDate: null,
        endDate: null,
      };

      let campaignRecurrentId = {};

      if (totalSelectedRows === 1) {
        campaignRecurrentId = { campaignId: selectedRowsWithID[0] };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      } else if (modalTypeProcess === ModalType.EDIT && screenTypeProcess === ScreenType.DETAIL) {
        campaignRecurrentId = { campaignId: detailCampaignRecurrent?.campaignId };
      }

      const { statusOK, recurrentCreated } = await service.createCampaignRecurrent({
        campaignRecurrent: {
          ...campaignRecurrentData,
          ...campaignRecurrentId,
        },
      });

      let title, message;

      if (
        totalSelectedRows === 1 ||
        (modalTypeProcess === ModalType.EDIT && screenTypeProcess === ScreenType.DETAIL)
      ) {
        if (statusOK) {
          title = t(`${TOAST_MESSAGES}.EDIT.SUCCESS.title`);
          message = t(`${TOAST_MESSAGES}.EDIT.SUCCESS.message`);
        } else {
          title = t(`${TOAST_MESSAGES}.EDIT.ERROR.title`);
          message = t(`${TOAST_MESSAGES}.EDIT.ERROR.message`);
        }
      } else if (statusOK) {
        title = t(`${TOAST_MESSAGES}.SUCCESS.title`);
        message = t(`${TOAST_MESSAGES}.SUCCESS.message`);
      } else {
        title = t(`${TOAST_MESSAGES}.ERROR.title`);
        message = t(`${TOAST_MESSAGES}.ERROR.message`);
      }

      toast({
        title,
        body: message,
        variant: statusOK ? 'success' : 'error',
      });

      setLoading(false);

      return recurrentCreated;
    },
    [service, totalSelectedRows, selectedRowsWithID, t, TOAST_MESSAGES]
  );

  const duplicateRecurrent = useCallback(
    async ({ name, campaignId }: { name: string; campaignId: TCampaignID }) => {
      if (!campaignId) return;

      setLoading(true);

      const campaignPayload: IDuplicateCampaigns = {
        name: name.replace(/\s+/g, ' ').trim(),
        campaignId: Number(campaignId),
        strategyId: null,
        strategyName: '',
        goal: '',
        startDate: null,
        endDate: null,
      };

      const campaignPayloadV1: IDuplicateCampaignsV1 = {
        campaignId: Number(campaignId),
        name: name,
      };

      let statusOK, campaignIdDuplicate;

      if (duplicateCampaignV2) {
        ({ statusOK, campaignId: campaignIdDuplicate } = await service.duplicateCampaigns(
          campaignPayload
        ));
      } else {
        ({ statusOK, campaignIdDuplicate } = await serviceV1.duplicateV1Campaigns(
          campaignPayloadV1
        ));
      }

      setLoading(false);

      toast({
        title: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.title`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.title`),
        body: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.message`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.message`),
        variant: statusOK ? 'success' : 'error',
      });

      return campaignIdDuplicate;
    },
    [serviceV1, t]
  );

  const deleteRecurrent = useCallback(
    async (campaignID: number[]) => {
      if (!campaignID) return;
      const isSingle: boolean = campaignID?.length === 1;

      if (campaignID?.length === 0) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      setLoading(true);

      const { statusOK } = await serviceV1.deleteV1Campaigns(campaignID);

      if (!statusOK) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      setLoading(false);
    },
    [serviceV1, t]
  );

  const createV1Campaigns = useCallback(
    async (data: any) => {
      const { name, templateId, campaignType } = data;

      setLoading(true);

      const isSingle: boolean = name?.length === 1;

      const { statusOK } = await serviceV1.createV1Campaigns({ name, templateId, campaignType });
      if (!statusOK) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      setLoading(false);
    },
    [serviceV1, t]
  );

  const getDetailRecurrentMessage = useCallback(
    async ({ campaignID }: { campaignID: TCampaignID }) => {
      setLoadingDetail(true);
      const { campaign } = await service.getCampaignByID(campaignID);
      setDetailCampaignRecurrent(campaign);
      setLoadingDetail(false);
    },
    [service, setDetailCampaignRecurrent, setLoadingDetail]
  );

  const isValidNameResource = useCallback(
    async (payload: ICheckNameResource) => {
      const isValid: boolean = await service.checkNameResource(payload);
      return isValid;
    },
    [service]
  );

  return {
    getList,
    getListAll,
    createCampaignRecurrent,
    createV1Campaigns,
    duplicateRecurrent,
    deleteRecurrent,
    getDetailRecurrentMessage,
    isValidNameResource,
    loading,
  };
};
