import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import InsightCard, { InsightCardProps } from '@/lib/v2/examples/InsightCard/InsightCard';
import { ActiveWidgetIcon, InactiveWidgetIcon, ThumbIcon } from '@/lib/v2/icons/solid';

import {
  useNpsMutationContext,
  useNpsStateContext,
} from '@/src/modules/NPSModule/contexts/NpsContext';

import { Widgets } from '@/modules/NPSModule/interfaces';

type WidgetsFilter = Pick<Widgets, 'filter'>;

export const WidgetsFilterCards = () => {
  const { t } = useTranslation();
  const { setStatusFilter, resetPagination } = useNpsMutationContext();
  const { allWidgets, widgetsActive, widgetsInactive, isLoadingWidgetsCount } =
    useNpsStateContext();

  const statusList = useMemo<(InsightCardProps & WidgetsFilter)[]>(
    () => [
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="primary"
            svgComponent={<ThumbIcon />}
            withPadding="medium"
          />
        ),
        description: t('NPS_SCREEN.allWidgets'),
        title: allWidgets,
        isLoading: isLoadingWidgetsCount,
        filter: 'all',
        tooltip: t('NPS_SCREEN.allWidgets'),
        onClick: () => {
          resetPagination();
          setStatusFilter(['']);
        },
        id: 'totalWidgets-card',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="success"
            svgComponent={<ActiveWidgetIcon />}
            withPadding="medium"
          />
        ),
        description: t('NPS_SCREEN.activeWidgets'),
        title: widgetsActive,
        isLoading: isLoadingWidgetsCount,
        filter: 'active',
        tooltip: t('NPS_SCREEN.activeWidgets'),
        onClick: () => {
          resetPagination();
          setStatusFilter(['active']);
        },
        id: 'activeWidgets-card',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="insecure"
            svgComponent={<InactiveWidgetIcon />}
            withPadding="medium"
          />
        ),
        description: t('NPS_SCREEN.inactiveWidgets'),
        title: widgetsInactive,
        isLoading: isLoadingWidgetsCount,
        filter: 'inactive',
        tooltip: t('NPS_SCREEN.inactiveWidgets'),
        onClick: () => {
          resetPagination();
          setStatusFilter(['inactive']);
        },
        id: 'inactiveWidgets-card',
      },
    ],
    [t, resetPagination, setStatusFilter]
  );

  return (
    <div
      className="mb-6 grid grid-cols-2 gap-1 sm:grid-cols-3 lg:grid-cols-3 lg:gap-6"
      data-testid="nps-resume-cards"
    >
      {statusList.map((listItem) => {
        const { filter, ...restOfProps } = listItem;
        return <InsightCard key={filter} {...restOfProps} />;
      })}
    </div>
  );
};
