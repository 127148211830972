import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TableSkeleton, TextSkeleton } from '@/lib/components';
import { Button, Card, CardSection, FloatModal, IconSvg, Spinner } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { CodeIcon, MiniThumbIcon, RemoveIcon } from '@/lib/v2/icons/solid';

import { getEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { WidgetNPS } from '@/src/ContactsModule/utils/enums/Enums';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import { IframeOnsite } from '@/src/modules/NPSModule/components/IframeOnsite';
import { InstallCodeModal } from '@/src/modules/NPSModule/components/InstallCodeModal';
import { WidgetsTable } from '@/src/modules/NPSModule/components/WidgetsTable/WidgetsTable';
import {
  useNpsMutationContext,
  useNpsStateContext,
} from '@/src/modules/NPSModule/contexts/NpsContext';
import { useGetScriptCode } from '@/src/modules/NPSModule/hooks/useGetScriptCode';

import { NPSActionBar } from '@/modules/NPSModule/components/NPSActionBar';
import { NPSTableFilters } from '@/modules/NPSModule/components/NPSTableFilters';
import { RemoveWidgetFunction, useRemoveWidget } from '@/modules/NPSModule/hooks/useRemoveWidget';
import { ITableWidget } from '@/modules/NPSModule/interfaces';
import { getOnsiteToken } from '@/modules/NPSModule/utils/getOnsiteToken';

export const WidgetsMain = ({ onCardToggle }: { onCardToggle: (showCards: boolean) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userData] = useUserData();
  const [onsiteToken, setOnsiteToken] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const {
    npsWidgets,
    pageSize,
    isLoadingWidgets,
    gotoPageIndex,
    searchQuery,
    totalPages,
    totalNpsWidgets,
    statusFilter,
    initialStateOrderByList,
    tableOrderByList,
    isRecycleBin,
  } = useNpsStateContext();

  const {
    setOrderDirection,
    setOrderColumn,
    setIsLoading,
    resetPagination,
    setTableOrderByList,
    setSearchQuery,
    changeTablePage,
    lastRefreshIncrement,
    setIsRecycleBin,
  } = useNpsMutationContext();

  const accountIdEmblue = `emblue${userData.companyId}`;

  const env = getEnv();
  const { URL_WIDGET_API } = configData.endpoints[env === 'storybook' ? 'development' : env];

  useEffect(() => {
    let isMounted = false;
    const fetchToken = async () => {
      const token = await getOnsiteToken(env);
      if (!isMounted && token) {
        setOnsiteToken(token);
      }
    };

    void fetchToken();

    return () => {
      isMounted = true;
    };
  }, [env]);

  const scriptCode = useGetScriptCode(accountIdEmblue, onsiteToken);
  const { restoreWidgetFromTrash, deleteWidget } = useRemoveWidget(accountIdEmblue, onsiteToken);
  const [showDeleteOrRestoreModal, setShowDeleteOrRestoreModal] = useState(false);
  const [showSpinnerByAction, setShowSpinnerByAction] = useState(false);

  const [reportNpsOnsite, setReportNpsOnsite] = useState(false);
  const [urlReportOnsite, setUrlReportOnsite] = useState('');
  const [showInstallCodeModal, setShowInstallCodeModal] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmRecycle, setConfirmRecycle] = useState(false);
  const [confirmRestore, setConfirmRestore] = useState(false);

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      let orderDirectionValue: string;

      if (element.id === 'status') {
        orderDirectionValue = element.isAsc === false ? 'inactive' : 'active';
      } else {
        orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
      }
      setOrderDirection(orderDirectionValue);
      setOrderColumn(element.id);
      setIsLoading(true);
    },
    [setIsLoading]
  );

  const [selectedWidgets, setSelectedWidgets] = useState<number[]>([]);
  const [selectAllResults, setSelectAllResults] = useState(false);

  const resetWidgetsList = () => {
    resetPagination();
    setOrderColumn(undefined);
    setOrderDirection('desc');
    setTableOrderByList(initialStateOrderByList);
    setSearchQuery('');
  };

  useEffect(() => {
    if (!isChecked && selectedWidgets.length > 0) {
      setIsChecked(true);
    }
    if (isChecked && !selectedWidgets.length) {
      setIsChecked(false);
      setSelectAllResults(false);
    }
  }, [selectedWidgets]);

  const getWidgetsIds = useCallback(
    (list?: ITableWidget[]) => {
      if (!npsWidgets) return;
      const listAux: ITableWidget[] = list ?? (npsWidgets as { list: ITableWidget[] }).list;
      const widgetsIds: number[] =
        selectedWidgets.length === pageSize
          ? listAux.map((c) => c.widget.id) // all ids of page
          : listAux.filter((c, i) => selectedWidgets.includes(i)).map((c) => c.widget.id); // ids of some widgets in page
      return widgetsIds;
    },
    [npsWidgets, pageSize, selectedWidgets]
  );

  const widgetsIds = getWidgetsIds();
  const selectedWidgetId = widgetsIds?.length && widgetsIds[0];
  const typeIdWidgetNPS = WidgetNPS.ID;

  const handleRowClick = (value: ITableWidget) => {
    const newUrlReportOnsite = `${URL_WIDGET_API}/Customers/nps-stats/${value.widget.id}/${value.widget.name}/fullScreen`;
    setUrlReportOnsite(newUrlReportOnsite);
    setReportNpsOnsite(true);
  };

  const handleRemoveOrRestoreWidget = async (
    action: RemoveWidgetFunction,
    widgetId: number[],
    deleteWidget: boolean = false
  ) => {
    setShowSpinnerByAction(true);
    const { responseStatus } = await action(widgetId, deleteWidget);
    if (responseStatus === 'OK') {
      lastRefreshIncrement();
      navigate('/v2/nps');
    }
    setShowSpinnerByAction(false);
  };

  const handleRecycleBin = () => {
    setIsRecycleBin(!isRecycleBin);
    lastRefreshIncrement();
  };

  useEffect(() => {
    if ((confirmDelete || confirmRecycle) && selectedWidgetId) {
      const idsToHandle = widgetsIds && widgetsIds.length > 1 ? widgetsIds : [selectedWidgetId];
      const isDeleteOperation = confirmDelete;

      void handleRemoveOrRestoreWidget(deleteWidget, idsToHandle, isDeleteOperation);

      setConfirmDelete(false);
      setConfirmRecycle(false);
      setShowDeleteOrRestoreModal(false);
    }

    if (confirmRestore && selectedWidgetId) {
      void handleRemoveOrRestoreWidget(restoreWidgetFromTrash, [selectedWidgetId]);
      setConfirmRestore(false);
      setShowDeleteOrRestoreModal(false);
    }
  }, [confirmDelete, confirmRecycle, confirmRestore, widgetsIds]);

  const iframeData = {
    reportNpsOnsite: {
      id: 'iframeReportNpsOnsite',
      title: 'ReportNpsOnsite',
      url: urlReportOnsite,
      defaultState: '',
    },
    createNpsOnsite: {
      id: 'iframeCreateNpsOnsite',
      title: 'CreateNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/editWidget?widgetTypeId=${typeIdWidgetNPS}&fullScreen=fullScreen`,
      defaultState: false,
    },
    editNpsOnsite: {
      id: 'iframeEditNpsOnsite',
      title: 'EditNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/editWidget/${accountIdEmblue}/${selectedWidgetId}`,
      defaultState: false,
    },
    preViewNpsOnsite: {
      id: 'iframePreViewNpsOnsite',
      title: 'PreViewNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/widgets/${accountIdEmblue}/f:nps/${accountIdEmblue}/${selectedWidgetId}`,
      defaultState: false,
    },
    duplicateNpsOnsite: {
      id: 'iframeDuplicateNpsOnsite',
      title: 'DuplicateNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/cloneWidget/${selectedWidgetId}/-1/fullScreen`,
      defaultState: false,
    },
    statisticsNpsOnsite: {
      id: 'iframeStatisticsNpsOnsite',
      title: 'StatisticsNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/widgetStatistics/${accountIdEmblue}/${selectedWidgetId}`,
      defaultState: false,
    },
    testAbNpsOnsite: {
      id: 'iframeTestAbNpsOnsite',
      title: 'TestAbNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/abtests/${accountIdEmblue}`,
      defaultState: false,
    },
    deleteNpsOnsite: {
      id: 'iframeDeleteNpsOnsite',
      title: 'DeleteNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/deleteWidget/${selectedWidgetId}/${accountIdEmblue}`,
      defaultState: false,
    },
    restoreNpsOnsite: {
      id: 'iframeRestoreNpsOnsite',
      title: 'RestoreNpsOnsite',
      url: `${URL_WIDGET_API}/Customers/restoreFromRecycleBin/${selectedWidgetId}/${accountIdEmblue}`,
      defaultState: false,
    },
  };

  const [createNpsOnsite, setCreateNpsOnsite] = useState(iframeData.createNpsOnsite.defaultState);
  const [editNpsOnsite, setEditNpsOnsite] = useState(iframeData.editNpsOnsite.defaultState);
  const [preViewNpsOnsite, setPreViewNpsOnsite] = useState(
    iframeData.preViewNpsOnsite.defaultState
  );
  const [duplicateNpsOnsite, setDuplicateNpsOnsite] = useState(
    iframeData.duplicateNpsOnsite.defaultState
  );
  const [statisticsNpsOnsite, setStatisticsNpsOnsite] = useState(
    iframeData.statisticsNpsOnsite.defaultState
  );
  const [testAbNpsOnsite, setTestAbNpsOnsite] = useState(iframeData.testAbNpsOnsite.defaultState);
  const [, setDeleteNpsOnsite] = useState(iframeData.deleteNpsOnsite.defaultState);

  const [, setRestoreNpsOnsite] = useState(iframeData.restoreNpsOnsite.defaultState);

  const handleButtonClick = useCallback(
    (setStateFunction: Dispatch<SetStateAction<boolean>>, value: boolean) => () => {
      setStateFunction(value);
      onCardToggle(value);
    },
    [onCardToggle]
  );

  const handleReturnNpsListButtonClick = () => {
    setCreateNpsOnsite(false);
    setEditNpsOnsite(false);
    setPreViewNpsOnsite(false);
    setDuplicateNpsOnsite(false);
    setStatisticsNpsOnsite(false);
    setTestAbNpsOnsite(false);
    onCardToggle(false);
    setReportNpsOnsite(false);
    setIsRecycleBin(false);

    lastRefreshIncrement();
    navigate('/v2/nps');
  };

  const actionsButtons = useMemo(
    () => (
      <>
        <Button
          outline
          secondary
          iconLeft={<IconSvg height="16px" svgComponent={<CodeIcon />} width="16px" />}
          id="script-code-button"
          size="small"
          onClick={() => setShowInstallCodeModal(true)}
        >
          {t('NPS_SCREEN.installCode')}
        </Button>
        {!isRecycleBin && (
          <Button
            outline
            secondary
            iconLeft={<IconSvg height="16px" svgComponent={<RemoveIcon />} width="16px" />}
            id="recycle-bin-button"
            size="small"
            onClick={handleRecycleBin}
          >
            {t('NPS_SCREEN.recycleBin')}
          </Button>
        )}
        <Button
          iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
          id="create-widget-button"
          size="small"
          onClick={handleButtonClick(setCreateNpsOnsite, true)}
        >
          {t('NPS_SCREEN.createWidget')}
        </Button>
      </>
    ),
    [handleButtonClick, t, isRecycleBin]
  );

  const sectionSubtitle = useMemo(() => {
    if (!npsWidgets || isLoadingWidgets) {
      return <TextSkeleton />;
    } else if (!searchQuery && !statusFilter.length && totalNpsWidgets) {
      return t('NPS_SCREEN.noFilters');
    } else if ((searchQuery || statusFilter.length) && totalNpsWidgets) {
      return (
        <>
          {t('NPS_SCREEN.youHave')}{' '}
          <span className="font-medium text-[#004DBC]">{quantifier(totalNpsWidgets)}</span>{' '}
          {npsWidgets.list.length === 1
            ? t('NPS_SCREEN.npsWidgetMatch')
            : t('NPS_SCREEN.npsWidgetsMatch')}
        </>
      );
    } else {
      return t('noResultsFound');
    }
  }, [isLoadingWidgets, npsWidgets, searchQuery, t, totalNpsWidgets, statusFilter]);

  const showBackButton = !(
    !createNpsOnsite &&
    !reportNpsOnsite &&
    !editNpsOnsite &&
    !preViewNpsOnsite &&
    !statisticsNpsOnsite &&
    !duplicateNpsOnsite &&
    !testAbNpsOnsite &&
    !isRecycleBin
  );

  return (
    <>
      <Card data-testid="nps-main-component">
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={showBackButton ? null : actionsButtons}
              backButton={showBackButton}
              icon={isRecycleBin ? <RemoveIcon /> : <MiniThumbIcon />}
              justifyContentActionsButtons="end"
              subtitle={
                !createNpsOnsite &&
                !reportNpsOnsite &&
                !editNpsOnsite &&
                !preViewNpsOnsite &&
                !duplicateNpsOnsite &&
                !statisticsNpsOnsite &&
                !testAbNpsOnsite &&
                sectionSubtitle
              }
              title={isRecycleBin ? t('NPS_SCREEN.recycleBin') : t('NPS_SCREEN.tableTitle')}
              onBack={handleReturnNpsListButtonClick}
            />
            {createNpsOnsite && (
              <IframeOnsite
                idName={iframeData.createNpsOnsite.id}
                title={iframeData.createNpsOnsite.title}
                urlIframe={iframeData.createNpsOnsite.url}
              />
            )}
            {reportNpsOnsite && (
              <IframeOnsite
                idName={iframeData.reportNpsOnsite.id}
                title={iframeData.reportNpsOnsite.title}
                urlIframe={iframeData.reportNpsOnsite.url}
              />
            )}
            {editNpsOnsite && (
              <IframeOnsite
                idName={iframeData.editNpsOnsite.id}
                title={iframeData.editNpsOnsite.title}
                urlIframe={iframeData.editNpsOnsite.url}
              />
            )}
            {preViewNpsOnsite && (
              <IframeOnsite
                idName={iframeData.preViewNpsOnsite.id}
                title={iframeData.preViewNpsOnsite.title}
                urlIframe={iframeData.preViewNpsOnsite.url}
              />
            )}
            {duplicateNpsOnsite && (
              <IframeOnsite
                idName={iframeData.duplicateNpsOnsite.id}
                title={iframeData.duplicateNpsOnsite.title}
                urlIframe={iframeData.duplicateNpsOnsite.url}
              />
            )}
            {statisticsNpsOnsite && (
              <IframeOnsite
                idName={iframeData.statisticsNpsOnsite.id}
                title={iframeData.statisticsNpsOnsite.title}
                urlIframe={iframeData.statisticsNpsOnsite.url}
              />
            )}
            {testAbNpsOnsite && (
              <IframeOnsite
                idName={iframeData.testAbNpsOnsite.id}
                title={iframeData.testAbNpsOnsite.title}
                urlIframe={iframeData.testAbNpsOnsite.url}
              />
            )}
            {!createNpsOnsite &&
              !reportNpsOnsite &&
              !editNpsOnsite &&
              !preViewNpsOnsite &&
              !duplicateNpsOnsite &&
              !statisticsNpsOnsite &&
              !testAbNpsOnsite && (
                <div className="h-full w-full" id="listNps">
                  {!isLoadingWidgets && (
                    <Flex alignment="start">
                      {!isChecked ? (
                        <div className="-z-0 pl-4">
                          {npsWidgets && npsWidgets?.countTotal > 0 && (
                            <NPSTableFilters
                              disableStatusDropdown={isChecked}
                              onReset={() => resetWidgetsList()}
                            />
                          )}
                        </div>
                      ) : (
                        <NPSActionBar
                          handleDeleteWidget={handleButtonClick(setDeleteNpsOnsite, false)}
                          handleDuplicateNpsOnsite={handleButtonClick(setDuplicateNpsOnsite, true)}
                          handleEditWidget={handleButtonClick(setEditNpsOnsite, true)}
                          handleModal={setShowDeleteOrRestoreModal}
                          handlePreViewNpsOnsite={handleButtonClick(setPreViewNpsOnsite, true)}
                          handleRestoreWidget={handleButtonClick(setRestoreNpsOnsite, false)}
                          handleStatisticsNpsOnsite={handleButtonClick(
                            setStatisticsNpsOnsite,
                            true
                          )}
                          handleTestAbWidget={handleButtonClick(setTestAbNpsOnsite, true)}
                          setConfirmDelete={setConfirmDelete}
                          setConfirmRecycle={setConfirmRecycle}
                          setConfirmRestore={setConfirmRestore}
                          showDeleteOrRestoreModal={showDeleteOrRestoreModal}
                          widgetSelection={selectAllResults ? 0 : selectedWidgets.length}
                        />
                      )}
                    </Flex>
                  )}
                  {!npsWidgets && isLoadingWidgets ? (
                    <div className="py- h-full w-full">
                      <TableSkeleton />
                    </div>
                  ) : (
                    <WidgetsTable
                      changeTableOrder={changeOrderBy}
                      changeTablePage={changeTablePage}
                      goToReportNps={handleRowClick}
                      gotoPageIndex={gotoPageIndex}
                      isLoading={isLoadingWidgets}
                      lastRefreshIncrement={lastRefreshIncrement}
                      npsWidgets={npsWidgets}
                      onsiteToken={onsiteToken}
                      resetWidgetsList={resetWidgetsList}
                      selectAllResults={selectAllResults}
                      setCreateNpsOnsite={setCreateNpsOnsite}
                      setSelectedWidgets={setSelectedWidgets}
                      setTableOrderByList={setTableOrderByList}
                      tableOrderByList={tableOrderByList}
                      totalPages={totalPages}
                    />
                  )}
                </div>
              )}
          </Flex>
        </CardSection>
      </Card>

      <InstallCodeModal
        scriptCode={scriptCode}
        setShowInstallCodeModal={setShowInstallCodeModal}
        showInstallCodeModal={showInstallCodeModal}
      />

      {showSpinnerByAction && (
        <FloatModal>
          <Spinner withoutOverlay />
        </FloatModal>
      )}
    </>
  );
};
