import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardSection, Flex, IconSvg } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';
import { ReportIcon } from '@/lib/v2/icons/outline';
import { CampaignsIcon } from '@/lib/v2/icons/solid';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { useVersion } from '@/src/application/hooks/useVersion';
import { goToAllReportsHandler } from '@/src/presentation/components/ReportsTable';

import { TableContainer } from './components/TableContainer';
import { LAST_ACTION_QUANTITY } from './constants';

import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import {
  EActionTypes,
  ICampaignActionsListPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

export type LastActionsReportProps = {
  userData: UserData;
};

const payload: ICampaignActionsListPayload = {
  page: 1,
  limit: LAST_ACTION_QUANTITY,
  sortField: 'exeDate',
  sortOrder: 'desc',
  type: EActionTypes.FINISHED,
};

const LastActionsReport = ({ userData }: LastActionsReportProps) => {
  const { t } = useTranslation();

  const { getList } = useCampaignActions();
  const { version } = useVersion();
  const navigate = useNavigate();

  useEffect(() => void getList(payload), [getList]);

  return (
    <Card>
      <CardSection noPadding>
        <Flex column>
          <SectionHeader
            actionsButtons={
              <Button
                iconLeft={<IconSvg strokeColor="white" svgComponent={<ReportIcon />} />}
                id="report-finished-actions"
                size="small"
                onClick={() => goToAllReportsHandler(userData, version, navigate)}
              >
                {t('REPORT_TABLE.goToReports')}
              </Button>
            }
            icon={<IconSvg height="20px" svgComponent={<CampaignsIcon />} width="20px" />}
            title={t('REPORT_TABLE.title')}
          />
          <Flex column withGap gapSize="medium">
            <TableContainer />
          </Flex>
        </Flex>
      </CardSection>
    </Card>
  );
};

export default LastActionsReport;
