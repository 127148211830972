import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { toast } from '@/lib/v2/components';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { AccountLimitResponse } from '@/src/infrastructure/interfaces/IResponses';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { IContractPaymentInfo } from '@/src/presentation/types/interfaces/IContractPaymentInfo';
import { useGetContext } from '@/src/utils/ContextUtils';

import { useBilling } from '@/modules/MyPlanModule/hooks/useBilling';

interface StateContext {
  userData: UserData;
  canCreateGroups: boolean;
  accountLimitsData: AccountLimitResponse;
  isLoadingAccountLimits: boolean;
  paymentInfo?: IContractPaymentInfo;
}

type TypeLimit = 'groups' | 'tags' | 'segments';

interface MutationContext {
  toggleAccountLimits: (type: TypeLimit, countTotal: number) => void;
}

interface ServiceProviderProps {
  children: ReactNode;
  userData: UserData;
  paymentInfo?: IContractPaymentInfo;
}

const StateUserContext = createContext<StateContext | undefined>(undefined);
const MutationUserContext = createContext<MutationContext | undefined>(undefined);

const UserProvider = ({ children, userData, paymentInfo }: ServiceProviderProps) => {
  const [canCreateGroups, setCanCreateGroups] = useState(true);
  const { contractDetail } = useBilling();

  const userDataWithPlan = useMemo<UserData>(() => {
    return { ...userData, plan: contractDetail?.planName };
  }, [contractDetail?.planName, userData]);

  const { companyId } = userData;

  const [accountLimits, errorAccountLimits, isLoadingAccountLimits] = useEmblue(
    ServiceMethods.getAccountLimits,
    Number(companyId)
  );

  useEffect(() => {
    if (!isLoadingAccountLimits && errorAccountLimits) {
      toast({
        variant: 'error',
        title: errorAccountLimits.message,
      });
    }
  }, [errorAccountLimits, isLoadingAccountLimits]);

  const accountLimitsData: AccountLimitResponse = useMemo(() => {
    if (!accountLimits) return {} as AccountLimitResponse;
    if ('success' in accountLimits && accountLimits.success) {
      return accountLimits.data;
    }
  }, [accountLimits]) as AccountLimitResponse;

  const toggleAccountLimits = useCallback((type: TypeLimit, countTotal: number) => {
    const accountLimitsSessionStr = sessionStorage.getItem('account_limits');
    if (accountLimitsSessionStr) {
      const { groupsLimit } = JSON.parse(accountLimitsSessionStr) as AccountLimitResponse;
      switch (type) {
        case 'groups': {
          const canCreate = countTotal < groupsLimit;
          setCanCreateGroups(canCreate);
          break;
        }
      }
    }
  }, []);

  const memoizedMutations = useMemo(
    () => ({
      toggleAccountLimits,
    }),
    [toggleAccountLimits]
  );

  return (
    <MutationUserContext.Provider value={memoizedMutations}>
      <StateUserContext.Provider
        value={{
          userData: userDataWithPlan,
          canCreateGroups,
          accountLimitsData,
          isLoadingAccountLimits,
          paymentInfo,
        }}
      >
        {children}
      </StateUserContext.Provider>
    </MutationUserContext.Provider>
  );
};

export const useStateUserContext = (): StateContext =>
  useGetContext<StateContext>(StateUserContext, 'UserProvider');

export const useMutationUserContext = (): MutationContext =>
  useGetContext<MutationContext>(MutationUserContext, 'UserProvider');

export default UserProvider;
