import { Dispatch, SetStateAction } from 'react';

import CardAction from '@/lib/v2/examples/CardAction/CardAction';

import { useSegmentsCards } from '@/modules/ContactsModule/hooks/useSegmentsCards';

type SegmentsCardsContainerProps = {
  FiltersParams?: string;
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setCreateCustomSegment?: Dispatch<SetStateAction<boolean>>;
  lastRefreshIncrement?: () => void;
};
export const SegmentsCardsContainer = ({
  FiltersParams,
  setShowModal,
  setCreateCustomSegment,
  lastRefreshIncrement,
  setIsLoading,
}: SegmentsCardsContainerProps) => {
  const cards = useSegmentsCards({
    setShowModal,
    setIsLoading,
    setCreateCustomSegment,
    lastRefreshIncrement,
  });

  return (
    <div className="left grid grid-cols-1 gap-[22px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {cards.map((card) => {
        const {
          title,
          description,
          icon,
          onClickAction,
          buttonText,
          secondaryIcon,
          disabled,
          badgeColor,
          badgeText,
          btnActionId,
        } = card;
        FiltersParams = FiltersParams
          ? FiltersParams.charAt(0).toUpperCase() + FiltersParams.slice(1)
          : FiltersParams;
        if (!FiltersParams) {
          return (
            <CardAction
              key={title}
              badgeColor={badgeColor}
              badgeText={badgeText}
              btnActionId={btnActionId}
              buttonText={buttonText}
              description={description}
              disabled={disabled}
              icon={icon}
              secondaryIcon={secondaryIcon}
              title={title}
              onClickAction={onClickAction}
            />
          );
        } else if (title.toLowerCase().includes(FiltersParams.toLowerCase())) {
          return (
            <CardAction
              key={title}
              badgeColor={badgeColor}
              badgeText={badgeText}
              buttonText={buttonText}
              description={description}
              disabled={disabled}
              icon={icon}
              secondaryIcon={secondaryIcon}
              title={title}
              onClickAction={onClickAction}
            />
          );
        }
      })}
    </div>
  );
};
