import { useAtom } from 'jotai';
import { lazy, ReactNode, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { SimpleBadge } from '@/lib/components';
import { Button, Card, CardSection, IconSvg, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { useRenameModal } from '@/lib/v2/examples/RenameItem/hooks/useRenameModal';
import { DownloadIcon } from '@/lib/v2/icons/general/DownloadIcon';
import { EditPencilIcon, SegmentsIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { atomContactsDownload } from '@/src/atoms/Contacts';
import { ContactsTableContainer } from '@/src/ContactsModule/components/ContactsTableContainer/ContactsTableContainer';
import { ISegmentTableRow } from '@/src/ContactsModule/interfaces/Interfaces';
import { SegmentsSwitcherEnum } from '@/src/ContactsModule/utils/enums/SegmentsSwitcher';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useFilterState } from '@/src/modules/ContactsModule/hooks/useFilterState';
import { RenameSegment } from '@/src/modules/ContactsModule/screens/CreateSegment/components';
import { ISegmentFilter, SegmentTypeEnum } from '@/src/modules/ContactsModule/types/Segments';

import { SectionHeader } from '@/modules/ContactsModule/components';
import { SEGMENT_PATH } from '@/modules/ContactsModule/constants';
import { SegmentConditionModal } from '@/modules/ContactsModule/screens/SegmentDetail/components';

const ModalSelectable = lazy(() => import('@/lib/v2/examples/ModalSelectable'));

export const SegmentDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { segmentId } = useParams();
  const service = useService();

  const [initialLastRefreshValue] = useEmblue(ServiceMethods.getLastRefreshSegmentsValue);
  const [lastRefresh, setLastRefresh] = useState<number>(0);
  const [openContactsDownload, setOpenContactsDownload] = useState(false);

  const [subtitleCountMessage, setSubtitleCountMessage] = useState<ReactNode>();
  const [, setContactsCountFiltered] = useState<number>();
  const [segment, setSegment] = useState<ISegmentTableRow>();
  const [segmentInfo, , isLoadingSegmentInfo] = useEmblue(ServiceMethods.getSegmentInfo, {
    segmentId: Number(segmentId),
  });
  const [segmentFilter] = useEmblue(
    ServiceMethods.getSegmentFilterDetailedValuesById,
    Number(segmentId)
  );
  const [contactsFieldsResponse] = useEmblue(ServiceMethods.getFieldsFilters);
  const contactsFields = useMemo(() => {
    let items: any[] = [
      {
        id: 'email',
        label: 'Email',
        checked: true,
        disabled: true,
      },
    ];
    const data = contactsFieldsResponse?.data;

    if (!data || typeof data === 'string') return [];

    // Defaults fields
    const defaultFields = data?.defaultFields?.map((item) => ({
      ...item,
      label: t(`CONTACT_INFORMATION.${item.name}`),
    }));
    items = items.concat(defaultFields ?? []);

    // Customs fields
    const customFields =
      data?.customFields
        ?.filter((item) => item?.metadata?.fieldType !== 'extended')
        ?.map((item) => ({ ...item, label: item.name })) ?? [];
    items = items.concat(customFields);

    // Activity fields
    const activityFields =
      data?.activityFields
        ?.filter((item) => item.name !== 'lastSend')
        ?.map((item) => {
          return {
            ...item,
            id: item.name,
            label: t(`CONTACT_INFORMATION.${item.name}`),
          };
        }) ?? [];
    items = items.concat(activityFields);

    return items;
  }, [contactsFieldsResponse?.data, t]);

  const [segmentFilters, setSegmentFilters] = useState<ISegmentFilter[]>();
  const { isFilterApplied } = useFilterState();
  const [emblueSegmentsInUse] = useEmblue(ServiceMethods.getEmblueSegmentsInUse, { lastRefresh });
  const [isEmblueSegment, setIsEmblueSegment] = useState<boolean>(true);
  const [isEcommerceSegment, setIsEcommerceSegment] = useState<boolean>(true);
  const [contactsDownload, setContactsDownload] = useAtom(atomContactsDownload);
  const featureContactDownload = useFeatureFlag('contactDownload');
  const [wordingsDownload, setWordingsDownload] = useState({
    title: t('MODAL_CONTACTS_DOWNLOAD.title'),
    reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
    resumeSelected: t('MODAL_CONTACTS_DOWNLOAD.resumeSelected'),
    search: {
      label: t('MODAL_CONTACTS_DOWNLOAD.search_label'),
      placeholder: t('MODAL_CONTACTS_DOWNLOAD.search_placeholder'),
    },
    actions: {
      accept: t('MODAL_CONTACTS_DOWNLOAD.export'),
    },
  });

  const { position, editNameModalIsOpen, setEditNameModalIsOpen, handleOpenEditNameModal } =
    useRenameModal();

  if (!isLoadingSegmentInfo) {
    const segmentTypes = {
      0: SegmentsSwitcherEnum.Customized,
      1: SegmentsSwitcherEnum.EmBlueRecipes,
      2: SegmentsSwitcherEnum.ECommerce,
    };
    const segmentTypeId = segmentInfo?.segmentInfo.segmentTypeId;
    if (segmentTypeId) sessionStorage.setItem('segmentType', segmentTypes[segmentTypeId]);
  }

  const handleBack = useCallback(() => {
    const queryParams = sessionStorage.getItem('queryParamsFilters');
    sessionStorage.removeItem('queryParamsFilters');
    if (queryParams) navigate(`${SEGMENT_PATH}?${queryParams}`);
    else navigate(SEGMENT_PATH);
  }, [navigate]);

  useEffect(() => {
    if (segmentInfo && !segment) {
      setSegment(segmentInfo.segmentInfo);
    }
    if (emblueSegmentsInUse && segmentId) {
      const emblueSegment = emblueSegmentsInUse.find(
        (segment) => segment.id.toString() === segmentId
      );
      setIsEmblueSegment(!!emblueSegment);
      setIsEcommerceSegment(emblueSegment?.segmentType === SegmentTypeEnum.eCommerce);
    }
  }, [segment, segmentInfo, emblueSegmentsInUse, segmentId]);

  useEffect(() => {
    if (initialLastRefreshValue && initialLastRefreshValue > lastRefresh) {
      setLastRefresh(initialLastRefreshValue);
    }
  }, [initialLastRefreshValue]);

  const [showExtendedModal, setShowExtendedModal] = useState(false);

  useEffect(() => {
    if (segmentFilter && segmentFilter.success && typeof segmentFilter.data === 'object') {
      const segment = segmentFilter.data;
      setSegmentFilters(segment.filters);
    }
  }, [segmentFilter]);

  const icon = useMemo(
    () => <IconSvg height="30px" svgComponent={<SegmentsIcon />} width="30px" />,
    []
  );

  const title = useMemo(
    () => (
      <Flex column itemAlignment="start">
        <Flex withGap alignment="start">
          {segment && (
            <Text color="primary" fontWeight="medium" variant="title-1">
              {segment.name}
            </Text>
          )}
          {segment && segment.contacts !== undefined && (
            <Text color="primary" fontWeight="medium" variant="title-1">
              ({quantifier(segment.contacts)})
            </Text>
          )}
          {segment && !isEmblueSegment && (
            <Button
              link
              className="!h-auto !p-0"
              iconLeft={<IconSvg height="20px" svgComponent={<EditPencilIcon />} width="20px" />}
              id="renameSegmentBtn"
              tooltip={t('SEGMENT_FILTERS.renameSegment')}
              onClick={handleOpenEditNameModal}
            />
          )}
          {segment && !isEcommerceSegment && (
            <button
              data-testid="conditionsBadgeBtn"
              id="conditionsBadgeBtn"
              onClick={() => setShowExtendedModal(true)}
            >
              <SimpleBadge
                arrow
                background
                text={
                  segmentFilters
                    ? `${segmentFilters.length} ${
                        segmentFilters.length === 1
                          ? t('SEGMENTS_MAIN.filter')
                          : t('SEGMENTS_MAIN.filters')
                      }`
                    : `${t('SEGMENTS_CONDITIONS.loading')}`
                }
              />
            </button>
          )}
        </Flex>
        <Text>
          {isFilterApplied
            ? subtitleCountMessage && subtitleCountMessage
            : t('CONTACTS_MAIN.noFilters')}
        </Text>
      </Flex>
    ),
    [
      handleOpenEditNameModal,
      isEcommerceSegment,
      isEmblueSegment,
      isFilterApplied,
      segment,
      segmentFilters,
      subtitleCountMessage,
      t,
    ]
  );

  const actionEdit = useMemo(() => {
    return isEmblueSegment || !segmentId ? (
      <></>
    ) : (
      <Button
        iconLeft={
          <IconSvg
            height="15px"
            strokeColor="white"
            svgComponent={<EditPencilIcon />}
            width="15px"
          />
        }
        id="editSegmentBtn"
        onClick={() => navigate(`${SEGMENT_PATH}/edit-segment/${segmentId}`)}
      >
        {t('SEGMENT_FILTERS.editSegment')}
      </Button>
    );
  }, [navigate, segmentId, isEmblueSegment, t]);

  const isAvailableFileExport = useCallback(() => {
    const dateStorage: string = localStorage.getItem('@segments/last_download') ?? '';
    const countDownload: string = localStorage.getItem('@segments/count_download') ?? '';
    const lastDownload = new Date(dateStorage);
    const currentTime = new Date();

    if (!dateStorage || parseInt(countDownload) < 5) return true;

    const diff = currentTime.getTime() - lastDownload.getTime();
    const hours = diff / (1000 * 60 * 60);

    if (hours >= 1) {
      localStorage.removeItem('@segments/last_download');
      localStorage.removeItem('@segments/count_download');

      return true;
    }

    return false;
  }, []);

  const handleExportContacts = useCallback(
    (fieldsForExport: any[]) => {
      if (!isAvailableFileExport()) return;

      void service?.exportContacts(
        Number(segmentId),
        fieldsForExport.filter((item) => item.id !== 'email')
      );
      setOpenContactsDownload(false);
      setContactsDownload((prevContactsDownload) => ({
        ...prevContactsDownload,
        show: true,
        file: {
          ...prevContactsDownload.file,
          name: segment?.name ?? 'segment',
        },
        status: 'loading',
        error: false,
        wordings: {
          message: t('NOTIFICATION_CONTACTS_DOWNLOAD.exporting'),
          action: '',
        },
      }));

      // Limit for 2 file download in 1h
      const countDownloadFile = localStorage.getItem('@segments/count_download');
      localStorage.setItem('@segments/last_download', new Date().toISOString());
      localStorage.setItem(
        '@segments/count_download',
        (countDownloadFile ? parseInt(countDownloadFile) + 1 : 1).toString()
      );
    },
    [isAvailableFileExport, segment?.name, segmentId, service, setContactsDownload, t]
  );

  const handleOpenModalDownload = useCallback(() => {
    if (!isAvailableFileExport()) {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.limitExceeded'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: true,
      }));
    }

    setOpenContactsDownload(true);
  }, [isAvailableFileExport, setContactsDownload, t]);

  const handleCloseModalDownload = useCallback(() => {
    setOpenContactsDownload(false);

    setTimeout(() => {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: false,
      }));
    }, 500);
  }, [setContactsDownload, t]);

  const [right, top] = position;

  return (
    <>
      <div className="w-full">
        <Card>
          <CardSection noPadding>
            <div className="flex flex-col">
              <SectionHeader
                backButton
                actionsButtons={
                  <Flex withGap alignment="end">
                    {featureContactDownload && (
                      <Button
                        outline
                        standard
                        disabled={contactsDownload.show}
                        iconLeft={
                          <IconSvg
                            fillColor="secondary"
                            height="20px"
                            svgComponent={<DownloadIcon color="#004DBC" />}
                            width="20px"
                          />
                        }
                        id="download-button"
                        secondary={true}
                        tooltip={t('SEGMENT_DOWNLOAD_MODAL.title')}
                        onClick={handleOpenModalDownload}
                      >
                        {isEmblueSegment || !segmentId ? t('SEGMENT_FILTERS.download') : undefined}
                      </Button>
                    )}

                    {actionEdit}
                  </Flex>
                }
                icon={icon}
                justifyContentActionsButtons="end"
                title={title}
                onBack={handleBack}
              />
              <div className="size-full">
                <ContactsTableContainer
                  isDisableGroupDropdown
                  isDisableHappinessDropdown
                  isDisableStatusDropdown
                  isDisableTagDropdown
                  setContactsCountFiltered={setContactsCountFiltered}
                  setCountMessage={setSubtitleCountMessage}
                />
              </div>
            </div>
            <RenameSegment
              isOpen={editNameModalIsOpen}
              rightClick={right}
              setSegment={setSegment}
              topClick={top}
              value={segment?.name}
              onClose={setEditNameModalIsOpen}
            />
          </CardSection>
        </Card>
      </div>
      {showExtendedModal && segmentInfo && (
        <SegmentConditionModal
          isEmblueSegment={isEmblueSegment}
          segmentFilters={segmentFilters ?? []}
          segmentInfo={segmentInfo.segmentInfo}
          setShowModal={setShowExtendedModal}
        />
      )}

      <Suspense>
        <ModalSelectable
          isAcceptDisabled={contactsDownload.disabled}
          items={contactsFields}
          show={openContactsDownload}
          wordings={wordingsDownload}
          onAccept={(e, selected) => handleExportContacts(selected)}
          onClose={handleCloseModalDownload}
        />
      </Suspense>
    </>
  );
};
