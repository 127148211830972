/* eslint-disable no-constant-binary-expression */
import { ReactNode, useMemo } from 'react';

export interface IAvatar {
  email?: string;
  name?: string;
  lastName?: string;
  sizeXl?: boolean;
  sizeL?: boolean;
  sizeM?: boolean;
  status?: 'inactive' | 'discarded' | 'asleep' | 'new' | 'frequent' | '';
  icon?: JSX.Element | ReactNode | null;
}

export const Avatar = ({ name, lastName, sizeXl, email, status, icon, sizeL, sizeM }: IAvatar) => {
  const getInitials = useMemo(() => {
    let nInitial = '';
    if (name && name.trim() !== '') {
      nInitial = name?.trim()?.[0]?.toUpperCase();
    } else if (email) {
      nInitial = email?.trim()?.[0]?.toUpperCase();
    }

    const lnInitial =
      lastName && lastName.trim() !== '' ? lastName?.trim()?.[0]?.toUpperCase() : '';

    return nInitial + lnInitial;
  }, [name, lastName, email]);

  const currentStatusColor = useMemo(() => {
    return {
      new: 'bg-emblueStatus-newStatus text-emblueStatus-newStatus',
      frequent: 'bg-emblueStatus-frequentStatus text-emblueStatus-frequentStatus',
      asleep: 'bg-emblueStatus-asleepStatus text-emblueStatus-asleepStatus',
      inactive: 'bg-emblueStatus-inactiveStatus text-emblueStatus-inactiveStatus',
      discarded: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      vtex: 'bg-ecommerce-vtex',
    };
  }, []);

  return (!status?.length || !status) && !icon ? (
    <span
      className={`${
        sizeXl ? 'p-11' : ''
      } inline-flex size-8 items-center justify-center rounded-full bg-emblueStatus-newStatus md:size-10`}
    >
      <span
        className={`${
          sizeXl ? 'text-[24px]' : 'xl:text-[16px] 2xl:text-[20px]'
        } font-medium leading-none text-emblueStatus-newStatus`}
        data-testid="initials"
      >
        {' '}
        {getInitials}{' '}
      </span>
    </span>
  ) : (
    <span
      className={`${sizeXl ? 'p-11' : ''} ${sizeL ? 'p-7 ' : ''} ${
        sizeM ? 'p-3' : ''
      } inline-flex size-8 items-center justify-center rounded-full md:size-10 ${
        currentStatusColor[status as keyof typeof currentStatusColor]
      }`}
    >
      {icon && <span>{icon}</span>}
      <span
        className={`${
          sizeXl ? 'text-[24px]' : 'xl:text-[16px] 2xl:text-[20px]'
        } font-medium leading-none`}
        data-testid="initials"
      >
        {' '}
        {getInitials}{' '}
      </span>
    </span>
  );
};
