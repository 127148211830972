import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Carousel, IconSvg, Text } from '@/lib/v2/components';

import { EmblueAcademy } from '@/src/modules/AuthModule/images/icons/EmblueAcademy';
import { EmblueLogo } from '@/src/modules/AuthModule/images/icons/EmblueLogo';
import image1 from '@/src/modules/AuthModule/images/image1.svg';
import image2 from '@/src/modules/AuthModule/images/image2.svg';
import image3 from '@/src/modules/AuthModule/images/image3.svg';
import image4 from '@/src/modules/AuthModule/images/image4.svg';
import image5 from '@/src/modules/AuthModule/images/image5.svg';

const LoginLayout = () => {
  const { t } = useTranslation();

  const images = useMemo(
    () => [
      {
        id: 1,
        url: image1 as string,
        redirectUrl: undefined,
        title: t('AUTH.SLIDES.ONE.title'),
        description: t('AUTH.SLIDES.ONE.description'),
        className: 'mb-8',
      },
      {
        id: 2,
        url: image2 as string,
        redirectUrl: 'https://www.youtube.com/watch?v=TndVvIrywFw',
        title: t('AUTH.SLIDES.TWO.title'),
        description: t('AUTH.SLIDES.TWO.description'),
        className: '',
      },
      {
        id: 3,
        url: image3 as string,
        redirectUrl: undefined,
        title: t('AUTH.SLIDES.THREE.title'),
        description: t('AUTH.SLIDES.THREE.description'),
        className: '',
      },
      {
        id: 4,
        url: image4 as string,
        redirectUrl: undefined,
        title: t('AUTH.SLIDES.FOUR.title'),
        description: t('AUTH.SLIDES.FOUR.description'),
        className: '',
      },
      {
        id: 4,
        url: image5 as string,
        redirectUrl: 'https://academy.embluemail.com/',
        title: t('AUTH.SLIDES.FIVE.title'),
        description: t('AUTH.SLIDES.FIVE.description'),
        className: '',
      },
    ],
    [t]
  );

  return (
    <div className="relative w-full bg-emblue-secondary-hover md:h-screen">
      <IconSvg className="ml-6 !h-16 !w-20" svgComponent={<EmblueLogo />} />
      <div className="h-[calc(100%-64px)] min-h-[717px] bg-emblue-secondary-hover px-3 pb-20 sm:px-6 md:px-10 xl:px-32">
        <div className="flex size-full flex-col rounded-lg border shadow-md md:flex-row">
          <div className="h-full rounded-l-lg bg-white px-4 py-10 sm:px-8 md:w-1/2 lg:px-16">
            <Outlet />
          </div>
          <div className="flex h-full flex-col items-center justify-center rounded-r-lg bg-emblue-primary px-8 py-10 md:w-1/2 lg:px-10">
            <Carousel autoplay autoplaySpeed={4000}>
              {images.map((slide) => {
                return (
                  <div key={slide.id}>
                    <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
                      <Text
                        as="h1"
                        className="!text-[16px] lg:!text-[24px] 2xl:!text-[30px]"
                        color="tertiary"
                        variant="title-2"
                      >
                        {slide.title}
                      </Text>
                      <div className="flex flex-col">
                        <Text
                          alignment="center"
                          className={classNames(
                            '!text-[12px] lg:!text-[14px] w-[200px] xl:w-[300px] 2xl:text-[16px]',
                            slide.className
                          )}
                          color="white"
                          fontWeight="normal"
                        >
                          {slide.description}
                        </Text>
                        {slide.redirectUrl && (
                          <Text
                            link
                            alignment="center"
                            className="!text-[12px] lg:!text-[14px] 2xl:!text-[16px]"
                            color="white"
                            href={slide.redirectUrl}
                            target="_blank"
                            textDecoration="underline"
                          >
                            {t('AUTH.LOGIN.viewMore')}
                          </Text>
                        )}
                      </div>
                      <a
                        key={slide.id}
                        className="h-auto w-full"
                        href={slide.redirectUrl}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <div key={slide.id} className="h-auto w-full">
                          <img alt="carousel item" className="h-auto w-full" src={slide.url} />
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 flex w-full flex-row items-center justify-center divide-x-2 divide-emblue-primary bg-[#D9D9D9] py-3">
        <div className="px-4">
          <Text
            link
            color="primary"
            href="https://help.embluemail.com/"
            target="_blank"
            variant="text"
          >
            {t('AUTH.LOGIN.help')}
          </Text>
        </div>
        <div className="px-4">
          <Text
            link
            color="primary"
            href="https://help.embluemail.com/primeros-pasos/canales-de-contacto/"
            target="_blank"
            variant="text"
          >
            {t('AUTH.LOGIN.support')}
          </Text>
        </div>
        <div className="px-4">
          <Text link href="https://academy.embluemail.com/" target="_blank">
            <IconSvg height="25px" svgComponent={<EmblueAcademy />} width="159px" />
          </Text>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
