import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { atomActionType } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomActionInputModalIsOpen,
  atomActionTypeName,
  atomRestrictions,
} from '@/src/modules/CampaignsModule/atoms/createActionsAtom';
import { ACTION_CREATE_MESSAGE } from '@/src/modules/CampaignsModule/constants';
import { convertValuesToString } from '@/src/utils/Objects';

import { handleInvalidMFA } from '@/modules/AuthModule/hooks/useMfa';
import { ACTION_CREATED } from '@/modules/ContactsModule/constants';

export const useCreateAction = (campaignId: string) => {
  const service = useService();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setActionInputModalIsOpen = useSetAtom(atomActionInputModalIsOpen);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const actionTypeName = useAtomValue(atomActionTypeName);
  const [restrictions, setRestrictions] = useAtom(atomRestrictions);
  const actionType = useAtomValue(atomActionType);

  const [createActionIsLoading, setCreateActionIsLoading] = useState(false);
  const { userData } = useStateUserContext();

  const handleSubmit = useCallback(
    async (actionName: string, campaignName: string) => {
      setCreateActionIsLoading(true);
      actionName = actionName.trim();
      try {
        const response = await service.createCampaignAction(
          actionName,
          actionTypeName,
          campaignId ?? ''
        );
        if (response.message === ACTION_CREATE_MESSAGE) {
          const actionId = response.id;

          const userDataStr = convertValuesToString(userData);
          const { name, email, plan, contractId } = userDataStr;
          await service.sendEventTrack('actions', {
            names: name as string,
            email: email as string,
            plan: plan as string,
            contractId: contractId as string,
            type: ACTION_CREATED,
          });

          toast({
            title: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateSuccess.title'),
            body: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateSuccess.body'),
          });
          setInfoCampaignV1({
            campaign: {
              id: parseInt(campaignId || '0'),
              name: campaignName,
            },
            action: {
              id: Number(actionId),
              name: actionName,
            },
            route: actionType
              ? `/v2/campaigns/actions-${actionType}`
              : `/v2/campaigns/${campaignId}`,
          });
          navigate(`${actionTypeName}/${actionId}`);
        }
      } catch (e) {
        if ((e as Error).message === 'INVALID_MFA_TOKEN') {
          handleInvalidMFA(t);
        } else {
          toast({
            variant: 'error',
            title: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateError.title'),
            body: (e as Error).message,
          });
        }
      }
      setCreateActionIsLoading(false);
      setActionInputModalIsOpen(false);
    },
    [
      actionType,
      actionTypeName,
      campaignId,
      setActionInputModalIsOpen,
      navigate,
      service,
      setInfoCampaignV1,
      t,
    ]
  );

  const getRestrictions = useCallback(async () => {
    const response = await service.getRestrictions();
    setRestrictions(response);
  }, [service, setRestrictions]);

  useEffect(() => {
    if (restrictions !== null) return;

    void getRestrictions();
  }, [getRestrictions, restrictions]);

  return {
    handleSubmit,
    createActionIsLoading,
    restrictions,
  };
};
