import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Button } from '@/lib/v2/components';

import {
  htmlEditorAtom,
  isLoadingCFAtom,
  isLoadingSaveAtom,
  isUploadFilePageModalOpenAtom,
} from '@/src/modules/CampaignsModule/atoms/htmlEditorAtom';

import { useSaveHtml } from './hooks';

const HeaderSection = () => {
  const { t } = useTranslation(['editorHtml']);
  const navigate = useNavigate();
  const setIsUploadFileOpen = useSetAtom(isUploadFilePageModalOpenAtom);
  const { exportEditorContent, handlePreview } = useSaveHtml();
  const setHtml = useSetAtom(htmlEditorAtom);

  const isLoadingSave = useAtomValue(isLoadingSaveAtom);
  const [isLoadingCF, setIsLoadingCF] = useAtom(isLoadingCFAtom);

  const resetHTML = useCallback(() => {
    setIsLoadingCF(true);
    setHtml(RESET);
    navigate(-1);
  }, [setHtml, setIsLoadingCF, navigate]);

  const HeaderSectionButtons = useMemo(() => {
    return (
      <>
        <Button
          gray
          outline
          className="mx-1"
          disabled={isLoadingCF}
          id="header-html-upload__preview"
          size="small"
          onClick={handlePreview}
        >
          {t('preview')}
        </Button>
        <Button
          gray
          outline
          className="mx-1"
          disabled={isLoadingCF}
          id="header-html-upload__upload"
          size="small"
          onClick={() => setIsUploadFileOpen(true)}
        >
          {t('uploadHtml')}
        </Button>
        <Button
          className="mx-1"
          disabled={isLoadingCF}
          id="header-html-upload__save"
          isLoading={isLoadingSave}
          size="small"
          onClick={exportEditorContent}
        >
          {t('saveMessage')}
        </Button>
      </>
    );
  }, [exportEditorContent, handlePreview, isLoadingCF, isLoadingSave, setIsUploadFileOpen, t]);

  const HeaderSectionPaths = useMemo(
    () => [
      {
        name: t('messageEditor'),
      },
    ],
    [t]
  );

  return (
    <Breadcrumb
      actionButtons={HeaderSectionButtons}
      customPaths={HeaderSectionPaths}
      handleOnBackClick={resetHTML}
    />
  );
};

export default HeaderSection;
