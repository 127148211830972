import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CardSection, Flex } from '@/lib/v2/components';

import {
  atomDeliveryInformation,
  atomDeliveryType,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { SchedulingSending } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling';
import { useDeliveryOptions } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/hooks/useDeliveryOptions';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { EStepHeaderAction } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const EmailSchedulingMain = () => {
  const deliveryInformation = useAtomValue(atomDeliveryInformation);
  const setDeliveryTypeSelected = useSetAtom(atomDeliveryType);

  const { immediateDelivery, scheduledDelivery } = useDeliveryOptions();
  const { actionId } = useParams();
  const { getDeliveryAction, loading } = useActionEmailData(Number(actionId));

  const deliveryOptions = [immediateDelivery, scheduledDelivery];
  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);

  useLayoutEffect(() => {
    if (currentStep !== EStepHeaderAction.MIDDLE) {
      setCurrentStep(EStepHeaderAction.MIDDLE);
    }
  }, [currentStep, setCurrentStep]);

  useLayoutEffect(() => void getDeliveryAction(), [getDeliveryAction]);

  useLayoutEffect(() => {
    if (deliveryInformation) {
      setDeliveryTypeSelected(
        deliveryInformation?.isImmediateShipping ? immediateDelivery.id : scheduledDelivery.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryInformation]);

  return (
    <div className="relative z-[0] bg-white">
      <CardSection noPadding>
        <Flex column>
          {deliveryInformation && (
            <SchedulingSending deliveryOptions={deliveryOptions} loading={loading} />
          )}
        </Flex>
      </CardSection>
    </div>
  );
};

export default EmailSchedulingMain;
