import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, If } from '@/lib/v2/components';
import { RenameItem } from '@/lib/v2/examples';
import { useRenameModal } from '@/lib/v2/examples/RenameItem/hooks/useRenameModal';

import {
  atomDisableEditAction,
  atomGlobalLoadingAction,
  atomProcessingAction,
} from '@/src/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomHeaderCurrentStep } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoSms } from '@/src/modules/CampaignsModule/atoms/actionSMSAtom';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { useEditActions } from '@/src/modules/CampaignsModule/components/CampaignsModal/EditActions/hooks/useEditActions';
import { useActionData } from '@/src/modules/CampaignsModule/hooks/useActionData';
import { useCampaignActions } from '@/src/modules/CampaignsModule/hooks/useCampaignActions';
import { ISmsInfoResponseV1 } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { isActionEditable } from '@/src/modules/CampaignsModule/utils';

import { BackToCampaignsModal } from './components/BackToCampaignsModal';
import { HeaderActionButtons } from './components/HeaderActionButtons';

const HeaderSectionActions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId, actionId } = useParams();
  const { pathname } = useLocation();

  const [toggleBackToCampaignsModal, setToggleBackToCampaignsModal] = useState<boolean>(false);
  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const [disableEditAction, setDisableEditAction] = useAtom(atomDisableEditAction);
  const globalLoadingAction = useAtomValue(atomGlobalLoadingAction);
  const processingAction = useAtomValue(atomProcessingAction);
  const currentStep = useAtomValue(atomHeaderCurrentStep);
  const setActionInfoSms = useSetAtom(atomActionInfoSms);

  const { action, refetch } = useActionData(Number(actionId));
  const { VALIDATION_RULES, isLoadingDebounce } = useEditActions(action?.name, Number(campaignId));
  const { editAction, loading } = useCampaignActions();
  const { position, editNameModalIsOpen, setEditNameModalIsOpen, handleOpenEditNameModal } =
    useRenameModal();
  const [right, top] = position;

  useEffect(() => {
    setDisableEditAction(!isActionEditable(Number(action?.status)));
  }, [action?.status, setDisableEditAction]);

  useEffect(() => {
    return () => setActionInfoSms({} as ISmsInfoResponseV1);
  }, [setActionInfoSms]);

  useEffect(() => {
    if (pathname.includes('/summary')) refetch();
  }, [pathname, refetch]);

  const mainActionPath = useMemo(() => {
    const parts = pathname.replace(/^\/|\/$/g, '').split('/');
    const mainPath = parts.slice(3, 5).join('/');
    return mainPath;
  }, [pathname]);

  const handleApplyRename = useCallback(
    async (nameValue: string) => {
      const statusOK = await editAction({ name: nameValue, actionId: Number(actionId) });

      if (statusOK) {
        refetch();

        setInfoCampaignV1({
          ...infoCampaignV1,
          action: { id: Number(actionId), name: nameValue },
        });
      }

      setEditNameModalIsOpen(false);
    },
    [actionId, editAction, infoCampaignV1, setEditNameModalIsOpen, setInfoCampaignV1, refetch]
  );

  const handleBackHeader = useCallback(() => {
    if (pathname.includes('/scheduling') || pathname.includes('/summary')) {
      navigate(-1);
    } else {
      !isActionEditable(Number(action?.status))
        ? navigate(`/v2/campaigns/${Number(campaignId)}`)
        : setToggleBackToCampaignsModal(true);
    }
  }, [action?.status, campaignId, navigate, pathname]);

  /* cspell:disable*/
  const HeaderSectionPaths = useMemo(() => {
    const breadcrumbLinks = [
      {
        name: action ? action.campaignName : '',
        to: {
          path: action ? `/v2/campaigns/${action.campaignId}` : '',
        },
      },
      {
        name: action ? action.name : '',
        to: {
          path: mainActionPath,
        },
      },
    ];
    if (pathname.includes('/scheduling')) {
      breadcrumbLinks.push({
        name: t('BREADCRUMB.sendConfiguration'),
        to: {
          path: `${mainActionPath}/scheduling`,
        },
      });
    }

    if (pathname.includes('/summary')) {
      breadcrumbLinks.push(
        {
          name: t('BREADCRUMB.sendConfiguration'),
          to: {
            path: `${mainActionPath}/scheduling`,
          },
        },
        {
          name: t('BREADCRUMB.summary'),
          to: {
            path: `${mainActionPath}/summary`,
          },
        }
      );
    }
    return breadcrumbLinks;
  }, [action, pathname, t, mainActionPath]);
  /* cspell:enable*/

  const HeaderSectionActionButtons = useMemo(
    () => (
      <HeaderActionButtons
        disabled={disableEditAction}
        loading={globalLoadingAction}
        processing={processingAction}
        step={currentStep}
      />
    ),
    [currentStep, disableEditAction, globalLoadingAction, processingAction]
  );

  return (
    <div className="relative z-[1] shadow-md">
      <If condition={!!action}>
        <Breadcrumb
          actionButtons={HeaderSectionActionButtons}
          customPaths={HeaderSectionPaths}
          handleOnBackClick={handleBackHeader}
          isEditable={!pathname.includes('scheduling') && !pathname.includes('summary')}
          itemId={action?.actionId}
          onEditableClick={handleOpenEditNameModal}
        />
      </If>

      <RenameItem
        actionButtonText={t('ITEMS_FILTERS.apply')}
        id="rename-action"
        isLoading={loading}
        isLoadingExternal={isLoadingDebounce}
        isOpen={editNameModalIsOpen}
        placeholder={t('CAMPAIGNS_ACTIONS.renameAction')}
        rightClick={right}
        rules={VALIDATION_RULES}
        title={t('ITEMS_FILTERS.rename')}
        topClick={top}
        value={action?.name}
        onActionButton={handleApplyRename}
        onClose={() => setEditNameModalIsOpen(false)}
      />

      {/* <ConfirmAction /> */}

      <BackToCampaignsModal
        isOpen={toggleBackToCampaignsModal}
        onClose={setToggleBackToCampaignsModal}
      />
    </div>
  );
};

export default HeaderSectionActions;
