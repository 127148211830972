import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionBar } from '@/lib/components/ActionBar';
import { Button, IconSvg, Text } from '@/lib/v2/components';
import { ModalConfirmation } from '@/lib/v2/examples';
import {
  ABTestIcon,
  CopyIcon,
  EyeIcon,
  PencilIcon,
  RemoveIcon,
  RestorePageIcon,
  StatisticsIcon,
} from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { useNpsStateContext } from '@/src/modules/NPSModule/contexts/NpsContext';

interface INPSActionBarProps {
  widgetSelection: number;
  setConfirmDelete: Dispatch<SetStateAction<boolean>>;
  setConfirmRecycle: Dispatch<SetStateAction<boolean>>;
  setConfirmRestore: Dispatch<SetStateAction<boolean>>;
  handleEditWidget: () => void;
  handlePreViewNpsOnsite: () => void;
  handleDuplicateNpsOnsite: () => void;
  handleStatisticsNpsOnsite: () => void;
  handleTestAbWidget: () => void;
  handleDeleteWidget: () => void;
  handleRestoreWidget: () => void;
  handleModal: Dispatch<SetStateAction<boolean>>;
  showDeleteOrRestoreModal: boolean;
}

interface ActionButton {
  icon: React.ReactElement;
  handleOnClick: () => void;
  tooltip: string;
  flagOn: boolean;
  disabled: boolean;
  disabledTooltip?: string;
  availableOnMultipleSelection: boolean;
  id: string;
}

export const NPSActionBar = ({
  widgetSelection,
  setConfirmDelete,
  setConfirmRecycle,
  setConfirmRestore,
  handleDeleteWidget,
  handleRestoreWidget,
  handleEditWidget,
  handlePreViewNpsOnsite,
  handleDuplicateNpsOnsite,
  handleStatisticsNpsOnsite,
  handleTestAbWidget,
  handleModal,
  showDeleteOrRestoreModal = false,
}: INPSActionBarProps) => {
  const { t } = useTranslation();
  const [isConfirmRestoreModal, setIsConfirmRestoreModal] = useState(false);
  const { isRecycleBin } = useNpsStateContext();
  /* cspell:disable */
  const actionButtons: ActionButton[] = [
    {
      icon: <IconSvg height="28px" svgComponent={<PencilIcon />} width="28px" />,
      handleOnClick: () => {
        handleEditWidget();
      },
      tooltip: t('NPS_SCREEN.editWidget'),
      flagOn: useFeatureFlag('showNpsModule'),
      disabled: false,
      disabledTooltip: '',
      availableOnMultipleSelection: false,
      id: 'action-edit-widget',
    },
    {
      icon: <IconSvg height="28px" svgComponent={<EyeIcon />} width="28px" />,
      handleOnClick: () => {
        handlePreViewNpsOnsite();
      },
      tooltip: t('NPS_SCREEN.previewWidget'),
      flagOn: useFeatureFlag('showNpsModule'),
      disabled: false,
      disabledTooltip: '',
      availableOnMultipleSelection: false,
      id: 'action-preview-widget',
    },
    {
      icon: <IconSvg height="28px" svgComponent={<StatisticsIcon />} width="28px" />,
      handleOnClick: () => {
        handleStatisticsNpsOnsite();
      },
      tooltip: t('NPS_SCREEN.widgetStatistics'),
      flagOn: useFeatureFlag('showNpsModule'),
      disabled: false,
      disabledTooltip: '',
      availableOnMultipleSelection: false,
      id: 'action-statistics-widget',
    },
    {
      icon: <IconSvg height="28px" svgComponent={<ABTestIcon />} width="28px" />,
      handleOnClick: () => {
        handleTestAbWidget();
      },
      tooltip: t('NPS_SCREEN.abtestWidget'),
      flagOn: useFeatureFlag('showNpsModule'),
      disabled: false,
      disabledTooltip: '',
      availableOnMultipleSelection: false,
      id: 'action-abtest-widget',
    },
    {
      icon: <IconSvg height="28px" svgComponent={<CopyIcon />} width="28px" />,
      handleOnClick: () => {
        handleDuplicateNpsOnsite();
      },
      tooltip: t('NPS_SCREEN.duplicateWidget'),
      flagOn: useFeatureFlag('showNpsModule'),
      disabled: false,
      disabledTooltip: '',
      availableOnMultipleSelection: false,
      id: 'action-duplicate-widget',
    },
    {
      icon: <IconSvg height="28px" svgComponent={<RestorePageIcon />} width="28px" />,
      handleOnClick: () => {
        setIsConfirmRestoreModal(true);
        handleModal(true);
        handleRestoreWidget();
      },
      tooltip: t('NPS_SCREEN.restoreWidget'),
      flagOn: useFeatureFlag('showNpsModule'),
      disabled: false,
      disabledTooltip: '',
      availableOnMultipleSelection: false,
      id: 'action-restore-widget',
    },
    {
      icon: <IconSvg height="28px" svgComponent={<RemoveIcon />} width="28px" />,
      handleOnClick: () => {
        handleModal(true);
        handleDeleteWidget();
      },
      tooltip: t('NPS_SCREEN.deleteWidget'),
      flagOn: useFeatureFlag('showNpsModule'),
      disabled: false,
      disabledTooltip: '',
      availableOnMultipleSelection: true,
      id: 'action-delete',
    },
  ];

  const renderActionButton = (element: ActionButton) => {
    if (isRecycleBin) {
      if (element.id === 'action-delete' || element.id === 'action-restore-widget') {
        return (
          <div key={element.id} className="mr-2">
            <Button
              outline
              primary
              standard
              disabled={element.disabled}
              iconLeft={element.icon}
              id={element.id}
              tooltip={element.disabled ? element.disabledTooltip : element.tooltip}
              onClick={element.handleOnClick}
            />
          </div>
        );
      }
    } else if (element.id !== 'action-restore-widget') {
      return (
        <div key={element.id} className="mr-2">
          <Button
            outline
            primary
            standard
            disabled={element.disabled}
            iconLeft={element.icon}
            id={element.id}
            tooltip={element.disabled ? element.disabledTooltip : element.tooltip}
            onClick={element.handleOnClick}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <ActionBar>
      <div>
        <section className="flex h-full items-center">
          <div className="text-xs ml-10 text-white" style={{ fontSize: '12px' }}>
            {`${quantifier(widgetSelection)} ${t('CONTACTS_ACTIONS_DROPDOWN.Selected')}`}
          </div>
          <div className="ml-5 flex">
            {actionButtons.map((element) => {
              if (widgetSelection === 1 && !element.availableOnMultipleSelection) {
                return element.flagOn && renderActionButton(element);
              }
              return (
                element.flagOn &&
                element.availableOnMultipleSelection &&
                renderActionButton(element)
              );
            })}
          </div>
        </section>
      </div>
      <ModalConfirmation
        isOpen={showDeleteOrRestoreModal}
        textConfirmButton={
          isConfirmRestoreModal
            ? t('NPS_SCREEN.restoreModalConfirmation')
            : t('NPS_SCREEN.deleteModalConfirmation')
        }
        textSecondaryButton={
          isRecycleBin ? t('NPS_SCREEN.cancel') : t('NPS_SCREEN.deleteModalMoveToTrash')
        }
        onClickSecondaryButton={() => {
          isRecycleBin ? handleModal(false) : setConfirmRecycle(true);
          setTimeout(() => {
            setIsConfirmRestoreModal(false);
          }, 500);
        }}
        onClose={() => {
          handleModal(false);
          setTimeout(() => {
            setIsConfirmRestoreModal(false);
          }, 500);
        }}
        onConfirmButton={() =>
          isConfirmRestoreModal ? setConfirmRestore(true) : setConfirmDelete(true)
        }
      >
        {isRecycleBin && isConfirmRestoreModal ? (
          <>
            <Text className="font-medium text-[#01263F]" variant="sub-headline">
              {t(
                widgetSelection === 1
                  ? 'NPS_SCREEN.restoreModalTitle'
                  : 'NPS_SCREEN.restoreModalTitlePlural'
              )}
            </Text>
          </>
        ) : (
          isRecycleBin &&
          !isConfirmRestoreModal && (
            <>
              <Text className="font-medium text-[#01263F]" variant="sub-headline">
                {t(
                  widgetSelection === 1
                    ? 'NPS_SCREEN.deleteModalTitle'
                    : 'NPS_SCREEN.deleteModalTitlePlural'
                )}
              </Text>
              <Text className="font-medium text-[#01263F]" variant="text">
                {t(
                  widgetSelection === 1
                    ? 'NPS_SCREEN.deleteModalDescription2'
                    : 'NPS_SCREEN.deleteModalDescriptionPlural2'
                )}
              </Text>
            </>
          )
        )}
        {!isRecycleBin && (
          <>
            <Text className="font-medium text-[#01263F]" variant="sub-headline">
              {t(
                widgetSelection === 1
                  ? 'NPS_SCREEN.deleteModalTitle'
                  : 'NPS_SCREEN.deleteModalTitlePlural'
              )}
            </Text>
            <Text className="font-medium text-[#01263F]" variant="text">
              {t(
                widgetSelection === 1
                  ? 'NPS_SCREEN.deleteModalDescription1'
                  : 'NPS_SCREEN.deleteModalDescriptionPlural1'
              )}
            </Text>
            <Text className="font-medium text-[#01263F]" variant="text">
              {t(
                widgetSelection === 1
                  ? 'NPS_SCREEN.deleteModalDescription2'
                  : 'NPS_SCREEN.deleteModalDescriptionPlural2'
              )}
            </Text>
          </>
        )}
      </ModalConfirmation>
    </ActionBar>
  );
};
