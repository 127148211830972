import _ from 'lodash';
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { RenameItem } from '@/lib/v2/examples';

import { ISegmentTableRow } from '@/src/ContactsModule/interfaces/Interfaces';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

import { SEGMENT_PATH } from '@/modules/ContactsModule/constants';
import {
  useMutationCreateSegmentContext,
  useStateCreateSegmentContext,
} from '@/modules/ContactsModule/contexts/CreateSegmentContext';

interface RenameSegmentProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  rightClick: number;
  topClick: number;
  setSegment?: Dispatch<SetStateAction<ISegmentTableRow | undefined>>;
  value?: string;
}

const RenameSegment = ({
  isOpen,
  onClose,
  rightClick,
  topClick,
  setSegment,
  value,
}: RenameSegmentProps) => {
  const [name, setName] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setSegmentName } = useMutationCreateSegmentContext();
  const { isEditPath, segmentName } = useStateCreateSegmentContext();

  const isDetailSegmentPath = useMatch('/v2/contacts/segments/:segmentId');

  const service = useService();
  const { segmentId } = useParams();

  const handleApply = useCallback(
    async (nameValue: string) => {
      setIsEditing(true);
      if (isDetailSegmentPath && segmentId) {
        const renameResponse = await service?.renameSegment(nameValue, Number(segmentId));

        if (renameResponse)
          setSegment?.((prevSegment) => {
            const newSegment = _.cloneDeep(prevSegment);
            newSegment && (newSegment.name = nameValue);
            return newSegment;
          });
        setIsEditing(false);
        onClose(false);
        return;
      }
      setIsEditing(false);
      onClose(false);
      if (isEditPath) setSegmentName(nameValue);
      else navigate(`${SEGMENT_PATH}/create-segment/${nameValue}`);
    },
    [
      isDetailSegmentPath,
      isEditPath,
      navigate,
      onClose,
      segmentId,
      service,
      setSegment,
      setSegmentName,
    ]
  );

  const segmentCurrentName = value ?? segmentName;

  useEffect(() => {
    if (segmentCurrentName.length > 0) setName(segmentCurrentName);
  }, [segmentCurrentName, segmentName, value]);

  return (
    <RenameItem
      actionButtonText={t('ITEMS_FILTERS.apply')}
      isLoading={isEditing}
      isOpen={isOpen}
      placeholder={t('ITEMS_FILTERS.renameSegment')}
      rightClick={rightClick}
      title={t('ITEMS_FILTERS.rename')}
      topClick={topClick}
      value={name}
      onActionButton={handleApply}
      onClose={() => onClose(false)}
    />
  );
};

export default memo(RenameSegment);
