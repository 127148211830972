import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Node, Position, useNodes } from 'reactflow';

import { Button, IconSvg, Text } from '@/lib/v2/components';
import { CubeIcon } from '@/lib/v2/icons/outline';

import { TRANSLATE_RULES_CALL_TO_ACTION } from '@/src/modules/RulesModule/constants';
import { AddOperationTypes } from '@/src/modules/RulesModule/interfaces';

interface InitialNodeData {
  type: string;
}

interface Props {
  title: string;
}

const InitialNode = ({ title }: Props) => {
  const { t } = useTranslation('rules');
  const nodes = useNodes<InitialNodeData>();

  const haveTriggerNode = nodes.some(
    (node: Node<InitialNodeData>) => node.data.type === AddOperationTypes.trigger
  );

  return (
    <>
      <div className="mb-2 flex items-center">
        <IconSvg
          fillColor="primary"
          height="24px"
          id="cube"
          svgComponent={<CubeIcon />}
          width="24px"
        />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {title}
        </Text>
      </div>
      <hr className="w-full min-w-64" />
      <Button link className="text-14">
        {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.clickToEdit`)}
      </Button>
      {haveTriggerNode && (
        <>
          <Handle className="handle" isConnectable={false} position={Position.Top} type="target" />
          <Handle
            className="handle"
            isConnectable={false}
            position={Position.Bottom}
            type="source"
          />
        </>
      )}
    </>
  );
};

export default memo(InitialNode);
