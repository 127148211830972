/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExclamationIcon } from '@heroicons/react/solid';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { TopBar } from '@/lib/components/TopBar/TopBar';
import { IconSvg } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { iframeInit as intercomInit, sendEvent } from './iframeIntercom';
import useMessage from './useMessage';

function showIframe(iframe: HTMLIFrameElement) {
  iframe.style.display = 'block';
}

function hideIframe(iframe: HTMLIFrameElement) {
  iframe.style.display = 'none';
}

let stateHandler: {
  status: 'connecting' | 'connected';
};

let lastSentTab: string | undefined;

function updateStateHandler(iframe: HTMLIFrameElement, tab: string) {
  if (!stateHandler) {
    stateHandler = {
      status: 'connecting',
    };
    // eslint-disable-next-line promise/catch-or-return
    (async () => {
      await intercomInit(iframe);
      stateHandler.status = 'connected';
      sendEvent('tabSwitch', { tab: lastSentTab || tab }, iframe);
      return;
    })().finally(() => {});
  }
  if (stateHandler.status === 'connected') {
    sendEvent('tabSwitch', { tab }, iframe);
  } else {
    lastSentTab = tab;
  }
}

function initIframe({
  targetIframe,
  enabled,
  containerId = 'v1-container',
  parentId = 'v1-container-parent',
  deprecateCampaigns,
}: {
  targetIframe: string;
  enabled: boolean;
  containerId?: string;
  parentId?: string;
  deprecateCampaigns?: boolean;
}) {
  let iframe = document.getElementById(containerId);

  if (!iframe) {
    const parent = document.createElement('div');
    parent.id = parentId;
    parent.style.position = 'relative';
    document.body.appendChild(parent);
    iframe = document.createElement('iframe');
    iframe.id = containerId;
    iframe.setAttribute('src', targetIframe);
    iframe.setAttribute('border', 'none');
    iframe.style.position = 'absolute';
    iframe.style.left = '0px';
    iframe.style.right = '0px';
    iframe.style.top = '-64px';
    iframe.style.marginTop = '23px';
    iframe.style.bottom = '0px';
    iframe.style.width = '100%';
    iframe.style.height = 'calc(100vh - 23px)';
    if (!enabled) {
      iframe.style.display = 'none';
    }
    parent.appendChild(iframe);
  } else if (deprecateCampaigns) {
    //TODO: remove this else if when the deprecation is done
    iframe.style.top = '-38px';
    iframe.style.height = 'calc(100vh - 50px)';
  } else {
    iframe.style.top = '-64px';
    iframe.style.height = 'calc(100vh - 23px)';
  }
  return iframe as HTMLIFrameElement;
}

type IframeContainerProps = V1CompatHandlerProps;

function IframeContainer(props: IframeContainerProps) {
  const val = useRef<IframeContainerProps>();
  const showDeprecateCampaignsV1 = useFeatureFlag('showDeprecateCampaignsV1');
  const showCampaigns = useFeatureFlag('showCampaigns');

  useEffect(() => {
    val.current = props;
  }, [props]);

  useEffect(() => {
    const iframe = initIframe({
      targetIframe: props.targetIframe,
      enabled: props.enabled,
      deprecateCampaigns:
        showDeprecateCampaignsV1 && props.tab?.includes('campaigns') && !showCampaigns,
    });
    props.enabled && showIframe(iframe);
    updateStateHandler(iframe, props.tab ?? '');
    return () => {
      if (iframe) {
        hideIframe(iframe);
      }
    };
  }, [
    val,
    props.tab,
    props.onTabUpdate,
    props.targetIframe,
    props.enabled,
    showDeprecateCampaignsV1,
    showCampaigns,
  ]);

  useMessage('emblueCompat', (_, payload: { event: string; tab: string }) => {
    if (payload.event === 'tabSwitch') {
      props.onTabUpdate?.(payload.tab);
    }
  });

  return null;
}
interface V1CompatHandlerProps {
  tab?: string;
  enabled: boolean;
  targetIframe: string;
  onTabUpdate?: (tab: string) => void;
}
export const V1CompatHandler = ({
  enabled = false,
  tab,
  onTabUpdate,
  targetIframe,
}: V1CompatHandlerProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const showDeprecateCampaignsV1 = useFeatureFlag('showDeprecateCampaignsV1');
  const showCampaigns = useFeatureFlag('showCampaigns');

  if (!tab) {
    tab = location.pathname;
  }
  const defaultTabUpdate = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );
  //TODO: remove this alert when the deprecation is done
  return (
    <div className="relative">
      {showDeprecateCampaignsV1 && tab.includes('campaigns') && !showCampaigns && (
        <div className="warning-campaigns absolute top-0 z-10 flex w-full items-center gap-2 border border-emblue-passive bg-[#FEFCEA] px-3 py-1">
          <IconSvg
            className="relative top-[1px]"
            fillColor="passive"
            height="16px"
            svgComponent={<ExclamationIcon />}
            width="16px"
          />
          <div className="text-14 text-emblue-text">
            <span className="font-medium">{t('CAMPAIGNS_MAIN.deprecated.featured')}</span>
            {t('CAMPAIGNS_MAIN.deprecated.description')}
          </div>
        </div>
      )}
      <IframeContainer
        enabled={enabled}
        tab={tab}
        targetIframe={targetIframe}
        onTabUpdate={onTabUpdate || defaultTabUpdate}
      />
    </div>
  );
};

export const V1CompatHandlerTopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div style={{ position: 'relative' }}>
      <TopBar email="jdoe@embluemail.com" name="John Doe" />
      <V1CompatHandler
        enabled
        tab={location.pathname}
        targetIframe={
          'https://apps.embluemail.com/r/ema-app-new-ui/4563e3b070120294bc00b1836ea26113eb9f3585/tabs-demo.html'
        }
        onTabUpdate={(tabValue: string) => {
          navigate(tabValue);
        }}
      />
    </div>
  );
};
