import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { memo, ReactNode, useMemo } from 'react';

import { IconSvg, Text } from '@/lib/v2/components';
import { ColorsIcon } from '@/lib/v2/components/IconSvg/types';
import { CloseIcon } from '@/lib/v2/icons/outline';

import './Alert.tailwind.css';

export interface AlertProps {
  type?: 'success' | 'warning' | 'info' | 'error';
  title?: string;
  description?: string;
  onClose?: () => void;
  textButton?: string;
  withLine?: boolean;
  onClickButton?: () => void;
  className?: string;
  children?: ReactNode;
  rounded?: boolean;
  borderLess?: boolean;
}

const Alert = ({
  type = 'success',
  title = '',
  description = '',
  onClose,
  textButton,
  withLine,
  onClickButton,
  className = '',
  children,
  rounded = true,
  borderLess = false,
}: AlertProps) => {
  const classes = classNames(
    'flex flex-row gap-1 p-2 lg:p-4  relative w-full',
    `bg-alert-${type}`,
    {
      border: !withLine,
      'border-0 border-l-4': withLine,
      'rounded-lg': rounded && !withLine,
      'border-0': borderLess,
    },
    className
  );
  const fillColor = useMemo(
    () => ({
      success: 'success',
      warning: 'passive',
      info: 'primary',
      error: 'destructive',
    }),
    []
  );

  const icon = useMemo(
    () => ({
      success: <CheckCircleIcon />,
      warning: <ExclamationIcon />,
      info: <InformationCircleIcon />,
      error: <ExclamationCircleIcon />,
    }),
    []
  );

  const textClasses = classNames('!text-[12px] lg:!text-[14px] text-wrap break-words');

  return (
    <div className={classes}>
      <IconSvg
        className="mt-0.5 min-w-[20px]"
        fillColor={fillColor[type] as ColorsIcon}
        height="20px"
        svgComponent={icon[type]}
        width="20px"
      />
      <div className="flex flex-col">
        <Text as="h2" className={textClasses} color="dark" fontWeight="medium">
          {title}
        </Text>
        <Text className={textClasses} color="dark" fontWeight="normal">
          {description}
        </Text>
        {textButton && (
          <button
            className={classNames('mt-2 font-medium inline-flex', textClasses)}
            onClick={onClickButton}
          >
            {textButton}
          </button>
        )}
        <div>{children}</div>
      </div>
      {onClose && (
        <button
          className="absolute right-4 top-4 inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
          type="button"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <IconSvg
            fillColor={fillColor[type] as ColorsIcon}
            height="12px"
            svgComponent={<CloseIcon />}
            width="12px"
          />
        </button>
      )}
    </div>
  );
};

export default memo(Alert);
