import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { Loadable } from 'jotai/vanilla/utils/loadable';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { atomCustomFieldsService } from '@/src/application/atoms/service/customFields.service';
import { ICustomFieldsResponse } from '@/src/infrastructure/interfaces/ICustomFields';

import {
  ECustomFieldFilterType,
  ECustomFieldsByType,
  ESERVICES_STATUS_ATOM,
  ICustomFieldsFilterParams,
  ICustomFieldsParams,
} from './interfaces/ICustomField';

//TODO: This custom hook not implemented activityFields type
export const useCustomField = () => {
  const { t } = useTranslation();

  const customFieldsListBase = useAtomValue(
    loadable(atomCustomFieldsService)
  ) as Loadable<ICustomFieldsResponse>;

  const getCustomFieldsByType = useCallback(
    ({ type = ECustomFieldsByType.ALL }: ICustomFieldsParams) => {
      if (customFieldsListBase.state !== ESERVICES_STATUS_ATOM.HAS_DATA) return [];

      return type === ECustomFieldsByType.ALL
        ? [...customFieldsListBase.data.defaultFields, ...customFieldsListBase.data.customFields]
        : customFieldsListBase.data[type] || [];
    },
    [customFieldsListBase]
  );

  const getCustomFieldsFilter = useCallback(
    ({ ids, filterType }: ICustomFieldsFilterParams) => {
      const customFieldList = getCustomFieldsByType({ type: ECustomFieldsByType.ALL });
      const idSet = new Set(ids);
      return customFieldList.filter((field) =>
        idSet.has(
          filterType === ECustomFieldFilterType.INTERNAL_NUMBER
            ? field.metadata.internalNumber
            : field.id
        )
      );
    },
    [getCustomFieldsByType]
  );

  const translateCustomField = useCallback(
    (fieldName: string) => {
      const translationName = t(`CUSTOM_FIELDS.${fieldName}`);
      return translationName.includes('CUSTOM_FIELDS.') ? fieldName : translationName;
    },
    [t]
  );

  return {
    events: {
      getCustomFieldsByType,
      getCustomFieldsFilter,
      translateCustomField,
    },
    state: {
      customFieldsListBase,
      loading: customFieldsListBase.state === ESERVICES_STATUS_ATOM.LOADING,
    },
  };
};
