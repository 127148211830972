import { ReactNode, useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { useLanguage } from './application/hooks/useLanguage';
import { useVersion } from './application/hooks/useVersion';
import { useUserData } from './infrastructure/services/useUserData';

const Translations = ({ children }: { children: ReactNode }) => {
  const [userData] = useUserData();
  const { version } = useVersion();
  const { language } = useLanguage({ userData, version });
  const { i18n, ready } = useTranslation();

  useEffect(() => {
    if (language && i18n && ready) {
      void i18n.changeLanguage(language.toLowerCase());
    }
  }, [i18n, language, ready]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default Translations;
