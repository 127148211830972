import { useSetAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { CardAction } from '@/lib/v2/examples/CardAction';
import { CardActionProps } from '@/lib/v2/examples/CardAction/CardAction';

import {
  atomActionInputModalIsOpen,
  atomActionTypeName,
} from '@/modules/CampaignsModule/atoms/createActionsAtom';
import {
  RulesAPIIcon,
  RulesDateIcon,
  RulesExternalURLIcon,
  RulesFormIcon,
  RulesTagAssociatedIcon,
} from '@/modules/CampaignsModule/images/icons';
import { ActionTypeName } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface RulesTabPaneContainerProps {
  onClose?: () => void;
}

const TRANSLATE_RULES_TYPES = 'ALL_CARDS_ACTIONS.RULES_TYPES';

const RulesTabPaneContainer = ({ onClose }: RulesTabPaneContainerProps) => {
  const { t } = useTranslation();
  const setActionTypeName = useSetAtom(atomActionTypeName);
  const setActionInputModalIsOpen = useSetAtom(atomActionInputModalIsOpen);

  const handleOpenCreateActionNameModal = useCallback(
    (actionType: ActionTypeName) => {
      setActionTypeName(actionType);
      setActionInputModalIsOpen(true);
      onClose?.();
    },
    [onClose, setActionInputModalIsOpen, setActionTypeName]
  );

  const cardActionsList = useMemo<CardActionProps[]>(
    () => [
      {
        description: t(`${TRANSLATE_RULES_TYPES}.TYPES.rulesDate.description`),
        title: t(`${TRANSLATE_RULES_TYPES}.TYPES.rulesDate.title`),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'rules-date',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<RulesDateIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onClickAction: () => handleOpenCreateActionNameModal('email-trigger'),
      },
      {
        description: t(`${TRANSLATE_RULES_TYPES}.TYPES.tagAssociated.description`),
        title: t(`${TRANSLATE_RULES_TYPES}.TYPES.tagAssociated.title`),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'tag-associated',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<RulesTagAssociatedIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onClickAction: () => handleOpenCreateActionNameModal('email-trigger'),
      },
      {
        description: t(`${TRANSLATE_RULES_TYPES}.TYPES.externalURL.description`),
        title: t(`${TRANSLATE_RULES_TYPES}.TYPES.externalURL.title`),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'external-url',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<RulesExternalURLIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onClickAction: () => handleOpenCreateActionNameModal('email-trigger'),
      },
      {
        description: t(`${TRANSLATE_RULES_TYPES}.TYPES.fromAPI.description`),
        title: t(`${TRANSLATE_RULES_TYPES}.TYPES.fromAPI.title`),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'from-api',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<RulesAPIIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onClickAction: () => handleOpenCreateActionNameModal('email-trigger'),
      },
      {
        description: t(`${TRANSLATE_RULES_TYPES}.TYPES.fromForm.description`),
        title: t(`${TRANSLATE_RULES_TYPES}.TYPES.fromForm.title`),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'from-form',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<RulesFormIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onClickAction: () => handleOpenCreateActionNameModal('email-trigger'),
      },
    ],
    [t, handleOpenCreateActionNameModal]
  );

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
      {cardActionsList.map((cardAction) => {
        return <CardAction key={cardAction.id} {...cardAction} justifyActionButton="end" />;
      })}
    </div>
  );
};

export default memo(RulesTabPaneContainer);
