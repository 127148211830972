import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Flex, Text } from '@/lib/v2/components';

import { EmailForm } from './components/EmailForm';
import { MessageCardsList } from './components/EmailForm/components/MessageCardsList';
import { MessageDisplay } from './components/EmailForm/components/MessageDisplay';

import {
  atomActionInfoEmail,
  atomDisableEditAction,
  atomIsActionInfoEmailErrors,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomHeaderCurrentStep,
  atomOpenMessageModal,
  haveMessageAtom,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { useSimpleEmail } from '@/modules/CampaignsModule/hooks/useSimpleEmail';
import {
  EActionTypesPath,
  EStepHeaderAction,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { EActionStatusAvailable } from '@/modules/CampaignsModule/interfaces/Campaigns';

const EmailAction = () => {
  const { t } = useTranslation();
  const { actionId } = useParams();

  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);
  const haveMessage = useAtomValue(haveMessageAtom);
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const disableEditAction = useAtomValue(atomDisableEditAction);
  const setOpenModal = useSetAtom(atomOpenMessageModal);
  const setActionTypePath = useSetAtom(atomActionTypePath);
  const setHaveErrors = useSetAtom(atomIsActionInfoEmailErrors);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const { cardList } = useSimpleEmail();
  const { getEmailInfo, setActionState, deleteEmailTemplate, loading } = useActionEmailData(
    Number(actionId)
  );

  const { content: messageHTML } = actionInfoEmail || {};

  useLayoutEffect(() => {
    if (currentStep !== EStepHeaderAction.FIRST) setCurrentStep(EStepHeaderAction.FIRST);
  }, [currentStep, setCurrentStep]);

  useLayoutEffect(() => void getEmailInfo(), [getEmailInfo]);

  useEffect(() => setActionTypePath(EActionTypesPath.EMAIL), [setActionTypePath]);

  //TODO: add methods for different types of messages
  const handleDeleteMessage = useCallback(async () => {
    setIsLoadingDelete(true);
    await deleteEmailTemplate(Number(actionId));
    await getEmailInfo();
    await setActionState({ actionId: Number(actionId), statusId: EActionStatusAvailable.DRAFT });

    setIsLoadingDelete(false);
    setOpenModal(false);
  }, [actionId, deleteEmailTemplate, getEmailInfo, setActionState, setOpenModal]);

  useEffect(() => {
    setHaveErrors(
      messageHTML?.errors?.includes('NonExistentTags') ||
        messageHTML?.errors?.includes('NotEnoughTags') ||
        false
    );
  }, [messageHTML?.errors, setHaveErrors]);

  return (
    <Flex itemAlignment="start">
      <div className="my-12 w-1/2 bg-white px-10">
        {<EmailForm disableForm={disableEditAction} loading={loading} />}
      </div>
      <div className="flex min-h-[calc(100dvh-145px)] w-1/2 items-center justify-center bg-[#F4F5FA] ">
        {actionInfoEmail?.content.message || loading ? (
          actionInfoEmail?.content && (
            <MessageDisplay
              disabled={disableEditAction}
              html={actionInfoEmail?.content}
              isLoading={loading || isLoadingDelete}
              onDeleteMessage={handleDeleteMessage}
            />
          )
        ) : (
          <div className="self-start">
            <div>
              <Text
                className="my-12 text-center"
                color={!haveMessage ? 'primary' : 'alert'}
                fontWeight="medium"
                id="simple-email-create-content-label"
              >
                {t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.createContent`)}
              </Text>
            </div>
            <div className="px-10">
              <MessageCardsList cardsList={cardList} />
            </div>
          </div>
        )}
      </div>
    </Flex>
  );
};

export default EmailAction;
