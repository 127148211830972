import { useSetAtom } from 'jotai';
import { FormEvent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { SenderInput } from './components/SenderInput';
import { SubjectInput } from './components/SubjectInput';
import { TouchRulesInput } from './components/TouchRulesInput';
import { useEmailFormSubmit } from './hooks/useEmailFormSubmit';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomCallbackStep } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useEmailForm } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/hooks/useEmailForm';
import { RecipientDropdown } from '@/modules/CampaignsModule/components/RecipientDropdown';

const EmailForm = ({ loading, disableForm }: { loading: boolean; disableForm: boolean }) => {
  const { actionId } = useParams();

  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);
  const setCallbackStep = useSetAtom(atomCallbackStep);

  const { control, handleSubmit, setValue, getValues, RULES } = useEmailForm();

  const formProps = useMemo(
    () => ({ control, setValue, getValues }),
    [control, setValue, getValues]
  );
  const { handleSubmitData } = useEmailFormSubmit({ actionId: Number(actionId) });

  useEffect(() => {
    setCallbackStep(() => handleSubmit?.(handleSubmitData));
    return () => setGlobalLoadingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmitData]);

  return (
    <div className="visible-scrollbar h-[calc(100vh_-_240px)] overflow-y-auto overflow-x-hidden pb-4">
      <form
        autoComplete="off"
        className="pb-4 pr-3"
        id="create-actions"
        onSubmit={(event: FormEvent) => {
          event.preventDefault();
          handleSubmit(handleSubmitData);
        }}
      >
        <div className="mb-12">
          <SenderInput disabled={disableForm} form={formProps} loading={loading} rules={RULES} />
        </div>
        <div className="mb-12">
          <SubjectInput disabled={disableForm} form={formProps} loading={loading} rules={RULES} />
        </div>
        <div className="mb-9">
          <RecipientDropdown
            disabled={disableForm}
            form={formProps}
            loading={loading}
            rules={RULES}
          />
        </div>
        <div>
          <TouchRulesInput disabled={disableForm} form={formProps} />
        </div>
      </form>
    </div>
  );
};

export default EmailForm;
