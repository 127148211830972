import { useEffect, useState } from 'react';

import { CreateCheckoutBody } from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useProrationStripe = (body: CreateCheckoutBody, calculateProrations?: boolean) => {
  const service = useService();
  const [proration, setProration] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProration = async () => {
      if (!service || !calculateProrations) return;
      setIsLoading(true);
      const proration = await service.getProration(body);
      setProration(proration.totalAmount);
      setIsLoading(false);
    };

    void getProration();
  }, [calculateProrations, service]);

  return { prorationAmount: proration, isLoading };
};
