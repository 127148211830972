import classNames from 'classnames';
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { CrossSimpleIcon, WhiteMagnifyingGlassIcon } from '@/lib/icon';
import { Button, IconSvg, Input, InputProps } from '@/lib/v2/components';

import './InputSearch.tailwind.css';

export interface InputSearchProps extends InputProps {
  /** this function is triggered by clicking on the search button or enter key */
  onAction?: (value: string) => void;
  /** this function is triggered by clicking on the x or clear InputSearch value */
  onClear?: () => void;

  actionText?: string;

  standard?: boolean;
}

export interface CleanInputSearchRef {
  cleanInputSearch: () => void;
}

const InputSearch = forwardRef<CleanInputSearchRef, InputSearchProps>(
  (
    {
      onAction,
      onClear,
      onChange,
      value,
      defaultValue,
      id,
      actionText,
      standard,
      ...restOfProps
    }: InputSearchProps,
    ref
  ): JSX.Element => {
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const keyDownEnter = (e: KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;

      if (e.key === 'Enter') {
        onAction?.(target.value);
      }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const handlerAction = () => {
      const search = (inputRef.current as HTMLInputElement).value;
      onAction?.(search);
    };

    const handlerChange = (event: ChangeEvent<HTMLInputElement>) => {
      const search = (inputRef.current as HTMLInputElement).value;
      search.length > 0 ? setShowCloseIcon(true) : setShowCloseIcon(false);
      onChange?.(event);
    };

    const handlerClear = useCallback(() => {
      onClear?.();
      clearInputUncontrolled();
    }, [onClear]);

    const clearInputUncontrolled = () => {
      (inputRef.current as HTMLInputElement).value = '';
    };

    useImperativeHandle(ref, () => ({ cleanInputSearch: () => clearInputUncontrolled() }), []);

    const closeIcon = (): JSX.Element | undefined => {
      return value || showCloseIcon ? (
        <IconSvg svgComponent={<CrossSimpleIcon />} onClick={handlerClear} />
      ) : undefined;
    };

    const classes = classNames('eb-input-search');

    return (
      <div className={classes} data-testid="input-search-component" id={id}>
        <div className="grow">
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            iconRight={value && value.length > 0 ? closeIcon() : undefined}
            id="search"
            name="search"
            standard={standard}
            value={value}
            onChange={handlerChange}
            onKeyDown={keyDownEnter}
            {...restOfProps}
          />
        </div>
        <div className="my-1">
          <Button
            iconLeft={
              !actionText && (
                <IconSvg
                  fillColor={standard ? 'gray-dark' : undefined}
                  height="20px"
                  svgComponent={<WhiteMagnifyingGlassIcon />}
                  width="20px"
                />
              )
            }
            id={'search-button'}
            standard={standard}
            onClick={handlerAction}
          >
            {actionText ? actionText : ''}
          </Button>
        </div>
      </div>
    );
  }
);

export default memo(InputSearch);
