import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { getEnv } from './application/hooks/util/useEnv';
import { AuthModuleRouter } from './modules/AuthModule';

let rumInitialized = false;
const isSignUpView = window.location.pathname.includes('sign-up');
const Auth = () => {
  const { i18n, ready } = useTranslation();

  useEffect(() => {
    if (i18n && ready) {
      /** cspell:disable */
      const language = localStorage.getItem('idioma') ?? window.navigator.language;
      /** cspell:enable */
      void i18n.changeLanguage(language);
    }

    if (!rumInitialized && !isSignUpView) {
      datadogRum.init({
        clientToken: 'pub58d3aa31599b679aaf3b81890699ea40',
        applicationId: '5789c698-ac9f-491e-a297-a261e2c131fe',
        site: 'datadoghq.com',
        service: 'ema',
        env: getEnv() === 'production' ? 'prod' : getEnv(),
        version: 'v2.0.0',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackFrustrations: true,
        trackInteractions: true,
        trackUserInteractions: true,
      });
      rumInitialized = true;
    }

    datadogRum.startSessionReplayRecording && datadogRum.startSessionReplayRecording();
  }, [i18n, ready]);

  return (
    <I18nextProvider i18n={i18n}>
      <AuthModuleRouter />
    </I18nextProvider>
  );
};

export default Auth;
