import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { Avatar, SimpleBadge, StatusBadge, TextSkeleton } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Table } from '@/lib/components/Table';
import { Switch, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyContactsIcon, EmptyGroupIcon } from '@/lib/v2/icons/general';

import { NpsBadge } from '@/src/ContactsModule/components/Badges/NpsBadge';
import { ActivityIcon } from '@/src/ContactsModule/Icons/ActivityIcon';
import { ContactNameIcon } from '@/src/ContactsModule/Icons/ContactNameIcon';
import { DateIcon } from '@/src/ContactsModule/Icons/DateIcon';
import { PhoneIcon } from '@/src/ContactsModule/Icons/PhoneIcon';
import { TagIcon } from '@/src/ContactsModule/Icons/TagIcon';
import { ITableContactProps, ITag, TableContact } from '@/src/ContactsModule/interfaces/Interfaces';
import { NpsStatus } from '@/src/ContactsModule/utils/NpsStatus';
import { useDate } from '@/src/infrastructure/services/useDate';

export function ContactsTable({
  contactList,
  totalContacts,
  changeTableOrder,
  changeTablePage,
  totalPages,
  lastPageViewed,
  isLoading,
  gotoPageIndex,
  callToAction,
  setSelectedContacts,
  toggleAllRowsSelected,
  isDiscarded,
  tableOrderByList,
  setTableOrderByList,
  noNegativeMargin,
  classNamePagination,
  isEmptySegment,
  setLastPageViewed,
  pageIndex,
}: ITableContactProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dateFormatter } = useDate();
  const [npsEnabled, setNpsEnabled] = useState<boolean>(false);

  const goToContactView = (emailId: number) => {
    window.location.pathname.includes('segments')
      ? navigate(`../../${emailId}`)
      : navigate(`../${emailId}`);
  };

  const contactColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ContactNameIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('CONTACTS_TABLE.name')} </span>
        <SortTableArrow
          elementName={'Name'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'contact',
    id: 'contact',
    Cell: ({ cell: { value } }: { cell: { value: TableContact } }) => (
      <Flex withGap alignment="start">
        <Avatar
          email={value.email}
          lastName={value.lastName}
          name={value.name}
          status={value.status}
        />
        <Flex column noGrow>
          <span
            className="self-start truncate font-medium text-[#364365] md:max-w-[200px] 2xl:max-w-[300px]"
            title={`${value.name} ${value.lastName}`}
          >
            {' '}
            {value.name} {value.lastName}{' '}
          </span>
          <span
            className="self-start truncate text-[#364365] md:max-w-[200px] 2xl:max-w-[300px]"
            title={value.email}
          >
            {' '}
            {value.email}{' '}
          </span>
        </Flex>
      </Flex>
    ),
  };
  const smsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <PhoneIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('CONTACTS_TABLE.sms')} </span>
      </Flex>
    ),
    accessor: 'contact',
    id: 'sms',
    Cell: ({ cell: { value } }: { cell: { value: TableContact } }) => (
      <Flex withGap alignment="start">
        <span className={`${!value.phone && 'opacity-50'}`}>
          {' '}
          {value.phone
            ? value.phone.startsWith('+')
              ? value.phone.trim()
              : `+${value.phone.trim()}`
            : t('CONTACTS_TABLE.smsAlert')}{' '}
        </span>
      </Flex>
    ),
  };
  const tagsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TagIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('CONTACTS_TABLE.tags')} </span>
      </Flex>
    ),
    accessor: 'contact.tags',
    id: 'tags',
    width: '18%',
    Cell: ({ cell: { value } }: { cell: { value: ITag[] } }) => (
      <Flex withGap alignment="start" gapSize="small">
        {value && value.length === 0 && (
          <span className="opacity-50">{t('CONTACTS_TABLE.noTagsAssigned')}</span>
        )}
        {value &&
          value.length <= 3 &&
          value.map((tag: ITag) => (
            <SimpleBadge key={tag.id} shortened={10} text={tag.name} tooltip={tag.name} />
          ))}
        {value &&
          value.length > 3 &&
          value
            .slice(0, 3)
            .map((tag: ITag) => (
              <SimpleBadge key={tag.id} shortened={7} text={tag.name} tooltip={tag.name} />
            ))}
        {value && value.length > 3 && <SimpleBadge dotted />}
      </Flex>
    ),
  };
  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <Switch
          withLabels
          checked={npsEnabled}
          leftLabel={t('CONTACTS_TABLE.status')}
          rightLabel={t('HAPPINESS')}
          onChange={setNpsEnabled}
        />
      </Flex>
    ),
    accessor: 'contact',
    id: !isDiscarded ? 'status' : 'discarded',
    Cell: ({ cell: { value } }: { cell: { value: TableContact } }) => (
      <Flex alignment="start">
        {npsEnabled ? (
          <NpsBadge npsCampaign={value.npsCampaign} npsStatus={NpsStatus(value.npsScore)} />
        ) : !isDiscarded ? (
          <StatusBadge status={value.status} />
        ) : (
          <StatusBadge status={value.discarded} />
        )}
      </Flex>
    ),
  };
  const createdColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <DateIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('CONTACTS_TABLE.created')} </span>
        <SortTableArrow
          elementName={'Created'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'contact.created',
    id: 'created',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex alignment="start">
        <>{(value && dateFormatter(value)) || <TextSkeleton size="sm" />}</>
      </Flex>
    ),
  };
  const lastActivityColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ActivityIcon stroked tableSize />
        <span className="text-left text-[#004DBC]"> {t('CONTACTS_TABLE.lastActivity')} </span>
        <SortTableArrow
          elementName={'LastActivity'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'contact.lastActivity',
    id: 'lastActivity',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex alignment="start">
        <span className="text-[#364365]">
          <>
            {(value && dateFormatter(value)) || (
              <span className="opacity-50">{t('CONTACTS_TABLE.noLastSend')}</span>
            )}
          </>
        </span>
      </Flex>
    ),
  };
  const contactsTableColumns: Column<object>[] = [
    contactColumn,
    smsColumn,
    tagsColumn,
    statusColumn,
    createdColumn,
    lastActivityColumn,
  ].filter((x) => x) as Column<object>[];

  const EmptyScreenTableNoDataDescription = useMemo(
    () => (
      <>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('EMPTY_SCREEN_TABLE.emptyContactsDescription1')}
        </Text>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('EMPTY_SCREEN_TABLE.emptyContactsDescription2')}
          <button
            className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
            data-testid="nps-screen-empty-table-create-link"
            role="link"
            onClick={callToAction && callToAction[1]}
          >
            {t('EMPTY_SCREEN_TABLE.emptyContactsAction')}
          </button>
        </Text>
      </>
    ),
    [callToAction, t]
  );

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        paginationWithPadding
        classNamePagination={classNamePagination}
        columns={contactsTableColumns}
        data={contactList}
        emptyScreen={{
          isEmptySegment: isEmptySegment,
          totalCount: totalContacts || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.contacts')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              description={EmptyScreenTableNoDataDescription}
              image={<EmptyGroupIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyContactsTitle')}
            />
          ),
          noContactSegment: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyContactSegmentAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyContactSegmentDescription')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyContactSegmentTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        lastPageViewed={lastPageViewed}
        noNegativeMargin={noNegativeMargin}
        setSelectedRows={setSelectedContacts}
        toggleAllRowsSelectedValue={toggleAllRowsSelected}
        withCheckbox={true}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
        onRowClick={(value) => {
          setLastPageViewed(pageIndex);
          goToContactView(value.contact.emailId);
        }}
      ></Table>
    </div>
  );
}
