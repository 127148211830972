import { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DropDown } from '@/lib/components';
import { CleanGroupBoxRef, Select } from '@/lib/components/Select/Select';
import { ActivityCleanFilterIcon, ReasonsIcon } from '@/lib/icon';
import { Button, InputSearch } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { GroupIcon } from '@/src/ContactsModule/Icons/GroupIcon';
import { HappinessFilterIcon } from '@/src/ContactsModule/Icons/HappinessFilterIcon';
import { StatusIcon } from '@/src/ContactsModule/Icons/StatusIcon';
import { TagIcon } from '@/src/ContactsModule/Icons/TagIcon';
import { IGroup, IHappiness, IStatus, ITag } from '@/src/ContactsModule/interfaces/Interfaces';

import {
  useMutationContactsContext,
  useStateContactsContext,
} from '@/modules/ContactsModule/contexts/ContactsContext';

export interface IContactFilter {
  status?: IStatus[];
  happiness?: IHappiness[];
  groups?: { group: IGroup }[];
  tags?: ITag[];
  filterDiscarded?: (filter: string[]) => void;
  onReset?: () => void;
  filterDiscardedStatusSelected?: string[];
  cleanGroupBoxRef?: RefObject<CleanGroupBoxRef>;
  discardedView?: boolean;
  // Disable flags for group/tags views
  disableGroupDropdown?: boolean;
  disableTagDropdown?: boolean;
  disableStatusDropdown?: boolean;
  disableHappinessDropdown?: boolean;
  statusFilterAction?: string;
}

export const ContactsFilters = ({
  onReset,
  cleanGroupBoxRef,
  status,
  happiness,
  groups,
  tags,
  disableGroupDropdown,
  disableTagDropdown,
  disableStatusDropdown,
  disableHappinessDropdown,
  discardedView,
}: IContactFilter) => {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();
  const { searchQuery, tagsFilter, groupsFilter, statusFilter, discardedFilter, happinessFilter } =
    useStateContactsContext();
  const {
    setSearchQuery,
    setTagsFilter,
    setGroupsFilter,
    setStatusFilter,
    setDiscardedFilter,
    setHappinessFilter,
    setLastPageViewed,
  } = useMutationContactsContext();

  const [searchQueryHandler, setSearchQueryHandler] = useState(searchQuery);

  const isDiscardedView = window.location.pathname.includes('discarded');

  const resetOnSearch = () => {
    onReset && onReset();
    setSearchParams({}); // Reset context filters
  };

  const getOnSearch = () => {
    setLastPageViewed(0);
    setSearchQuery(searchQueryHandler ?? '');
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchQueryHandler(event.target.value);

  const handleClear = () => {
    setSearchQuery('');
    setSearchQueryHandler('');
    setLastPageViewed(0);
  };

  useEffect(() => {
    setSearchQueryHandler(searchQuery);
  }, [searchQuery]);

  const handleTags = (tagSelectedList: string[]) => {
    setTagsFilter(tagSelectedList.map((i) => Number(i)));
    setLastPageViewed(0);
  };

  const handleGroups = (groupSelectedList: string[]) => {
    setGroupsFilter(groupSelectedList.map((i) => Number(i)));
    setLastPageViewed(0);
  };

  const handleStatus = (statusSelectedList: string[]) => {
    setStatusFilter(statusSelectedList);
    discardedView && setDiscardedFilter(statusSelectedList);
    setLastPageViewed(0);
  };

  const handleHappiness = (happinessSelectedList: string[]) => {
    setHappinessFilter(happinessSelectedList);
    setLastPageViewed(0);
  };

  return (
    <Flex alignment="start" itemAlignment="center">
      <Flex withGap alignment="start" className="mb-[-90px] xl:max-w-[900px] 2xl:max-w-fit">
        {
          <div className="w-[340px]">
            <InputSearch
              id={'search-contacts'}
              placeHolder={t('CONTACTS_FILTERS.searchInputBar')}
              value={searchQueryHandler}
              onAction={getOnSearch}
              onChange={handleOnChange}
              onClear={handleClear}
            />
          </div>
        }
        {!disableStatusDropdown && (
          <>
            {
              <div className="w-auto">
                <DropDown
                  checkBox
                  overflowWidth
                  btnOnAction={handleStatus}
                  btnText={t('CONTACTS_FILTERS.apply')}
                  iconHead={isDiscardedView ? <ReasonsIcon /> : <StatusIcon stroked tableSize />}
                  itemList={status?.map((x) => ({
                    id: x.id,
                    title: t(`STATUS_FILTER.${x.name}`),
                  }))}
                  titleHead={
                    isDiscardedView
                      ? t('CONTACTS_FILTERS.statusDiscarded')
                      : t('CONTACTS_FILTERS.status')
                  }
                  tooltip={
                    isDiscardedView
                      ? t('CONTACTS_FILTERS.filterStatusDiscarded')
                      : t('CONTACTS_FILTERS.filterStatus')
                  }
                  totalSelectedChk={
                    discardedView
                      ? discardedFilter.filter((discarded) => discarded !== 'discarded').length
                      : statusFilter.length
                  }
                  valuesSelected={discardedView ? discardedFilter : statusFilter}
                />
              </div>
            }
          </>
        )}
        {!disableGroupDropdown && (
          <>
            {
              <div className="w-auto">
                <Select
                  ref={cleanGroupBoxRef}
                  clearValue
                  overflowWidth
                  search
                  defaultValue={groupsFilter.join()}
                  iconHead={<GroupIcon stroked tableSize />}
                  itemList={
                    groups &&
                    groups
                      .flat()
                      .flatMap((x) => ({
                        id: String(x.group.id),
                        title: x.group.groupName,
                        state: false,
                        icon: <GroupIcon tableSize />,
                      }))
                      .filter((notUndefined) => notUndefined.title !== undefined)
                  }
                  searchPlaceHolder={t('CONTACTS_FILTERS.search')}
                  title={t('CONTACTS_FILTERS.groups')}
                  onClick={handleGroups}
                />
              </div>
            }
          </>
        )}
        {!disableTagDropdown && (
          <>
            {
              <div className="w-auto">
                <DropDown
                  checkBox
                  overflowWidth
                  search
                  btnOnAction={handleTags}
                  btnText={t('CONTACTS_FILTERS.apply')}
                  iconHead={<TagIcon stroked tableSize />}
                  itemList={tags?.map((x) => ({
                    id: String(x.id),
                    title: x.name,
                    state: false,
                  }))}
                  searchPlaceHolder={t('CONTACTS_FILTERS.search')}
                  subtitle={t('CONTACTS_FILTERS.selectTags')}
                  titleHead={t('CONTACTS_FILTERS.tags')}
                  tooltip={t('CONTACTS_FILTERS.filterTags')}
                  totalSelectedChk={tagsFilter.length}
                  valuesSelected={tagsFilter.map((i) => String(i))}
                />
              </div>
            }
          </>
        )}
        {!disableHappinessDropdown && (
          <>
            {
              <div className="w-auto">
                <DropDown
                  checkBox
                  overflowWidth
                  btnOnAction={handleHappiness}
                  btnText={t('CONTACTS_FILTERS.apply')}
                  iconHead={<HappinessFilterIcon />}
                  id="happiness-filter"
                  itemList={happiness?.map((x) => ({
                    id: x.id,
                    title: t(`HAPPINESS_FILTER.${x.name}`),
                  }))}
                  titleHead={t('CONTACTS_FILTERS.happiness')}
                  tooltip={t('CONTACTS_FILTERS.filterHappiness')}
                  totalSelectedChk={happinessFilter?.length}
                  valuesSelected={happinessFilter}
                />
              </div>
            }
          </>
        )}
        {!(
          disableStatusDropdown &&
          disableGroupDropdown &&
          disableTagDropdown &&
          disableHappinessDropdown
        ) && (
          <div className="w-auto">
            <Button
              fullWidth
              standard
              iconLeft={<ActivityCleanFilterIcon color="#004DBC" />}
              id="clear-filters-button"
              tooltip={t('CONTACTS_FILTERS.cleanAllFilters')}
              onClick={resetOnSearch}
            ></Button>
          </div>
        )}
      </Flex>
    </Flex>
  );
};
