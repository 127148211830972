import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment } from 'react';

import { Flex, Text } from '@/lib/v2/components';

import { atomDisableEditAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomDeliveryType } from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  DELIVERY_OPTIONS,
  DeliveryOption,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface SchedulingSendingProps {
  deliveryOptions: DeliveryOption[];
  loading: boolean;
}
const SchedulingSending = ({ deliveryOptions, loading }: SchedulingSendingProps) => {
  const [deliveryTypeSelected, setDeliveryTypeSelected] = useAtom(atomDeliveryType);
  const disableEditAction = useAtomValue(atomDisableEditAction);

  const handleOptionClick = (id: string) => {
    if (disableEditAction) return;
    setDeliveryTypeSelected(id as DELIVERY_OPTIONS);
  };

  const optionSelectedClass = classNames('cursor-default pb-14 pt-9 border border-transparent', {
    '!border-emblue-primary': deliveryOptions.length > 1,
  });

  return (
    <Flex
      column
      withGap
      alignment="start"
      className="min-h-[calc(100dvh-145px)] px-32 py-10"
      gapSize="medium"
      itemAlignment="center"
    >
      {deliveryOptions.map(({ id, icon, title, description, children }) => {
        const isOptionSelected = id === deliveryTypeSelected;
        return (
          <Fragment key={id}>
            {/*eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
            <div
              key={id}
              className={`w-full max-w-[1024px] rounded-lg px-12 ${
                isOptionSelected
                  ? optionSelectedClass
                  : 'cursor-pointer border py-9 transition duration-200 hover:border-emblue-primary hover:bg-gray-50 hover:text-emblue-disabled'
              }`}
              data-id={id}
              id={`box__${id}`}
              role="button"
              tabIndex={0}
              onClick={() => handleOptionClick(id)}
            >
              <div
                className={`flex items-start justify-start gap-4 ${
                  isOptionSelected ? 'grayscale-0' : 'grayscale'
                }`}
              >
                {icon}
                <div className="flex flex-col">
                  <Text
                    className={`!text-18 ${
                      isOptionSelected ? '!text-emblue-primary' : '!text-emblue-subheadings'
                    }`}
                    fontWeight="bold"
                    variant="text"
                  >
                    {title}
                  </Text>
                  <Text className="!text-14" variant="sub-headline">
                    {description}
                  </Text>
                </div>
              </div>
              {isOptionSelected ? (
                <div
                  className={`${isOptionSelected ? 'flex' : 'hidden'} flex-col gap-5 pt-12`}
                  id="immediate-configuration"
                >
                  {loading ? (
                    <div className="w-full">
                      <section className="mb-12 animate-pulse">
                        <div className="mb-2.5 h-5 w-full rounded-full bg-gray-300 pb-2"></div>
                        <div className="mt-6">
                          <div className="w- mb-3 h-3 w-200 rounded-full bg-gray-300"></div>
                          <div className="h-3 w-300 rounded-full bg-gray-300 "></div>
                        </div>
                        <div className="mt-6">
                          <div className="w- mb-3 h-3 w-200 rounded-full bg-gray-300"></div>
                          <div className="h-3 w-300 rounded-full bg-gray-300 "></div>
                        </div>
                      </section>
                    </div>
                  ) : (
                    children
                  )}
                </div>
              ) : null}
            </div>
          </Fragment>
        );
      })}
    </Flex>
  );
};

export default SchedulingSending;
