/* cspell:disable*/
import { ChangeEvent, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Flex, Select, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';

import {
  TRANSLATE_RULES_CREATE_RULES,
  TRANSLATE_RULES_LABELS,
} from '@/src/modules/RulesModule/constants';

import { useConditionOptions } from './hooks/useConditionOptions';
import { useFormCondition } from './hooks/useFormCondition';
import { RecipientDropdownRules } from './components';

const FormCondition = () => {
  const { t } = useTranslation('rules');
  const { presenceOptions } = useConditionOptions();
  const { control, handleSubmit, handleCancel, RULES, errors, setValue, getValues } =
    useFormCondition();

  const formProps = useMemo(
    () => ({ control, setValue, getValues }),
    [control, setValue, getValues]
  );

  const actionsButtons = useMemo(
    () => (
      <>
        <Button outline secondary id="header-action__back" size="small" onClick={handleCancel}>
          {t(`${TRANSLATE_RULES_CREATE_RULES}.cancel`)}
        </Button>
        <Button id="header-action__continue" size="small" type="submit">
          {t(`${TRANSLATE_RULES_CREATE_RULES}.save`)}
        </Button>
      </>
    ),
    [handleCancel, t]
  );

  return (
    <>
      {/*eslint-disable-next-line @typescript-eslint/no-misused-promises*/}
      <form id="condition-form" onSubmit={handleSubmit}>
        <div className="pl-4 pr-2">
          <SectionHeader
            sticky
            withoutDivide
            actionsButtons={actionsButtons}
            isLoading={false}
            titleNode={
              <Text as="h3" className="!2xl:text-20 !text-16" fontWeight="medium" variant="title-1">
                <Flex withGap>{t(`${TRANSLATE_RULES_LABELS}.condition`)}</Flex>
              </Text>
            }
          />
          <hr className="w-full" />
        </div>
        <div className="p-8">
          <div className="mb-6 mr-8">
            <Controller
              control={control}
              name="presenceCondition"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t(`${TRANSLATE_RULES_LABELS}.recipients`)}
                  options={presenceOptions}
                  value={field.value}
                />
              )}
              rules={RULES.presenceCondition}
            />
            {errors.presenceCondition && (
              <Text color="alert" variant="text-sm">
                {errors?.presenceCondition?.message}
              </Text>
            )}
          </div>
          <div className="mr-8">
            <RecipientDropdownRules form={formProps} rules={RULES} />
            {errors.selectedGroupOrSegment && (
              <Text color="alert" variant="text-sm">
                {errors?.selectedGroupOrSegment?.message}
              </Text>
            )}
          </div>
          <div className="mt-8">
            <Controller
              control={control}
              name="touchRules"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  ref={ref}
                  checked={value}
                  id="touchRules"
                  label={
                    <Text variant="text">
                      {t(`${TRANSLATE_RULES_LABELS}.subordinate`)} <strong>Touch Rules</strong>
                    </Text>
                  }
                  onBlur={onBlur}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                />
              )}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default FormCondition;
