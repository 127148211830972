import classNames from 'classnames';
import { FC, memo, MouseEventHandler } from 'react';

import { RightArrowNextIcon } from '@/lib/icon';
import { LockIcon } from '@/lib/icon/LockIcon';
import { Button, Card, CardFooter, CardSection, IconSvg, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import './CardAction.tailwind.css';

type Justify = 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
type BadgeColors = 'primary' | 'success' | 'often' | 'sleepy' | 'alert' | 'insecure';
type lineClamps = 'line-clamp-2' | 'line-clamp-3';

export interface CardActionProps {
  /** Card Action title */
  title: string;
  /** Card Action description */
  description: string;
  /** Text button action */
  buttonText?: string;
  /** Controls the flex position of the action button. */
  justifyActionButton?: Justify;
  /** Button onclick callback function  */
  onClickAction?: MouseEventHandler<HTMLButtonElement>;
  /** Label onClick callback function */
  onLabelClick?: MouseEventHandler<HTMLButtonElement>;
  /** Card icon. Accept any component of type svg icon */
  icon?: JSX.Element;
  /** The withIconLeft property will change the structure of the CardAction (home card) placing the icon on the left and the section on the right */
  withIconLeft?: boolean;
  /** The secondaryIcon show icon at the top right */
  secondaryIcon?: JSX.Element;
  /** Text of the badge */
  badgeText?: string;
  /** Bg color of the badge */
  badgeColor?: BadgeColors;
  /** show the button disabled */
  disabled?: boolean;
  /** show label next to title */
  withLabel?: string;
  /** show tooltip to card */
  tooltip?: string;
  /** show the card if the widget is active */
  active?: boolean;
  /** set line-clamp to description*/
  descLineClamp?: lineClamps;
  /** show tooltip to button lock */
  btnTooltip?: string;
  btnActionId?: string;
  noShadow?: boolean;
  noBorder?: boolean;
  classNameSection?: string;
  id?: string;
}

const CardAction: FC<CardActionProps> = ({
  title = 'Title',
  description = 'Card action description',
  buttonText,
  justifyActionButton = 'end',
  onClickAction,
  onLabelClick,
  icon,
  withIconLeft = false,
  secondaryIcon,
  badgeText,
  badgeColor = 'primary',
  disabled,
  withLabel,
  tooltip,
  descLineClamp,
  btnTooltip,
  btnActionId,
  noShadow,
  noBorder,
  classNameSection,
  id,
}): JSX.Element => {
  const classesActionButton = classNames(`flex flex-row justify-${justifyActionButton}`);
  const classesSection = classNames(
    'flex flex-col gap-3 p-4 pb-0 h-full',
    {
      '!flex-row': withIconLeft,
      '!p-0': withIconLeft,
    },
    classNameSection
  );

  const ButtonAction = ({ btnActionId }: { btnActionId?: string }) => (
    <div className={classesActionButton}>
      <Button
        disabled={withLabel ? true : disabled}
        fullWidth={withIconLeft}
        iconRight={
          !withIconLeft && !disabled ? (
            <IconSvg fillColor="primary" svgComponent={<RightArrowNextIcon />} />
          ) : undefined
        }
        id={btnActionId}
        link={!withIconLeft}
        onClick={onClickAction}
      >
        {buttonText}
      </Button>
    </div>
  );

  return (
    <Card
      className="justify-between"
      id={id}
      noBorder={noBorder}
      noShadow={withIconLeft || noShadow}
      tooltip={tooltip}
    >
      <CardSection disabled={disabled}>
        <div className={classesSection}>
          <Flex noGrow alignment="spaceBetween">
            {icon && <div className="relative">{icon}</div>}
            {secondaryIcon && <div>{secondaryIcon}</div>}
          </Flex>
          <div className="flex w-full flex-col gap-2">
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex items-center gap-2">
                <Text fontWeight="bold" variant="sub-headline">
                  {title}
                </Text>
                {withLabel && (
                  <Button
                    outline
                    tertiary
                    className="absolute right-5 top-5"
                    size="small"
                    tooltip={btnTooltip}
                    onClick={onLabelClick}
                  >
                    {withLabel}
                  </Button>
                )}
              </div>
              <Text className={descLineClamp} color="light-black" variant="text">
                {description}
              </Text>
              {badgeColor && badgeText && (
                <span
                  className={`eb-badge-color-${badgeColor} inline-flex w-fit items-center gap-0 rounded-full px-1.5 py-0.5 text-12 font-medium`}
                >
                  {badgeText}
                </span>
              )}
            </div>
            {withIconLeft && <ButtonAction btnActionId={btnActionId} />}
          </div>
        </div>
      </CardSection>
      {!withIconLeft && buttonText && (
        <CardFooter>
          <ButtonAction btnActionId={btnActionId} />
        </CardFooter>
      )}
    </Card>
  );
};

export default memo(CardAction);
