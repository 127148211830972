/* eslint-disable @typescript-eslint/no-unused-vars */
import { timeout } from '@/src/compat/util';

import { IRulesService } from './IRulesService';

import { IRulesV1Response } from '@/modules/RulesModule/interfaces';

export class StubRulesService implements IRulesService {
  static create(): IRulesService {
    return new StubRulesService();
  }

  /**
   * @param payload: IRulesV1Response
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Save rules
   */
  async saveRule(payload: IRulesV1Response): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param payload: actionId: number
   * @returns {Promise<{ statusOK: boolean; data?: IRulesV1Response }>}
   * @description Get rule detail
   */
  async getRuleById(actionId: number): Promise<{ statusOK: boolean; data?: IRulesV1Response }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param payload: active: boolean; actionId: number
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Set touch rules
   */
  async setTouchRules(payload: {
    active: boolean;
    actionId: number;
  }): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }
}
