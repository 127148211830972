import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table, TextSkeleton } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import {
  ActivityEmailIcon,
  ActivityPushNotificationIcon,
  ActivitySmsIcon,
  ActivityTriggerIcon,
} from '@/lib/icon';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyScreenTable } from '@/lib/v2/examples';
import { EmptyActivityIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';

import {
  ChannelType,
  ITableContactProfileChannelProps,
} from '@/src/ContactsModule/interfaces/Interfaces';
import { useDate } from '@/src/infrastructure/services/useDate';

export function ContactsProfileChannelTable({
  activityList,
  activityTotalCount,
  changeTableOrder,
  changeTablePage,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
  tableOrderByList,
  setTableOrderByList,
}: ITableContactProfileChannelProps) {
  const { t } = useTranslation();
  const { dateFormatter } = useDate();

  const channelColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.channel')}</span>
      </Flex>
    ),
    accessor: 'channel',
    id: 'channel',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        {value === ChannelType.EMAIL && <ActivityEmailIcon color="#004DBC" />}
        {value === ChannelType.SMS && <ActivitySmsIcon color="#004DBC" />}
        {value === ChannelType.TRIGGER && <ActivityTriggerIcon color="#004DBC" />}
        {value === ChannelType.PUSH && <ActivityPushNotificationIcon color="#004DBC" />}
        <span className="self-start px-1 text-[#364365]">
          <strong>{`${t(`PROFILE_CHANNEL_TABLE.channelType.${value}`)}`}</strong>
        </span>
      </Flex>
    ),
  };
  const dateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.date')}</span>
        <SortTableArrow
          elementName={'Date'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'date',
    id: 'date',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          <>{(value && dateFormatter(value)) || <TextSkeleton size="sm" />}</>
        </span>
      </Flex>
    ),
  };
  const actionNameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.name')}</span>
        <SortTableArrow
          elementName={'ActionName'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'actionName',
    id: 'actionName',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };
  const activityTypeColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.activity')}</span>
      </Flex>
    ),
    accessor: 'activityType',
    id: 'activityType',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{`${t(
          `PROFILE_CHANNEL_TABLE.activityType.${value}`
        )}`}</span>
      </Flex>
    ),
  };
  const campaignColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.campaign')}</span>
      </Flex>
    ),
    accessor: 'campaignName',
    id: 'campaignName',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };

  const activityTableColumns: Column<object>[] = [
    channelColumn,
    dateColumn,
    actionNameColumn,
    activityTypeColumn,
    campaignColumn,
  ].filter((x) => x) as Column<object>[];

  return (
    <div className="h-full w-full text-base text-[#364365] 2xl:text-14">
      <Table
        // eslint-disable-next-line react/no-children-prop
        children={<></>}
        isPaginateOnOverflow
        classNamePagination="pr-4"
        columns={activityTableColumns}
        data={activityList}
        emptyScreen={{
          totalCount: activityTotalCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.activity')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityChannelAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityChannelDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyActivityTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        withCheckbox={false}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
      />
    </div>
  );
}
