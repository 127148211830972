import { useCallback, useState } from 'react';

import { Option } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  IEmailDetail,
  ISenderData,
} from '@/src/presentation/types/interfaces/ISenderData.interface';

import { SenderState, SenderStatus } from '@/modules/SendersModule/interfaces/Senders';

const DEFAULT_PAYLOAD = {
  search: '',
  limit: 100,
  page: 0,
};

export const useSender = () => {
  const [loading, setLoading] = useState(false);
  const [senderDropdownOptions, setSenderDropdownOptions] = useState<Option[]>([]);
  const service = useService();

  const getValidSenders = useCallback((senderList: ISenderData[]) => {
    return senderList?.filter((sender: ISenderData) => {
      const emailDetail = sender?.emailFrom?.details.find((detail) => detail.key === 'email');
      return emailDetail?.state === SenderState.Verify;
    });
  }, []);

  const getStatusSender = useCallback((sender: IEmailDetail[]) => {
    if (!sender) return SenderStatus.NoCheck;

    const emailItem = sender.find((item) => item.key === 'email');
    const otherItems = sender.filter((item) => item.key !== 'email');

    if (emailItem && emailItem.state === SenderState.NotVerify) return SenderStatus.NoCheck;

    const nonEmailItemsWarning = otherItems.some((item) =>
      [SenderState.NotVerify, SenderState.Incorrect, SenderState.NonExistent].includes(
        item.state as SenderState
      )
    );

    if (nonEmailItemsWarning) return SenderStatus.Warning;

    return SenderStatus.Verify;
  }, []);

  const setSenderOptionsDropdown = useCallback(
    (senderList: ISenderData[]) => {
      const validSenders = getValidSenders(senderList);

      const senderOptions = validSenders?.map((sender: ISenderData) => {
        const status = getStatusSender(sender?.emailFrom?.details as IEmailDetail[]);

        return {
          id: sender?.id,
          name: `${sender.name} <${String(sender?.emailFrom?.email)}>`,
          value: sender?.emailFrom?.email,
          iconStatus: status,
        };
      });

      setSenderDropdownOptions(senderOptions);
    },
    [getStatusSender, getValidSenders]
  );

  const getCurrentSender = useCallback((senderId: number, senderDropdownOptions: Option[]) => {
    return senderDropdownOptions?.find((option) => option.id === senderId);
  }, []);

  const getSenders = useCallback(async () => {
    setLoading(true);

    const response = await service.getSenders(DEFAULT_PAYLOAD);
    setSenderOptionsDropdown(response?.result || []);

    setLoading(false);
  }, [service, setSenderOptionsDropdown]);

  return {
    getSenders,
    senderDropdownOptions,
    getCurrentSender,
    loading,
  };
};
