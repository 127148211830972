import { FilterIcon } from '@heroicons/react/solid';

import { Button, IconSvg } from '@/lib/v2/components';
import DropdownCustom from '@/lib/v2/examples/DropdownCustom';
import { IDropdownCustom } from '@/lib/v2/examples/DropdownCustom/DropdownCustom';

export interface IDropdownFilterProps extends Omit<IDropdownCustom, 'action'> {
  id: string;
  label: string;
}

const DropdownFilter = ({ children, id, label, ...props }: IDropdownFilterProps) => {
  const OnAction = (
    <Button
      gray
      outline
      standard
      className="shadow-none"
      iconLeft={<IconSvg fillColor="gray-dark" svgComponent={<FilterIcon />} />}
      id={`button-${id}`}
      {...props}
    >
      {label}
    </Button>
  );

  return (
    <DropdownCustom {...props} action={OnAction}>
      {children}
    </DropdownCustom>
  );
};

export default DropdownFilter;
