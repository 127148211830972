import { Route, Routes } from 'react-router-dom';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import ActionsLayout from '@/src/modules/CampaignsModule/layouts/ActionsLayout';
import CampaignActionsLayout from '@/src/modules/CampaignsModule/layouts/CampaignActionsLayout';
import CampaignMainLayout from '@/src/modules/CampaignsModule/layouts/CampaignMainLayout';
import CampaignsLayout from '@/src/modules/CampaignsModule/layouts/CampaignsLayout';
import CatalogTemplatesLayout from '@/src/modules/CampaignsModule/layouts/CatalogTemplatesLayout';
import { ActionContent } from '@/src/modules/CampaignsModule/screens/ActionContent';
import { ActionPreview } from '@/src/modules/CampaignsModule/screens/ActionPreview';
import ActionReport from '@/src/modules/CampaignsModule/screens/ActionReport';
import { ActionsAll } from '@/src/modules/CampaignsModule/screens/ActionsAll';
import { ActionsDraft } from '@/src/modules/CampaignsModule/screens/ActionsDraft';
import { ActionsFinished } from '@/src/modules/CampaignsModule/screens/ActionsFinished';
import { CampaignActionsMain } from '@/src/modules/CampaignsModule/screens/CampaignActionsMain';
import { CampaignCalendarMain } from '@/src/modules/CampaignsModule/screens/CampaignCalendarMain';
import { CampaignsMain } from '@/src/modules/CampaignsModule/screens/CampaignsMain';
import { CatalogTemplates } from '@/src/modules/CampaignsModule/screens/CatalogTemplates';
import { EditorContent } from '@/src/modules/CampaignsModule/screens/EditorContent';
import { MyTemplates } from '@/src/modules/CampaignsModule/screens/MyTemplates';

import HtmlEditor from '@/modules/CampaignsModule/components/HtmlUpload/HtmlEditor';
import ActionManagementLayout from '@/modules/CampaignsModule/layouts/ActionManagementLayout';
import CampaignWithWrapperLayout from '@/modules/CampaignsModule/layouts/CampaignWithWrapperLayout';
import EmailMain from '@/modules/CampaignsModule/screens/ActionManagement/Email/EmailMain';
import EmailSchedulingMain from '@/modules/CampaignsModule/screens/ActionManagement/Email/EmailSchedulingMain/EmailSchedulingMain';
import { EmailSummaryMain } from '@/modules/CampaignsModule/screens/ActionManagement/Email/EmailSummaryMain';
import SmsMain from '@/modules/CampaignsModule/screens/ActionManagement/Sms/SmsMain';
import { SmsSchedulingMain } from '@/modules/CampaignsModule/screens/ActionManagement/Sms/SmsSchedulingMain';
import { SmsSummaryMain } from '@/modules/CampaignsModule/screens/ActionManagement/Sms/SmsSummaryMain';
import CampaignRecurrentActionsMain from '@/modules/CampaignsModule/screens/CampaignRecurrentActionsMain/CampaignRecurrentActionsMain';
import { CampaignRecurrentMain } from '@/modules/CampaignsModule/screens/CampaignRecurrentMain';
import ReportCampaign from '@/modules/CampaignsModule/screens/CampaignReport';
import RulesLayout from '@/modules/RulesModule/layouts/RulesLayout';
import RulesDetail from '@/modules/RulesModule/screens/RulesDetail';

const ActionsWrapperLayout = CampaignWithWrapperLayout(ActionsLayout);
const ActionReportWrapperLayout = CampaignWithWrapperLayout(ActionReport);
const EditorContentWrapperLayout = CampaignWithWrapperLayout(EditorContent);

export const CampaignsModuleRouter = () => {
  const createSimpleEmail = useFeatureFlag('createSimpleEmail');
  const createSimpleSMS = useFeatureFlag('createSimpleSMS');

  return (
    <CampaignMainLayout>
      <Routes>
        <Route element={<CampaignsLayout />} path="/">
          <Route index element={<CampaignsMain />} />
          <Route element={<CampaignRecurrentMain />} path="/recurrent" />
          <Route element={<ActionsAll />} path="/actions-all" />
          <Route element={<ActionsDraft />} path="/actions-draft" />
          <Route element={<ActionsFinished />} path="/actions-finished" />
        </Route>
        <Route element={<CampaignActionsLayout />} path="/:id">
          <Route index element={<CampaignActionsMain />} />
          <Route element={<ReportCampaign />} path="report" />
        </Route>
        <Route element={<CampaignsLayout />} path="/recurrent/:id">
          <Route index element={<CampaignRecurrentActionsMain />} />
        </Route>

        <Route element={<RulesLayout />} path="/rules/:actionId">
          <Route index element={<RulesDetail />} />
        </Route>

        {(createSimpleEmail || createSimpleSMS) && (
          <>
            {createSimpleEmail && (
              <Route element={<ActionManagementLayout />} path="/:campaignId">
                <Route element={<EmailMain />} path="email/:actionId" />
                <Route element={<EmailSchedulingMain />} path="email/:actionId/scheduling" />
                <Route element={<EmailSummaryMain />} path="email/:actionId/summary" />
              </Route>
            )}
            {createSimpleSMS && (
              <Route element={<ActionManagementLayout />} path="/:campaignId">
                <Route element={<SmsMain />} path="sms/:actionId" />
                <Route element={<SmsSchedulingMain />} path="sms/:actionId/scheduling" />
                <Route element={<SmsSummaryMain />} path="sms/:actionId/summary" />
              </Route>
            )}
            <Route element={<ActionsWrapperLayout />} path="/:campaignId">
              <Route element={<ActionContent />} path=":actionType/:actionId" />
            </Route>
          </>
        )}

        {!createSimpleEmail && !createSimpleSMS && (
          <Route element={<ActionsWrapperLayout />} path="/:campaignId">
            <Route element={<ActionContent />} path=":actionType/:actionId" />
          </Route>
        )}

        <Route
          element={<ActionReportWrapperLayout />}
          path=":campaignId/:actionType/:actionId/report"
        />
        <Route
          element={<EditorContentWrapperLayout />}
          path=":campaignId/:actionType/:actionId/editor"
        />
        <Route element={<HtmlEditor />} path=":campaignId/:actionType/:actionId/html-editor" />
        <Route element={<ActionPreview />} path=":campaignId/:actionType/:actionId/preview" />
        <Route element={<CampaignCalendarMain />} path="/calendar" />
        <Route element={<CatalogTemplatesLayout />} path="/catalogs">
          <Route element={<CatalogTemplates />} path="email/:actionId" />
          <Route element={<MyTemplates />} path="email/:actionId/my-templates" />
        </Route>
      </Routes>
    </CampaignMainLayout>
  );
};
