import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { atomInfoCampaignV1 } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomRefetchAllData,
  atomRefetchDetailData,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomModalTypeProcess } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignRecurrent } from '@/modules/CampaignsModule/hooks/useCampaignRecurrent';
import { ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IUseHandleEventsProps {
  onClose: () => void;
}

export const useHandleEvents = ({ onClose }: IUseHandleEventsProps) => {
  const { createCampaignRecurrent, loading } = useCampaignRecurrent();
  const navigate = useNavigate();

  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const setRefetchDetailData = useSetAtom(atomRefetchDetailData);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);

  const handleCloseModal = useCallback(() => onClose(), [onClose]);

  const handleSubmitData = useCallback(
    async (data: { name: string }) => {
      const { campaignId, actionId } = await createCampaignRecurrent(data.name.trim());
      setRefetchDetailData(true);
      handleCloseModal();
      setRefetchAllData(true);
      setInfoCampaignV1({
        campaign: {
          id: Number(campaignId),
          name: data.name ?? '',
        },
        action: {
          id: actionId,
          name: data.name,
        },
        route: `/v2/campaigns/${campaignId}`,
      });
      if (modalTypeProcess === ModalType.CREATE) {
        navigate(`/v2/campaigns/${campaignId}/master/${actionId}`);
      }
    },
    [
      createCampaignRecurrent,
      setRefetchDetailData,
      handleCloseModal,
      setRefetchAllData,
      setInfoCampaignV1,
      modalTypeProcess,
      navigate,
    ]
  );

  return {
    loading,
    handleCloseModal,
    handleSubmitData,
  };
};
