import { Edge, Node } from 'reactflow';

import { generateUniqueId } from '@/src/modules/RulesModule/utils/generateUniqueId';

export const INITIAL_NODES: Node[] = [
  {
    id: generateUniqueId(),
    position: { x: 0, y: 0 },
    data: {
      title_detail: {
        title_text: 'First Node',
        icon: 'icon',
      },
      data: {
        nature: 'AND',
        rules: [
          {
            description: {
              html: 'First Node',
            },
            configuration_name: 'string',
            configuration_parameters: {
              tag_id: '',
              operator: 'equals',
            },
          },
        ],
      },
      type: 'trigger',
      reactflowType: 'trigger',
      id: generateUniqueId(),
      parent: null,
      children: [],
      is_root: true,
      is_leaf: false,
      is_valid: true,
      is_deleted: false,
      errors: {},
    },
    type: 'trigger',
  },
];

export const PLACEHOLDER_NODE: Node = {
  id: generateUniqueId(),
  data: {
    title_detail: {
      title_text: 'Add Node',
      icon: 'icon',
    },
    data: {
      description: {
        html: 'Add Node',
      },
      configuration_name: 'string',
      configuration_parameters: {
        tag_id: '',
      },
    },
    type: 'placeholder',
    reactflowType: 'placeholder',
    id: generateUniqueId(),
    parent: INITIAL_NODES[0].id,
    children: [],
    is_root: false,
    is_leaf: true,
    is_valid: true,
    is_deleted: false,
    errors: {},
  },
  position: { x: 0, y: 150 },
  type: 'placeholder',
};

export const INITIAL_EDGES: Edge[] = [
  {
    id: generateUniqueId(),
    source: INITIAL_NODES[0].id,
    target: PLACEHOLDER_NODE.id,
    type: 'buttonEdge',
    data: {
      showButton: false,
    },
  },
];
