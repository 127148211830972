import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';

import {
  atomEmailPreview,
  atomPreviewContactId,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { IEmailPreview } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { cleanContentFromCustomFields } from '@/modules/CampaignsModule/utils/cleanContentFromCustomFields';

export const useActionPreview = () => {
  const { actionId } = useParams();

  const [emailPreview, setEmailPreview] = useAtom(atomEmailPreview);
  const previewContactId = useAtomValue(atomPreviewContactId);

  const [loading, setLoading] = useState<boolean>(false);

  const campaignService = useCampaignsService();

  const getEmailPreviewWithoutEmailId = useCallback(async (): Promise<IEmailPreview> => {
    const emailInfoResponse = await campaignService.getEmailInfo(Number(actionId));
    const { emailInfo: emailDetails, content, sender } = emailInfoResponse || {};

    const infoEmailPreview: IEmailPreview = {
      subject: cleanContentFromCustomFields(emailDetails?.subject ?? ''),
      content: {
        editorVersion: content?.editorVersion ?? '',
        footer: cleanContentFromCustomFields(content?.footer ?? ''),
        header: content?.header ?? '',
        message: cleanContentFromCustomFields(content?.message ?? ''),
      },
      sender: {
        id: sender?.id ?? 0,
        nameFrom: sender?.nameFrom ?? '',
        emailFrom: sender?.emailFrom ?? '',
      },
    };

    return infoEmailPreview;
  }, [actionId, campaignService]);

  const getEmailPreviewWithEmailId = useCallback(
    async (emailContactId: number): Promise<IEmailPreview> => {
      const infoEmailPreview = (await campaignService.getPreviewWithEmailID(
        Number(actionId),
        Number(emailContactId)
      )) as IEmailPreview;
      return infoEmailPreview;
    },
    [actionId, campaignService]
  );

  const getEmailPreview = useCallback(
    async (emailContactId: number) => {
      setLoading(true);
      const infoEmailPreview =
        emailContactId !== 0
          ? await getEmailPreviewWithEmailId(emailContactId)
          : await getEmailPreviewWithoutEmailId();
      setEmailPreview(infoEmailPreview);
      setLoading(false);
    },
    [getEmailPreviewWithEmailId, getEmailPreviewWithoutEmailId, setEmailPreview]
  );

  useEffect(() => {
    if (previewContactId === undefined) return;
    void getEmailPreview(Number(previewContactId));
    return () => {
      setEmailPreview(undefined);
    };
  }, [getEmailPreview, setEmailPreview, previewContactId]);

  const buildHtmlString = useCallback(() => {
    const { content } = emailPreview || {};

    if (content?.header && content.message && content.footer) {
      return `
      <div>
        ${content.header}
        <div>
          ${content.message}
        </div>
        ${content.footer}
      </div>
    `;
    }
  }, [emailPreview]);

  return {
    emailPreview,
    htmlString: buildHtmlString(),
    getEmailPreview,
    loading,
    previewContactId,
  };
};
